import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UploadFileService } from '../../providers/upload.service';
import { HttpEventType } from '@angular/common/http';
import { ConfigurationService } from '../../providers/configuration.service';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
var ExpeditionComponent = /** @class */ (function () {
    function ExpeditionComponent(dialog, drService, mdrService, router, snackBar, uploadService, configService, dpipe) {
        this.dialog = dialog;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.uploadService = uploadService;
        this.configService = configService;
        this.dpipe = dpipe;
        this.data = [];
        this.barcodeValue = false;
        this.mdr = null;
        this.viewOnly = false;
        this.fileUploaded = null;
        this.searchBy = null;
        this.expeditionList = [];
        this.expeditionFocus = [];
        this.files = {};
    }
    ExpeditionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configService.getConfiguration('expedition').subscribe(function (response) {
            // console.log(response);
            _this.expeditionFocus = response['expedition'];
            // console.log(this.expeditionFocus);
        });
        // console.log(this.mdr);
        if (this.mdr !== null) {
            this.viewOnly = true;
            this.searchBy = this.mdr.pickupID;
            this.search();
        }
    };
    ExpeditionComponent.prototype.openBarcode = function () {
        this.barcodeValue = true;
        this.barecodeScanner.start();
    };
    ExpeditionComponent.prototype.onValueChanges = function (result) {
        this.searchBy = result.codeResult.code;
        this.search();
        this.barcodeValue = false;
        this.barecodeScanner.stop();
    };
    ExpeditionComponent.prototype.onStarted = function (started) {
        // console.log(started);
    };
    ExpeditionComponent.prototype.receiveFile = function (res, type) {
        var _this = this;
        this.uploadService.pushFileToStorage([res], this.mdr.id + '', 'expedition').subscribe(function (event) {
            if (HttpEventType.UploadProgress === event.type) {
                // this.progress.percentage = Math.round(100 * event.loaded / event.total);
            }
            else if (event.type === HttpEventType.Response) {
                // Criar File Info;
                // console.log('File is completely uploaded!');
                var k = JSON.parse(event.body + '');
                // console.log(k);
                var fileInfo = {};
                fileInfo['info_name'] = type;
                fileInfo['info_id'] = k['idFile'];
                fileInfo['info_timeDeliveredAt'] = new Date();
                _this.uploadService.updateFileInfo(_this.mdr.id, fileInfo).subscribe(function (elem) {
                    _this.search();
                });
            }
        });
    };
    ExpeditionComponent.prototype.search = function () {
        var _this = this;
        this.mdrService.getMDRbyPickupId(this.searchBy).subscribe(function (elem) {
            // console.log(elem);
            // TRATAR se tiver mais de uma MDR. (O que fazer?)
            if (elem.mdr !== null) {
                // console.log("HI");
                _this.mdr = elem.mdr[0];
                _this.expeditionList = [];
                for (var item in _this.expeditionFocus) {
                    if (item) {
                        _this.expeditionList.push({ name: _this.expeditionFocus[item], path: '' });
                    }
                }
                elem.file.forEach(function (element) {
                    if (element['fileI'] !== null && element['fileI'].name) {
                        var k = _this.expeditionList.findIndex(function (e) { return e.name === element['fileI'].name; });
                        if (k !== -1) {
                            _this.expeditionList[k] = { name: element['fileI'].name, path: element['file'].path };
                        }
                    }
                });
            }
            else {
                _this.snackBar.open('Expedição não encontrada', 'OK', { duration: 5000 });
                _this.expeditionList = [];
                _this.mdr = null;
            }
        });
    };
    return ExpeditionComponent;
}());
export { ExpeditionComponent };
