import { OnInit } from '@angular/core';
import { Chart } from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
var PieChartComponent = /** @class */ (function () {
    function PieChartComponent(translateService, excelService) {
        this.translateService = translateService;
        this.excelService = excelService;
        this.colors = ['#8ed4c6', '#b4bae8', '#ebe095', '#9bdeb2', '#addded', '#eedab8'];
        this.total = 0;
        this.data = [];
        this.downloadData = [];
    }
    PieChartComponent.prototype.titleSize = function (width) {
        switch (width) {
            case 1:
                return '30px';
        }
        if (width !== 1) {
            return '10px';
        }
    };
    PieChartComponent.prototype.getClientColor = function (i) {
        return this.colors[i];
    };
    PieChartComponent.prototype.download = function () {
        this.excelService.exportAsExcelFile(this.downloadData, 'drs');
    };
    PieChartComponent.prototype.createChart = function (data, id, download) {
        if (download) {
            this.downloadData = download;
        }
        this.data = data;
        this.total = this.data.map(function (client) { return client.y; }).reduce(function (a, b) { return a + b; });
        this.chart = new Chart({
            title: {
                verticalAlign: 'middle',
                text: '<b style="font-family: Roboto; font-weight: bold; color: #404040;">' + ("" + this.total) + '</b>' + '<br>' + 'total',
            },
            chart: {
                type: 'pie',
                renderTo: id,
                events: {
                    redraw: function () {
                        var title = this.title;
                        title.attr({
                            y: this.plotTop + this.plotHeight / 2,
                            x: this.plotLeft + this.plotWidth / 2
                        });
                    }
                }
            },
            legend: {
                enabled: true,
                verticalAlign: 'middle',
                layout: 'vertical',
                align: 'right',
                labelFormat: '{name}' + '<br>' + '<b>' + '{y}' + '</b>',
                squareSymbol: false,
                symbolHeight: 5,
                symbolWidth: 15,
                symbolRadius: 5,
                symbolPadding: 5,
                itemStyle: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    useHtml: true,
                    width: 70,
                }
            },
            tooltip: {
                followPointer: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    dataLabels: {
                        useHTML: true,
                        formatter: function () {
                            var color = ['#09A081', '#5768C8', '#D3B91C', '#26B356', '#4DB4D5', '#D8AA65'];
                            return '<span style="font-size: 15px; color: ' + color[this.point.colorIndex] + '">' + this.percentage.toFixed(0) + '%</span>';
                        },
                        distance: '-30%',
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 0
                        }
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            },
            colors: this.colors,
            series: [{
                    innerSize: '50%',
                    name: this.seriesType,
                    colorByPoint: true,
                    type: undefined,
                    data: data
                }],
            responsive: {
                rules: [{
                        condition: {
                            maxWidth: 330
                        },
                        chartOptions: {
                            legend: {
                                verticalAlign: 'top',
                                layout: 'horizontal',
                                align: 'center',
                            },
                        }
                    }, {
                        condition: {
                            maxWidth: 200
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    dataLabels: {
                                        enabled: false,
                                    }
                                }
                            },
                            title: {
                                text: '<b style="font-family: Roboto; font-weight: bold; font-size: 11px; color: #404040;">' + ("" + this.total) + '</b>' + '<br>' + '<b style="font-weight: normal; font-size: 11px;">' + 'total' + '</b>',
                            }
                        }
                    }]
            }
        });
        this.chart.reflow();
    };
    PieChartComponent.prototype.ngOnInit = function () {
    };
    return PieChartComponent;
}());
export { PieChartComponent };
