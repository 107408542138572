import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from '../../providers/auth.service';
var UserActivityComponent = /** @class */ (function () {
    function UserActivityComponent(dialog, router, snackBar, auth) {
        this.dialog = dialog;
        this.router = router;
        this.snackBar = snackBar;
        this.auth = auth;
        this.columns = [
            { name: 'USER_ACTIVITY/table/name', value: 'name' },
            { name: 'USER_ACTIVITY/table/email', value: 'email' },
            { name: 'USER_ACTIVITY/table/last-login', value: 'lastLogin' },
            { name: 'USER_ACTIVITY/table/last-reset', value: 'lastPasswordReset' },
            { name: 'USER_ACTIVITY/table/deleted-by', value: 'deletedBy' },
            { name: 'USER_ACTIVITY/table/deleted-on', value: 'deletedOn' },
            { name: 'USER_ACTIVITY/table/delete', value: 'icon', icon: 'icon', color: this.styleIcon },
        ];
        this.data = [];
        this.role = -1;
        this.toRemove = [];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        if (this.role !== 1) {
            this.snackBar.open('Você não possui as permissões necessárias para visualizar esta página', 'Ok', { duration: 4000 });
            this.router.navigate(['/']);
        }
        this.getData();
    }
    UserActivityComponent.prototype.ngOnInit = function () { };
    UserActivityComponent.prototype.getData = function () {
        var _this = this;
        this.auth.getUserActivities().subscribe(function (response) {
            _this.data = response.map(function (row) {
                // convert values to Date
                row.lastLogin = (row.lastLogin ? new Date(row.lastLogin) : null);
                row.lastReset = (row.lastReset ? new Date(row.lastReset) : null);
                row.deletedOn = (row.deletedOn ? new Date(row.deletedOn) : null);
                // allow removing only non-role-1
                if (row.user !== null && row.user.role !== 1) {
                    row.icon = 'person_remove';
                }
                return row;
            });
        }, function (err) {
            _this.snackBar.open('Erro ao carregar dados', 'Ok', { duration: 4000 });
        });
    };
    UserActivityComponent.prototype.styleIcon = function (row, that) {
        if (row.icon) {
            return 'red';
        }
    };
    UserActivityComponent.prototype.removeUser = function (row, that) {
        // can't remove already removed users
        // or role-1 users
        if (row.user === null || row.user.role === 1) {
            return;
        }
        // show confirmation modal
        var dialogRef = that.dialog.open(ConfirmRemoveUserComponent, {});
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === true) {
                that.auth.removeUser(row.user.email)
                    .subscribe(function (response) {
                    that.snackBar.open("Usu\u00E1rio removido com sucesso [" + row.user.email + "]", 'Ok', { duration: 4000 });
                    that.getData();
                }, function (err) {
                    that.snackBar.open("Erro ao remover usu\u00E1rio [" + row.user.email + "]", 'Ok', { duration: 4000 });
                });
            }
        });
    };
    return UserActivityComponent;
}());
export { UserActivityComponent };
var ConfirmRemoveUserComponent = /** @class */ (function () {
    function ConfirmRemoveUserComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ConfirmRemoveUserComponent.prototype.onClickOk = function () {
        this.dialogRef.close(true);
    };
    ConfirmRemoveUserComponent.prototype.onClickBack = function () {
        this.dialogRef.close(false);
    };
    return ConfirmRemoveUserComponent;
}());
export { ConfirmRemoveUserComponent };
