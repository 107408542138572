import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {DR} from '../models/dr';

const base_url: string = environment.apiUrl;

@Injectable()
export class PriceMakerService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getPrice(price): Observable<any> {
    // console.log(price);
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   // console.log(token);

    return this.http.post(base_url + 'PM/getPriceMaker', {
      price: price
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  getPriceTable(vehicle: string, distance: number, fromEstate: string, toEstate: string, subRegion: string, value: number, drAmount: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'PM/getPriceTable', {
      vehicle: vehicle,
      km: distance,
      fromEstate: fromEstate,
      toEstate: toEstate,
      subRegion: subRegion,
      value: value,
      drAmount: drAmount
    }, {headers: header}).map( (response) => {
      return response;
    });
  }

  getAirPriceTable(fromEstate: string, toEstate: string, deliveryDestination: string, height: number, width: number, depth: number, weight: number, value: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'PM/getAirPriceTable', {
      fromEstate: fromEstate,
      toEstate: toEstate,
      deliveryDestination: deliveryDestination,
      value: value,
      weight: weight,
      height: height,
      width: width,
      depth: depth,
    }, {headers: header}).map( (response) => {
      return response;
    });
  }

  getUOCTable(vehicle: string, distance: number, fromEstate: string, toEstate: string, subRegion: string, value: number, drAmount: number, uoc: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'UOC/getUOCTable', {
      vehicle: vehicle,
      km: distance,
      fromEstate: fromEstate,
      toEstate: toEstate,
      subRegion: subRegion,
      value: value,
      drAmount: drAmount,
      service: uoc
    }, {headers: header}).map( (response) => {
      return response;
    });
  }

  tableRead(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'PM/table/read', {headers: header}).map( (response) => {
      return response;
    });
  }

  tableDelete(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.delete(base_url + 'PM/table/delete/' + id, {headers: header}).map( (response) => {
      return response;
    });
  }

  tableUpdate(pm: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'PM/table/update', pm, {headers: header}).map( (response) => {
      return response;
    });
  }

  tableCreate(pm: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'PM/table/create', pm, {headers: header}).map( (response) => {
      return response;
    });
  }

  getIcmsStates(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'PM/icms/states', {headers: header});
  }

  getAirFreightDestinations(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'PM/airFreight/destinations', {headers: header});
  }
}
