import { Observable } from 'rxjs/Observable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var ExcelService = /** @class */ (function () {
    function ExcelService() {
    }
    ExcelService.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    ExcelService.prototype.exportAsExcelFileWithMultipleSheets = function (json, excelFileName) {
        // create workbook object
        var workbookObj = { Sheets: {}, SheetNames: [] };
        for (var _i = 0, _a = Object.keys(json); _i < _a.length; _i++) {
            var key = _a[_i];
            // create a new sheet for each key
            var worksheet = XLSX.utils.json_to_sheet(json[key]);
            workbookObj.Sheets[key] = worksheet;
            workbookObj.SheetNames.push(key);
        }
        var workbook = workbookObj;
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    ExcelService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + ' ' + this.getTodaysDate() + EXCEL_EXTENSION);
    };
    ExcelService.prototype.getTodaysDate = function () {
        var d = new Date();
        return d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
    };
    ExcelService.prototype.importFromExcel = function (ev) {
        var workbook;
        var excelInJSON;
        var fileReader = new FileReader();
        // init read
        fileReader.readAsArrayBuffer(ev.target.files[0]);
        return new Observable(function (observer) {
            // if success
            fileReader.onload = function (file) {
                var binary = '';
                var bytes = new Uint8Array(file.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                // Converts the excel data in to json
                workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
                // only first sheet
                excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                observer.next(excelInJSON);
            };
            // if failed
            fileReader.onerror = function (error) { return observer.error(error); };
        });
    };
    return ExcelService;
}());
export { ExcelService };
