import { Routes } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';
import { CockpitComponent } from './cockpit.component';
var routes = [
    { path: 'cockpit', component: CockpitComponent, canActivate: [RoleGuardService] }
];
var CockpitRoutingModule = /** @class */ (function () {
    function CockpitRoutingModule() {
    }
    return CockpitRoutingModule;
}());
export { CockpitRoutingModule };
