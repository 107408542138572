import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var HubService = /** @class */ (function () {
    function HubService(http) {
        this.http = http;
        this.cache = {};
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    HubService.prototype.getAll = function (active) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('active', String(active));
        return this.http.get(base_url + 'hub/all', { headers: header, params: params })
            .map(function (response) {
            return response;
        });
    };
    HubService.prototype.getFromHub = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'hub/' + id, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    HubService.prototype.getHub = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'hub/one/' + id, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    HubService.prototype.create = function (name, lat, lng, address, shippingCompany) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'hub/new', { name: name, lat: lat, lng: lng, address: address, shippingCompany: shippingCompany }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    HubService.prototype.createHubDr = function (hubdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'hub/' + hubdr.hubID + '/' + hubdr.drID, { hub: hubdr }, { headers: header })
            .map(function (response) {
            // console.log(response);
            return response;
        });
    };
    HubService.prototype.removeHubDr = function (hubdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'hub/' + hubdr.hubID + '/' + hubdr.drID, { headers: header })
            .map(function (response) {
            // console.log(response);
            return response;
        });
    };
    HubService.prototype.removeHub = function (hubid) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'hub/' + hubid, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    return HubService;
}());
export { HubService };
