/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mdr-lsp.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../misc/table/table.component.ngfactory";
import * as i4 from "../../misc/table/table.component";
import * as i5 from "../../services/xlsx.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/router";
import * as i8 from "ngx-cookie-service";
import * as i9 from "./mdr-lsp.component";
import * as i10 from "../../providers/mdr.service";
var styles_MdrLspComponent = [i0.styles];
var RenderType_MdrLspComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MdrLspComponent, data: {} });
export { RenderType_MdrLspComponent as RenderType_MdrLspComponent };
export function View_MdrLspComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "colored-background table-background px-5 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-table", [], null, null, null, i3.View_TableComponent_0, i3.RenderType_TableComponent)), i1.ɵdid(6, 770048, null, 0, i4.TableComponent, [i5.XlsxService, i6.MatDialog, i7.Router, i8.CookieService], { parent: [0, "parent"], columns: [1, "columns"], data: [2, "data"], clickCallback: [3, "clickCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co; var currVal_2 = _co.columns; var currVal_3 = _co.mdr; var currVal_4 = _co.onRowClick; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MDR_LSP/title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_MdrLspComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mdr-lsp", [], null, null, null, View_MdrLspComponent_0, RenderType_MdrLspComponent)), i1.ɵdid(1, 114688, null, 0, i9.MdrLspComponent, [i7.ActivatedRoute, i10.MdrService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MdrLspComponentNgFactory = i1.ɵccf("app-mdr-lsp", i9.MdrLspComponent, View_MdrLspComponent_Host_0, {}, {}, []);
export { MdrLspComponentNgFactory as MdrLspComponentNgFactory };
