import { OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../providers/configuration.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(auth, router, occService) {
        this.auth = auth;
        this.router = router;
        this.occService = occService;
        this.show = false;
        this.openOccurrence = '0';
        this.menuItems = [
            { name: 'MENU/REQUISITION', icon: 'fa-clipboard', link: '/new-delivery-request' },
            { name: 'MENU/OPTIMIZER', icon: 'fa-cogs', link: '/delivery-optimizer' },
            { name: 'MENU/OCCURRENCE', icon: 'fa-envelope-open-text', link: '/occurrence-list', type: 'badge' },
            { name: 'MENU/NEW_MDR', icon: 'fa-shield-alt', link: '/mdr-management' },
            { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
            { name: 'MENU/MAP_MDR', icon: 'fa-map', link: '/mdr-map' },
            { name: 'MENU/TOWER_CONTROL', icon: 'fa-broadcast-tower', link: '/control-tower' },
            { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
            // Gerenciamento Operacional
            { name: 'MENU/WAREHOUSE', icon: 'fa-warehouse', link: '/hub' },
            { name: 'MENU/PRICE_MAKER_TABLE', icon: 'fa-tags', link: '/price-maker/table' },
            { name: 'MENU/ADD_LSP_USER', icon: 'fa-users-cog', link: '/lsp-user-admin' },
            // Gerenciamento de Arquivos
            { name: 'MENU/UPLOAD', icon: 'fa-cloud-upload-alt', link: '/insert-new-data' },
            { name: 'MENU/BULK_NFE_UP', icon: 'fa-file-upload', link: '/bulk-nfe-upload' },
            { name: 'MENU/INSERT_NFE', icon: 'fa-edit', link: '/insert-nfe' },
            { name: 'Lista Nota Fiscal', icon: 'fa-folder-open', link: '/nfe-list' },
            { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
            { name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list' },
            // SI (...)
            { name: 'MENU/DASHBOARD', icon: 'fa-chart-line', link: '/dashboard' },
            { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
            { name: 'MENU/VEHICLE_CALCULATOR', icon: 'fa-truck-loading', link: '/vehicle-calculator' },
            // { name: 'MENU/ADD_HUB',            icon: 'fa-plus',                link: '/add-hub'                },
            { name: 'MENU/CLIENT_TP', icon: 'fa-link', link: '/client-tp' },
            { name: 'MENU/DELIVERY_ORDER', icon: 'fa-stream', link: '/delivery-order' },
            // { name: 'MENU/DELIVERY_REQUESTS',  icon: 'fa-paper-plane',         link: '/delivery-requests'      },
            { name: 'MENU/MDR_LSP', icon: 'fa-location-arrow', link: '/mdr-lsp' },
            { name: 'MENU/NEW_MDR_REQUEST', icon: 'fa-align-justify', link: '/new-mdr-request' },
            // { name: 'MENU/SHIPPING_C_ADMIN',   icon: 'fa-user-minus',          link: '/shipping-company-admin' },
            { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-table', link: '/site-investigation' },
            { name: 'MENU/DR_BOOKING', icon: 'fa-map-pin', link: '/dr-booking' },
        ];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        // console.log(this.role);
        if (this.role === 5) {
            this.menuItems = [
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-building', link: '/occurrence-list' },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-dolly', link: '/control-tower' },
                { name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list' },
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-cogs', link: '/site-investigation' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 12) {
            this.menuItems = [
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-building', link: '/occurrence-list' },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-dolly', link: '/control-tower' },
                { name: 'MENU/VEHICLE_CALCULATOR', icon: 'fa-truck-loading', link: '/vehicle-calculator' },
                { name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list' },
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-cogs', link: '/site-investigation' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 14) {
            this.menuItems = [
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-building', link: '/occurrence-list' },
                // { name: 'MENU/UOC_LIST',           icon: 'fa-dollar-sign',         link: '/uoc-list'           },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-dolly', link: '/control-tower' },
                { name: 'MENU/VEHICLE_CALCULATOR', icon: 'fa-truck-loading', link: '/vehicle-calculator' },
                { name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list' },
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-cogs', link: '/site-investigation' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 19 || this.role === 35) {
            this.menuItems = [
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/DR_BOOKING', icon: 'fa-map-pin', link: '/dr-booking' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 2) {
            this.menuItems = [
                { name: 'MENU/REQUISITION', icon: 'fa-clipboard', link: '/new-delivery-request' },
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/MAP_MDR', icon: 'fa-map', link: '/mdr-map' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-dolly', link: '/lsp-control-tower' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 77) {
            this.menuItems = [
                { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
                // { name: 'MENU/UOC_LIST', icon: 'fa-dollar-sign',                link: '/uoc-list' },
                { name: 'MENU/VEHICLE_CALCULATOR', icon: 'fa-truck-loading', link: '/vehicle-calculator' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-dollar-sign', link: '/occurrence-list', type: 'badge' },
                { name: 'MENU/PRICE_MAKER_TABLE', icon: 'fa-tags', link: '/price-maker/table' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 8) {
            this.menuItems = [
                { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 6) {
            this.menuItems = [];
        }
        else if (this.role === 9) {
            this.menuItems = [
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 3) {
            this.menuItems = [
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'Lista Nota Fiscal', icon: 'fa-folder-open', link: '/nfe-list' },
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        // this.occService.allCount().subscribe((response) => {
        //   if (response > 100) {
        //     this.openOccurrence = '99+';
        //   } else {
        //     this.openOccurrence = response + '';
        //   }
        // });
        this.openOccurrence = '99+';
    }
    MenuComponent.prototype.ngOnInit = function () {
    };
    MenuComponent.prototype.logout = function () {
        this.auth.logout();
        this.router.navigate(['login']);
    };
    return MenuComponent;
}());
export { MenuComponent };
