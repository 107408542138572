import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DashboardService } from '../../../providers/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { BarObject } from '../../../models/bar-object';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';

@Component({
  selector: 'app-basic-bar-chart',
  templateUrl: './basic-bar-chart.component.html',
  styleUrls: ['./basic-bar-chart.component.scss']
})

export class BasicBarChartComponent implements OnInit {
  @Input() container: string;
  chart: any;
  chartData: BarObject;
  downloadData = [];

  constructor(private dashboardService: DashboardService, private excelService: ExcelService,
              private translate: TranslateService) {
                this.chartData = {
                  number: 0,
                  percentage: 0, // percentage should be positive or negative (not just the absolute value)
                  categories: [],
                  series: [],
                  subtitle: '',
                };
  }

  ngOnInit() {
  }

  download() {
    this.excelService.exportAsExcelFile(this.downloadData, 'drs');
  }

  createChart(data: BarObject, id: string, download?) {
    if (download) {
      this.downloadData = download;
    }
    this.chartData = data;
    if (data.categories.length === 0) {
      this.chart = 'empty';
    } else {
      this.chart = Highcharts.chart(id, {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: data.categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: data.subtitle
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: data.series
      });
      this.chart.reflow();
    }
  }
}
