<h1 mat-dialog-title>Criar usuário Ericsson</h1>
<div mat-dialog-content>
   <form class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
       <input required matInput placeholder="Nome do usuário" name="name" [(ngModel)]="user.name">
    </mat-form-field>
     <mat-form-field class="col-md-6">
       <input required matInput placeholder="E-mail" name="user" [(ngModel)]="user.email">
     </mat-form-field>
     <mat-form-field class="col-md-6">
      <mat-select required placeholder="Role" name="role" [(ngModel)]="user.role">
        <mat-option *ngFor="let role of listOfRoles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>
   </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button (click)="onYesClick()" cdkFocusInitial>Criar</button>
   <button mat-button cdkFocusInitial (click)="onNoClick()">Voltar</button>
</div>
