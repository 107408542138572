<div class="mx-5 my-4">
  <p class="mat-h1">Lista de NFs de Terceiro</p>
  <p class="mx-4">A lista financeira permite editar as NF de Terceiro</p>
</div>
<div *ngIf="data.length > 0" class="colored-background table-background px-5 py-2">
  <app-table
    [data]="data"
    [columns]="columns"
    downloadAs="finance-list_%.xlsx"
    [dateSearch]="['createdAt']"
    [this]="this"
    [clickCallback]="goToNFe"
  ></app-table>
</div>

<div *ngIf="data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'FINANCE-LIST/available' | translate }} </p>
</div>
