import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
var MdrLspComponent = /** @class */ (function () {
    function MdrLspComponent(aRoute, mdrService, router) {
        this.aRoute = aRoute;
        this.mdrService = mdrService;
        this.router = router;
        this.columns = [
            { value: 'id', name: 'id' },
            { value: 'drList', name: 'MDR_LSP/table/mdr/dr' },
            { value: 'status', name: 'status' }
        ];
        this.mdr = [];
    }
    MdrLspComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getAllLSP().subscribe(function (data) {
            _this.mdr = data;
        });
    };
    MdrLspComponent.prototype.onRowClick = function (row, that) {
        that.router.navigate(['logistic-order', row.id]);
    };
    return MdrLspComponent;
}());
export { MdrLspComponent };
