import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Occurrence, OCCURRENCE_STATUS } from '../../../models/occurrence';
import { MDR } from '../../../models/mdr';
import { MdrService } from '../../../providers/mdr.service';
import { UOCMDR } from '../../../models/uoc__mdr';
import { UOC } from '../../../models/uoc';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { UOCObservable } from '../../../services/uoc.service';
import { MdrObservable } from '../../../services/mdr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { COPQMDR } from '../../../models/copq__mdr';
import { UploadFileService } from '../../../providers/upload.service';
import { environment } from '../../../../environments/environment';
import { LSPEmailDialogComponent } from '../logistic-order.component';
import { DatePipe } from '@angular/common';
var CostApprovalComponent = /** @class */ (function () {
    function CostApprovalComponent(dialog, mdrService, snackBar, aRoute, drService, drObservable, mdrObservable, uploadService, sanitizer) {
        var _this = this;
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.aRoute = aRoute;
        this.drService = drService;
        this.drObservable = drObservable;
        this.mdrObservable = mdrObservable;
        this.uploadService = uploadService;
        this.sanitizer = sanitizer;
        this.base_url = environment.apiUrl;
        this.dataOccurrence = new MatTableDataSource();
        this.occurrenceColumns = ['id', 'uoc', 'text', 'status', 'createdAt', 'upload', 'file', 'resolution'];
        this.myControl = new FormControl();
        this.drList = [];
        this.showAllRows = false;
        this.occurrence = new Occurrence();
        this.role = 0;
        this.mdr = new MDR;
        this.uocColumns = ['uoc', 'value', 'observation'];
        this.uoc = [];
        this.sumTotal = 0;
        this.data = [];
        this.copq = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.columnsToDisplay = this.role !== 1 ? ['id', 'uoc', 'text', 'resolution', 'status'] : ['id', 'uoc', 'text', 'status'];
        this.occurrenceColumns.push('action');
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            _this.drObservable.currentDR.subscribe(function (dr) {
                _this.drList = dr;
            });
            _this.getListOccurrence();
        });
        this.mdrService.getListCoPQ().subscribe(function (copq) {
            _this.copq = copq;
        });
        this.mdrService.getOccurrenceEnum().subscribe(function (data) {
            _this.uoc = data.map(function (e) {
                var u = new UOC();
                u.id = e.id;
                u.name = e.occurrence;
                return u;
            });
        });
    }
    CostApprovalComponent.prototype.ngOnInit = function () {
    };
    CostApprovalComponent.prototype.createOccurrence = function () {
        var _this = this;
        this.occurrence.idMDR = this.mdr.id;
        // console.log(this.occurrence);
        this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        if (['Status', 'Descarte de Embalagem'].includes(this.occurrence.uoc)) {
            this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        } // gambiarra, remover, corrigir TODO DEBUG; kkk remover, corrigir kkk
        this.mdrService.createOccurrence(this.occurrence).subscribe(function (response) {
            if (response !== undefined) {
                // console.log(this.occurrence);
                if (_this.occurrence.uoc === 'COVID-19') {
                    // console.log(this.drList);
                    _this.mdrService.updateCoronaVirus(_this.drList).subscribe(function (el) {
                    });
                }
                _this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    CostApprovalComponent.prototype.showAll = function (elem) {
        this.showAllRows = !this.showAllRows;
        if (this.showAllRows) {
            this.uploadService.findByEntity(elem.id, 'occurrence').subscribe(function (ans) {
                elem.file = (ans.length === 0) ? undefined : ans;
            });
        }
    };
    CostApprovalComponent.prototype.getListOccurrence = function () {
        var _this = this;
        this.mdrService.getOccurences(this.mdr).subscribe(function (data) {
            if (_this.role === 1 || _this.role === 14 || _this.role === 77) {
                data = data.filter(function (occurrence) { return occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL ||
                    occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT ||
                    occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED; });
            }
            _this.dataOccurrence = new MatTableDataSource(data);
        });
    };
    CostApprovalComponent.prototype.approveOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT;
        // console.log(occurrence);
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            // console.log("Aqui é dentro");
            // console.log(occurrence);
            // console.log(response);
            var uoc = new UOCMDR();
            uoc.idMDR = _this.mdr.id;
            uoc.value = parseFloat(occurrence.resolution) * 100;
            uoc.uoc = occurrence.uoc;
            uoc.needsAttachment = true;
            uoc.idOccurrence = occurrence.id;
            // console.log(uoc);
            _this.mdrService.createUOC(uoc).subscribe(function (responseUOC) {
                var occ = new Occurrence();
                occ.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
                occ.idMDR = _this.mdr.id;
                occ.uoc = 'Agendamento';
                occ.text = (occurrence.uoc === 'Balsa') ? 'Custo de Balsa aprovado' : 'Custo Extra Aprovado via sistema';
                occ.createdAt = new Date();
                _this.mdrService.createOccurrence(occ).subscribe(function (elem) {
                });
                // atualiza lista de UOCs da MDR
                // this.mdrService.getUOC(this.data.mdr).subscribe((uocmdr) => {
                //   this.uocObservable.changeUOC(uocmdr);
                // });
            });
            if (response !== undefined) {
                _this.snackBar.open('Custo Aprovado com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
            for (var i = 0; i < _this.myControl.value.length; i++) {
                for (var j = 0; j < _this.drList.length; j++) {
                    if (_this.drList[j].id === _this.myControl.value[i]) {
                        uoc.drList.push(_this.drList[j]);
                    }
                }
            }
            // cria a UOC
        });
    };
    CostApprovalComponent.prototype.receiveFile = function ($event) {
        this.getListOccurrence();
    };
    CostApprovalComponent.prototype.resolveOccurrence = function (occurrence) {
        // occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        var _this = this;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    CostApprovalComponent.prototype.removeOccurrence = function (j) {
        var _this = this;
        this.mdrService.deleteOccurrence(j).subscribe(function (data) {
            _this.getListOccurrence();
        });
    };
    CostApprovalComponent.prototype.isArray = function (val) { return typeof val === 'object'; };
    CostApprovalComponent.prototype.getSafeURL = function (aquivai) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + aquivai.fileName);
    };
    CostApprovalComponent.prototype.getUOCValue = function () {
        var _this = this;
        this.mdrService.getUOCValue(this.occurrence, this.mdr).subscribe(function (data) {
            _this.occurrence.value = data;
        });
    };
    CostApprovalComponent.prototype.editOccurrence = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(EditOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
            if (response !== undefined) {
                occurrence.resolution = response.resolution;
                occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL;
                _this.mdrService.sendApproveCostEmail(_this.mdr, []).subscribe(function (k) {
                    // console.log(k);
                    // console.log('Email enviado de aprovação');
                });
                _this.resolveOccurrence(occurrence);
            }
        });
    };
    CostApprovalComponent.prototype.getHistory = function (occurrence) {
        var dialogRef = this.dialog.open(OccurrenceHistoryDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
            if (response !== undefined) {
                // console.log(response);
                // occurrence.resolution = response;
                // this.resolveOccurrence(occurrence);
            }
        });
    };
    CostApprovalComponent.prototype.openDialog = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
            if (response !== undefined) {
                occurrence.resolution = response;
                _this.resolveOccurrence(occurrence);
            }
        });
    };
    CostApprovalComponent.prototype.rejectOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED;
        var dialogRef = this.dialog.open(EditOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            _this.mdrService.getLSPUsers(_this.mdr.shippingCompany).subscribe(function (users) {
                if (users !== null) {
                    if (users.length > 0) {
                        var dialogRef2 = _this.dialog.open(LSPEmailDialogComponent, {
                            data: {
                                users: users.filter(function (e) { return e.role === 14; }),
                                existeNfVermelha: null,
                            }
                        });
                        dialogRef2.afterClosed().subscribe(function (result) {
                            if (result[0]) {
                                var selectedUsers = result[1].value;
                                if (selectedUsers !== null) {
                                    _this.mdrService.sendReproveEmail(_this.mdr, selectedUsers).subscribe();
                                }
                                // console.log('Envio de email');
                            }
                        });
                    }
                }
            });
            // console.log(response);
            // if (response !== undefined) {
            //   occurrence.resolution = response;
            //   this.resolveOccurrence(occurrence);
            // }
        });
        // this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
        //   if (response !== undefined) {
        //     this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
        //       duration: 4000,
        //     });
        //     this.getListOccurrence();
        //   }
        // });
    };
    return CostApprovalComponent;
}());
export { CostApprovalComponent };
var AcceptOccurrenceDialogComponent = /** @class */ (function () {
    function AcceptOccurrenceDialogComponent(dialogRef, mdrService, uocObservable, snackBar, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.uocObservable = uocObservable;
        this.snackBar = snackBar;
        this.data = data;
        this.isUOC = false;
        this.isCOPQ = false;
        this.totalValue = 0;
        this.uocMult = 1;
        this.copqFinalValue = 0;
        this.copqMult = 1;
        this.valueCOPQ = 0;
        this.myControl = new FormControl();
        // pega UOCs possíveis
        this.mdrService.getListUOC().subscribe(function (response) {
            _this.uoc = response;
        });
        this.data.needsAttachment = true;
        this.copq = data.copq;
        this.drList = data.drList;
        this.occ = data.occ;
        this.uocSelected = this.occ.uoc;
    }
    AcceptOccurrenceDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // se é UOC
        if (this.isUOC === true) {
            if (this.myControl.value === null) {
                this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
                    duration: 4000,
                });
                return;
            }
            // nao permite criar UOC com value 0
            if ((this.valueUOC * this.uocMult) === 0 || isNaN(this.valueUOC * this.uocMult)) {
                this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
                    duration: 4000,
                });
                return;
            }
            var uoc_1 = new UOCMDR();
            uoc_1.idMDR = this.data.mdr.id;
            uoc_1.value = this.valueUOC * this.uocMult * 100;
            uoc_1.uoc = this.uocSelected;
            uoc_1.needsAttachment = this.data.needsAttachment;
            uoc_1.idOccurrence = this.occ.id;
            for (var i = 0; i < this.myControl.value.length; i++) {
                for (var j = 0; j < this.drList.length; j++) {
                    if (this.drList[j].id === this.myControl.value[i]) {
                        uoc_1.drList.push(this.drList[j]);
                    }
                }
            }
            // cria a UOC
            this.mdrService.createUOC(uoc_1).subscribe(function (responseUOC) {
                // atualiza lista de UOCs da MDR
                _this.mdrService.getUOC(_this.data.mdr).subscribe(function (uocmdr) {
                    _this.uocObservable.changeUOC(uocmdr);
                });
                // se tambem é CoPQ
                if (_this.isCOPQ === true) {
                    var copq = new COPQMDR();
                    copq.idMDR = _this.data.mdr.id;
                    copq.value = _this.valueCOPQ * _this.copqMult * 100;
                    copq.copq = _this.copqSelected;
                    if (_this.copqSelected === 'Falta de Acesso COVID - 19') {
                        _this.mdrService.updateCoronaVirus(uoc_1.drList).subscribe(function (el) {
                            // console.log(el);
                        });
                    }
                    _this.mdrService.createCoPQ(copq).subscribe(function (responseCOPQ) {
                        // fecha dialog
                        _this.dialogRef.close(_this.data.text);
                    });
                }
                else { // nao é CoPQ
                    // fecha dialog
                    _this.dialogRef.close(_this.data.text);
                }
            });
        }
        else { // nao é UOC
            // fecha dialog
            this.dialogRef.close(this.data.text);
        }
    };
    AcceptOccurrenceDialogComponent.prototype.getUOCValue = function (elem) {
        var _this = this;
        // console.log('teste')
        this.mdrService.getUOCValue(elem, this.data.mdr).subscribe(function (data) {
            _this.valueUOC = data;
            // console.log(data);
        });
    };
    AcceptOccurrenceDialogComponent.prototype.onNoClick = function () {
        this.data = 0;
        this.dialogRef.close();
    };
    return AcceptOccurrenceDialogComponent;
}());
export { AcceptOccurrenceDialogComponent };
var OccurrenceHistoryDialogComponent = /** @class */ (function () {
    function OccurrenceHistoryDialogComponent(data, datepipe, mdrService) {
        var _this = this;
        this.data = data;
        this.datepipe = datepipe;
        this.mdrService = mdrService;
        this.history = [];
        this.columns = [
            { value: 'quando', name: 'quando' },
            { value: 'resolution', name: 'Valor' },
            { value: 'text', name: 'Resolução' },
        ];
        this.mdrService.getOccurrenceHistory(this.data.occ.id).subscribe(function (el) {
            el.forEach(function (k) {
                k.quando = new Date(k.quando);
            });
            _this.history = el;
        });
    }
    OccurrenceHistoryDialogComponent.prototype.ngOnInit = function () { };
    return OccurrenceHistoryDialogComponent;
}());
export { OccurrenceHistoryDialogComponent };
var EditOccurrenceDialogComponent = /** @class */ (function () {
    function EditOccurrenceDialogComponent(dialogRef, mdrService, uocObservable, snackBar, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.uocObservable = uocObservable;
        this.snackBar = snackBar;
        this.data = data;
        this.isUOC = false;
        this.isCOPQ = false;
        this.totalValue = 0;
        this.uocMult = 1;
        this.copqFinalValue = 0;
        this.copqMult = 1;
        this.valueCOPQ = 0;
        this.myControl = new FormControl();
        // pega UOCs possíveis
        this.mdrService.getListUOC().subscribe(function (response) {
            _this.uoc = response;
        });
        this.data.needsAttachment = true;
        this.copq = data.copq;
        this.drList = data.drList;
        this.occ = data.occ;
        this.uocSelected = this.occ.uoc;
    }
    EditOccurrenceDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        this.occ.obs = 'Aqui Mudou';
        this.mdrService.editOccurrence(this.occ).subscribe(function (data) {
            // console.log(data);
            _this.dialogRef.close(data);
            // this.mdrService.sendApproveCostEmail(this.data.mdr, []).subscribe(k => {
            //   console.log(k);
            //   console.log('Email enviado de aprovação');
            // });
        });
    };
    EditOccurrenceDialogComponent.prototype.getUOCValue = function (elem) {
        var _this = this;
        // console.log('teste')
        this.mdrService.getUOCValue(elem, this.data.mdr).subscribe(function (data) {
            _this.valueUOC = data;
            // console.log(data);
        });
    };
    EditOccurrenceDialogComponent.prototype.onNoClick = function () {
        this.data = 0;
        this.dialogRef.close();
    };
    return EditOccurrenceDialogComponent;
}());
export { EditOccurrenceDialogComponent };
