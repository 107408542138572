var Zrsdpac = /** @class */ (function () {
    function Zrsdpac() {
        this.id = null;
        this.ourOrderNo = null;
        this.boxNo = null;
        this.box = null;
        this.type = null;
        this.grossWeight = null;
        this.height = null;
        this.length = null;
        this.width = null;
        this.volume = null;
        this.agreementId = null;
        this.customerPoNumber = null;
        this.customerPoItem = null;
        this.site = null;
        this.soNumber = null;
        this.soItem = null;
        this.delivery = null;
        this.shipment = null;
        this.loadingWeight = null;
        this.weightUnit = null;
        this.uolForDimensions = null;
        this.soGroupId = null;
        this.netWeight = null;
        this.countryOfOrigin = null;
        this.rfrStatus = null;
        this.externalId1 = null;
        this.externalId2 = null;
        this.descriptionShip = null;
        this.shipToPartyPoNum = null;
        this.shipToPartyPoLte = null;
        this.integrated = null;
        this.insertedBy = null;
        this.updatedBy = null;
        this.insert = null;
        this.update = null;
        this.active = null;
    }
    return Zrsdpac;
}());
export { Zrsdpac };
