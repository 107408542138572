/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delivery-order.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../misc/table/table.component.ngfactory";
import * as i3 from "../../misc/table/table.component";
import * as i4 from "../../services/xlsx.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "ngx-cookie-service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/common";
import * as i10 from "./delivery-order.component";
import * as i11 from "../../providers/mdr.service";
var styles_DeliveryOrderComponent = [i0.styles];
var RenderType_DeliveryOrderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeliveryOrderComponent, data: {} });
export { RenderType_DeliveryOrderComponent as RenderType_DeliveryOrderComponent };
function View_DeliveryOrderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 770048, null, 0, i3.TableComponent, [i4.XlsxService, i5.MatDialog, i6.Router, i7.CookieService], { parent: [0, "parent"], columns: [1, "columns"], data: [2, "data"], clickCallback: [3, "clickCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = _co.columns; var currVal_2 = _co.mdr; var currVal_3 = _co.goTo; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DeliveryOrderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../assets/images/happy_face.png"], ["style", "width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["style", "color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("DELIVERY_ORDER/empty")); _ck(_v, 3, 0, currVal_0); }); }
export function View_DeliveryOrderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "colored-background table-background px-5 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeliveryOrderComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeliveryOrderComponent_2)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.mdr.length > 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.mdr.length === 0); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("DELIVERY_ORDER/title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_DeliveryOrderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delivery-order", [], null, null, null, View_DeliveryOrderComponent_0, RenderType_DeliveryOrderComponent)), i1.ɵdid(1, 114688, null, 0, i10.DeliveryOrderComponent, [i11.MdrService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeliveryOrderComponentNgFactory = i1.ɵccf("app-delivery-order", i10.DeliveryOrderComponent, View_DeliveryOrderComponent_Host_0, {}, {}, []);
export { DeliveryOrderComponentNgFactory as DeliveryOrderComponentNgFactory };
