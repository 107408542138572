import { Pipe, PipeTransform } from '@angular/core';
/*
 * Formata o endereço recebido
 * Recebe uma string de formatação cujo valor default é '%A'
 * Formato esperado:
 *
 * Uso:
 *   endereço | address:formatação
 * Exempo:
 *   {{ 'Rua, Número - Bairro, Cidade - Estado, País' | address:'%c/%E' }}
 *   resulta em: 'Cidade/Estado'
 * Campos:
 *  %r  - rua, sem o número
 *  %N  - número
 *  %b  - bairro
 *  %c  - cidade
 *  %E  - estado
 *  %P  - país
 *  %A  - endereço completo
 */
@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {
  transform(address: string, format: string = '%A'): string {
    if (address === null) {
      address = '';
    }
    const addr = {
      '%r': 'sem rua',
      '%N': 'sem número',
      '%b': 'sem bairro',
      '%c': 'sem cidade',
      '%E': '??',
      '%P': 'sem país',
      '%A': address
    };

    const splt = address.split('-');
    if (splt.length >= 3 && splt[2].includes(',')) {
      addr['%E'] = splt[2].split(',')[0];
      addr['%P'] = splt[2].split(',')[1];
    }
    if (splt.length >= 2 && splt[1].includes(',')) {
      addr['%b'] = splt[1].split(',')[0];
      addr['%c'] = splt[1].split(',')[1];
    }
    if (splt.length >= 1 && splt[0].includes(',')) {
      addr['%r'] = splt[0].split(',')[0];
      addr['%N'] = splt[0].split(',')[1];
    }

    // substitui os valores na string de saida conforme format
    let output = format;
    for (const el of Object.keys(addr)) {
      output = output.replace(el, addr[el].trim());
    }
    // POG TODO remover essa gambiarra
    if ( output === 'sem cidade/??' ) {
      output = 'Ericsson';
    }// POG-fim
    return output;
  }
}
