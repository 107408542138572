import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { MatSnackBar } from '@angular/material';
var CheckTokenGuard = /** @class */ (function () {
    function CheckTokenGuard(router, service, snack) {
        this.router = router;
        this.service = service;
        this.snack = snack;
    }
    /**********
     * espera-se que a url siga o seguinte formato:
     *
     *  /view/TOKEN/<resto da url>
     *
     */
    CheckTokenGuard.prototype.extractTokenFromURL = function (url) {
        var token = url.split('/')[2];
        return token ? token : null;
    };
    CheckTokenGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        var token = this.extractTokenFromURL(state.url);
        if (token === null) {
            this.snack.open('Token ausente', 'Ok', { duration: 4000 });
            this.router.navigate(['login']);
            return false;
        }
        return this.service.checkTokenWithoutLogin(token).map(function (response) {
            if (response === true) {
                return true;
            }
            else {
                _this.snack.open('Token inválido', 'Ok', { duration: 4000 });
                _this.router.navigate(['login']);
                return false;
            }
        });
    };
    return CheckTokenGuard;
}());
export { CheckTokenGuard };
