<div style="max-height: 550px;">
  <div class="mat-elevation-z8" style="margin-bottom: 30px;">
    <div class="more-info">
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lat' | translate }}</span>: {{ data.dr['lat'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lng' | translate }}</span>: {{ data.dr['lng'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/userArrivalTime' | translate }}</span>: {{ data.dr['userArrivalTime'] | date: 'medium'}}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/arrivalTime' | translate }}</span>: {{ data.dr['arrivalTime'] | date: 'medium' }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/hus' | translate }}</span>: {{ HUs.length }}</p>
    </div>
    <div *ngIf="role !== 5  && role !== 12  && role !== 14 && dataSource.length > 0">
      <app-table
        [data]="dataSource"
        [columns]="columns"
        downloadAs="sheets_%.xlsx">
      </app-table>
    </div>
  </div>
</div>
