<h1 mat-dialog-title>Cost Avoidance</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-wrap">
    <!-- <mat-form-field class="col-md-6">
      <input matInput placeholder="Endereço" name="text" [(ngModel)]="current.deliveryAddress" required>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-select [(ngModel)]="current.status" placeholder="Selecione o Status" name="status" required>
        <mat-option *ngFor="let status of statusList" [value]="status">
          {{ status | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field class="col-md-6">
      <mat-select  placeholder="Cost Avoidance" name="costAvoidance" [(ngModel)]="caNew.costAvoidance" required>
        <mat-option *ngFor="let elem of costAvoidanceList" [value]="elem.name"  >
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput placeholder="Valor" name="valor" type="number" [(ngModel)]="caNew.value" required>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <input matInput placeholder="Observation" name="obs" type="text" [(ngModel)]="caNew.observation">
    </mat-form-field>
  </form>
</div>
<br>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button (click)="onYesClick()" [disabled]="!form.valid">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
