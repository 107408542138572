var OV = /** @class */ (function () {
    function OV() {
        this.id = null;
        this.sDDocument = null;
        this.salesDocument = null;
        this.salesOrganization = null;
        this.salesOffileKam = null;
        this.salesDocumentType = null;
        this.customerPurchaseOrderNumber = null;
        this.nameOfOrderer = null;
        this.customerName = null;
        this.customerCity = null;
        this.customerAddress = null;
        this.shipToPartyPurchaseOrderNumber = null;
        this.assignmentInformation = null;
        this.salesSequence = null;
        this.higherLevelItemInBill = null;
        this.materialCode = null;
        this.materialDescription = null;
        this.salesOrderQuantity = null;
        this.conditionValue = null;
        this.reasonForRejection = null;
        this.billingDocument = null;
        this.referenceNoNf = null;
        this.referenceDoc = null;
        this.itemNomeText = null;
        this.integrated = null;
        this.integratedDate = null;
        this.insertedBy = null;
        this.updatedBy = null;
        this.insertDate = null;
        this.updateDate = null;
        this.active = null;
    }
    return OV;
}());
export { OV };
