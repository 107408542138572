var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { OVService } from '../../providers/ov.service';
import { ERBService } from '../../providers/erb.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DR } from '../../models/dr';
import { parseString } from 'xml2js';
import { NfeService } from '../../providers/nfe.service';
import { HubService } from '../../providers/hub.service';
import { SiteService, ESTRUTURA, ACESSO } from '../../providers/site.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var NewDeliveryRequestComponent = /** @class */ (function () {
    function NewDeliveryRequestComponent(dialog, mapsAPILoader, ovService, router, drService, nfService, excelService, siteService, clientUserService, snackBar, hubService, erbService) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.ovService = ovService;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.excelService = excelService;
        this.siteService = siteService;
        this.clientUserService = clientUserService;
        this.snackBar = snackBar;
        this.hubService = hubService;
        this.erbService = erbService;
        this.ngUnsubscribe = new Subject();
        this.onGoingRequest = false;
        this.dr = new DR();
        this.uploaded = false;
        this.displayedColumnsSearched = ['select', 'mdr'];
        this.separatorKeysCodes = [ENTER, SPACE, COMMA, TAB];
        this.dataSource = new MatTableDataSource();
        this.inputOVs = [];
        this.ovOrNf = false;
        this.allValid = false;
        this.minDate = new Date();
        this.displayedColumnsOV = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
        this.displayedColumnsNF = ['invoiceNumber', 'client', 'materials'];
        this.dataSourceOV = new MatTableDataSource();
        this.dataSourceNF = new MatTableDataSource();
        this.displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'pickupAddress', 'site'];
        this.dataSourceDR = new MatTableDataSource();
        this.selection = new SelectionModel(true, []);
        this.newDrList = [];
        this.valid = true;
        this.showSiteInfo = false;
        this.site = {
            'color': '',
            'tooltips': {
                'grey': 'site não existe',
                'yellow-1': 'site não aprovado',
                'yellow-2': 'site não liberado',
                'yellow-3': 'site não aprovado e não liberado',
                'green': 'site aprovado',
                'undefined': ''
            },
            'icons': {
                'grey': 'fa-question-circle',
                'yellow-1': 'fa-exclamation-triangle',
                'yellow-2': 'fa-exclamation-triangle',
                'yellow-3': 'fa-exclamation-triangle',
                'green': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'grey': 'icon-grey',
                'yellow-1': 'icon-yellow',
                'yellow-2': 'icon-yellow',
                'yellow-3': 'icon-yellow',
                'green': 'icon-green',
                'undefined': ''
            }
        };
        this.clientList = [];
        this.paste_input = '';
        this.map_show = false;
        this.map_data = null;
        this.red_marker = {
            url: './assets/images/pin_red.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.blue_marker = {
            url: './assets/images/pin.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.dataSource.sort = this.sort;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        if (this.role === 1) {
            this.minDate = new Date('1970-01-01');
        }
        this.hubService.getAll(this.valid).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.hubList = data;
        });
        this.clientUserService.getClients().pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    NewDeliveryRequestComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewDeliveryRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElement2Ref.nativeElement, {
                types: ['address']
            });
            autocomplete.addListener('place_changed', function () {
                // console.log('Place Changed');
                var place = autocomplete.getPlace();
                _this.dr.deliveryAddress = place.formatted_address;
                _this.dr.lat = place.geometry.location.lat();
                _this.dr.lng = place.geometry.location.lng();
                // console.log(this.dr);
            });
        });
    };
    NewDeliveryRequestComponent.prototype.fillFromClipboard = function (e) {
        var _this = this;
        var DMStoDD = function (str) {
            var parts = str.split(/[°'"]/).map(function (part) {
                return +part.replace(',', '.');
            });
            var decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
            return parts[0] + decimal;
        };
        var input = e.clipboardData.getData('text');
        var splitted = input.split('\t');
        // this.salesOrders = splitted[1];
        this.dr.deliveryAddress = splitted[6];
        this.dr.lat = +DMStoDD(splitted[25]);
        this.dr.lng = +DMStoDD(splitted[26]);
        this.dr.receiverName = splitted[9];
        this.dr.receiverPhone = parseInt(splitted[10], 10);
        this.dr.dateScheduling = new Date(splitted[3]);
        this.dr.site = splitted[4];
        this.dr.infraType = splitted[11];
        this.dr.unificationId = splitted[0];
        this.dr.obs = splitted[12];
        setTimeout(function () {
            _this.paste_input = '';
        }, 200);
    };
    NewDeliveryRequestComponent.prototype.toggleMap = function () {
        var _this = this;
        if (this.map_show) {
            this.map_show = false;
        }
        else {
            if (this.dr.lat && this.dr.lng) {
                this.erbService.getNearERB(this.dr.lat, this.dr.lng).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                    _this.dr.lat = +_this.dr.lat;
                    _this.dr.lng = +_this.dr.lng;
                    _this.map_data = data;
                    _this.map_show = true;
                });
            }
        }
    };
    NewDeliveryRequestComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        // const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
        var addr = this.dr.deliveryAddress;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertDRAddressToLatLng = function (dr) {
        var _this = this;
        var error = false;
        var geocoder = new google.maps.Geocoder();
        var addr = dr.deliveryAddress;
        // console.log(addr);
        return geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                dr.deliveryAddress = results[0].formatted_address;
                dr.lat = results[0].geometry.location.lat();
                dr.lng = results[0].geometry.location.lng();
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertAddressToLatLngXml = function (addr) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                // console.log('true');
                return true;
            }
            else {
                // console.log('false');
                return false;
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertLatLngToAddressFromUpload = function (dr) {
        var _this = this;
        var latInp = dr.lat;
        var lngInp = dr.lng;
        if (latInp === null || lngInp === null) {
            return false;
        }
        var lat = latInp;
        var lng = lngInp;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                dr.deliveryAddress = results[0].formatted_address;
                dr.lat = results[0].geometry.location.lat();
                dr.lng = results[0].geometry.location.lng();
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    NewDeliveryRequestComponent.prototype.addNewItem = function () {
        if (this.ovOrNf) { // item é NF
            this.addNewNF();
        }
        else { // item é OV
            this.addNewOV();
        }
    };
    NewDeliveryRequestComponent.prototype.addNewOV = function () {
        var _this = this;
        this.selection.selected.forEach(function (el) {
            if (_this.dataSourceOV.data.indexOf(el) < 0) {
                _this.dataSourceOV.data.push(el);
            }
        });
        this.dataSourceOV = new MatTableDataSource(this.dataSourceOV.data);
        this.dataSource = new MatTableDataSource();
    };
    NewDeliveryRequestComponent.prototype.addNewNF = function () {
        var _this = this;
        this.selection.selected.forEach(function (el) {
            if (_this.dataSourceNF.data.indexOf(el) < 0) {
                _this.dataSourceNF.data.push(el);
            }
        });
        this.dataSourceNF = new MatTableDataSource(this.dataSourceNF.data);
    };
    NewDeliveryRequestComponent.prototype.selectAll = function () {
        var _this = this;
        this.dataSource.data.forEach(function (el) {
            if (_this.ovOrNf) { // item é NF
                if (_this.dataSourceNF.data.indexOf(el) < 0) {
                    if (!el.drId) {
                        _this.dataSourceNF.data.push(el);
                    }
                }
            }
            else { // item é OV
                if (_this.dataSourceOV.data.indexOf(el) < 0) {
                    if (!el.drId) {
                        _this.dataSourceOV.data.push(el);
                    }
                }
            }
        });
        if (this.dataSourceOV.data.length) {
            this.snackBar.open('Não há NFs disponíveis', 'Ok', { duration: 4000 });
        }
        if (this.ovOrNf) { // item é NF
            this.dataSourceNF = new MatTableDataSource(this.dataSourceNF.data);
            this.dataSource = new MatTableDataSource();
        }
        else { // item é OV
            this.dataSourceOV = new MatTableDataSource(this.dataSourceOV.data);
            this.dataSource = new MatTableDataSource();
        }
    };
    NewDeliveryRequestComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        // console.log(fileupload);
        this.newDrList = [];
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        var _loop_1 = function (index) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this_1.excelService.importFromExcel(fileupload).pipe(takeUntil(this_1.ngUnsubscribe)).subscribe(function (data) {
                    var _loop_2 = function (i) {
                        var drAux = new DR();
                        setTimeout(function () {
                            try {
                                drAux.pickupAddress = data[i].pickup_address;
                                drAux.deliveryAddress = data[i].delivery_address;
                                drAux.site = data[i].site;
                                drAux.receiverName = data[i].receiver_name;
                                drAux.receiverPhone = data[i].receiver_phone;
                                drAux.supervisorName = data[i].supervisor_name;
                                drAux.supervisorPhone = data[i].supervisor_phone;
                                drAux.infraType = data[i].infra;
                                drAux.obs = data[i].obs;
                                drAux.prolog = data[i].prolog;
                                drAux.client = data[i].client;
                                drAux.dateScheduling = data[i].dateScheduling;
                                drAux.status = data[i].ov;
                                if (_this.convertDRAddressToLatLng(drAux) === false) {
                                    _this.snackBar.open('Endereço inválido', 'Ok', { duration: 2000 });
                                    error = true;
                                }
                                // console.log(el.ov);
                                // console.log(drAux.status);
                                // console.log(drAux.pickupAddress);
                                if (drAux.pickupAddress.toLowerCase() !== 'ericsson') {
                                    drAux.pickupDifAddress = true;
                                }
                                else {
                                    drAux.pickupAddress = null;
                                }
                                // console.log(drAux.pickupAddress);
                                if (+new Date(drAux.dateScheduling) > Date.now()) {
                                    _this.newDrList.push(drAux);
                                    String(drAux.status).split(',').forEach(function (part) {
                                        ovList.push(+part.trim());
                                    });
                                }
                                else {
                                    _this.snackBar.open('Algumas DRs foram filtradas, pois não é possível criar Drs com data no passado. Favor verificar o "dateScheduling" do arquivo e tente novamente', 'Ok', { duration: 5000 });
                                }
                            }
                            catch (ex) {
                                // console.log('erro ao ler arquivo xlsx ',ex);
                                _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                            }
                            _this.uploaded = true;
                            _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                            // this.ovService.validateOVs(ovList).subscribe( (isValid) => {
                            // console.log(isValid);
                            // if( isValid === false || error === true ){
                            //   this.uploaded = true;
                            //   //console.log(isValid);
                            //   //console.log(error);
                            //   this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
                            // } else {
                        }, ((data.length <= 10) ? 0 : (data.length <= 20 ? 550 : 800)) * i);
                    };
                    for (var i = 0; i < data.length; i++) {
                        _loop_2(i);
                    }
                });
            }
            else { // arquivo xml
                var reader_1 = new FileReader();
                reader_1.onload = function () {
                    parseString(reader_1.result, function (err, result) {
                        // drAux.deliveryAddress = result.
                        result.Workbook.Worksheet[0].Table[0].Row.forEach(function (el, i, arr) {
                            setTimeout(function () {
                                if (i === 0) { // pula a primeira linha
                                    return;
                                }
                                var drAux = new DR();
                                // console.log(el);
                                drAux.pickupAddress = el.Cell[0].Data[0]._; // Ver se é nullo
                                if (_this.convertAddressToLatLngXml(drAux.pickupAddress) === false) {
                                    _this.snackBar.open('Endereço invalido', 'Ok', { duration: 2000 });
                                    // console.log(drAux.pickupAddress);
                                    error = true;
                                }
                                drAux.deliveryAddress = el.Cell[1].Data[0]._;
                                if (_this.convertAddressToLatLngXml(drAux.deliveryAddress) === false) {
                                    _this.snackBar.open('Endereço invalido', 'Ok', { duration: 2000 });
                                    // console.log(drAux.pickupAddress);
                                    error = true;
                                }
                                drAux.site = el.Cell[2].Data[0]._;
                                drAux.receiverName = el.Cell[3].Data[0]._;
                                drAux.receiverPhone = el.Cell[4].Data[0]._;
                                drAux.supervisorName = el.Cell[5].Data[0]._;
                                drAux.supervisorPhone = el.Cell[6].Data[0]._;
                                drAux.infraType = el.Cell[7].Data[0]._;
                                drAux.obs = el.Cell[8].Data[0]._;
                                drAux.dateScheduling = el.Cell[9].Data[0]._;
                                drAux.status = el.Cell[10].Data[0]._;
                                // const aux = el.Cell[0].Data[0]._;
                                // TODO Insert OVs in ov List and check if only the id works to change
                                _this.newDrList.push(drAux);
                                // drAux.deliveryAddress = el.
                                drAux.status.split(',').forEach(function (part) {
                                    ovList.push(part.trim());
                                });
                            }, ((arr.length <= 10) ? 0 : (arr.length <= 20 ? 550 : 800)) * i);
                        });
                        _this.ovService.validateOVs(ovList).pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (isValid) {
                            if (isValid === false || error === false) {
                                _this.uploaded = false;
                                _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                            }
                            else {
                                _this.uploaded = true;
                                _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                            }
                        });
                    });
                };
                reader_1.readAsText(input.files[index]);
            }
        };
        var this_1 = this;
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
        // console.log(this.newDrList);
        // console.log(this.dataSourceDR);
    };
    NewDeliveryRequestComponent.prototype.validateData = function () {
        this.dr.deliveryAddress = (document.getElementById('deliveryAddress').value);
        if (this.dr.deliveryAddress === '' && (this.dr.lat === null || this.dr.lng === null)) {
            return false;
        }
        return true;
    };
    NewDeliveryRequestComponent.prototype.validate = function () {
        var _this = this;
        this.newDrList.forEach(function (elem) { return __awaiter(_this, void 0, void 0, function () {
            var ovs, i, j, _i, ovs_1, k, data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ovs = String(elem.status).split(',');
                        for (i = 0; i < ovs.length; i++) {
                            if (ovs[i] !== undefined) {
                                ovs[i] = ovs[i].trim();
                            }
                        }
                        elem.ovList = [];
                        j = [];
                        _i = 0, ovs_1 = ovs;
                        _a.label = 1;
                    case 1:
                        if (!(_i < ovs_1.length)) return [3 /*break*/, 4];
                        k = ovs_1[_i];
                        return [4 /*yield*/, this.ovService.getAll(parseInt(k, 10)).toPromise()];
                    case 2:
                        data = _a.sent();
                        // console.log(data);
                        elem.prolog = data[0]['assignmentInformation'];
                        j.push(data);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        // console.log(j);
                        elem.ovList = j.reduce(function (acc, val) { return acc.concat(val); }, []);
                        // console.log(elem);
                        this.ovService.validateOVs(ovs).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (isValid) {
                            _this.allValid = isValid;
                            // console.log(isValid);
                            if (isValid === false) {
                                // OVs invalidas segundo backend
                                elem.idDRidentifier = 'red';
                                _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                                _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                            }
                            else if (elem.pickupDifAddress === true) {
                                // valida HUB recebido do arquivo
                                // console.log(isValid);
                                _this.ovService.validateHub(elem.pickupAddress).pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (resp) {
                                    if (resp !== undefined && resp.text !== 0) {
                                        // HUB é válido
                                        elem.pickupAddress = resp.text;
                                    }
                                    else {
                                        // HUB é inválido
                                        _this.allValid = false;
                                        elem.idDRidentifier = 'red';
                                        _this.snackBar.open('Erro ao criar requisições a partir de arquivo: HUB não valido', 'Ok', { duration: 4000 });
                                        _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                                    }
                                });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    NewDeliveryRequestComponent.prototype.create = function () {
        var _this = this;
        if (this.validateData()) {
            this.dr.receiverPhone = this.dr.receiverPhone.valueOf();
            this.dr.supervisorPhone = this.dr.supervisorPhone.valueOf();
            // array de OVs
            this.dr.ovList = new Array();
            this.dataSourceOV.data.forEach(function (el) {
                _this.dr.ovList.push(el);
            });
            // array de NFs
            this.dr.nfList = new Array();
            this.dataSourceNF.data.forEach(function (el) {
                _this.dr.nfList.push(el);
            });
            // impede novo click do botão
            this.onGoingRequest = true;
            this.drService.insert(this.dr).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (response) {
                // reseta botão
                _this.onGoingRequest = false;
                var dialogRef = _this.dialog.open(OverviewExampleDialogComponent, {
                    data: {
                        id: response.idDRidentifier
                    }
                });
                dialogRef.afterClosed().pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) {
                    _this.router.navigate(['delivery-optimizer']);
                });
            }, function (error) {
                // reseta botão
                _this.onGoingRequest = false;
                _this.snackBar.open('Erro ao criar DR: uma ou mais OVs já estão em uso', 'Ok', { duration: 4000 });
                // reseta tabelas
                _this.dataSource.data = [];
                _this.dataSourceOV.data = [];
                _this.dataSourceNF.data = [];
                _this.selection.clear();
            });
        }
        else {
            this.snackBar.open('Alguns campos obrigatórios estão faltando', 'Ok', { duration: 4000 });
        }
    };
    NewDeliveryRequestComponent.prototype.createRequisition = function () {
        var _this = this;
        var successfullyCreated = 0;
        // console.log(this.newDrList);
        this.newDrList.forEach(function (elem) {
            // console.log(elem);
            _this.drService.insert(elem).pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (response) {
                _this.snackBar.open("DR criada com sucesso [" + response.idDRidentifier + "]", 'Ok', { duration: 4000 });
                successfullyCreated++;
                if (successfullyCreated === _this.newDrList.length) {
                    _this.uploaded = false;
                }
            }, function (err) {
                console.error(err, elem);
                elem.idDRidentifier = 'red';
                _this.snackBar.open("Erro ao criar DR", 'Ok', { duration: 4000 });
                _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
            });
        });
    };
    NewDeliveryRequestComponent.prototype.checkSite = function () {
        var _this = this;
        this.showSiteInfo = false;
        if (this.dr.site === '') {
            this.site.color = '';
            return;
        }
        this.siteService.getSiteFromCode(this.dr.site).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (!data) {
                _this.site.color = 'grey';
                return;
            }
            _this.siteSelected = data;
            _this.siteSelected.operation = [];
            if (_this.siteSelected.form_6_1 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Grua');
            }
            if (_this.siteSelected.form_6_2 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Correia');
            }
            if (_this.siteSelected.form_6_3 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Veiculo Esp.');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Manual');
            }
            if (_this.siteSelected.form_6_5 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Guindaste');
            }
            if (_this.siteSelected.operation.length > 0) {
                _this.dr.thirdPartInvoice = true;
                // console.log(this.dr.thirdPartInvoice);
            }
            if (!data.aprovado) {
                _this.site.color = 'yellow-1';
            }
            if (data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-2';
            }
            if (!data.aprovado && data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-3';
            }
            if (data.aprovado && data.acesso === ACESSO.LIBERADO) {
                _this.showSiteInfo = true;
                _this.site.color = 'green';
            }
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    NewDeliveryRequestComponent.prototype.setSite = function (idZVSD1) {
        var _this = this;
        // nao modifica o site se já houver um valor
        if (this.dr.site !== '') {
            return;
        }
        this.siteService.getSiteID(idZVSD1).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.dr.site = data.code.slice(-4); // ultimos 4 caracteres
            _this.checkSite();
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    NewDeliveryRequestComponent.prototype.removeOV = function (ov) {
        var index = this.inputOVs.indexOf(ov);
        if (index >= 0) {
            this.inputOVs.splice(index, 1);
        }
    };
    NewDeliveryRequestComponent.prototype.addOV = function (event) {
        var value = (event.value || '').trim();
        // Add OV
        if (value) {
            this.inputOVs.push(value);
        }
        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
    };
    NewDeliveryRequestComponent.prototype.searchOV = function () {
        var _this = this;
        // reseta itens selecionados
        this.selection.clear();
        if (!this.inputOVs.length) {
            this.snackBar.open('Preencha um valor de OV', 'Ok', { duration: 4000 });
        }
        else {
            var tableData_1 = [];
            for (var _i = 0, _a = this.inputOVs; _i < _a.length; _i++) {
                var ov = _a[_i];
                this.ovService.getAll(Number(ov)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                    if (!data.length) {
                        _this.snackBar.open('OV fora do sistema', '', { duration: 4000 });
                    }
                    else {
                        data.forEach(function (element) {
                            if (element['assignmentInformation'] !== null) {
                                _this.dr.prolog = element['assignmentInformation'];
                                _this.dr.site = element['shipToPartyPurchaseOrderNumber'];
                                _this.dr.deliveryAddress = element['customerCity'];
                            }
                        });
                        tableData_1 = tableData_1.concat(data);
                        _this.dataSource = new MatTableDataSource(tableData_1);
                    }
                }, function (error) {
                    _this.snackBar.open('OV já utilizada ou fora do sistema', '', { duration: 4000 });
                });
            }
        }
    };
    NewDeliveryRequestComponent.prototype.searchNFe = function () {
        var _this = this;
        // reseta itens selecionados
        this.selection.clear();
        // console.log('searchNFe',this.inputNFs);
        if (this.inputNFs === undefined) {
            this.snackBar.open('Preencha um valor de NFe', 'Ok', { duration: 4000 });
        }
        else {
            this.nfService.getAll(this.inputNFs).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                if (!data || data.drId !== null) {
                    _this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
                }
                else {
                    _this.dataSource = new MatTableDataSource([data]);
                }
            }, function (error) {
                // console.log(error);
                _this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
            });
        }
    };
    return NewDeliveryRequestComponent;
}());
export { NewDeliveryRequestComponent };
var OverviewExampleDialogComponent = /** @class */ (function () {
    function OverviewExampleDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    OverviewExampleDialogComponent.prototype.onYesClick = function () {
        this.data.value = 1;
        this.dialogRef.close();
    };
    OverviewExampleDialogComponent.prototype.onNoClick = function () {
        this.data.value = 0;
        this.dialogRef.close();
    };
    return OverviewExampleDialogComponent;
}());
export { OverviewExampleDialogComponent };
