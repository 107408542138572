<h1 mat-dialog-title>Anexo de Confirmação</h1>
<div mat-dialog-content>
  <p>Para resolver essa Ocorrência é obrigatório a inserção do Anexo</p>
  <form #form="ngForm" class="row">
      <mat-form-field class="col-md-6">
        <input matInput disabled placeholder="Tipo de UOC" [(ngModel)]="uocSelected" name="uocType">
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-select placeholder="DRs" [formControl]="myControl" multiple name="DRs" required>
          <mat-option *ngFor="let dr of drList" [value]="dr.id">{{dr.idDRidentifier}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-10">
        <input matInput style="width: 100%" placeholder="Comentário" name="text" [(ngModel)]="data.text" required>
      </mat-form-field>
      <div class="row justify-content-end buttons-row">
        <button class="blue col-8">
          <app-upload-files name="files" [id]="occ.id" [entity]="'occurrence'"
            (messageEvent)="receiveFile($event)">
          </app-upload-files>
        </button>
    </div>
  </form>
</div>

<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button [disabled]="!form.valid || !fileUploaded" (click)="onYesClick()">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
