export var OCCURRENCE_STATUS;
(function (OCCURRENCE_STATUS) {
    OCCURRENCE_STATUS["SENT_TO_LSP"] = "SENT_TO_LSP";
    OCCURRENCE_STATUS["OCCURRENCE_TO_RESOLVE"] = "OCCURRENCE_TO_RESOLVE";
    OCCURRENCE_STATUS["OCCURRENCE_RESOLVED"] = "OCCURRENCE_RESOLVED";
    OCCURRENCE_STATUS["OCCURRENCE_DENIED"] = "OCCURRENCE_DENIED";
    OCCURRENCE_STATUS["OCCURRENCE_COST_APPROVAL"] = "OCCURRENCE_COST_APPROVAL";
    OCCURRENCE_STATUS["OCCURRENCE_COST_APPROVAL_DENIED"] = "OCCURRENCE_COST_APPROVAL_DENIED";
    OCCURRENCE_STATUS["OCCURRENCE_COST_APPROVAL_ACCEPT"] = "OCCURRENCE_COST_APPROVAL_ACCEPT";
})(OCCURRENCE_STATUS || (OCCURRENCE_STATUS = {}));
var Occurrence = /** @class */ (function () {
    function Occurrence() {
        this.id = null;
        this.idMDR = null;
        this.uoc = '';
        this.text = '';
        this.status = '';
        this.createdAt = null;
        this.resolution = '';
        this.value = 0;
        this.multiplicator = 1;
        this.file = null;
    }
    return Occurrence;
}());
export { Occurrence };
