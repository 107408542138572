import { OnInit, EventEmitter } from '@angular/core';
import { SideNavService } from '../../services/sidenav.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { environment } from '../../../environments/environment';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(sidenavService, router, drService, mdrService) {
        this.sidenavService = sidenavService;
        this.router = router;
        this.drService = drService;
        this.mdrService = mdrService;
        this.toggled = new EventEmitter();
        this.currentUser = { token: '', user: '' };
        this.haveNewMDR = false;
        this.haveNewOccurrence = false;
        this.haveNewMDRLSP = false;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // if ( this.currentUser.user.role === 1 || this.currentUser.user.role === 3 ) {
        //   this.mdrService.haveNewOccurrence().subscribe( (response) => {
        //     this.haveNewOccurrence = response;
        //   });
        // }
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.version = environment.VERSION;
        // this.mdrService.getAllLSP().subscribe( (data) => {
        //   // console.log(data);
        //   if (data.length !== 0) {
        //     this.haveNewMDRLSP = true;
        //  }
        // });
    };
    HeaderComponent.prototype.sideNavToggle = function () {
        this.toggled.emit(true);
    };
    HeaderComponent.prototype.search = function () {
        this.router.navigate(['ovsearch/' + this.searchValue]);
        this.searchValue = '';
    };
    HeaderComponent.prototype.goToHome = function () {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        var role = user.user.role;
        if (role === 1 || role === 3) {
            this.router.navigate(['cockpit']);
        }
        else if (role === 5 || role === 12 || role === 14) {
            this.router.navigate(['cockpit']);
        }
        else if (role === 7) {
            this.router.navigate(['finance-list']);
        }
        else if (role === 9) {
            this.router.navigate(['reports']);
        }
        else if (role === 8) {
            this.router.navigate(['expedition']);
        }
        else if (role === 2) {
            this.router.navigate(['new-delivery-request']);
        }
    };
    HeaderComponent.prototype.goToNotification = function () {
        this.router.navigate(['occurrence-list']);
        this.toggled.emit(true);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
