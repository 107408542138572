import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import * as jwtDecode from 'jwt-decode';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class AuthService {
  public token: string;
  private user: any;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    // console.log(this.token);
  }

  public isTokenExpired(jwt) {
    const token = jwtDecode(jwt);
    const time = {
      now : Date.now(),
      token: token.exp * 1000
    };
    if (time.now < time.token) {
      return false;
    }
    return true;
  }

  public isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // Check whether the token is expired and return
    // true or false
    if (user === null) {
      return false;
    } else if (user.token === null || user.token.message === 'Not a valid email or password') {
      return false;
    }
    return !this.isTokenExpired(user.token);
  }

  ssoLogin(email: string) {
    return this.http.post(base_url + 'sso/login', { email: email });
  }

  login(username: string, password: string, captchaToken: string): Observable<string> {
      const header = new HttpHeaders().set('x-api-key', api_key).set('Authorization', 'Bear');
      return this.http.post(base_url + 'JWT/', {
          user: {
              email: username,
              password: password
          },
          captcha: captchaToken
      }, {headers: header}).map((response: {token: string, user: any}) => {
        if (response.user) {
              localStorage.setItem('currentUser', JSON.stringify({ user: response.user, token: response.token }));
              // console.log(JSON.parse(localStorage.getItem('currentUser')));
              if (response.token === 'Not a valid email or password') {
                  return 'cred';
              } else {
                  return 'ok';
              }
          } else {
              // console.log('Error');
              return 'cap';
          }
      });
  }

  validateForgotPasswordToken(username: string): any {
    const header = new HttpHeaders().set('x-api-key', api_key);
    return this.http.post(base_url + 'JWT/validateForgotPasswordToken', { user: {token: username}}, {headers: header})
               .map((response) => {
                 if (response) {
                   return response;
                 } else {
                   return false;
                 }
               });
  }
  sendEmail(username: string, token: string): Observable<boolean> {
    const header = new HttpHeaders().set('x-api-key', api_key);
    return this.http.post(base_url + 'JWT/sendEmail', { user: {email: username}, captcha: token}, {headers: header})
               .map((response) => {
                 // console.log(response)
                 if (response) {
                   return true;
                 } else {
                   return false;
                 }
               });
  }
  changeForgotPassword(username: string, password: string): Observable<boolean> {
    const header = new HttpHeaders().set('x-api-key', api_key);
    return this.http.post(base_url + 'JWT/changeForgotPassword', { user: {email: username, password: password}}, {headers: header})
               .map((response: {token, user}) => {
                 if (response) {
                   return true;
                 } else {
                   return false;
                 }
               });
  }

  validateForgotPasswordMobileToken(username: string): any {
    const header = new HttpHeaders().set('x-api-key', api_key);
    return this.http.post(base_url + 'JWT/mobile/password/check', { user: {token: username}}, {headers: header})
               .map((response) => {
                 if (response) {
                   return response;
                 } else {
                   return false;
                 }
               });
  }
  changeForgotPasswordMobile(username: string, password: string): Observable<boolean> {
    const header = new HttpHeaders().set('x-api-key', api_key);
    return this.http.post(base_url + 'JWT/mobile/password/reset', { user: {email: username, password: password}}, {headers: header})
               .map((response: {token, user}) => {
                 if (response) {
                   return true;
                 } else {
                   return false;
                 }
               });
  }

  logout(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'JWT/logout', {}, {headers: header}).map( (response) => {
      return response;
    });
  }

  checkTokenWithoutLogin(token: string): Observable<any> {
    return this.http.post(base_url + 'no-login/token/check', {token: token});
  }

  getUserActivities(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'JWT/activities', {headers: header});
  }

  removeUser(email: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.delete(base_url + 'JWT/' + email, {headers: header});
  }

}
