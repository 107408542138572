import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogisticRoutingModule } from './logistic-routing.module';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AgmCoreModule, MarkerManager} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { MatMenuModule} from '@angular/material/menu';
import {MatIconModule,
  MatDatepickerModule,
  MatCardModule,
  MatTooltipModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatPaginator,
  MatExpansionModule,
  MatButtonModule,
  MatTableDataSource,
        MatCheckboxModule} from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';

import { GeolocationComponent } from './geolocation/geolocation.component';
import {DeliveryRouteComponent, ViewInfoComponent, EditDrDialogComponent, HistoryDialogComponent, ViewNfOvComponent} from './delivery-route/delivery-route.component';
import { TransportComponent } from './transport/transport.component';
import { TaxComponent, ConfirmDeleteDialogComponent, CreateCostAvoidanceComponent, FinanceHistoryDialogComponent } from './tax/tax.component';
import { OccurrenceComponent, ConfirmDeleteOccDialogComponent } from './occurrence/occurrence.component';
// import { CostAvoidanceComponent } from './cost-avoidance/cost-avoidance.component';
import {AttachmentsComponent, RemoveNfsDialogComponent, AddNfsDialogComponent} from './attachments/attachments.component';

import { ImageLightboxModule } from '../../misc/image-lightbox/image-lightbox.module';

import { UploadModule} from '../../misc/upload/upload.module';
import {PipesModule} from '../../misc/pipes/pipes.module';

import { LogisticOrderComponent, MdrHistoryDialogComponent } from './logistic-order.component';
import { SlaComponent } from './sla/sla.component';
import { AirplaneComponent } from './airplane/airplane.component';
import { TableModule } from '../../misc/table/table.module';
import { AcceptOccurrenceDialogComponent, CostApprovalComponent, EditOccurrenceDialogComponent, OccurrenceHistoryDialogComponent } from './cost-approval/cost-approval.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    MatDividerModule,
    MatPaginatorModule,
    MatButtonModule,
    CommonModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatTableModule,
    MatMenuModule,
    TableModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    UploadModule,
    LogisticRoutingModule,
    MatSelectModule,
    MatExpansionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageLightboxModule,
    AgmDirectionModule,
    MatTabsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCCmErwi13bcNNDELOOrlEby8ESb2nkwQ0',
      libraries: ['places'],
      language: 'pt'
    }),
  ],
  declarations: [
    LogisticOrderComponent,
    GeolocationComponent,
    DeliveryRouteComponent,
    ViewInfoComponent,
    EditDrDialogComponent,
    RemoveNfsDialogComponent,
    AddNfsDialogComponent,
    ConfirmDeleteDialogComponent,
    TransportComponent,
    TaxComponent,
    OccurrenceComponent,
    CreateCostAvoidanceComponent,
    EditOccurrenceDialogComponent,
    OccurrenceHistoryDialogComponent,
    CostApprovalComponent,
    // CostAvoidanceComponent,
    AcceptOccurrenceDialogComponent,
    AttachmentsComponent,
    SlaComponent,
    AirplaneComponent,
    HistoryDialogComponent,
    ViewNfOvComponent,
    MdrHistoryDialogComponent,
    FinanceHistoryDialogComponent,
    ConfirmDeleteOccDialogComponent
  ],
  entryComponents: [
    ViewInfoComponent,
    TransportComponent,
    ConfirmDeleteDialogComponent,
    EditOccurrenceDialogComponent,
    OccurrenceHistoryDialogComponent,
    AcceptOccurrenceDialogComponent,
    EditDrDialogComponent,
    RemoveNfsDialogComponent,
    AddNfsDialogComponent,
    CreateCostAvoidanceComponent,
    HistoryDialogComponent,
    ViewNfOvComponent,
    MdrHistoryDialogComponent,
    FinanceHistoryDialogComponent,
    ConfirmDeleteOccDialogComponent
  ],
  exports: []
})
export class LogisticOrderModule { }
