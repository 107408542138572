export class PriceMaker {
  id: Number;
  shippingCompany: String;
  service: String;
  region: String;
  vehicle: String;
  price: String;
  absolute: String;
  sapCode: String;

  constructor() {
    this.id = null;
    this.shippingCompany = '';
    this.service = '';
    this.region = '';
    this.vehicle = '';
    this.price = '';
    this.absolute = '';
    this.sapCode = '';
  }
}
