/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./composite-bars-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./composite-bars-chart.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../misc/export-xlsx/export-xlsx";
var styles_CompositeBarsChartComponent = [i0.styles];
var RenderType_CompositeBarsChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompositeBarsChartComponent, data: {} });
export { RenderType_CompositeBarsChartComponent as RenderType_CompositeBarsChartComponent };
export function View_CompositeBarsChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "i", [["class", "fa fa-file-pdf click-button"], ["matTooltip", "Download em excel"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.download() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "Download em excel"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.container, ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_CompositeBarsChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-composite-bars-chart", [], null, null, null, View_CompositeBarsChartComponent_0, RenderType_CompositeBarsChartComponent)), i1.ɵdid(1, 114688, null, 0, i9.CompositeBarsChartComponent, [i10.TranslateService, i11.ExcelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompositeBarsChartComponentNgFactory = i1.ɵccf("app-composite-bars-chart", i9.CompositeBarsChartComponent, View_CompositeBarsChartComponent_Host_0, { container: "container" }, {}, []);
export { CompositeBarsChartComponentNgFactory as CompositeBarsChartComponentNgFactory };
