export class User {
    id: number;
    name: string;
    password: string;
    email: string;
    role: number;

    constructor() {
        this.id = null;
        this.name = '';
        this.password = '';
        this.email = '';
        this.role = null;
    }
}
