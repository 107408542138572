<h1 mat-dialog-title><b>Deletar usuário</b></h1>
<div mat-dialog-content>
   <p>Tem certeza que deseja deletar o usuário abaixo? A ação não poderá ser desfeita</p>
   <p>Nome: <b>{{data.u_name}}</b></p>
   <p>Email: <b>{{data.u_email}}</b></p>
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button (click)="onYesClick()" cdkFocusInitial>Sim</button>
   <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
