var PriceMaker = /** @class */ (function () {
    function PriceMaker() {
        this.id = null;
        this.shippingCompany = '';
        this.service = '';
        this.region = '';
        this.vehicle = '';
        this.price = '';
        this.absolute = '';
        this.sapCode = '';
    }
    return PriceMaker;
}());
export { PriceMaker };
