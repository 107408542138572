import { Component, OnInit, Input, ElementRef,
         HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.scss']
})
export class FormUploadComponent {
  @Input() label;
  @Input() name;
  @Input() showName = true;
  @Output() setFile: EventEmitter<any> = new EventEmitter();
  selecionado: any;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event && event.item(0);
    this.selecionado = file.name;
    this.setFile.emit(file);
  }

  constructor(private host: ElementRef) {
    (host.nativeElement as HTMLInputElement).value = '';
  }

  onClick() {
    document.getElementById('fileInput-' + this.name).click();
  }
}
