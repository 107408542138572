import { Routes } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';
import { LogisticOrderComponent } from './logistic-order.component';
var routes = [
    { path: 'logistic-order/:id', component: LogisticOrderComponent, canActivate: [RoleGuardService] }
];
var LogisticRoutingModule = /** @class */ (function () {
    function LogisticRoutingModule() {
    }
    return LogisticRoutingModule;
}());
export { LogisticRoutingModule };
