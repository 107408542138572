<div class="mx-5 my-4 d-flex bd-highlight">
  <div class="p-2 flex-grow-1 bd-highlight">
    <p class="mat-h1">{{ 'DR_BOOKING/title' | translate }}</p>
    <p class="mx-4" *ngIf="uploaded" (click)='uploaded = !uploaded'><i class="fas fa-arrow-left"></i>Voltar</p>
  </div>
  <!--<div class="p-2 bd-highlight">
    <input [(ngModel)]="paste_input" (paste)="fillFromClipboard($event)" matInput placeholder="{{ 'ATTACHMENTS/paste' | translate }}">
  </div> -->
</div>


<div class="colored-background pt-5">
  <mat-card *ngIf="uploaded" class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <div class="row" style="width: 100%;">
      <div class="col-md-12" *ngIf="dataSourceDR">
        <div class="mat-elevation-z8 col-md-12" *ngIf="dataSourceDR.data.length > 0" style="margin-bottom: 30px;">
          <!-- <app-table *ngIf="mdr.length > 0"
            [columns]="columns"
            [data]="mdr"
            [this]="this"
            [clickCallback]="goTo"
          ></app-table> -->
          <table mat-table #table [dataSource]="dataSourceDR" matSort>
            <ng-container matColumnDef="dateScheduling">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/date' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date: "dd/MM/yy, h:mm a"}} </td>
            </ng-container>
            <ng-container matColumnDef="deliveryAddress">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/delivery-address' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
            </ng-container>
            <ng-container matColumnDef="infraType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/infra' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.infraType}} </td>
            </ng-container>
            <ng-container matColumnDef="obs">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/observation' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.obs}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/list-ov' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>
            <ng-container matColumnDef="pickupAddress">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/collect-address-hub' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.pickupAddress}} </td>
            </ng-container>
            <ng-container matColumnDef="site">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/site' | translate }} <th>
              <td mat-cell *matCellDef="let element"> {{element.site}} </td>
            </ng-container>
            <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns3;" [ngClass]="{'make-red': row.idDRidentifier === 'red' }"></tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row" style="width: 100%;">
      <div class="col-md-12">
        <span *ngIf="!allValid && dataSourceDR"><button mat-raised-button class="left green-button" *ngIf="dataSourceDR.data.length> 0" (click)="validate()">Validate DRs</button></span>
        <button mat-raised-button class="left green-button" *ngIf="allValid" (click)="createRequisition()">{{'NEW-DELIVERY-REQUEST/text/create' | translate}}</button>
      </div>
    </div>
  </mat-card>
  <mat-card *ngIf="!uploaded" class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <input #fileInput multiple
              class="hidden"
              type='file'
              accept='xlsx'
              (change)='openFile($event)'
            >
    <div  style="width: 100%;" class="d-flex flex-wrap"><button mat-raised-button class="w-100 primary-button" style="margin-bottom: 20px;"
      (click)="fileInput.click()">{{'DR_BOOKING/btn/upload-massive' | translate}}</button></div>
      <div class="title col-md-3">
        <a href="../../../assets/extras/multiUpload.xlsx" download="">{{ 'NEW-DELIVERY-REQUEST/dowload_model' | translate }} <i matSuffix font class="fa fa-download"></i></a>
      </div>
    <p style="width: 100%;">------ OU ------</p>
    <form style="width: 100%;" #form="ngForm" class="d-flex flex-wrap">
      <mat-form-field class="col-md-12">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}"
          [(ngModel)]="salesOrders" name="salesOrders">
      </mat-form-field>
      <button mat-raised-button class="w-100 primary-button" style="margin-bottom: 20px;"
        (click)="searchSydleInformation()">{{'DR_BOOKING/btn/search-sydle' | translate}}</button>
      <div class="col-md-6">
        <mat-form-field style="width: calc(100% - 28px);">
          <input matInput required #addrSearch type="text" autocapitalize="off" spellcheck="off"
            placeholder="{{ 'DR_BOOKING/text/delivery-address' | translate }}" name="deliveryAddress"
            id="deliveryAddress" [(ngModel)]="dr.deliveryAddress" (change)="convertAddressToLatLng()">
        </mat-form-field>
        <span (click)="toggleMap()"><i class="fa fa-map"></i></span>
      </div>
      <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-2">
          <input matInput required name="prolog" placeholder="Prolog" [(ngModel)]="dr.prolog" id="prolog">
        </mat-form-field>

        <mat-form-field class="col-md-2">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/lat' | translate }}" id="latitudeAddress"
            name="latitudeAddress" [(ngModel)]="dr.lat" (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-2">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/lng' | translate }}" id="longitudeAddress"
            name="longitudeAddress" [(ngModel)]="dr.lng" (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-3">
          <input matInput required disabled name="distance" placeholder="Distance" [(ngModel)]="distance"><span
            matSuffix> KM</span>
        </mat-form-field>
        <div class="col-md-3">
          <button mat-raised-button class="w-100 primary-button">
            <i matListIcon class="fa fa-search" style="color:white" (click)="convertLatLngToAddress()"></i>
          </button>
        </div>
      </div>
      <div *ngIf="distance !== null">
        <div *ngIf="map_show" class="col-md-12">
          <agm-map [latitude]="dr.lat" [longitude]="dr.lng" [mapTypeControl]="true" [zoom]="15">
            <agm-marker [iconUrl]="red_marker" [latitude]="dr.lat" [longitude]="dr.lng"></agm-marker>
            <agm-marker *ngFor="let m of map_data" [iconUrl]="blue_marker" [latitude]="m.lat" [longitude]="m.lng">
            </agm-marker>
          </agm-map>
          <br>
        </div>
        <mat-form-field class="col-md-6">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/name-receptor' | translate }}"
            [(ngModel)]="dr.receiverName" name="receiverName">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required mask="(00) 00009-0000" type="number" pattern="^[1-9]{2}[0-9]{4,5}[0-9]{4}$"
            placeholder="{{ 'DR_BOOKING/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone"
            name="receiverPhone">
          <mat-icon matSuffix matTooltip="Digite o telefone sem caracteres especiais (ex: 55555555555)" class="my-icon">info</mat-icon>
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/name-supervisor' | translate }}"
            [(ngModel)]="dr.supervisorName" name="dr.supervisorName">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required  mask="(00) 00009-0000" type="number" pattern="^[1-9]{2}[0-9]{4,5}[0-9]{4}$" 
            placeholder="{{ 'DR_BOOKING/text/cellphone-higher' | translate }}" [(ngModel)]="dr.supervisorPhone"
            name="dr.supervisorPhone" > 
          <mat-icon matSuffix matTooltip="Digite o telefone sem caracteres especiais (ex: 55555555555)" class="my-icon">info</mat-icon>
        </mat-form-field>

        <mat-form-field class="col-md-6">
          <input matInput required name="dateCollect" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
            [placeholder]="'DR_BOOKING/text/date' | translate" [(ngModel)]="dr.dateScheduling">
          <owl-date-time #dt></owl-date-time>
        </mat-form-field>
        <mat-form-field class="col-md-3">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/site' | translate }}" [(ngModel)]="dr.site"
            name="dr.site" (change)="checkSite()">
          <i class="fa" [ngClass]="site.color ? [ site.icons[site.color] , site.classes[site.color] ] : ''" matSuffix
            [matTooltip]="site.tooltips[site.color]"></i>
        </mat-form-field>
        <mat-form-field class="col-md-3">
          <input matInput required placeholder="{{ 'DR_BOOKING/text/infra' | translate }}" [(ngModel)]="dr.infraType"
            name="dr.infraType">
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <input matInput placeholder="{{ 'DR_BOOKING/text/unification-id' | translate }}"
            [(ngModel)]="dr.unificationId" name="unificationId">
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <input matInput placeholder="{{ 'DR_BOOKING/text/site-team' | translate }}" [(ngModel)]="dr.siteTeam"
            name="siteTeam">
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>{{'DR_BOOKING/text/client' | translate}}</mat-label>
          <mat-select required [(ngModel)]="dr.client" name="client">
            <mat-option *ngFor="let client of clientList" [value]="client">
              {{client}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="col-md-12">
          <textarea matInput placeholder="{{ 'DR_BOOKING/text/obs' | translate }}" [(ngModel)]="dr.obs"
            name="dr.obs"></textarea>
        </mat-form-field>
        <!-- fim preenchimento campos -->
      </div>
    </form>
    <div *ngIf="showSiteInfo" class="col-md-12 row">
      <div class="col-md-3">
        <p>Lat:</p>
        <p>{{siteSelected.lat}}</p>
      </div>
      <div class="col-md-3">
        <p>Lng:</p>
        <p>{{siteSelected.lng}}</p>
      </div>
      <div class="col-md-3">
        <p>Regional:</p>
        <p>{{siteSelected.regional}}</p>
      </div>
      <div class="col-md-3">
        <p>Cliente:</p>
        <p>{{siteSelected.operadora}}</p>
      </div>
      <div class="col-md-3">
        <p>Obs:</p>
        <p>{{siteSelected.obs}}</p>
      </div>
      <div class="col-md-3">
        <p>Operação:</p>
        <p>{{siteSelected.operation.join(", ")}}</p>
      </div>
      <div class="col-md-3">
        <p>Vistoria:</p>
        <p>{{siteSelected.vistoriadatahora | date: "dd/MM/yy, h:mm a"}}</p>
      </div>
      <div class="col-md-3">
        <p>Site ID:</p>
        <p>{{siteSelected.code}}</p>
      </div>
      <div class="col-md-3">
        <p>LSP:</p>
        <p>{{siteSelected.shippingcompany ? siteSelected.shippingcompany.name : 'Sem LSP associada'}}</p>
      </div>
      <div class="col-md-3">
        <p>Laudo:</p>
        <p>
          <a [routerLink]="'/site-report/'+siteSelected.id">link</a>
        </p>
      </div>
    </div>
    <!-- fim informações do site -->
    <p *ngIf="ovValidated === false" style="color:darkred"><i class="fas fa-exclamation-triangle"></i> {{
      'DR_BOOKING/booking_not_allowed' | translate}}</p>
    <button mat-raised-button class="w-100 primary-button" style="margin-bottom: 20px;"
      (click)="validateOV()">{{'DR_BOOKING/btn/validateOV' | translate}}</button>
    <div *ngFor="let ov of listOV">
      <div class="col-md-3">
        <p>OV: {{ov.ov}}</p>
        <p>Status: {{ ov.solut ? 'Disponível': 'Não Disponível'}}</p>
      </div>
    </div>
    <ng-container *ngIf="ovValidated && distance">
      <div class="w-100" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="end">
          <input hidden type="file" #fileInput (change)="selectFile($event)" multiple>
          <button (click)="fileInput.click()" mat-raised-button class="w-100 button-yellow">
            {{ 'DR_BOOKING/upload' | translate }}
          </button>
        </div>

        <div *ngFor="let file of uploadedFiles; index as index" class="padding" fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <a [href]="file.path" download> {{ file.name }} </a>
          </div>
          <button mat-raised-button class="right warn-button" (click)="removeFile(file, index)">
            {{ 'ATTACHMENTS/remove' | translate }}
          </button>
        </div>

        <button mat-raised-button class="primary-button" fxFlex="100"
          [disabled]="!form.valid || distance === null" (click)="createDRBooking()">
          {{'DR_BOOKING/btn/create-booking' | translate }}
        </button>
      </div>
    </ng-container>
    <div class="w-100" *ngIf="ovValidated && !distance">
      <button *ngIf="ovValidated" mat-raised-button class="w-100 primary-button" [disabled]="true">Calcule a disância da
        viagem</button>
    </div>
    <button *ngIf="ovValidated && distance" mat-raised-button class="w-100 red-button"
      (click)="removeBooking()">{{'DR_BOOKING/btn/delete-booking' | translate}}</button>
  </mat-card>
</div>
