import { OnInit } from '@angular/core';
import { CompositeBarsChartComponent } from './composite-bars-chart/composite-bars-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DashboardService } from '../../providers/dashboard.service';
import { SpeedometerChartComponent } from './speedometer-chart/speedometer-chart.component';
import { BasicBarChartComponent } from './basic-bar-chart/basic-bar-chart.component';
import { TranslateService } from '@ngx-translate/core';
import { WordcloudChartComponent } from './wordcloud-chart/wordcloud-chart.component';
var GraphCardComponent = /** @class */ (function () {
    function GraphCardComponent(dashboardService, translateService) {
        this.dashboardService = dashboardService;
        this.translateService = translateService;
        this.eco = false;
    }
    GraphCardComponent.prototype.ngOnInit = function () {
    };
    GraphCardComponent.prototype.createChart = function (data, id, option) {
        switch (this.selector) {
            case 'pie':
                this.pieChartComponent.createChart(data, id, option);
                break;
            case 'composite-bars':
                if (data['eco']) {
                    this.eco = true;
                }
                this.compositeBarsChartComponent.createChart(data, id, option);
                break;
            case 'speedometer':
                this.speedometerChartComponent.createChart(data, id, this.translateService, option);
                break;
            case 'bar':
                this.basicBarChartComponent.createChart(data, id, option);
                break;
            case 'wordcloud':
                this.wordcloudChartComponent.createChart(data, id, this.translateService);
                break;
        }
    };
    return GraphCardComponent;
}());
export { GraphCardComponent };
