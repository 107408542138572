<mat-toolbar class="float-right">
   <mat-toolbar-row class="justify-content-between">
      <div>
         <img id="logo" class="ml-5" src="../../../../assets/images/logo-name.png">
        <span style="font-size: 8px; color: white;">v{{version}}</span>
      </div>
      <div class="text-white">
         <b>{{ 'HEADER/hi' | translate }},</b> {{ 'WITHOUT_LOGIN/guest' | translate }}
      </div>
   </mat-toolbar-row>
</mat-toolbar>
