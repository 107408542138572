import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ChatService = /** @class */ (function () {
    function ChatService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ChatService.prototype.newMessagesAvailable = function (id, chatType) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'chat/UnseenMessages/' + id + '/' + chatType, { headers: header }).map(function (response) {
            return response;
        });
    };
    ChatService.prototype.getByChannel = function (id, chatType) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'chat/All/' + id + '/' + chatType, { headers: header }).map(function (response) {
            return response;
        });
    };
    return ChatService;
}());
export { ChatService };
