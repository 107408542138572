var NF = /** @class */ (function () {
    function NF() {
        this.id = null;
        this.invoiceNumber = null;
        this.series = null;
        this.client = null;
        this.type = null;
        this.weight = null;
        this.materials = null;
        this.totalValue = null;
        this.emissionDate = null;
        this.width = null;
        this.height = null;
        this.depth = null;
        this.drId = null;
    }
    return NF;
}());
export { NF };
