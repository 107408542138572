<h1 mat-dialog-title>Criar novo HUB</h1>
<div mat-dialog-content>
    <form class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
        <input matInput required placeholder="Nome do HUB" name="name" [(ngModel)]="hub.name">
    </mat-form-field>
    <mat-form-field class="col-md-6">
        <input matInput required #newAddres type="text"  autocapitalize="off" spellcheck="off"
            placeholder="Endereço do HUB"
            id="hubAddress"
            name="address"
            (change)="convertAddressToLatLng()"
            [(ngModel)]="hub.address"
            >
    </mat-form-field>
    <mat-form-field class="col-md-6">
        <input matInput required placeholder="Latitude"
            id="latitudeAddress"
            (change)="convertLatLngToAddress()"
            name="lat"
            [(ngModel)]="hub.lat"
            >
    </mat-form-field>
    <mat-form-field class="col-md-6">
        <input matInput required placeholder="Longitude"
            id="longitudeAddress"
            (change)="convertLatLngToAddress()"
            name="lng"
            [(ngModel)]="hub.lng"
            >
    </mat-form-field>
    <mat-form-field class="col-md-6">
        <mat-select required placeholder="Transportadora" name="lsp" [(ngModel)]="lsp"  >
          <mat-option *ngFor="let elem of lspList" [value]="elem.id"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button (click)="onYesClick()" cdkFocusInitial>Criar</button>
    <button mat-button cdkFocusInitial (click)="onNoClick()">Voltar</button>
</div>
