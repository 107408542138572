import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MdrService } from '../../../providers/mdr.service';
var SlaComponent = /** @class */ (function () {
    function SlaComponent(route, router, mdrService) {
        this.route = route;
        this.router = router;
        this.mdrService = mdrService;
        this.isSLA = false;
        this.isUserSLA = false;
        this.editMDRSla = false;
        this.role = null;
        this.newSla = false;
        this.currentUser = null;
        this.mdrSla = [
            { name: 'Yes', value: true },
            { name: 'No', value: false },
        ];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
    }
    SlaComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id$ = this.route.paramMap.pipe(switchMap(function (params) {
            return _this.mdrService.getMDR(parseInt(params.get('id'), 10));
        }));
        id$.subscribe(function (response) {
            _this.isUserSLA = !response.deliveryOutSOP;
        });
        var sla$ = this.route.paramMap.pipe(switchMap(function (params) {
            return _this.mdrService.isSLA(parseInt(params.get('id'), 10));
        }));
        sla$.subscribe(function (response) {
            _this.isSLA = response;
        });
    };
    SlaComponent.prototype.editSla = function () {
        this.editMDRSla = !this.editMDRSla;
    };
    SlaComponent.prototype.saveSla = function () {
        var _this = this;
        this.editMDRSla = !this.editMDRSla;
        if (this.newSla) {
            this.mdrService.setSLA(this.id).subscribe(function (response) {
                _this.isUserSLA = true;
            });
        }
        else {
            this.mdrService.unsetSLA(this.id).subscribe(function (response) {
                _this.isUserSLA = false;
            });
        }
    };
    return SlaComponent;
}());
export { SlaComponent };
