import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteService, ESTRUTURA } from '../../providers/site.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Observable';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import 'rxjs/add/operator/map';

import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-site-report',
  templateUrl: './site-investigation-report.component.html',
  styleUrls: ['./site-investigation-report.component.scss']
})
export class SiteInvestigationReportComponent implements OnInit {
  @ViewChild('form') form;
  siteid: number = null;
  token: string = null;
  site: any = null;
  report = false;
  siteFromCookies: any = {};
  enums: any = {};
  tipos: any = [];
  SITE_ESTRUTURA = ESTRUTURA;
  keysForCookies = [
    'lat', 'lng', 'regional', 'infra', 'tipo',
    'form_1_1', 'form_1_2', 'form_1_3', 'form_1_4', 'form_1_5', 'form_1_6', 'form_1_7', 'form_1_8', 'form_1_9', 'form_2_1', 'form_2_2', 'form_2_3', 'form_2_4', 'form_2_5', 'form_2_6', 'form_2_7', 'form_2_8', 'form_2_9', 'form_3_1', 'form_3_2', 'form_3_3', 'form_4_1', 'form_4_2', 'form_4_3', 'form_5_1', 'form_5_2', 'form_5_3', 'form_6_1', 'form_6_2', 'form_6_3', 'form_6_4', 'form_6_5', 'form_7_1', 'form_7_2', 'form_7_3',
    'form_1_1_obs', 'form_1_2_obs', 'form_1_3_obs', 'form_1_4_obs', 'form_1_5_obs', 'form_1_6_obs', 'form_1_7_obs', 'form_1_8_obs', 'form_1_9_obs', 'form_2_1_obs', 'form_2_2_obs', 'form_2_3_obs', 'form_2_4_obs', 'form_2_5_obs', 'form_2_6_obs', 'form_2_7_obs', 'form_2_8_obs', 'form_2_9_obs', 'form_3_1_obs', 'form_3_2_obs', 'form_3_3_obs', 'form_4_1_obs', 'form_4_2_obs', 'form_4_3_obs', 'form_5_1_obs', 'form_5_2_obs', 'form_5_3_obs', 'form_6_1_obs', 'form_6_2_obs', 'form_6_3_obs', 'form_6_4_obs', 'form_6_5_obs', 'form_7_1_obs', 'form_7_2_obs', 'form_7_3_obs'
  ];
  fileKeys = [
    'form_1_1_anexo', 'form_1_2_anexo', 'form_1_3_anexo', 'form_1_4_anexo', 'form_1_5_anexo', 'form_1_6_anexo', 'form_1_7_anexo', 'form_1_8_anexo', 'form_1_9_anexo', 'form_2_1_anexo', 'form_2_2_anexo', 'form_2_3_anexo', 'form_2_4_anexo', 'form_2_5_anexo', 'form_2_6_anexo', 'form_2_7_anexo', 'form_2_8_anexo', 'form_2_9_anexo', 'form_3_1_anexo', 'form_3_2_anexo', 'form_3_3_anexo', 'form_4_1_anexo', 'form_4_2_anexo', 'form_4_3_anexo', 'form_5_1_anexo', 'form_5_2_anexo', 'form_5_3_anexo', 'form_6_1_anexo', 'form_6_2_anexo', 'form_6_3_anexo', 'form_6_4_anexo', 'form_6_5_anexo', 'form_7_1_anexo', 'form_7_2_anexo', 'form_7_3_anexo'
  ];

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'printable', // the id of html/table element
    options: {
      pagespilt: true,
      html2canvas: {
        useCORS: true
      },
      pagebreak: {
        mode: 'avoid-all',
      }
    }
  };

  constructor(public aRoute: ActivatedRoute, public snackBar: MatSnackBar,
              private exportAsService: ExportAsService,
              public siteService: SiteService, private cookieService: CookieService) {
    this.aRoute.params.subscribe( (params) => {
      this.token = params.token;
      this.siteid = params.siteid;
    });
    this.enums = this.siteService.getEnums();
  }

  onClickPrint() {
    // this.siteService.getPDF(this.siteid).subscribe((data) => {
    //   FileSaver.saveAs(data, 'report.pdf');
    // });
    this.exportAsService.save(this.exportAsConfig, 'report');
  }

  ngOnInit() {
    this.siteService.readById(this.siteid).subscribe((data) => {
     // console.log(data);
      // separa tipo em objeto
      const tipo = {};
      this.enums.tipo.map( (el) => {
        tipo[el.value] = false;
      });
      data.tipo.map( (el) => {
        tipo[el.tipo] = true;
      });
      data.tipo = tipo;
      // armazena
      this.site = data;
    });
  }

}
