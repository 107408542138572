import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { PriceMakerService } from '../../../providers/price-maker.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { PriceMaker } from '../../../models/price-maker';

@Component({
  selector: 'app-price-maker-table',
  templateUrl: './price-maker-table.component.html',
  styleUrls: ['./price-maker-table.component.scss']
})

export class PriceMakerTableComponent implements OnInit {
  data: any[];
  role = 0;
  columns = [
    {name: 'id', value: 'id'},
    {name: 'PRICE_MAKER_TABLE/table/shippingCompany', value: 'shippingCompany'},
    {name: 'PRICE_MAKER_TABLE/table/service', value: 'service'},
    {name: 'PRICE_MAKER_TABLE/table/region', value: 'region'},
    {name: 'vehicle', value: 'vehicle'},
    {name: 'PRICE_MAKER_TABLE/table/price', value: 'price'},
    {name: 'PRICE_MAKER_TABLE/table/absolute', value: 'absolute'},
    {name: 'PRICE_MAKER_TABLE/table/sapCode', value: 'sapCode'},
    {name: 'createdAt', value: 'createdAt'},
    {name: 'createdBy', value: 'createdBy'},
    {name: 'updatedAt', value: 'updatedAt'},
    {name: 'updatedBy', value: 'updatedBy'}
  ];

  constructor(public pmService: PriceMakerService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public excelService: ExcelService) {
    const role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.role = role;
    this.data = [];
  }

  ngOnInit() {
    this.pmService.tableRead().subscribe((data) => {
      // pega as keys dos objetos retornados
      const keys = data.length ? Object.keys(data[0]) : [] ;
      // remove os valores null
      this.data = data.map( (el) => {
        for (let i = 0, l = keys.length; i < l; i++) {
          if ( el[keys[i]] === null ) {
            el[keys[i]] = '';
          }
        }
        // Removing internal/unnecessary info
        delete el.sc.active;
        delete el.sc.clientCnpj;
        el.createdAt = new Date(el.createdAt);
        el.updatedAt = new Date(el.updatedAt);
        return el;
      });

    });
  }

  addItem() {
    const dialogRef = this.dialog.open(NewPriceMakerTableDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result.what === 'insert' ) {
        this.pmService.tableCreate([result.data]).subscribe((data) => {
          this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        }, (er) => {
          if (er.status === 403) {
            this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', {duration: 4000});
          } else if (er.status === 200) {
            this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
          }
        });
      }
    });
  }

  onRowClick(row, that) {
    // that aponta para this, mas é necessário para garantir que não é o this do app-table
    that.openDialog(row);
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    const error = false;
    const ovList = [];
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(fileupload).subscribe( (data) => {
          // console.log(data);
          const multiple = [];
          data.forEach( (el, i, arr) => {
            const newPrice = new PriceMaker();
            if (el['id'] !== null) {
              newPrice.id = el['id'];
            }
            newPrice.absolute = el['absolute'];
            newPrice.price = el['price'];
            newPrice.region = el['region'];
            newPrice.sapCode = el['sapCode'];
            newPrice.service = el['service'];
            newPrice.shippingCompany = el['shippingCompany'];
            newPrice.vehicle = el['vehicle'];
            multiple.push(newPrice);
          });
          this.pmService.tableCreate(multiple).subscribe((el) => {
            this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
          }, (er) => {
            // console.log(er);
            if (er.status === 403) {
              this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', {duration: 4000});
            }
          });
        });
      }
    }
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(EditPriceMakerTableDialogComponent, {
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result.what === 'delete' ) {
        this.pmService.tableDelete(result.data.id).subscribe((data) => {
          this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        }, (er) => {
          // console.log(er);
          if (er.status === 403) {
            this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', {duration: 4000});
          }
        });
      } else if ( result.what === 'update' ) {
        this.pmService.tableUpdate(result.data).subscribe((data) => {
        }, (er) => {
          // console.log(er);
          if (er.status === 403) {
            this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', {duration: 4000});
          }
        });
      }
    });
  }

}

@Component({
  selector: 'app-dialog-edit-price-maker-table-dialog',
  templateUrl: 'dialog-edit-price-maker-table-dialog.html',
  styleUrls: ['./price-maker-table.component.scss']
})
export class EditPriceMakerTableDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditPriceMakerTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickRemove(): void {
    this.dialogRef.close({ what: 'delete', data: this.data });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'update', data: this.data });
  }
}

@Component({
  selector: 'app-dialog-new-price-maker-table-dialog',
  templateUrl: 'dialog-new-price-maker-table-dialog.html',
  styleUrls: ['./price-maker-table.component.scss']
})
export class NewPriceMakerTableDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewPriceMakerTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'insert', data: this.data });
  }
}
