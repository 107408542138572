<div class="colored-background table-background">
    <div class="mx-5 py-3">
        <mat-card class="rounded-corners">
            <mat-card-content>
                <div class="row">
                    <div [ngClass]=" role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders') ? 'col-md-2' : 'col-md-3'">
                        <mat-form-field class="center">
                            <mat-label>Relatorio</mat-label>
                            <mat-select [(value)]="report">
                                <mat-option *ngFor="let report of reportList" [value]="report">{{report.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf='report && report.type === "financial"' [ngClass]="role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders') ? 'col-md-2' : 'col-md-3'">
                        <mat-form-field class="center">
                        <input matInput type="text" placeholder="Insira POs separadas por vírgula" [(ngModel)]="filters">
                        </mat-form-field>
                    </div>

                    <div [ngClass]="role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders') ? 'col-md-2' : 'col-md-3'">
                        <mat-form-field class="center">
                          <input matInput
                            [owlDateTimeTrigger]="start"
                            [owlDateTime]="start"
                            placeholder="Inicio"
                            [min]="minDate"
                            [max]="maxDate"
                            [(ngModel)]="startDate">
                          <owl-date-time #start pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div [ngClass]="role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders') ? 'col-md-2' : 'col-md-3'" >
                        <mat-form-field class="center">
                          <input matInput
                            [owlDateTimeTrigger]="end"
                            [owlDateTime]="end"
                            placeholder="Fim"
                            [min]="minDate"
                            [max]="maxDate"
                            [(ngModel)]="endDate">
                          <owl-date-time #end pickerType="calendar"></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div *ngIf=" role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders')" [ngClass]=" role === 1 && report && (report.type === 'operational' || report.type === 'stakeholders') ? 'col-md-2' : 'col-md-3'">
                        <mat-form-field class="center">
                            <mat-select placeholder="Cliente" [(ngModel)]="byClient">
                                <mat-option *ngFor="let client of clientList" [value]="client">
                                    {{client}}
                                </mat-option>
                            </mat-select>
                            <button mat-icon-button matSuffix mat-select-trigger matTooltip="Resetar filtro" (click)="resetField()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <button mat-flat-button (click)="download()" class="center fill-h">Download XLSX</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>