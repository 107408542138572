var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDatepicker } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../providers/report.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
import { ConfigurationService } from '../../providers/configuration.service';
import { Observable } from 'rxjs';
import { ClientUserService } from '../../providers/client-user.service';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(translate, reportService, excel, router, configService, clientUserService, snackBar) {
        this.translate = translate;
        this.reportService = reportService;
        this.excel = excel;
        this.router = router;
        this.configService = configService;
        this.clientUserService = clientUserService;
        this.snackBar = snackBar;
        this.startDate = null;
        this.endDate = null;
        this.filters = null;
        this.minDate = new Date(2000, 0, 1);
        this.maxDate = new Date(new Date().getFullYear() + 10, 0, 1);
        this.reportList = [
            { type: 'LSP', name: 'LSP' },
            { type: 'LSPKPI', name: 'LSP KPI' },
            { type: 'perfLSP', name: 'Performance' },
            { type: 'operation', name: 'Operação' },
            { type: 'operational', name: 'Operacional' },
            { type: 'cockpit', name: 'MDRs e DRs' },
            { type: 'stakeholders', name: 'Stakeholders' },
            { type: 'cmdr', name: 'CMDR' },
            { type: 'cov', name: 'COV' },
            { type: 'poddp', name: 'POD DP' },
            { type: 'hub', name: 'Hub' },
            { type: 'inHub', name: 'inHub' },
            { type: 'dpunion', name: 'DP Union' },
            { type: 'uoc', name: 'UOC' },
            { type: 'copq', name: 'CoPQ' },
            { type: 'atachmentsUOC', name: 'atachmentsUOC' },
            { type: 'getDR', name: 'Entregas Criadas' },
            { type: 'getHubs', name: 'Relatório de Hubs' },
        ];
        this.report = null;
        this.loading = false;
        this.subscriptions = [];
        this.validUser = ['clara.gama+role5@awarelog.com', 'andressa.aquino@awarelog.com', 'mayara.porto@awarelog.com', 'nadine.blair@awarelog.com'];
        this.clientList = [];
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        this.email = currentUser.user.email;
    }
    ReportsComponent.prototype.ngOnInit = function () {
        if (this.role !== 1) {
            this.reportList = [{ type: 'stakeholders', name: 'Stakeholders' }, { type: 'perfLSP', name: 'Performance' }];
            if (this.role === 5 || this.role === 12 || this.role === 14) {
                this.reportList.push({ type: 'operational', name: 'Operacional' });
            }
        }
        if (this.validUser.includes(this.email)) {
            this.reportList = [{ type: 'getDR', name: 'Entregas Criadas' }];
        }
        if (this.role === 1) {
            this.reportList.push({ type: 'financial', name: 'Financeiro' });
        }
        if (this.role === 3) {
            this.reportList = [{ type: 'operational', name: 'Operacional' }];
        }
        this.getClientList();
    };
    ReportsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    ReportsComponent.prototype.getClientList = function () {
        var _this = this;
        this.clientUserService.getClients().subscribe(function (client) {
            _this.clientList = client.map(function (c) { return c.clientGroup; });
        });
    };
    ReportsComponent.prototype.resetField = function () {
        this.byClient = null;
    };
    ReportsComponent.prototype.checkAvailability = function (report) {
        var _this = this;
        return new Observable(function (observer) {
            _this.subscriptions.push(_this.configService.getConfiguration('reports', true).subscribe(function (configuration) {
                // console.log(report, configuration[report], configuration);
                if (report in configuration && 'available' in configuration[report]) {
                    // se o relatório existe na configuração
                    //   => retorna sua disponibilidade
                    observer.next(configuration[report].available);
                }
                else {
                    // caso contrário
                    //   => permite baixar
                    observer.next(true);
                }
            }, function (err) {
                // não existe esta configuração/restrição
                //   => permite baixar
                observer.next(true);
            }));
        });
    };
    ReportsComponent.prototype.download = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.startDate || (!this.endDate && this.report.type !== 'getHubs')) {
                    this.snackBar.open('Selecione as datas a consultar', 'Fechar', {
                        duration: 2000,
                    });
                    return [2 /*return*/];
                }
                this.subscriptions.push(this.checkAvailability(this.report.type).subscribe(function (available) {
                    if (available === true) {
                        _this.loading = true;
                        var filterWillSend = null;
                        var filterClient = null;
                        if (_this.report.type === 'financial') {
                            filterWillSend = _this.filters.split(',');
                        }
                        else if (_this.report.type === 'operational' || _this.report.type === 'stakeholders') {
                            filterClient = _this.byClient;
                        }
                        _this.subscriptions.push(_this.reportService.getReport(_this.startDate, _this.endDate, _this.report.type, filterWillSend, filterClient).subscribe(function (data) {
                            var workbook;
                            var sheets;
                            var names;
                            switch (_this.report.type) {
                                case 'stakeholders': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'stake': worksheet };
                                    names = ['stake'];
                                    break;
                                }
                                case 'lsp': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'LSP': worksheet };
                                    names = ['LSP'];
                                    break;
                                }
                                case 'getHubs': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'Hubs': worksheet };
                                    names = ['Hubs'];
                                    break;
                                }
                                case 'LSPKPI': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true,
                                        header: ['19', '3', '15', '7', '8', '9', '10', '22', '20', '21', '18', '23', '11', '12', '13', '14', '6', '4', '16', '17', '0', '5', '1', '2']
                                    });
                                    sheets = { 'KPI': worksheet };
                                    names = ['KPI'];
                                    break;
                                }
                                case 'perfLSP': {
                                    sheets = {};
                                    names = [];
                                    for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
                                        var title = _a[_i];
                                        // console.log(title);
                                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                            skipHeader: true
                                        });
                                        names.push(title);
                                    }
                                    break;
                                }
                                case 'operation': {
                                    sheets = {};
                                    names = [];
                                    for (var _b = 0, _c = Object.keys(data); _b < _c.length; _b++) {
                                        var title = _c[_b];
                                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                            skipHeader: true
                                        });
                                        names.push(title);
                                    }
                                    break;
                                }
                                case 'operational': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'LSP': worksheet };
                                    names = ['LSP'];
                                    break;
                                }
                                case 'bat': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'LSP': worksheet };
                                    names = ['LSP'];
                                    break;
                                }
                                case 'cmdr': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'CMDR': worksheet };
                                    names = ['CMDR'];
                                    break;
                                }
                                case 'cov': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'COV': worksheet };
                                    names = ['COV'];
                                    break;
                                }
                                case 'poddp': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'PODDP': worksheet };
                                    names = ['PODDP'];
                                    break;
                                }
                                case 'hub': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'Hub': worksheet };
                                    names = ['Hub'];
                                    break;
                                }
                                case 'inHub': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'Hub': worksheet };
                                    names = ['Hub'];
                                    break;
                                }
                                case 'dpunion': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'DPUnion': worksheet };
                                    names = ['DPUnion'];
                                    break;
                                }
                                case 'uoc': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'UOC': worksheet };
                                    names = ['UOC'];
                                    break;
                                }
                                case 'atachmentsUOC': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'atachmentsUOC': worksheet };
                                    names = ['atachmentsUOC'];
                                    break;
                                }
                                case 'copq': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'CoPQ': worksheet };
                                    names = ['CoPQ'];
                                    break;
                                }
                                case 'getDR': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'Entregas': worksheet };
                                    names = ['Entregas'];
                                    break;
                                }
                                case 'cockpit': {
                                    sheets = {};
                                    names = [];
                                    for (var _d = 0, _e = Object.keys(data); _d < _e.length; _d++) {
                                        var title = _e[_d];
                                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                            skipHeader: true
                                        });
                                        names.push(title);
                                    }
                                    break;
                                }
                                case 'financial': {
                                    var worksheet = XLSX.utils.json_to_sheet(data, {
                                        skipHeader: true
                                    });
                                    sheets = { 'financial': worksheet };
                                    names = ['financial'];
                                    break;
                                }
                            }
                            workbook = { Sheets: sheets, SheetNames: names };
                            var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                            _this.excel.saveAsExcelFile(excelBuffer, _this.report.name);
                            _this.loading = false;
                        }));
                    }
                    else {
                        _this.subscriptions.push(_this.configService.getConfiguration('reports', true).subscribe());
                        _this.snackBar.open('Estamos em manutenção, por favor tente em outro momento', 'Fechar', {
                            duration: 2000,
                        });
                    }
                }));
                return [2 /*return*/];
            });
        });
    };
    return ReportsComponent;
}());
export { ReportsComponent };
