import { HttpEventType } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var dontShowLoader = ['driver/GetLastPositions', 'DR/All'];
var LoadingInterceptor = /** @class */ (function () {
    function LoadingInterceptor(spinnerService) {
        this.spinnerService = spinnerService;
    }
    LoadingInterceptor.prototype.canShowLoader = function (request) {
        var counter = 0;
        dontShowLoader.forEach(function (data) {
            if (request.url.indexOf(data) === -1) {
                counter++;
            }
        });
        if (counter === dontShowLoader.length) {
            return true;
        }
        return false;
    };
    LoadingInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        // mostra spinner
        if (this.canShowLoader(request)) {
            this.spinnerService.show();
        }
        // console.log('Hello');
        return next.handle(request).pipe(tap(function (event) {
            if (event.type === HttpEventType.Response) {
                // oculta spinner
                _this.spinnerService.hide();
            }
        }, function (err) {
            // oculta spinner
            _this.spinnerService.hide();
        }));
    };
    return LoadingInterceptor;
}());
export { LoadingInterceptor };
