import { Subject } from 'rxjs/Subject';
var PriceObservable = /** @class */ (function () {
    function PriceObservable() {
        this.price = new Subject();
        this.currentPrice = this.price.asObservable();
    }
    PriceObservable.prototype.changePrice = function (getPrice) {
        this.price.next(getPrice);
    };
    PriceObservable.prototype.getPrice = function () {
        return this.price;
    };
    return PriceObservable;
}());
export { PriceObservable };
