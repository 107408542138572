import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
var ViewMdrComponent = /** @class */ (function () {
    function ViewMdrComponent(route, router) {
        this.route = route;
        this.router = router;
    }
    ViewMdrComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.token = _this.route.snapshot.paramMap.get('token');
            _this.mdr = data.mdr;
        });
    };
    return ViewMdrComponent;
}());
export { ViewMdrComponent };
