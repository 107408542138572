import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from './providers/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bluebee';

  constructor(public auth: AuthService, public translate: TranslateService) {
    translate.setDefaultLang('en');
    // Uncomment for set language based on the browser preferences

    const browserLang = translate.getBrowserLang();
    // console.log(browserLang);
    if (browserLang !== undefined) {
      translate.use(browserLang);
    } else {
      translate.use('pt');
    }

    // (<any>window).Conpass.init();

  }
}
