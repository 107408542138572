import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CompositeBarsChartComponent } from './composite-bars-chart/composite-bars-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DashboardService } from '../../providers/dashboard.service';
import { SpeedometerChartComponent } from './speedometer-chart/speedometer-chart.component';
import { BasicBarChartComponent } from './basic-bar-chart/basic-bar-chart.component';
import { TranslateService } from '@ngx-translate/core';
import { WordcloudChartComponent } from './wordcloud-chart/wordcloud-chart.component';

@Component({
  selector: 'app-graph-card',
  templateUrl: './graph-card.component.html',
  styleUrls: ['./graph-card.component.scss']
})
export class GraphCardComponent implements OnInit {
  @Input() data: any;
  @Input() layout: string;
  @Input() layoutAlign: string;
  @Input() title: string;
  @Input() selector: string;
  @Input() container: string;
  @Input() seriesType: string;
  @Input() dates:  any[];
  @ViewChild('pieChartComponent') pieChartComponent: PieChartComponent;
  @ViewChild('compositeBarsChartComponent') compositeBarsChartComponent: CompositeBarsChartComponent;
  @ViewChild('speedometerChartComponent') speedometerChartComponent: SpeedometerChartComponent;
  @ViewChild('basicBarChartComponent') basicBarChartComponent: BasicBarChartComponent;
  @ViewChild('wordcloudChartComponent') wordcloudChartComponent: WordcloudChartComponent;
  eco = false;
  constructor(private dashboardService: DashboardService, public translateService: TranslateService) {
  }

  ngOnInit() {
  }

  createChart(data, id, option?) {
    switch (this.selector) {
      case 'pie':
        this.pieChartComponent.createChart(data, id, option);
        break;
      case 'composite-bars':
        if (data['eco']) {
          this.eco = true;
        }
        this.compositeBarsChartComponent.createChart(data, id, option);
        break;
      case 'speedometer':
        this.speedometerChartComponent.createChart(data, id, this.translateService, option);
        break;
      case 'bar':
        this.basicBarChartComponent.createChart(data, id, option);
        break;
      case 'wordcloud':
        this.wordcloudChartComponent.createChart(data, id, this.translateService);
        break;
    }
  }
}
