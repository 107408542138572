import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { MdrService } from '../../../providers/mdr.service';
import { MDR } from '../../../models/mdr';

@Component({
  selector: 'app-airplane',
  templateUrl: './airplane.component.html',
  styleUrls: ['./airplane.component.scss']
})
export class AirplaneComponent implements OnInit {
  @Input() mdr: MDR;
  public isAirplane = null;
  public editMDRAirplane = false;
  public role = null;
  public newAirplane = false;
  public currentUser = null;
  mdrAirplane = [
    {name: 'Aéreo', value: true},
    {name: 'Rodoviário', value: false},
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mdrService: MdrService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
  }

  ngOnInit() {
    const id$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.mdrService.getMDR(parseInt(params.get('id'), 10)))
    );
    id$.subscribe((response: any) => {
      this.isAirplane = response.airplane;
    });
  }

  editAirplane() {
    this.editMDRAirplane = !this.editMDRAirplane;
  }

  saveAirplane() {
    this.editMDRAirplane = !this.editMDRAirplane;
    this.mdr.airplane = this.newAirplane;
    this.mdrService.update(this.mdr, this.currentUser.id).subscribe((response) => {
      this.isAirplane = this.newAirplane;
    });
  }
}
