import {Component, OnInit, Input, Inject} from '@angular/core';
import {MDR} from '../../../models/mdr';
import {DR} from '../../../models/dr';
import {Transport} from '../../../models/transport';

import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Occurrence} from '../../../models/occurrence';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, TooltipPosition} from '@angular/material';
import {MdrObservable} from '../../../services/mdr.service';
import {PriceObservable} from '../../../services/price.service';
import {MdrService} from '../../../providers/mdr.service';
import {TranslateService} from '@ngx-translate/core';
import {Driver} from '../../../models/driver';
import { DrObservable } from '../../../services/dr.service';
import { SiteService } from '../../../providers/site.service';
import { OVService } from '../../../providers/ov.service';
import {DrService} from '../../../providers/dr.service';

@Component({
   selector: 'app-transport',
   templateUrl: './transport.component.html',
   styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {
   mdr: MDR = new MDR;
   dr: DR[];
   HUs: any[];
   transports: Transport[];
   role: any;
   currentUser: any;
   time: any;
   statusContainer = false;
   pickupDateSchedulled = null;
   veiculo: Veiculo[] = [
      {id: '1', name: 'Fiorino' },
      {id: '2', name: 'Sprinter'},
      {id: '3', name: 'VUC'},
      {id: '4', name: 'Leve 3/4'},
      {id: '5', name: 'TOCO'},
      {id: '6', name: 'TRUCK'},
      {id: '7', name: 'CARRETA'}];

   transportadora: any[] = [];
   @Input() drivers: Driver[] = [];
   leader: any = null;
   user: any;
   tooltip = {
     maxH: '0',
     maxL: '0',
     maxW: '0',
     wei: '0',
     vol: '0',
   };
   showVehicleData = false;
   positionOptions: TooltipPosition = 'after';

   constructor(public translate: TranslateService, public router: Router,
      public priceObservable: PriceObservable , public mdrService: MdrService, public ovService: OVService,
      public mdrObservable: MdrObservable, public drObservable: DrObservable, public aRoute: ActivatedRoute,
      public siteService: SiteService, public drService: DrService,
      public dialog: MatDialog, public snackBar: MatSnackBar) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.role = this.currentUser.user.role;
      this.user = this.currentUser.user.id;
   }


  ngOnInit() {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };

    this.mdrObservable.currentMDR.subscribe( (mdr) => {
      this.mdr = mdr;

      this.siteService.getShippingCompanys().subscribe((shippingCompanies) => {
         this.transportadora = shippingCompanies;

         // this is definitely the lazy way
         this.mdrService.getAllPriceR(this.mdr).subscribe((data: any[]) => {
           for (const transp of this.transportadora) {
              const transpFreight = data.find(v => v.sc === transp.name);
              transp.freightValue = transpFreight ? transpFreight.total : Infinity;
           }

           this.transportadora.sort((a, b) => a.freightValue - b.freightValue);
         });
      });

      this.drService.getByMdr(this.mdr.id).subscribe((drs) => {
        this.drService.getHUs( drs.map( dr => dr.id) ).subscribe( (data) => {
          this.HUs = data && data.HUs ? data.HUs : [];
        });
      });

      this.ovService.getNfByMdr(this.mdr.id).subscribe((data) => {
         const ovs = data.map((e) => e.ov).filter(unique);
         // Sets the vehicle to the best available acording to the number of OVs
         this.ovService.getVehicle(ovs).subscribe((response) => {
           this.tooltip.wei = (response.searched.weight).toFixed(2);
           this.tooltip.maxH = (response.searched.height / 10).toFixed(3);
           this.tooltip.maxW = (response.searched.width / 10).toFixed(3);
           this.tooltip.maxL = (response.searched.length / 10).toFixed(3);
           this.tooltip.vol = (response.searched.volume / 100).toFixed(2);

           if ((this.mdr.status === 'MDR em criação' || this.mdr.status === 'Nova MDR') && !this.mdr.vehicle) {
             this.mdr.vehicle = (response.vehicle);
             this.mdrService.getAllPrice(this.mdr).subscribe( (resp) => {
               this.priceObservable.changePrice(resp);
             });
           }
         });
      });

      const date = new Date(this.mdr.pickupDate);

      if (this.mdr.pickupDate === null) {
        this.time = '00:00';
      } else {
        this.time = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
      }
      this.getDriver();
    });
  }

  getTimeList() {
      console.log('Clicked change Time ');
      this.mdrService.scheduleTimeList(this.mdr.pickupDate).subscribe((resp) => {
      this.pickupDateSchedulled = resp;
     });

  }

   updateShippingCompany(elem: Transportadora) {
      this.mdr.shippingCompany = elem.name;
      this.mdrService.getAllPrice(this.mdr).subscribe( (resp) => {
         this.priceObservable.changePrice(resp);
      });
   }

   changeTime() {
      const time = this.time.split(':');
      this.mdr.pickupDate = new Date(this.mdr.pickupDate);
      this.mdr.pickupDate.setHours(time[0]);
      this.mdr.pickupDate.setMinutes((time[1]));
   }

   updateVehicle(elem: Veiculo) {
     this.mdr.vehicle = elem.name;
     this.mdrService.getAllPrice(this.mdr).subscribe( (resp) => {
        this.priceObservable.changePrice(resp);
     });
   }

   goToChat() {
      this.router.navigate(['chat/' + this.mdr.id + '-driver']);
   }

   getVehicleSizes() {
      // End Point para pega valores que seta tamanho do veículo
   }

   getDriver() {
      this.mdrService.getDriverByMdrIdAll(this.mdr.id).subscribe( (response) => {
         this.drivers = response;
         const index = this.drivers.map((driver, i) => [i, driver]).filter(driver => driver[1]['leader'] === true);
         if ( index.length ) {
            this.leader = index[0][0];
         }
      });
   }
   saveDriver() {
      // se algum dos motoristas estiver incompleto
      if ( this.drivers.some( (el, i, arr) => {
         if ( el.name === null
            || el.phoneNumber === null
            || el.plate === null ) {
            return true;
         }
      }) ) {
         this.snackBar.open('Favor preencher os detalhes do motorista', 'Ok', {duration: 4000});
         return;
      }

      this.drivers = this.drivers.map(driver => {driver.leader = false; return driver; });
      const driversCompare = this.drivers.filter(driver => driver.status === true ).length;
      if (driversCompare >= 2) {
         this.drivers[this.leader].leader = true;
      }
      this.mdrService.updateDriver(this.drivers, this.mdr.id).subscribe( (response) => {
         this.snackBar.open('Alterações Salvas. Recarregar a página ', 'Ok', {duration: 4000});
         this.getDriver();
      });
   }
   saveConveyor() {
      this.mdrService.update(this.mdr, this.user).subscribe( (response) => {
      });
   }

   addDriver() {
      const newDriver = new Driver();
      newDriver.phoneNumber = parseInt(this.mdr.id + '' + this.drivers.length, 10);
      this.drivers.push(newDriver);
      const index = this.drivers.map((driver, i) => [i, driver]).filter(driver => driver[1]['status'] !== false);
      if ( index.length === 2 ) {
         this.leader = index[0][0];
      }
   }

   removerDriver(i) {
      this.drivers[i].status = false;
      if (i === this.leader) {
         const index = this.drivers.map((driver, idx) => [idx, driver]).filter(driver => driver[1]['status'] !== false);

         if (index.length === 1) {
            this.leader = null;
         } else {
            this.leader = index[0][0];
         }
      } else {
         const index = this.drivers.map((driver, idx) => [idx, driver]).filter(driver => driver[1]['status'] !== false);
         if (index.length === 1) {
            this.leader = null;
         }
      }
   }

   getVehicle() {
      const unique = (value, index, self) => {
         return self.indexOf(value) === index;
      };

      this.ovService.getNfByMdr(this.mdr.id).subscribe((responseOvs) => {
         if (responseOvs) {
            this.ovService.getVehicle(responseOvs.map((e) => e.ov).filter(unique)).subscribe((responseVehicle) => {
               if (responseVehicle) {
                  this.mdr.vehicle = responseVehicle.vehicle;
                  this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe((data) => {
                     this.snackBar.open('Veículo recalculado com sucesso', 'Ok', { duration: 4000 });
                  }, error => {
                     this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
                  });
               }
            }, error => {
               this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
            });
         }
      }, error => {
         this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
      });
   }

}
export interface Transportadora {
   id: string;
   name: string;
}

export interface Veiculo {
   id: string;
   name: string;
}
