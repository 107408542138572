import { OnInit } from '@angular/core';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
var DeliveryOrderComponent = /** @class */ (function () {
    function DeliveryOrderComponent(mdrService, router) {
        this.mdrService = mdrService;
        this.router = router;
        this.mdr = [];
        this.columns = [
            { value: 'observation', name: 'DELIVERY_ORDER/table/observation' },
            { value: 'status', name: 'status' },
            { value: 'client', name: 'DELIVERY_ORDER/table/client' },
            { value: 'shippingCompany', name: 'DELIVERY_ORDER/table/shippingCompany' },
        ];
    }
    DeliveryOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getAllWithClient().subscribe(function (data) {
            _this.mdr = [];
            for (var i = 0; i < data.length; i++) {
                data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('EDB-TP' + ('0000000' + data[i].id).slice(-6)));
                data[i].client = (data[i].drList[0].client ? data[i].drList[0].client : 'SEM CLIENTE');
                _this.mdr.push(data[i]);
            }
        });
    };
    DeliveryOrderComponent.prototype.goTo = function (row, that) {
        that.router.navigate(['logistic-order', row.id]);
    };
    return DeliveryOrderComponent;
}());
export { DeliveryOrderComponent };
