import {Component, OnInit, Input, ViewChild, Inject, ElementRef} from '@angular/core';
import {DR} from '../../../models/dr';
import {MatTableDataSource, MatSort, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatPaginator} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {TranslateService} from '@ngx-translate/core';
import {Occurrence} from '../../../models/occurrence';
import {HUB} from '../../../models/hub';
import {HubService} from '../../../providers/hub.service';
import {HUBDR} from '../../../models/hub__dr';
import {OVService} from '../../../providers/ov.service';
import {OV} from '../../../models/ov';
import { MDR } from '../../../models/mdr';
import { ESTRUTURA } from '../../../providers/site.service';
import {ClientUserService} from '../../../providers/client-user.service';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { tick } from '@angular/core/src/render3';

declare var google: any;

@Component({
  selector: 'app-delivery-route',
  templateUrl: './delivery-route.component.html',
  styleUrls: ['./delivery-route.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveryRouteComponent implements OnInit {
  data: DR[] = [];
  @Input() mdr: MDR;
  dataSource = new MatTableDataSource<DR>(this.data);
  displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'pickupDate', 'arrivalTime', 'client', 'spec-ops', 'obs', 'view'];
  columnsToDisplay = ['deliveryAddress', 'site', 'status', 'dateScheduling', 'pickupDate', 'arrivalTime', 'client'];

  @ViewChild(MatSort) sort: MatSort;
  currentUser: any;
  role: any;
  siteNomes = {
    'form_6_1': 'Grua',
    'form_6_2': 'Correia',
    'form_6_3': 'Veículos Esp.',
    'form_6_4': 'Operação Manual',
    'form_6_5': 'Guindates'
  };

  constructor(
    private drService: DrService,
    private mapsAPILoader: MapsAPILoader,
    public aRoute: ActivatedRoute,
    public dialog: MatDialog,
    public drObservable: DrObservable,
    public hubService: HubService,
    public ovService: OVService,
    public snackBar: MatSnackBar,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    if (this.role !== 5) { // pra role!==5 adiciona botoes de editar e visualizar
      this.displayedColumns = [].concat(this.displayedColumns, ['action', 'view']);
    }
  }

  ngOnInit() {
    // console.log(this.mdr);
    this.drService.getByMdr(this.mdr.id).subscribe((drs) => {
      for (const dr of drs) {
        if (dr.ovList) {
          dr.ovList = Array.from(new Set(dr.ovList));
        }

        dr.site_form6 = [];
        if (dr.site_form) {
          for (const [key, value] of Object.entries(dr.site_form)) {
            if (!key.startsWith('form_6_') || key.includes('obs_')) {
              continue;
            }

            if (value === ESTRUTURA.EX) {
              dr.site_form6.push(this.siteNomes[key]);
            }
          }
        }

        const zs = dr.zvsd1s.map(z => z.salesDocument);
        const ns = dr.nfes.map(n => n.invoiceNumber);

        dr.salesOrders = Array.from(new Set([...zs, ...ns].filter(v => !!v)));
      }

      this.data = drs;
      this.dataSource = new MatTableDataSource<DR>(this.data);
    });
  }

  setUserArrivalTime(dr: DR) {
    dr.userArrivalTime = new Date();

    this.drService.update(dr).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
      } else {
        dr.userArrivalTime = null;
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  setCoronaNull(dr: DR) {
    dr.unificationId = null;
    this.drService.update(dr).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
      } else {
        dr.userArrivalTime = null;
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  openEditDialog(dr: DR): void {
    const dialogRef = this.dialog.open(EditDrDialogComponent, {
      data: {
        role: this.role,
        dr: dr,
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
      this.ngOnInit();
    });
  }

  openExpedition(): void {
    // console.log('OPEN');
    const dialogRef = this.dialog.open(ViewExpeditionComponent, {
      height: '800px',
      data: {
        mdr: this.mdr,
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
    });
  }

  listOVNF(): void {
    // console.log('OPEN');
    const dialogRef = this.dialog.open(ViewNfOvComponent, {
      height: '600px',
      data: {
        mdr: this.mdr,
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
    });
  }

  openViewDialog(dr: DR): void {
    const dialogRef = this.dialog.open(ViewInfoComponent, {
      width: '600px',
      data: {
        dr: dr,
        role: this.role
      },
    });

    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
    });
  }

  openHistoryDialog(dr: DR): void {
    this.drService.getHistory(dr.id).subscribe((history) => {
      const dialogRef = this.dialog.open(HistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: {history: history}
      });
    });
  }

}

@Component({
  selector: 'app-edit-dr-dialog',
  templateUrl: 'edit-dr-dialog.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class EditDrDialogComponent implements OnInit {
  current: DR;
  hubList: HUB[] = [];
  hubId: any;
  hubObs: any;
  currentUser = null;
  role = null;
  allowed = false;
  allowedCampo = false;
  clientList = [];
  valid = true;
  aspList = [{name: 'Servicetel', value: 1}];
  statusList = ['InTransit',
                'READY_TO_PICKUP',
                'Devolução/Trânsito',
                'Devolvido EDB',
                'Replan - Retido',
                'Replan - Sefaz',
                'Replan - Pernoite',
                'Replan - Antecipação',
                'Replan - Postergação',
                'HubReturn',
                'Canceled',
                'Delivered',
                'Sinistro'];

  @ViewChild('newAddres')
  public searchElement2Ref: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public clientService: ClientUserService,
    public dialogRef: MatDialogRef<EditDrDialogComponent>,
    public drService: DrService,
    public hubService: HubService,
    public mapsAPILoader: MapsAPILoader,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {
    this.hubService.getAll(this.valid).subscribe( (hubs) => {
      this.hubList = hubs;
    });
    this.clientService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    if (this.role === 1) { // pra role!==5 adiciona botoes de editar e visualizar
      this.allowed = true;
      // console.log(this.allowed);
    }
    if ( this.role === 1 || this.role === 12 || this.role === 5) { // roles que podem editar o site (Campo)
      this.allowedCampo = true;
      // console.log(this.allowed);
    }
  }

  ngOnInit() {
    // faz uma copia da DR para edição
    this.current = Object.assign({}, this.data.dr);
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElement2Ref.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        // console.log('Place Changed');
        const place = autocomplete.getPlace();
        this.current.deliveryAddress = place.formatted_address;
        this.current.lat = place.geometry.location.lat();
        this.current.lng = place.geometry.location.lng();
      });
    });
  }

  recalculateLegs() {
    if (this.data.dr.lat !== this.current.lat || this.data.dr.lng !== this.current.lng ) {
      this.drService.recalculateLeg(this.current.lat, this.current.lng, this.data.dr).subscribe(resp => {
        this.snackBar.open('Alterado a posição de entrega encontrada', 'Ok', {duration: 4000});
        this.data.dr = Object.assign({}, resp.dr);
      });
    } else {
      this.snackBar.open('Não houve alteração de pontos para mudança de posição', 'Ok', {duration: 4000});
    }
  }

  onYesClick() {
    // compara DR copiada para edição com DR anterior
    this.current.deliveryAddress = (document.getElementById('address') as HTMLInputElement).value;
    const checkChanges = (key) => {
      return ( this.current[key] !== this.data.dr[key] );
    };

    // se nao houve alguma modificação
    const keys = Object.keys(this.current);
    if ( !keys.some(checkChanges) ) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    if ( this.current.status === 'HubReturn') {
      const newHUBDR = new HUBDR();
      newHUBDR.hubID = this.hubId;
      newHUBDR.drID = this.data.dr.id;
      newHUBDR.when = new Date();
      newHUBDR.observation = this.hubObs;
      this.hubService.createHubDr(newHUBDR).subscribe( (elem) => {
        // console.log(elem);
      });
    }

    const today = new Date();
    today.setDate(today.getDate() - 30);
    if (this.current.dateScheduling < today) {
      this.snackBar.open('Data de Entrega inválida.', 'Ok', {duration: 3000});
    }

    this.drService.update(this.current).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        // copia valores pra DR anterior
        keys.forEach((key) => {
          this.data.dr[key] = this.current[key];
        });
        this.dialogRef.close();
      } else {
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-view-info',
  templateUrl: 'view-info.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class ViewInfoComponent implements OnInit {
  role: number;
  dataSource: any[] = [];
  HUs: string[] = [];
  columns = [
    { value: 'salesDocument', name: 'OV'},
    { value: 'salesOrderQuantity', name: 'Quantity' },
    { value: 'materialCode', name: 'Material' },
    { value: 'customerName', name: 'Client' }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public drService: DrService
  ) {
    this.role = data.role;
  }

  ngOnInit() {
    this.drService.getAllInfo(this.data.dr.id).subscribe((elem) => {
      if (elem) {
        const dataFiltered = [];
        let HUs = [];

        (elem.drList).forEach( (dr) => {
          // Removes unnecessary properties
          const drFiltered = {};
          this.columns.forEach( (column) => {
            // All the atributes are mapped with column.name
            // to allow cleaner xlsx column names in the download file
            drFiltered[column.name] = dr[column.value];
          });

          HUs = HUs.concat(dr.HUs);
          dataFiltered.push(drFiltered);
        });

        if (elem.relationHUs && elem.relationHUs.length > 0) {
          HUs = elem.relationHUs;
        }

         // Updates column values
        this.columns.forEach( (column) => {
          column.value = column.name;
        });

        // remove HUs repetidas
        const unique = (value, index, self) => {
          return self.indexOf(value) === index;
        };

        this.HUs = HUs.filter(unique);
        this.dataSource = dataFiltered;
      }
    });
  }
}


@Component({
  selector: 'app-view-nf-ov-component',
  templateUrl: 'history.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class ViewNfOvComponent implements OnInit {
  history = [];
  columns = [
    {value: 'ov', name: 'OV'},
    {value: 'nf', name: 'NF'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public ovService: OVService
  ) {
    this.ovService.getNfByMdr(this.data.mdr.id).subscribe(el => {
      // console.log(el);
      this.history = el;
    });
  }
  ngOnInit() { }

}



@Component({
  selector: 'app-history',
  templateUrl: 'history.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class HistoryDialogComponent implements OnInit {
  history = [];
  currentUser = null;
  role = null;
  allowed = false;
  columns = [
    {value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address'},
    {value: 'status', name: 'status'},
    {value: 'quem', name: 'DELIVERY_ROUTE/by'},
    {value: 'quando', name: 'DELIVERY_ROUTE/on'},
    {value: 'dateScheduling', name: 'DELIVERY_ROUTE/date'}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public datepipe: DatePipe) {
    this.history = data.history;
    this.history.forEach(day => {
      day.dateScheduling = new Date(day.dateScheduling);
      day.dateScheduling = this.datepipe.transform(day.dateScheduling, 'dd/MM/yyyy hh:mm:ss');
      day.quando = new Date(day.quando);
      day.quando = this.datepipe.transform(day.quando, 'dd/MM/yyyy hh:mm:ss');
    });
  }
  ngOnInit() {

  }
}


@Component({
  selector: 'app-view-expedition',
  templateUrl: 'view-expedition.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class ViewExpeditionComponent implements OnInit {
  mdr = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public datepipe: DatePipe) {
    this.mdr = data.mdr;
  }
  ngOnInit() {
  }
}
