import { Observable } from 'rxjs/Observable';
import { Event } from '../models/events';
import * as socketIo from 'socket.io-client';
import { environment } from '../../environments/environment';
var base_url = environment.websocketUrl;
var api_key = environment.apiKey;
var SocketService = /** @class */ (function () {
    function SocketService() {
    }
    // constructor(public auth: AuthService, public router: Router) {}
    SocketService.prototype.initSocket = function (id) {
        // console.log('init socket',base_url,id);
        this.socket = socketIo(base_url, {
            transports: ['websocket'],
            secure: true
        });
        this.joinConversation(id);
        // console.log(this.socket);
    };
    SocketService.prototype.initSocketMDR = function (id, userid) {
        // console.log('init socket');
        this.socket = socketIo(base_url, {
            transports: ['websocket'],
            secure: true
        });
        this.socket.emit('enter conversation mdr', { room: id, userId: userid });
        // console.log(this.socket);
    };
    SocketService.prototype.initSocketNotification = function (id) {
        // console.log('init socket');
        this.socket = socketIo(base_url, {
            transports: ['websocket'],
            secure: true
        });
        this.socket.emit('enter notification', { room: id });
        // console.log(this.socket);
    };
    SocketService.prototype.emit = function (eventName, eventBody) {
        // console.log('emitindo',eventName,eventBody);//DEBUG
        this.socket.emit(eventName, eventBody);
    };
    SocketService.prototype.send = function (message) {
        this.emit('message', message);
    };
    SocketService.prototype.sendNotification = function (message) {
        // console.log('Send message to Server');
        // console.log(message);
        this.socket.emit('notification', message);
    };
    SocketService.prototype.joinConversation = function (id) {
        this.socket.emit('enter conversation', { room: id });
    };
    SocketService.prototype.onMessage = function () {
        return this.onEvent(Event.NEW_MESSAGE);
    };
    SocketService.prototype.onComeMDRLists = function () {
        return this.onEvent(Event.NEW_MDRList);
    };
    SocketService.prototype.onNotification = function () {
        return this.onEvent(Event.NEW_NOTIFICATION);
    };
    SocketService.prototype.onEvent = function (event) {
        var _this = this;
        // console.log('Chegou alguma coisa');
        // console.log(event)
        return new Observable(function (observer) {
            _this.socket.on(event, function (data) {
                // console.log('chegou evento',event,data);//DEBUG
                observer.next(data);
            });
        });
    };
    return SocketService;
}());
export { SocketService };
