import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { MdrService } from '../../providers/mdr.service';
import { DrService } from '../../providers/dr.service';
import { Router } from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DashboardService } from '../../providers/dashboard.service';
import { DatePipe } from '@angular/common';
import * as Highcharts from 'highcharts';
var Boost = require('highcharts/modules/boost');
var noData = require('highcharts/modules/no-data-to-display');
var More = require('highcharts/highcharts-more');
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
var MdrMapComponent = /** @class */ (function () {
    function MdrMapComponent(snackBar, mdrService, drService, router, translateService, pDate, dashboardService, document) {
        var _this = this;
        this.snackBar = snackBar;
        this.mdrService = mdrService;
        this.drService = drService;
        this.router = router;
        this.translateService = translateService;
        this.pDate = pDate;
        this.dashboardService = dashboardService;
        this.document = document;
        this.zoom = 4;
        this.lat = -15;
        this.lng = -55;
        this.fullScreen = false;
        this.markers = {
            'red': './assets/images/red_truck.png',
            'yellow': './assets/images/blue_truck.png',
            'green': './assets/images/green_truck.png',
            'undefined': './assets/images/white_truck.png'
        };
        this.list = [];
        this.kiloData = '';
        this.drCount = 0;
        this.occurrenceCount = 0;
        this.mdrShippingCompanies = [];
        // configs das buscas
        this.search_toggle = true;
        this.removable = true;
        this.selectable = true;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        // valores das buscas
        this.searchDates = { 'start': null, 'end': null };
        this.searchChips = [];
        this.searchFields = {
            chips: ['pickupID', 'site', 'shippingCompany'],
            dates: ['createdAt']
        };
        this.getData();
        this.dashboardService.getCostKg('day').subscribe(function (data) {
            var allCostPerKilo = [];
            var allData = Object.values(data && data['aggregate'] ? data['aggregate'] : []);
            for (var i = 0; i < allData.length; i++) {
                if (allData[i].cost && allData[i].weight) {
                    allCostPerKilo.push(allData[i].cost / allData[i].weight);
                }
            }
            var totalCost = allCostPerKilo.reduce(function (acc, curr) { return acc + curr; }, 0);
            _this.kiloData = allCostPerKilo.length ? (totalCost / allCostPerKilo.length).toFixed(2) : '0';
        });
        this.dashboardService.getMdrByShippingCompany().subscribe(function (data) {
            _this.mdrShippingCompanies = Object.values(data && data['aggregate'] ? data['aggregate'] : []);
            _this.createChart();
        });
    }
    MdrMapComponent.prototype.createChart = function () {
        Highcharts.chart({
            chart: {
                type: 'pie',
                renderTo: 'chart',
                backgroundColor: 'transparent',
                spacingBottom: 0,
                spacingTop: 0,
                spacingLeft: 0,
                spacingRight: 0,
                margin: 0,
                height: 240,
            },
            title: {
                text: null,
            },
            lang: {
                noData: this.translateService.instant('NO_DATA'),
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: 10,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '75%'],
                    size: '130%'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                    name: 'MDRS',
                    type: undefined,
                    innerSize: '50%',
                    data: this.mdrShippingCompanies.map(function (i) { return [i.shippingCompany, i.count]; })
                }]
        });
    };
    MdrMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        setInterval(function () {
            _this.getData();
        }, 60000);
    };
    MdrMapComponent.prototype.statusColor = function (when) {
        var color = 'green';
        var hora = 1000 * 60 * 60;
        var now = Date.now();
        var d = new Date(when);
        if (now - d.getTime() > 1 * hora) {
            // se o caminhao nao é visto a mais de 1h
            color = 'red';
        }
        else if (now - d.getTime() > 0.5 * hora) {
            // se o caminhao nao é visto a mais de 30min
            color = (color === 'red') ? color : 'yellow';
        }
        return color;
    };
    MdrMapComponent.prototype.getData = function () {
        var _this = this;
        // pega todas as DRs
        this.drService.getAll().subscribe(function (responseAll) {
            // pega as DRs que tem status InTransit
            var drList = responseAll.filter(function (e) { return e.status === 'InTransit'; });
            // console.log(drList);
            _this.drCount = drList.length;
            // passa os idMDRs pra pegar as ultimas posicoes
            _this.mdrService.getLastPositions(drList.map(function (e) { return e.idMDR; })).subscribe(function (response) {
                var _loop_1 = function (i, len) {
                    var dr = drList.find(function (e) { return e.idMDR === response[i].mdrId; });
                    _this.list.push({
                        lat: response[i].lat,
                        lng: response[i].lng,
                        icon: {
                            url: _this.markers[_this.statusColor(response[i].createdAt)],
                            scaledSize: {
                                width: 40,
                                height: 40
                            }
                        },
                        mdrId: dr.idMDR,
                        pickupID: dr['mdr'].pickupID,
                        site: dr.site,
                        createdAt: response[i].createdAt,
                        show: true,
                        dateScheduling: _this.pDate.transform(dr.dateScheduling, 'dd/MM/yyyy HH:mm')
                    });
                };
                for (var i = 0, len = response.length; i < len; i++) {
                    _loop_1(i, len);
                }
                if (_this.list.length === 0) {
                    _this.snackBar.open('Nenhuma posição disponível', 'Ok', { duration: 2000 });
                }
            });
        });
        this.dashboardService.getOccurrence().subscribe(function (data) {
            // console.log(data);
            data.forEach(function (el) {
                // console.log({el: el});
                if (el.occ_status === 'OCCURRENCE_TO_RESOLVE') {
                    _this.occurrenceCount = el.count;
                }
            });
        });
    };
    // fullscreen() {
    //   const elem = document.documentElement;
    //   if (!this.fullScreen) {
    //     if (elem.requestFullscreen) {
    //       elem.requestFullscreen();
    //     } else if (elem.mozRequestFullScreen) {
    //       /* Firefox */
    //       elem.mozRequestFullScreen();
    //     } else if (elem.webkitRequestFullscreen) {
    //       /* Chrome, Safari and Opera */
    //       elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) {
    //       /* IE/Edge */
    //       elem.msRequestFullscreen();
    //     }
    //   } else {
    //     if (elem.exitFullscreen) {
    //       elem.exitFullscreen();
    //     } else if (elem.mozCancelFullScreen) {
    //       /* Firefox */
    //       elem.mozCancelFullScreen();
    //     } else if (elem.webkitExitFullscreen) {
    //       /* Chrome, Safari and Opera */
    //       elem.webkitExitFullscreen();
    //     } else if (elem.msExitFullscreen) {
    //       /* IE/Edge */
    //       elem.msExitFullscreen();
    //     }
    //   }
    // }
    MdrMapComponent.prototype.onMouseOver = function (infoWindow, $event) {
        infoWindow.open();
    };
    MdrMapComponent.prototype.onMouseOut = function (infoWindow, $event) {
        infoWindow.close();
    };
    MdrMapComponent.prototype.showMDR = function (point) {
        this.router.navigate(['logistic-order/' + point.mdrId]);
    };
    MdrMapComponent.prototype.centerMap = function () {
        var lats = this.list.map(function (e) { return e.lat; });
        var lngs = this.list.map(function (e) { return e.lng; });
        // calcula o centro do mapa
        var latSUM = lats.reduce(function (a, b) { return a + b; }, 0);
        this.lat = latSUM / this.list.length;
        var lngSUM = lngs.reduce(function (a, b) { return a + b; }, 0);
        this.lng = lngSUM / this.list.length;
    };
    /****
     * funções relativas a busca
     */
    MdrMapComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.searchChips.push({ 'name': value.trim() });
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    MdrMapComponent.prototype.remove = function (chip) {
        var index = this.searchChips.indexOf(chip);
        if (index >= 0) {
            this.searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    MdrMapComponent.prototype.dateChanged = function () {
        this.applyFilter();
    };
    MdrMapComponent.prototype.applyFilter = function () {
        var _this = this;
        var dates = this.searchDates;
        this.list.forEach(function (item) {
            item.show = true;
            if (_this.searchChips.length === 0 && dates.start === null && dates.end === null) {
                // busca vazia
                return;
            }
            // assume que pode mostrar
            var displayFromDate = true;
            var d = new Date(item[_this.searchFields.dates[0]]); // TODO mudar pra poder receber um array de dates
            // se estiver fora do range, nao mostra
            if (dates.start && d.getTime() < dates.start.getTime()) {
                displayFromDate = false;
            }
            if (dates.end && dates.end.getTime() <= d.getTime()) {
                displayFromDate = false;
            }
            var checkChip = function (chip) {
                return _this.searchFields.chips.some(function (key) {
                    if (key in item && item[key] !== null) {
                        // se o campo existe
                        if (typeof item[key] === 'string' &&
                            item[key].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                            // se o tipo é string e existe uma match
                            return true;
                        }
                        else if (typeof item[key] === 'number' &&
                            item[key] === parseInt(chip, 10)) {
                            // se o tipo é number e existe uma match
                            return true;
                        }
                    }
                    return false;
                });
            };
            // dependendo se deve haver match para todos os chips, ou para algum chip
            var displayFromChips = _this.search_toggle ?
                _this.searchChips.map(function (el) { return el.name; }).some(checkChip) :
                _this.searchChips.map(function (el) { return el.name; }).every(checkChip);
            // se puder mostrar pelo chip ou pelas datas
            item.show = displayFromChips && displayFromDate;
        });
    };
    return MdrMapComponent;
}());
export { MdrMapComponent };
