import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { OVService } from '../../providers/ov.service';
import { OV } from '../../models/ov';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DR } from '../../models/dr';
import { parseString } from 'xml2js';
import { NfeService } from '../../providers/nfe.service';
var DeliveryRequestComponent = /** @class */ (function () {
    function DeliveryRequestComponent(dialog, mapsAPILoader, ovService, router, drService, nfService, snackBar) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.ovService = ovService;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.snackBar = snackBar;
        this.dr = new DR();
        this.uploaded = false;
        this.displayedColumns = ['mdrID', 'route', 'driver', 'status'];
        this.dataSource = new MatTableDataSource();
        this.ovOrNf = false;
        this.minDate = new Date();
        this.displayedColumns2 = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
        this.dataSourceOV = new MatTableDataSource();
        this.displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'pickupAddress', 'site'];
        this.dataSourceDR = new MatTableDataSource();
        this.initialSelection = [];
        this.selection = new SelectionModel(true, this.initialSelection);
        this.newDrList = [];
        this.latlngInsert = false;
        this.subscriptions = [];
        this.dataSource.sort = this.sort;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        if (this.role === 1) {
            this.minDate = new Date('1970-01-01');
        }
        this.subscriptions.push(this.drService.getLastIdInserted().subscribe(function (data) {
            _this.newId = data;
        }));
    }
    DeliveryRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchControl2 = new FormControl();
        this.searchControl = new FormControl();
        this.subscriptions.push(this.drService.getAllMDR().subscribe(function (elem) {
            // console.log(elem);
            _this.dataSource = new MatTableDataSource(elem);
        }));
    };
    DeliveryRequestComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DeliveryRequestComponent.prototype.getRowColor = function (status) {
        var colors = {
            green: ['Delivered'],
            yellow: ['READY_TO_PICKUP']
        };
        for (var color in colors) {
            if (colors[color].indexOf(status) >= 0) {
                return 'request-status-' + color;
            }
        }
        return 'request-status-undefined';
    };
    /** Whether the number of selected elements matches the total number of rows. */
    DeliveryRequestComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    DeliveryRequestComponent.prototype.backTo = function () {
        this.uploaded = false;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    DeliveryRequestComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    DeliveryRequestComponent.prototype.addNewOV = function () {
        var _this = this;
        this.selection.selected.forEach(function (el) {
            if (_this.dataSourceOV.data.indexOf(el) < 0) {
                _this.dataSourceOV.data.push(el);
            }
        });
        this.dataSourceOV = new MatTableDataSource(this.dataSourceOV.data);
    };
    DeliveryRequestComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        // console.log(fileupload);
        var input = fileupload.target;
        // console.log(fileupload);
        this.newDrList = [];
        var _loop_1 = function (index) {
            var reader = new FileReader();
            reader.onload = function () {
                parseString(reader.result, function (err, result) {
                    // drAux.deliveryAddress = result.
                    var i = 0;
                    result.Workbook.Worksheet[0].Table[0].Row.forEach(function (el) {
                        if (i !== 0) {
                            var drAux = new DR();
                            // console.log(el);
                            drAux.pickupAddress = el.Cell[0].Data[0]._; // Ver se é nullo
                            drAux.deliveryAddress = el.Cell[1].Data[0]._;
                            drAux.site = el.Cell[2].Data[0]._;
                            drAux.receiverName = el.Cell[3].Data[0]._;
                            drAux.receiverPhone = el.Cell[4].Data[0]._;
                            drAux.supervisorName = el.Cell[5].Data[0]._;
                            drAux.supervisorPhone = el.Cell[6].Data[0]._;
                            drAux.infraType = el.Cell[7].Data[0]._;
                            drAux.obs = el.Cell[8].Data[0]._;
                            drAux.dateScheduling = el.Cell[9].Data[0]._;
                            drAux.status = el.Cell[10].Data[0]._;
                            drAux.lat = el.Cell[11].Data[0]._;
                            drAux.lng = el.Cell[12].Data[0]._;
                            // const aux = el.Cell[0].Data[0]._;
                            // TODO Insert OVs in ov List and check if only the id works to change
                            _this.newDrList.push(drAux);
                            // drAux.deliveryAddress = el.
                        }
                        i++;
                    });
                });
            };
            reader.readAsText(input.files[index]);
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
        this.uploaded = true;
        this.dataSourceDR = new MatTableDataSource(this.newDrList);
        // console.log(this.newDrList);
    };
    // POG
    DeliveryRequestComponent.prototype.validateData = function () {
        if (!this.latlngInsert) {
            this.dr.deliveryAddress = (document.getElementById('deliveryAddress').value);
            if (this.dr.deliveryAddress === '') {
                return false;
            }
        }
        this.dr.pickupAddress = (document.getElementById('pickupAddress').value);
        // this.dateCollect = ((document.getElementById("deliveryAddress") as HTMLInputElement).value);
        this.hour = (document.getElementById('hour').value);
        // console.log(this.hour);
        if (this.dr.deliveryAddress === '') {
            return false;
        }
        if (this.hour === '') {
            return false;
        }
        return !(this.dr.infraType === '' || this.dr.site === '' || this.dr.obs === '');
    };
    DeliveryRequestComponent.prototype.openDialog2 = function () {
        var today = new Date();
        var dd = today.getDate() + '';
        var mm = (today.getMonth() + 1) + ''; // January is 0!
        var yyyy = today.getFullYear();
        if (Number(dd) < 10) {
            dd = '0' + dd;
        }
        if (Number(mm) < 10) {
            mm = '0' + mm;
        }
        var idDate = yyyy + mm + dd + ('00000000' + this.newId).slice(-8);
        // generate a new ID based on last MDR
    };
    DeliveryRequestComponent.prototype.isCompleted = function (correct) {
        if (correct < this.newDrList.length) {
            this.snackBar.open('Algumas DRs não puderam ser criadas', 'Ok', { duration: 4000 });
        }
        else {
            this.snackBar.open('DRs Criadas com sucesso', 'Ok', { duration: 4000 });
        }
    };
    DeliveryRequestComponent.prototype.openDialog = function () {
        // generate a new ID based on last MDR
        var today = new Date();
        var dd = today.getDate() + '';
        var mm = (today.getMonth() + 1) + ''; // January is 0!
        var yyyy = today.getFullYear();
        if (Number(dd) < 10) {
            dd = '0' + dd;
        }
        if (Number(mm) < 10) {
            mm = '0' + mm;
        }
        var idDate = yyyy + mm + dd + ('00000000' + this.newId).slice(-8);
    };
    DeliveryRequestComponent.prototype.searchOV = function () {
        var _this = this;
        // console.log(this.inputOVs);
        if (this.inputOVs === undefined) {
            this.snackBar.open('Preencha um valor de OV', 'Ok', { duration: 4000 });
        }
        else {
            this.subscriptions.push(this.ovService.getAll(this.inputOVs).subscribe(function (data) {
                // console.log(data)
                if (data === null) {
                    _this.snackBar.open('OV já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
                }
                else {
                    _this.dataSource = new MatTableDataSource([data]);
                }
            }));
        }
    };
    DeliveryRequestComponent.prototype.searchNFe = function () {
        var _this = this;
        if (this.inputNFs === undefined) {
            this.snackBar.open('Preencha um valor de NFe', 'Ok', { duration: 4000 });
        }
        else {
            this.subscriptions.push(this.nfService.getAll(this.inputNFs).subscribe(function (data) {
                if (!data || data.drId !== null) {
                    _this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
                }
                else {
                    var ovAux = new OV();
                    ovAux.salesDocument = '';
                    ovAux.referenceNoNf = data[0].invoiceNumber;
                    ovAux.customerName = data[0].client;
                    ovAux.materialDescription = data[0].materials;
                    _this.dataSource = new MatTableDataSource([ovAux]);
                }
            }));
        }
    };
    DeliveryRequestComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    };
    DeliveryRequestComponent.prototype.goToMDR = function (k) {
        this.router.navigate(['logistic-order/' + k.mdr.id]);
    };
    return DeliveryRequestComponent;
}());
export { DeliveryRequestComponent };
