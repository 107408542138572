<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <!-- this creates a google map on the page with the given lat/lng from -->
      <!-- the component as the initial center of the map: -->
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'GEOLOCATION/title' | translate }}
        </div>
      </div>
      <hr>
      <span *ngIf="timeRemaining && distanceTotal && finishedDriveStatus.indexOf(mdr.status) === -1">
         <div class="col-md-4">
           <p>{{ 'GEOLOCATION/text/distance' | translate: { remaning: distanceRemaining, total: distanceTotal} }}</p>
         </div>
         <div class="col-md-4">
           <p>{{ 'GEOLOCATION/timeRemaining' | translate }} {{ timeRemaining }}</p>
         </div>
      </span>

      <div *ngIf="showMap">
        <agm-map #gmap [latitude]="lat" [longitude]="lng" [mapTypeControl]="true"  [usePanning]="true"  >
          <span *ngIf="showMarkers">

            <agm-marker *ngFor="let mark of markers"
              [markerDraggable]="mdr.status === 'MDR em validação'"
              (dragEnd)="updateMarker($event, mark)"
              [label]="{color: '#FFFFFF', fontSize: '16px', text: mark.label}"
              [latitude]="mark.lat"
              [longitude]="mark.lng"
            ></agm-marker>

            <!-- pontos do tracking -->
            <agm-marker *ngFor="let mark of markersDriver"
              [markerDraggable]="false"
              [iconUrl]="mark.icon"
              [latitude]="mark.lat"
              [longitude]="mark.lng"
            ></agm-marker>

            <!-- linha cinza -->
            <span *ngFor="let leg of legsNewLegsDelayed; index as legIndex">
              <agm-direction *ngIf="leg.type === 'Rodoviário'"
                (onChange)="onResponse($event)"
                [visible] = "show"
                [origin]="leg.pointInit"
                [destination]="leg.pointEnd"
                [renderOptions]="showDriverInMap && leg.order > legInTransitIndex ? renderOptionsDriver : renderOptions"
              ></agm-direction>

              <agm-polyline *ngIf="leg.type !== 'Rodoviário'"
                [geodesic]="true"
                [visible] = "show">
                <agm-polyline-point
                  [latitude]="leg.pointInit.lat"
                  [longitude]="leg.pointInit.lng"
                ></agm-polyline-point>
                <agm-polyline-point
                  [latitude]="leg.pointEnd.lat"
                  [longitude]="leg.pointEnd.lng"
                ></agm-polyline-point>
              </agm-polyline>
            </span>

            <span *ngIf="showDriverInMap && legInTransitIndex !== -1">
              <!-- linha azul -->
              <agm-direction *ngIf="legsNewLegs.length && legsWithDistances"
                (onResponse)="onResponseDriver($event)"
                (onChange)="onResponseDriver($event)"
                [visible] = "show"
                [renderOptions]="renderOptionsDriver"
                [origin]="driverPosition"
                [destination]="legsNewLegs[legInTransitIndex].pointEnd"
              ></agm-direction>

              <!-- marker do caminhão -->
              <agm-marker
                [markerDraggable]="false"
                [latitude]="driverPosition.lat"
                [iconUrl]="icon"
                [longitude]="driverPosition.lng"
              ></agm-marker>
            </span>

          </span>
        </agm-map>

        <!-- tabela com posições dos motoristas -->
        <app-table
          *ngIf="markersDriver.length > 0"
          [data]="driverPositionList"
          [columns]="driverPositionTableColumns"
        ></app-table>
      </div>

      <div *ngIf="mdr.status === 'MDR em validação' || (mdr.status === 'MDR aprovada LSP' && role === 12)" class="row">
        <div class="row">
          <div class="col-md-12">
            <button mat-raised-button class="left red-button"  (click)="removeDirection()">{{ 'GEOLOCATION/remove_route' | translate }} </button>
            <button mat-raised-button class="left green-button"  (click)="showDirection()">{{ 'GEOLOCATION/show_route' | translate }}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table mat-table *ngIf="legsNewLegs.length > 0" #table [dataSource]="dataSource" matSort>
              <!-- Add pickup Address -->

              <ng-container matColumnDef="pointStart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GEOLOCATION/point_init' | translate }}  </th>
                <td mat-cell *matCellDef="let element"> {{ element.pointInit.label}} </td>
              </ng-container>

              <ng-container matColumnDef="pointEnd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/point_end' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.pointEnd.label}} </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/type' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.type }} </td>
              </ng-container>

              <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/split' | translate }} </th>
                <td mat-cell *matCellDef="let element" > <span  (click)="removeLeg(element)"><i [ngClass]="{'fas-grey':mdr.lock === false}" class="fas fa-times"></i></span></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/create_point' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <input matInput type="number" placeholder="{{ 'GEOLOCATION/lat' | translate }}" [(ngModel)]="latNew">
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <input type="number"  matInput placeholder="{{ 'GEOLOCATION/lng' | translate }}" [(ngModel)]="lngNew">
              </mat-form-field>
              <button mat-raised-button class="green-button right"  (click)="addPin()">{{ 'GEOLOCATION/add_point' | translate }}</button>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/create_leg' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-7">
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/point_init' | translate }}" [(ngModel)]="markerInit">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/point_end' | translate }}" [(ngModel)]="markerEnd">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/type' | translate }}" [(ngModel)]="selectedType">
                  <mat-option *ngFor="let elem of typeTransp" [value]="elem.name">
                    {{ elem.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <!--<button mat-raised-button class="yellow-button right"  (click)="saveRoute()">Salvar Alterações</button>-->
              <button mat-raised-button class="green-button right"  (click)="createLeg()">{{ 'GEOLOCATION/add_leg' | translate }}</button>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/remove_point' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/selected_point' | translate }}" [(ngModel)]="markerDelete">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <button mat-raised-button class="red-button right" (click)="deletePin()">{{ 'GEOLOCATION/delete_point' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
