import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import { Occurrence } from '../../models/occurrence';

@Component({
  selector: 'app-tower-control',
  templateUrl: './tower-control.component.html',
  styleUrls: ['./tower-control.component.scss']
})
export class TowerControlComponent implements OnInit, OnDestroy {
  data: DR[] = null;
  cachedData: DR[] = null;
  allowCacheUpdate = false;
  loadingFullData;
  role: number;

  icons = {
    'undefined': 'help',
    'red':       'error',
    'yellow':    'warning',
    'green':     'check_circle',
    'covid':     'notes-medical',
  };

  columns = [
    {value: 'client', name: 'CONTROL_TOWER/table/client'},
    {value: 'pickupID', name: 'CONTROL_TOWER/table/mdr'},
    {value: 'icon', name: 'Status Card', icon: 'icon', tooltip: 'tooltip', color: this.styleIcon},
    {value: 'mdrShippingCompany', name: 'lsp'},
    {value: 'site', name: 'CONTROL_TOWER/table/site'},
    {value: 'infraType', name: 'CONTROL_TOWER/table/infra'},
    {value: 'ops', name: 'CONTROL_TOWER/table/specialOps'},
    {value: 'salesOrders', name: 'CONTROL_TOWER/table/salesOrders'},
    {value: 'dateScheduling', name: 'CONTROL_TOWER/table/date'},
    {value: 'status', name: 'status'},
    {value: 'occurrence', name: 'CONTROL_TOWER/table/occurrence'},
    {value: 'covid', name: 'covid-19'},
    {value: 'city', name: 'CONTROL_TOWER/table/city'},
    {value: 'uf', name: 'CONTROL_TOWER/table/uf'},
    {value: 'copqSum', name: 'CONTROL_TOWER/table/copq', currency: 'BRL'},
  ];

  filterDates: any = {
    start: null,
    end: null,
  };

  subscriptions: any[] = [];

  constructor(public translate: TranslateService,
              public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              public clientService: ClientUserService,
              private drService: DrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;

    this.filterDates.start = new Date();
    this.filterDates.start.setDate(this.filterDates.start.getDate() - 5);
    this.filterDates.end = new Date();
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getData(rangeDates: Date[] = null) {
    const body: Date[] = rangeDates && rangeDates.length >= 2 ? rangeDates : [];

    this.subscriptions.push(this.drService.getTower(...body).subscribe( (data) => {
      const objs = [];

      if (!data) {
        data = [];
      }

      data.map( (k) => {
        const y = {};
        // gera campos
        if (!(k.arrivalTime) && k.status === 'InTransit') {
          y['status'] = 'UNLOADING_PROCESS';
        } else {
          y['status'] = k.status;
        }
        y['covid'] = k.unificationId ? 'covid' : '';
        y['salesOrders'] = k.salesOrders ? k.salesOrders.join(' \n') : '';
        y['mdrID'] = (k.mdr && k.mdr.id) ? k.mdr.id : '';
        y['pickupID'] = (k.mdr && k.mdr.pickupID) ? k.mdr.pickupID : '';
        y['romaneio'] = k.pickupID ? k.pickupID : ( 'EDB-TP' + ('0000000' + k.mdrID).slice(-6) );
        y['mdrShippingCompany'] = (k.mdr && k.mdr.shippingCompany) ? k.mdr.shippingCompany : '';
        y['city'] = (k.city) ? k.city : '';
        y['site'] = (k.site) ? k.site : '';
        y['uf'] = (k.uf) ? k.uf : '';
        y['ops'] = (k.thirdPartInvoice) ? 'Operação Especial' : '';

        y['dateScheduling'] = (k.dateScheduling) ? new Date(k.dateScheduling) : '';

        y['infraType'] = (k.infraType) ? k.infraType : '';
        y['client'] = (k.client) ? k.client : '';

        y['occurrence'] = '';
        y['occurrenceAll'] = '';
        if (k.occ !== null && k.occ.length > 0) {
          const sortedDates = k.occ.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
          // Add to field, most recent on top
          for (let i = 0; i < k.occ.length; i++) {
            y['occurrenceAll'] += '[' + this.dpipe.transform(sortedDates[i].createdAt, 'dd/MM/yyyy hh:mm') + '] ' + sortedDates[i].text + '\n';
          }
          // Add most recent to occurrence
          y['occurrence'] += '[' + this.dpipe.transform(sortedDates[0].createdAt, 'dd/MM/yyyy hh:mm') + '] ' + sortedDates[0].text + '\n';
        }
        y['sumUOC'] = 0;
        if (k.mdr.uoc.length) {
          for ( let i = 0; i < k.mdr.uoc.length; i++) {
            y['sumUOC'] += k.mdr.uoc[i].value / 100;
          }
        }
        if (k.status === null) {
          y['status'] = 'Sem Status';
        } else {
          y['status'] = this.translate.instant(y['status']);
        }
        y['copqSum'] = 0;
        if (k['copq'] !== undefined) {
          for (let i = 0; i < k['copq'].length; i++) {
            y['copqSum'] += k['copq'][i].value;
          }
          y['copqSum'] = y['copqSum'] / 100;
        }
        y['frete'] = k.mdr.frete ? parseFloat(k.mdr.frete.total) : 0;

        if (y['frete'] + y['sumUOC'] > 0) {
          y['percentual'] = y['copqSum'] / (y['frete'] + y['sumUOC']);
        } else {
          y['percentual'] = 0;
        }


        const info = this.getInfo(k);
        y['icon'] = this.icons[info.color];
        y['tooltip'] = info.tooltip.join(', ');
        objs.push(y);
      });

      // setta a table
      this.data = objs;

      if (rangeDates) {
        this.snackBar.open('Novos dados carregandos com sucesso', 'Ok', {
          duration: 4000,
        });
      }
    }));
  }

  updateFromCachedData(data = null) {
    this.snackBar.open('Carregando novos dados, por favor aguarde um instante', 'Ok', {
      duration: 4000,
    });

    this.getData(data);
  }

  getInfo(dr) {
    const tooltip = [];
    let color = 'green';
    const hora = 1000 * 60 * 60;
    const now = Date.now();

    // se está aguardando atualizações da LSP
    if ( dr.status === 'Waiting LSP' ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 1 * hora ) {
          // se a última atualização ocorreu a menos de 1h
          color = 'green';
        } else if ( now - d.getTime() < 2 * hora ) {
          // se a última atualização ocorreu a menos de 2h
          color = 'yellow';
          tooltip.push('última atualização a mais de 1h');
        } else {
          // se a última atualização ocorreu a mais de 2h
          color = 'red';
          tooltip.push('última atualização a mais de 2h');
        }
      }
    }

    // se está pronto para coleta
    if ( dr.status === 'READY_TO_PICKUP' ) {
      if ( dr.mdr.pickupDate === null ) {
        color = 'undefined';
        tooltip.push('data de coleta ausente');
      } else {
        const d = new Date(dr.mdr.pickupDate);
        if ( d.getTime() - now > 1 * hora ) {
          // mais de 1h até a hora de coleta
          color = 'green';
        } else if ( d.getTime() - now > 0.5 * hora ) {
          // mais de 30min até a hora de coleta
          color = 'yellow';
          tooltip.push('menos de 1h até a hora de coleta');
        } else {
          // menos de 30min até a hora de coleta
          color = 'red';
          tooltip.push('menos de 30 minutos até a hora de coleta');
        }
      }
    }

    // se já foi entregue
    if ( dr.status === 'Delivered' ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 6 * hora ) {
          // se a última atualização ocorreu a menos de 6h
          color = 'green';
        } else if ( now - d.getTime() < 12 * hora ) {
          // se a última atualização ocorreu a menos de 12h
          color = 'yellow';
          tooltip.push('última atualização a mais de 6h');
        } else {
          // se a última atualização ocorreu a mais de 12h
          color = 'red';
          tooltip.push('última atualização a mais de 12h');
        }
      }
    }

    if ( ['HubReturn',
          'Replan - Retido',
          'Replan - Sefaz',
          'Replan - Antecipação',
          'Replan - Postergação',
          'Replan - Pernoite'].indexOf(dr.status) !== -1 ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 5 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 5d
          color = 'green';
        } else if ( now - d.getTime() < 10 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 10d
          color = 'yellow';
          tooltip.push('última atualização a mais de 5 dias');
        } else {
          // se a última atualização ocorreu a mais de 10d
          color = 'red';
          tooltip.push('última atualização a mais de 10 dias');
        }
      }
    }

    // verifica as ocorrencias da MDR
    let occStatus = 'SENT_TO_LSP';
    if ( this.role === 1 ) { occStatus = 'OCCURRENCE_TO_RESOLVE'; }
    if ( 'occurrences' in dr && dr.occurrences.length > 0 ) {
      const occurrencesNotSolved = [];
      for (let i = 0; i < dr.occurrences.length; i++ ) {
        if (dr.occurrences[i].status === occStatus ) {
          occurrencesNotSolved.push(dr.occurrences[i]);
        }
      }

      if (occurrencesNotSolved.length > 0) {
        const s = ((occurrencesNotSolved.length === 1) ? '' : 's');
        if (occurrencesNotSolved.some((el) => {
          const d = new Date(el.createdAt);
          return (now - d.getTime() > 2 * hora);
        })) {
          // se alguma ocorrencia foi criada mais de 2h atrás
          color = 'red';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        } else {
          // se alguma ocorrencia foi criada, e todas menos de 2h atrás
          color = (color === 'red') ? color : 'yellow';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        }
      }
    } // fim verificação ocorrencias

    return {
      color: color,
      tooltip: tooltip
    };
  }

  getTowerControl() {
    this.subscriptions.push(this.drService.getTower().subscribe( (elem) => {
      // console.log(elem);
    }));
  }

  onRowClick(row, that) {
    that.openDialog(row);
  }

  goToMDR(k) {
    // TODO adicionar isso no expandable-row
    this.router.navigate(['logistic-order/' + k.mdr.id]);
  }

  styleIcon(row, that) {
    // colore a celula de acordo com o icon
    for ( const key of Object.keys(that.icons) ) {
      if (that.icons[key] === row.icon) {
        return key;
      }
    }
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(CreateOccurrenceStatusDialogComponent, {
      data: row
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      this.getData();
    }));
  }

}

@Component({
  selector: 'app-create-occurrence-status-dialog',
  templateUrl: 'create-occurrence-status-dialog.html',
})
export class CreateOccurrenceStatusDialogComponent implements OnDestroy {
  occurrence = new Occurrence();
  subscriptions: any[] = [];

  constructor(
    public mdrService: MdrService, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateOccurrenceStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }

  onClickSave(): void {
    this.occurrence.idMDR = this.data.mdrID;
    this.occurrence.status = 'OCCURRENCE_RESOLVED';
    this.occurrence.uoc = 'Status';
    this.subscriptions.push(this.mdrService.createOccurrence(this.occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
          duration: 4000,
        });
      }
    }));
    this.dialogRef.close({ what: 'update', data: this.data });
  }

}
