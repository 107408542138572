import { ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { FormControl } from '@angular/forms';
import { DrService } from '../../providers/dr.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
var NewMdrRequestComponent = /** @class */ (function () {
    function NewMdrRequestComponent(dialog, mapsAPILoader, drService, mdrService, router) {
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.displayedColumns = ['id', 'status'];
        this.data = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
    }
    NewMdrRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchControl = new FormControl();
        this.mdrService.getAllLSP().subscribe(function (data) {
            // console.log(data);
            _this.data = data;
            _this.dataSource = new MatTableDataSource(data);
        });
    };
    NewMdrRequestComponent.prototype.goTo = function (mdr) {
        this.router.navigate(['logistic-order', mdr.id]);
    };
    return NewMdrRequestComponent;
}());
export { NewMdrRequestComponent };
