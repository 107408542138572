import { PipeTransform } from '@angular/core';
/*
 * Formata o id da MDR recebida
 * Recebe um valor inteiro
 *
 * Uso:
 *   Number | mdr-id
 * Exempo:
 *   {{ 132 | mdr-id }}
 *   resulta em: 'EDB-TP000132'
 */
var MdrIdPipe = /** @class */ (function () {
    function MdrIdPipe() {
    }
    MdrIdPipe.prototype.transform = function (id) {
        var len = 6;
        var preffix = 'EDB-TP';
        return preffix + ('0000000' + id).slice(-len);
    };
    return MdrIdPipe;
}());
export { MdrIdPipe };
