import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar, MatPaginator} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
import { parseString } from 'xml2js';
import {NF} from '../../models/nfe';
import {NfeService} from '../../providers/nfe.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-bulk-nfe-upload',
    templateUrl: './bulk-nfe-upload.component.html',
    styleUrls: ['./bulk-nfe-upload.component.scss']
})

export class BulkNfeComponent implements OnInit {
    public searchControl: FormControl;
    uploaded = false;
    displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
        'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials'];
    dataSourceNF = new MatTableDataSource<NF>();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    newNfList: NF[] = [];
    fileList: File[] = [];

    constructor(public dialog: MatDialog, public ovService: OVService,
                public translate: TranslateService,
                public router: Router, public drService: DrService, public nfService: NfeService,
                public snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    insertNFe() {
        this.nfService.create_xml(this.newNfList, this.fileList).subscribe( (data) => {
            this.uploaded = false;
        });
    }

  openFile(fileupload: any) {
    const input = fileupload.target;
    this.newNfList = [];
    this.fileList = [];
    for (let index = 0; index < input.files.length; index++) {
      const reader = new FileReader();
      reader.readAsText(input.files[index]);
      reader.onload = () => {
        parseString(reader.result, (err, result) => {
          try {
            const info = result.nfeProc.NFe[0].infNFe[0];
            const nf = new NF();
            nf.totalValue = info.total['0'].ICMSTot['0'].vNF['0'];
            nf.emissionDate = info.ide['0'].dhEmi['0'];
            nf.client = info.dest['0'].xNome['0'];
            nf.series = info.ide['0'].serie['0'];
            nf.type = info.ide['0'].tpNF['0'];
            // console.log(info.transp['0'].vol['0']);
            if ( info.transp['0'].vol !== undefined) {
              if (info.transp['0'].vol['0'].pesoB !== undefined) {
                nf.weight = info.transp['0'].vol['0'].pesoB['0'];
              } else {
                nf.weight = '0';
              }
            } else {
              nf.weight = '0';
            }
            nf.materials = info.det['0'].prod['0'].xProd['0'];
            nf.invoiceNumber = info.ide['0'].nNF['0'];
            this.newNfList.push(nf);
            this.fileList.push(input.files[index]);
            if (index === input.files.length - 1) {
              this.dataSourceNF = new MatTableDataSource<NF>(this.newNfList);
              this.dataSourceNF.paginator = this.paginator;
              this.uploaded = true;
            }
          } catch (ex) {
            console.error(ex);
            this.snackBar.open('Arquivo não segue padrão esperado', 'Fechar', {duration: 4000});
          }
        });
      };
    }
  }

}
