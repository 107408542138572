import {Component, OnInit, ViewChild, Inject, ElementRef, AfterViewInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatChipInputEvent} from '@angular/material';
import {HubService} from '../../providers/hub.service';
import {HUB} from '../../models/hub';
import {HUBDR} from '../../models/hub__dr';

import { SiteService } from '../../providers/site.service';
import { AotSummaryResolver } from '@angular/compiler';
import { } from 'googlemaps';
declare var google: any;

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})

export class HubComponent implements AfterViewInit {
  public searchControl: FormControl;
  inputOVs: number;
  selection = new SelectionModel<HUB>(true, []);
  opened: any;
  data: HUB[];
  lat: any;
  lng: any;
  marker = false;
  displayedColumns = ['address', 'name', 'sc', 'latitude', 'longitude'];
  hubColumns = ['dr', 'value', 'since'];
  dataSource = new MatTableDataSource<HUB>(this.data);
  hubSource = new MatTableDataSource<any>();
  totalHub = 0;
  showDeleted = false;
  hubUserAdmin = ['clara.gama@awarelog.com', 'henrique@awarelog.com', 'nicole@awarelog.com', 'vitor.marge@awarelog.com', 'israel.alves@ericsson.com', 'rafael.aparecido@ericsson.com', 'juliane.turman@ericsson.com'];

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  public searchChips: any[] = [];
  removable = true;
  selectable = true;
  selectedHub: HUB = undefined;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  searchTerm = '';
  tokenSeparator = '@@@@@@@@@';

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public clientService: ClientUserService,
              private drService: DrService, private hubService: HubService,
              public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.dataSource.sort = this.sort;
    this.getAllHubs();
  }

  ngAfterViewInit() {
  }

  getTowerControl() {
    this.drService.getTower().subscribe( (elem) => {
      // console.log(elem);
    });
  }

  goToHub(row) {
    this.hubService.getFromHub(row.id).subscribe( (data) => {
      this.selectedHub = row;
     // console.log(data);
      this.hubSource =  new MatTableDataSource<any>(data);
      this.totalHub = 0;
      data.forEach((el) => {
        this.totalHub += parseInt(el.dr.value, 10);
      });
    });
  }

  openAddHubDialog(hub: any) {
    if (hub === undefined) {
      hub = 'Não foi possivel criar Hub';
    }
    const dialogRef = this.dialog.open(AddHubDialogComponent, {
      width: '600px',
      data: hub,
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response && response.text.indexOf('Hub created correctly') !== -1) {
        this.getAllHubs();
      }
    });
  }

  getAllHubs() {
    this.hubService.getAll(!this.showDeleted).subscribe((response) => {
      this.data = response;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data: any, mergedFilter: string ) => {
        const filter = this.splitSearchTerms(mergedFilter);
        let display = false;
        this.displayedColumns.forEach( (el, i, arr) => {
          // atualmente é feita a comparação com OR lógico
          // para mudar para AND lógico: trocar .some() por .every()
          if ( filter.length === 0 ) {
            display = true;
          } else if (filter.some( chip => {
              if (el in data &&
                data[el] !== null) {
                if (typeof data[el] === 'string' &&
                  data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof data[el] === 'number' &&
                  data[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            })) {
            display = true;
          }
        });
        return display;
      };
    });

  }

  changeShowDeleted() {
    this.showDeleted = !this.showDeleted;
    this.getAllHubs();
  }

  // editHub() {
  //  // console.log(this.selectedHub);
  //   this.router.navigate(['add-hub/' + this.selectedHub.id]);
  // }

  removeHub() {
    // console.log(this.selectedHub);
    this.hubService.removeHub(this.selectedHub.id).subscribe((data) => {
      this.data = this.data.filter(e => (e.id !== this.selectedHub.id));
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.selectedHub = undefined;
      if (data && data.text === 'Hub deleted correctly') {
        this.snackBar.open('Hub deletado com sucesso', 'Ok', {duration: 5000});
        this.showDeleted = false;
        this.getAllHubs();
      }
    }, (error) => {
      if (error && error.error.text === 'Cannot delete Hub') {
        this.snackBar.open('Não foi possível deletar o Hub', 'Ok', {duration: 5000});
      }
     // console.log(error);
    });
  }

  getMapClass() {
    return  this.marker ? 'map-show' : 'map-hidden';
  }

  viewMap() {
   // console.log(this.marker);
    if (this.marker === true ) {
      this.marker = false;
    } else {
     // console.log(this.lat);
      if (this.lat === undefined || this.lng === undefined ) {
        this.convertAddressToLatLng();
        this.marker = true;
      } else {
        this.marker = true;
      }
    }
  }

  convertAddressToLatLng() {
    // const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': this.selectedHub.address}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.lat = lat;
        this.lng = lng;
        this.marker = true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  goToMDR(k) {
    this.router.navigate(['logistic-order/' + k.dr.idMDR]);
  }

  applyFilter() {
    this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchChips.push({'name': value.trim()});
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  mergeSearchTerms(terms): string {
    let merged = '';
    terms.forEach( (el, i, arr) => {
      if (typeof el.name === 'string') {
        merged = merged + this.tokenSeparator + '"' + el.name + '"';
      } else if (typeof el.name === 'number') {
        merged = merged + this.tokenSeparator + String(el.name);
      }
    });
    merged = merged.substring(this.tokenSeparator.length, merged.length);
    return merged;
  }

  splitSearchTerms(terms) {
    const split = [];
    terms.split(this.tokenSeparator).forEach( (el, i, arr) => {
      if (el[0] === '"' && el[el.length - 1] === '"') {
        split.push(el.substring(1, el.length - 1));
      } else {
        split.push(parseInt(el, 10));
      }
    });
    return split;
  }

  return(): void {
    this.selectedHub = undefined;
  }
}

@Component({
  selector: 'app-add-hub-dialog',
  templateUrl: 'add-hub-dialog.html',
  styleUrls: ['./hub.component.scss']
})
export class AddHubDialogComponent implements OnInit {
  name: string;
  address;
  lat;
  lng;
  lsp;
  lspList = [];
  hub = new HUB();
  @ViewChild('newAddres')
  public searchElementRef: ElementRef;

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  constructor(
    public dialogRef: MatDialogRef<AddHubDialogComponent>, public clientUserService: ClientUserService, public hubService: HubService, public mapsAPILoader: MapsAPILoader,
    @Inject(MAT_DIALOG_DATA) public data, public snackBar: MatSnackBar) {
      this.clientUserService.getLspList().subscribe( (lsps) => {
        this.lspList = lsps;
      });
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('hubAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.hub.lat = lat;
        this.hub.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  convertLatLngToAddress() {
    if (this.hub.lat === null || this.hub.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.hub.address = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
       // console.log(status);
      }
    });
  }

  onYesClick() {
    this.hubService.create(this.hub.name, this.hub.lat, this.hub.lng, this.hub.address, this.lsp).subscribe( (data) => {
      if (data && data.text === 'Hub created correctly') {
        this.snackBar.open('Hub criado com sucesso', 'Ok', {duration: 5000 });
      }
      this.dialogRef.close(data);
    }, (error) => {
      if (error && error.error.text === 'Failed to create Hub') {
        this.snackBar.open('Erro ao criar Hub', 'Ok', {duration: 5000});
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
