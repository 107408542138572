<h1 mat-dialog-title>{{ 'id' | translate }}: {{data.id}}</h1>
<div>Site Investigation <mat-slide-toggle [(ngModel)]="aware">Aware</mat-slide-toggle></div>
<a *ngIf="data && data.reportlink" [href]="data.reportlink">{{ 'SITE_INVESTIGATION/dialog/report' | translate }}</a>
<a *ngIf="aware && forms" href="../../../assets/extras/456 - Qua, Set 29 2021.pdf" download=""><i *ngIf="aware" class="fa fa-file-pdf click-button" matTooltip="Download em pdf" (click)="onClickPrint()"></i></a>
<!-- <i *ngIf="aware" class="fa fa-file-pdf click-button" matTooltip="Download em pdf" (click)="onClickPrint()"></i> -->
<div id="printable" class="" mat-dialog-content>

  <div class="d-flex col-md-12 mt-3 px-0">
    <div class="col-md-6">
      <button mat-raised-button *ngIf="!aware" class="full-width primary-button" (click)="onClickNewToken()">{{ 'SITE_INVESTIGATION/dialog/btn/token' | translate }}</button>
      <button mat-raised-button *ngIf="aware" class="full-width primary-button" (click)="onClickNewAwareToken()">Novo Laudo</button>
      <p>{{ 'SITE_INVESTIGATION/table/token' | translate }}:
        <span *ngIf="data.accesstokenlink">
          <a [href]="data.accesstokenlink">{{ 'YES' | translate }}</a>
        </span>
        <span *ngIf="!data.accesstokenlink">{{ 'NO' | translate }}</span>
      </p>
    </div>

    <div class="col-md-6">
      <button mat-raised-button [disabled]="data.aprovado" class="full-width" (click)="onClickApprove()">{{ 'SITE_INVESTIGATION/dialog/btn/approve' | translate }}</button>
      <p>{{ 'SITE_INVESTIGATION/table/aprovado' | translate }}: {{ (data.aprovado ? 'YES' : 'NO') | translate }}</p>
    </div>
  </div>
  

  <form #form="ngForm"  class="d-flex flex-wrap">
    <div *ngIf="aware" style="width: 100%;">
      <app-table *ngIf="formsList.length > 0"
        [columns]="columns"
        [data]="formsList"
        [clickCallback]="goTo"
        [this]="this"
      ></app-table>
      <div *ngIf="formsList.length === 0">Não existem ainda Laudos criados</div>

      <div *ngIf="forms !== undefined"  class="col-md-12">
        <div class="col-md-12 p-0" *ngFor="let temp of forms.templates">
          <p>{{temp.title}}</p>
          <div class="col-md-6 p-0" style="display: inline-grid;" *ngFor="let question of temp.questions">
            <mat-form-field class="col-md-12">
              <mat-label>{{question.description}}</mat-label>
              <input matInput disabled [(ngModel)]="question.answer.value" [name]="question.description">
            </mat-form-field>
            <div class="col-md-12">
              <span *ngIf="question['answer']['files'].length > 0">
                <app-image-lightbox [images]="[{id:question['answer']['files']['name'],url:question['answer']['files'][0]['url']}]"></app-image-lightbox>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  <div *ngIf="!aware" class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
      <input matInput name="visitadatahora" [owlDateTimeTrigger]="dt" name="visitaTime" [owlDateTime]="dt" [placeholder]="'SITE_INVESTIGATION/table/visitadatahora' | translate" [(ngModel)]="data.visitadatahora" required>
      <owl-date-time #dt></owl-date-time>
    </mat-form-field>
    <!-- <mat-form-field class="col-md-6">
      <input matInput name="vistoriadatahora" [owlDateTimeTrigger]="dt2" name="vistoriaTime" [owlDateTime]="dt2" [placeholder]="'SITE_INVESTIGATION/table/vistoriadatahora' | translate" [(ngModel)]="data.vistoriadatahora" required>
      <owl-date-time #dt2></owl-date-time>
    </mat-form-field> -->

    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/acesso' | translate }}</mat-label>
      <mat-select required [(ngModel)]="data.acesso" name="acesso">
        <mat-option *ngFor="let item of tipos['acesso']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/obs' | translate }}</mat-label>
      <textarea required matInput [(ngModel)]="data.obs" name="obs"></textarea>
    </mat-form-field>

    <mat-form-field class="col-md-4">
      <mat-label>{{ 'SITE_INVESTIGATION/table/code' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.code" name="code">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'SITE_INVESTIGATION/table/operadora' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.operadora" name="operadora">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'SITE_INVESTIGATION/table/prolog' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.prolog" name="prolog">
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/address' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.address" name="address" (change)="address2latlng()">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/lat' | translate }}</mat-label>
      <input required matInput type="number" [(ngModel)]="data.lat" name="lat" (change)="latlng2address()">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/lng' | translate }}</mat-label>
      <input required matInput type="number" [(ngModel)]="data.lng" name="lng" (change)="latlng2address()">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/municipio' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.municipio" name="municipio">
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/rsu' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.rsu" name="rsu">
    </mat-form-field>
    <!-- <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/site' | translate }}</mat-label>
      <input required matInput [(ngModel)]="data.site" name="site">
    </mat-form-field> -->
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/regional' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.regional" name="regional">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/visitanumero' | translate }}</mat-label>
      <input matInput type="number" [(ngModel)]="data.visitanumero" name="visitanumero">
    </mat-form-field>

    <div>
      {{ 'SITE_INVESTIGATION/table/tipo' | translate }}:
      <p *ngFor="let item of tipos['tipo']">
        <mat-checkbox
          [checked]="data.tipo[item.value]"
          (change)="data.tipo[item.value] = !data.tipo[item.value]"
          name="item.show">
          {{item.show | translate}}</mat-checkbox>
      </p>
    </div>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/infra' | translate }}</mat-label>
      <mat-select [(ngModel)]="data.infra" name="infra">
        <mat-option *ngFor="let item of tipos['infra']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_nome' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.vistoriador_nome" name="vistoriador_nome">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_rg' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.vistoriador_rg" name="vistoriador_rg">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_cpf' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.vistoriador_cpf" name="vistoriador_cpf">
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_nome' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.responsavel_nome" name="responsavel_nome">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_rg' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.responsavel_rg" name="responsavel_rg">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_cpf' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.responsavel_cpf" name="responsavel_cpf">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_contato' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.responsavel_contato" name="responsavel_contato">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_funcao' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.responsavel_funcao" name="responsavel_funcao">
    </mat-form-field>

    <mat-divider class="col-md-12"></mat-divider>
    <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_1' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_1_1','form_1_2','form_1_3','form_1_4','form_1_5','form_1_6','form_1_7','form_1_8','form_1_9']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
            {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_2' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_2_1','form_2_2','form_2_3','form_2_4','form_2_5','form_2_6','form_2_7','form_2_8','form_2_9']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
                        {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_3' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_3_1','form_3_2','form_3_3']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
                        {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_4' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_4_1','form_4_2','form_4_3']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
                        {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_5' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_5_1','form_5_2','form_5_3']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
                        {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_6' | translate }}</h5>

    <div class="col-md-12 p-0"
      *ngFor="let form of ['form_6_1','form_6_2','form_6_3','form_6_4','form_6_5']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
                        {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_7' | translate }}</h5>

    <div class="col-md-12 p-0"
            *ngFor="let form of ['form_7_1','form_7_2','form_7_3']">
      <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
      <mat-form-field class="col-md-12">
        <mat-select [(ngModel)]="data[form]" [name]="form">
          <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
            {{item.show | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
        <input matInput [(ngModel)]="data[form+'_obs']" [name]="[form+'_obs']">
      </mat-form-field>
      <div class="col-md-12"
        *ngIf="data[form] === SITE_ESTRUTURA.EX">
        <span *ngIf="data[form+'_anexo'] && data[form+'_anexo'].path">
          <app-image-lightbox [images]="[{id:form+'_anexo',url:data[form+'_anexo'].path}]"></app-image-lightbox>
        </span>
        <app-form-upload [name]="[form+'_anexo']" (setFile)="data[form+'_anexo'] = $event"></app-form-upload>
      </div>
      <mat-divider class="col-md-12"></mat-divider>
    </div>
  </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickBack()" cdkFocusInitial>{{ 'SITE_INVESTIGATION/dialog/btn/back' | translate }}</button>
  <span></span>
  <button mat-button (click)="onClickRemove()" color="warn">{{ 'SITE_INVESTIGATION/dialog/btn/remove' | translate }}</button>
  <button mat-raised-button class=" -button" [disabled]="!form.valid" (click)="onClickSave()">{{ 'SITE_INVESTIGATION/dialog/btn/save' | translate }}</button>
  <!-- <button mat-raised-button class=" -button" [disabled]="!form.valid" (click)="onClickSaveTeste()">Salvar Novo Modelo</button> -->
</div>
