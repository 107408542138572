var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { DR } from '../../models/dr';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { ERBService } from '../../providers/erb.service';
import { SiteService, ESTRUTURA, ACESSO } from '../../providers/site.service';
import { ClientUserService } from '../../providers/client-user.service';
import { OVService } from '../../providers/ov.service';
import { UploadFileService } from '../../providers/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
var DrBookingComponent = /** @class */ (function () {
    function DrBookingComponent(snackBar, mapsAPILoader, clientUserService, drService, erbService, ovService, siteService, uploadService, router, excelService, sanitizer) {
        var _this = this;
        this.snackBar = snackBar;
        this.mapsAPILoader = mapsAPILoader;
        this.clientUserService = clientUserService;
        this.drService = drService;
        this.erbService = erbService;
        this.ovService = ovService;
        this.siteService = siteService;
        this.uploadService = uploadService;
        this.router = router;
        this.excelService = excelService;
        this.sanitizer = sanitizer;
        this.dr = new DR();
        this.ovValidated = null;
        this.showtable = false;
        this.listOV = [];
        this.drLengthUploaded = 0;
        this.uploaded = false;
        this.paste_input = '';
        this.showSiteInfo = false;
        this.displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'site'];
        this.newDrList = [];
        this.minDate = new Date();
        this.distance = null;
        this.uploadedFiles = [];
        this.columns = [
            { value: 'ov', name: 'OV' },
            { value: 'solut', name: 'status' },
        ];
        this.site = {
            'color': '',
            'tooltips': {
                'grey': 'site não existe',
                'yellow-1': 'site não aprovado',
                'yellow-2': 'site não liberado',
                'yellow-3': 'site não aprovado e não liberado',
                'green': 'site aprovado',
                'undefined': ''
            },
            'icons': {
                'grey': 'fa-question-circle',
                'yellow-1': 'fa-exclamation-triangle',
                'yellow-2': 'fa-exclamation-triangle',
                'yellow-3': 'fa-exclamation-triangle',
                'green': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'grey': 'icon-grey',
                'yellow-1': 'icon-yellow',
                'yellow-2': 'icon-yellow',
                'yellow-3': 'icon-yellow',
                'green': 'icon-green',
                'undefined': ''
            }
        };
        this.clientList = [];
        this.map_show = false;
        this.map_data = null;
        this.red_marker = {
            url: './assets/images/pin_red.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.blue_marker = {
            url: './assets/images/pin.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    DrBookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    DrBookingComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = +lat;
                _this.dr.lng = +lng;
                _this.drService.getDistanceToLatLng(lat, lng).subscribe(function (elem) {
                    if (elem.distance) {
                        var k = elem.distance.value / 600000;
                        var dNew = new Date();
                        _this.minDate = new Date(dNew.setDate(dNew.getDate() + k + 2));
                        _this.distance = elem.distance.value / 1000;
                        _this.dr.distance = _this.distance;
                    }
                });
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    DrBookingComponent.prototype.validate = function () {
        var _this = this;
        this.newDrList.forEach(function (elem) { return __awaiter(_this, void 0, void 0, function () {
            var ovs, i, j, _i, ovs_1, k, data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ovs = String(elem.status).split(',');
                        for (i = 0; i < ovs.length; i++) {
                            if (ovs[i] !== undefined) {
                                ovs[i] = ovs[i].trim();
                            }
                        }
                        elem.ovList = [];
                        j = [];
                        _i = 0, ovs_1 = ovs;
                        _a.label = 1;
                    case 1:
                        if (!(_i < ovs_1.length)) return [3 /*break*/, 4];
                        k = ovs_1[_i];
                        return [4 /*yield*/, this.ovService.getAll(parseInt(k, 10)).toPromise()];
                    case 2:
                        data = _a.sent();
                        elem.prolog = data[0]['assignmentInformation'];
                        j.push(data);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        // console.log(j);
                        elem.ovList = j.reduce(function (acc, val) { return acc.concat(val); }, []);
                        // console.log(elem);
                        this.ovService.validateOVs(ovs).subscribe(function (isValid) {
                            _this.allValid = isValid;
                            // console.log(isValid);
                            if (isValid === false) {
                                // OVs invalidas segundo backend
                                elem.idDRidentifier = 'red';
                                _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                                _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                            }
                            else if (elem.pickupDifAddress === true) {
                                // valida HUB recebido do arquivo
                                // console.log(isValid);
                                _this.ovService.validateHub(elem.pickupAddress).subscribe(function (resp) {
                                    if (resp !== undefined && resp.text !== 0) {
                                        // HUB é válido
                                        elem.pickupAddress = resp.text;
                                    }
                                    else {
                                        // HUB é inválido
                                        _this.allValid = false;
                                        elem.idDRidentifier = 'red';
                                        _this.snackBar.open('Erro ao criar requisições a partir de arquivo: HUB não valido', 'Ok', { duration: 4000 });
                                        _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                                    }
                                });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DrBookingComponent.prototype.validateOV = function () {
        var _this = this;
        var ovAuxList = [];
        this.showtable = false;
        var newList = this.salesOrders.split(',');
        var cont = newList.length;
        this.ovValidated = true;
        newList.forEach(function (el) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.ovService.validateOVs([el]).subscribe(function (resp) {
                    ovAuxList.push({ ov: el, solut: resp });
                    if (!resp) {
                        _this.ovValidated = false;
                    }
                    if (cont--) {
                        _this.listOV = ovAuxList;
                        _this.showtable = true;
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    DrBookingComponent.prototype.convertDRAddressToLatLng = function (dr) {
        var _this = this;
        var error = false;
        var geocoder = new google.maps.Geocoder();
        var addr = dr.deliveryAddress;
        // console.log(addr);
        return geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                dr.deliveryAddress = results[0].formatted_address;
                dr.lat = results[0].geometry.location.lat();
                dr.lng = results[0].geometry.location.lng();
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    DrBookingComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        this.uploaded = false;
        var error = false;
        // console.log(fileupload);
        this.newDrList = [];
        this.drLengthUploaded = 0;
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                    var lengthDRs = data.length;
                    data.forEach(function (el, i, arr) {
                        var drAux = new DR();
                        try {
                            drAux.status = el.ov;
                            var ovs = String(drAux.status).split(',');
                            _this.drService.getFromSydle(ovs[0]).subscribe(function (resp) {
                                try {
                                    var info = resp['obras'][0];
                                    if (info['site']['coordenadaGeografica']['coordinates']) {
                                        if (info['site']['coordenadaGeografica']['coordinates'][0] !== null && info['site']['coordenadaGeografica']['coordinates'][1] !== null) {
                                            drAux.lat = info['site']['coordenadaGeografica']['coordinates'][1];
                                            drAux.lng = info['site']['coordenadaGeografica']['coordinates'][0];
                                            // if (!this.convertLatLngToAddress()) {
                                            drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                                            // this.convertAddressToLatLng();
                                            // }
                                        }
                                        else {
                                            drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                                            // this.convertAddressToLatLng();
                                        }
                                    }
                                    else {
                                        drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                                        // this.convertAddressToLatLng();
                                    }
                                    drAux.site = info['site']['nome'];
                                    drAux.client = info['cliente'];
                                    drAux.prolog = info['prolog'];
                                    drAux.supervisorName = info['supervisorEricsson']['nome'];
                                    drAux.supervisorPhone = el.supervisor_phone;
                                    drAux.receiverPhone = el.receiver_phone;
                                    drAux.receiverName = el.receiver_name;
                                    drAux.obs = el.obs;
                                    drAux.infraType = el.infra;
                                    drAux.dateScheduling = el.dateScheduling;
                                    // drAux.salesOrders = el.ov;
                                    _this.newDrList.push(drAux);
                                    String(drAux.status).split(',').forEach(function (part) {
                                        ovList.push(+part.trim());
                                    });
                                    if (++_this.drLengthUploaded === lengthDRs) {
                                        _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                                        _this.uploaded = true;
                                    }
                                    // this.dr.supervisorPhone = info['supervisorEricsson']['telefoneCelular'];
                                }
                                catch (er) {
                                    // console.log(er);
                                    _this.snackBar.open('Não foi possivel validar um ou mais dados no Sydle', 'Ok', { duration: 2000 });
                                    drAux.deliveryAddress = el.delivery_address;
                                    drAux.site = el.site;
                                    drAux.receiverName = el.receiver_name;
                                    drAux.receiverPhone = el.receiver_phone;
                                    drAux.supervisorName = el.supervisor_name;
                                    drAux.supervisorPhone = el.supervisor_phone;
                                    drAux.infraType = el.infra;
                                    drAux.obs = el.obs;
                                    drAux.prolog = el.prolog;
                                    drAux.client = el.client;
                                    drAux.dateScheduling = el.dateScheduling;
                                    if (_this.convertDRAddressToLatLng(drAux) === false) {
                                        _this.snackBar.open('Endereço inválido', 'Ok', { duration: 2000 });
                                        error = true;
                                    }
                                    drAux.pickupAddress = null;
                                    // drAux.salesOrders = el.ov;
                                    _this.newDrList.push(drAux);
                                    if (++_this.drLengthUploaded === lengthDRs) {
                                        _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                                        _this.uploaded = true;
                                    }
                                    String(drAux.status).split(',').forEach(function (part) {
                                        ovList.push(+part.trim());
                                    });
                                }
                            });
                        }
                        catch (ex) {
                            // console.log('erro ao ler arquivo xlsx ',ex);
                            _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                        }
                    });
                    // console.log(this.newDrList);
                    // this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
                    // console.log(this.dataSourceDR);
                    // this.uploaded = true;
                });
            }
        }
    };
    DrBookingComponent.prototype.searchSydleInformation = function () {
        var _this = this;
        this.drService.getFromSydle(this.salesOrders).subscribe(function (resp) {
            try {
                var info = resp['obras'][0];
                if (info['site']['coordenadaGeografica']['coordinates']) {
                    if (info['site']['coordenadaGeografica']['coordinates'][0] !== null && info['site']['coordenadaGeografica']['coordinates'][1] !== null) {
                        _this.dr.lat = info['site']['coordenadaGeografica']['coordinates'][1];
                        _this.dr.lng = info['site']['coordenadaGeografica']['coordinates'][0];
                        // if (!this.convertLatLngToAddress()) {
                        _this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                        // this.convertAddressToLatLng();
                        // }
                    }
                    else {
                        _this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                        // this.convertAddressToLatLng();
                    }
                }
                else {
                    _this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                    // this.convertAddressToLatLng();
                }
                _this.dr.site = info['site']['nome'];
                _this.dr.client = info['cliente'];
                _this.dr.prolog = info['prolog'];
                _this.dr.supervisorName = info['supervisorEricsson']['nome'];
                // this.dr.supervisorPhone = info['supervisorEricsson']['telefoneCelular'];
            }
            catch (er) {
                // console.log(er);
                _this.snackBar.open('Não foi possivel validar os dados Sydle', 'Ok', { duration: 2000 });
            }
        });
    };
    DrBookingComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
                _this.drService.getDistanceToLatLng(lat, lng).subscribe(function (elem) {
                    if (elem.distance) {
                        var k = elem.distance.value / 600000;
                        var dNew = new Date();
                        _this.minDate = new Date(dNew.setDate(dNew.getDate() + k + 1));
                        _this.distance = elem.distance.value / 1000;
                        _this.dr.distance = _this.distance;
                        // console.log(this.minDate);
                    }
                });
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    DrBookingComponent.prototype.createRequisition = function () {
        var _this = this;
        var successfullyCreated = 0;
        // console.log(this.newDrList);
        for (var _i = 0, _a = this.newDrList; _i < _a.length; _i++) {
            var drNew = _a[_i];
            // console.log(elem);
            // drNew.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
            drNew.distance = 1;
            drNew.unificationId = null;
            // console.log(drNew);
            drNew.status = drNew.status + '';
            drNew.salesOrders = drNew.status
                .replace(/ /g, ',') // troca espaços por vírgulas
                .split(',') // quebra em array nas vírgulas
                .filter(function (e) { return e.length; }); // remove strings vazias
            this.drService.createBooking(drNew).subscribe(function (response) {
                // console.log(this.uploadedFiles, this.uploadedFiles.length );
                if (_this.uploadedFiles && _this.uploadedFiles.length) {
                    _this.uploadFiles(response.id);
                }
                else {
                    _this.snackBar.open('DR Booking criada com sucesso', 'Ok', { duration: 2000 });
                    // reseta campos
                    _this.dr = new DR();
                    _this.salesOrders = '';
                    _this.router.navigate(['/cockpit']);
                }
            }, function (err) {
                _this.snackBar.open('Erro ao criar DR Booking', 'Ok', { duration: 2000 });
            });
        }
    };
    DrBookingComponent.prototype.createDRBooking = function () {
        var _this = this;
        if (this.dr.dateScheduling.getTime() - new Date().getTime() < 172800000) {
            this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora de agora.', 'Ok', { duration: 4000 });
            return;
        }
        this.dr.deliveryAddress = (document.getElementById('deliveryAddress').value);
        this.dr.salesOrders = this.salesOrders
            .replace(/ /g, ',') // troca espaços por vírgulas
            .split(',') // quebra em array nas vírgulas
            .filter(function (e) { return e.length; }); // remove strings vazias
        this.drService.createBooking(this.dr).subscribe(function (response) {
            // console.log(this.uploadedFiles, this.uploadedFiles.length );
            if (_this.uploadedFiles && _this.uploadedFiles.length) {
                _this.uploadFiles(response.id);
            }
            else {
                _this.snackBar.open('DR Booking criada com sucesso', 'Ok', { duration: 2000 });
                // reseta campos
                _this.dr = new DR();
                _this.salesOrders = '';
                _this.router.navigate(['/cockpit']);
            }
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrBookingComponent.prototype.uploadFiles = function (drId) {
        var _this = this;
        this.uploadService.pushFileToStorage(this.uploadedFiles, drId, 'dr').subscribe(function (event) {
            if (event.type === HttpEventType.Response) {
                // reseta campos
                _this.dr = new DR();
                _this.salesOrders = '';
                _this.uploadedFiles = [];
                _this.snackBar.open('DR Booking criada com sucesso', 'Ok', { duration: 2000 });
                _this.router.navigate(['/cockpit']);
            }
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrBookingComponent.prototype.fillFromClipboard = function (e) {
        var _this = this;
        var DMStoDD = function (str) {
            var parts = str.split(/[°'"]/).map(function (part) {
                return +part.replace(',', '.');
            });
            var decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
            return parts[0] + decimal;
        };
        var input = e.clipboardData.getData('text');
        var splitted = input.split('\t');
        this.salesOrders = splitted[1];
        this.dr.deliveryAddress = splitted[6];
        this.dr.lat = +DMStoDD(splitted[25]);
        this.dr.lng = +DMStoDD(splitted[26]);
        this.dr.receiverName = splitted[9];
        this.dr.receiverPhone = parseInt(splitted[10], 10);
        this.dr.dateScheduling = new Date(splitted[3]);
        this.dr.site = splitted[4];
        this.dr.infraType = splitted[11];
        this.dr.unificationId = splitted[0];
        this.dr.obs = splitted[12];
        setTimeout(function () {
            _this.paste_input = '';
        }, 200);
    };
    DrBookingComponent.prototype.toggleMap = function () {
        if (this.map_show) {
            this.map_show = false;
        }
        else {
            if (this.dr.lat && this.dr.lng) {
                // this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe((data) => {
                this.dr.lat = +this.dr.lat;
                this.dr.lng = +this.dr.lng;
                // this.map_data = data;
                this.map_show = true;
                // });
            }
        }
    };
    DrBookingComponent.prototype.checkSite = function () {
        var _this = this;
        this.showSiteInfo = false;
        if (this.dr.site === '') {
            this.site.color = '';
            return;
        }
        this.siteService.getSiteFromCode(this.dr.site).subscribe(function (data) {
            if (!data) {
                _this.site.color = 'grey';
                return;
            }
            _this.siteSelected = data;
            _this.siteSelected.operation = [];
            if (_this.siteSelected.form_6_1 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Grua');
            }
            if (_this.siteSelected.form_6_2 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Correia');
            }
            if (_this.siteSelected.form_6_3 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Veiculo Esp.');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Manual');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Guindaste');
            }
            if (_this.siteSelected.operation.length > 0) {
                _this.dr.thirdPartInvoice = true;
                // console.log(this.dr.thirdPartInvoice);
            }
            if (!data.aprovado) {
                _this.site.color = 'yellow-1';
            }
            if (data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-2';
            }
            if (!data.aprovado && data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-3';
            }
            if (data.aprovado && data.acesso === ACESSO.LIBERADO) {
                _this.showSiteInfo = true;
                _this.site.color = 'green';
            }
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    DrBookingComponent.prototype.receiveFile = function (event) {
        var _this = this;
        if (this.dr.id) {
            this.drService.getFilesFromDrManually(this.dr.id).subscribe(function (response) {
                _this.uploadedFiles = response;
            });
        }
    };
    DrBookingComponent.prototype.removeFile = function (file, index) {
        this.uploadedFiles.splice(index, 1);
    };
    DrBookingComponent.prototype.selectFile = function (event) {
        var selectedFiles = event.target.files;
        for (var i = 0; i < selectedFiles.length; i++) {
            selectedFiles[i].path = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(selectedFiles[i]));
            this.uploadedFiles.push(selectedFiles[i]);
        }
    };
    return DrBookingComponent;
}());
export { DrBookingComponent };
