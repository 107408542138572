import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
import { NfeService } from '../../providers/nfe.service';
import { NF } from '../../models/nfe';
import { ClientUserService } from '../../providers/client-user.service';
import { DR } from '../../models/dr';
import { UploadFileService } from '../../providers/upload.service';

@Component({
  selector: 'app-nfe-list',
  templateUrl: './nfe-list.component.html',
  styleUrls: ['./nfe-list.component.scss']
})

export class NFeListComponent implements OnInit {
  paymentList: any[] = [];
  data: any[] = [];
  listPay: any[] = [];
  role: number;

  columns = [
    {value: 'invoiceNumber', name: 'Nota Fiscal'},
    {value: 'series', name: 'Serie'},
    {value: 'client', name: 'Cliente'},
    {value: 'type', name: 'Tipo'},
    {value: 'weight', name: 'Peso'},
    {value: 'prolog', name: 'Prolog'},
    // {value: 'OV', name: 'FINANCE_LIST/table/ov'},
    // {value: 'type', name: 'FINANCE_LIST/table/type'},
    // {value: 'origem', name: 'FINANCE_LIST/table/origem'},
    {value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL'},
    // {value: 'createdAt', name: 'FINANCE_LIST/table/date'}
  ];

  constructor(public dialog: MatDialog,
              private mdrService: MdrService,
              public excelService: ExcelService,
              private drService: DrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public nfeService: NfeService,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit() {
    // console.log('HI');
    this.nfeService.getAllNfe().subscribe(data => {
      // console.log(data);
      const keys = data.length ? Object.keys(data[0]) : [];
      this.data = data.map( (k) => {
        for (let i = 0 , l = keys.length; i < l; i++) {
          if ( k[keys[i]] === null || k[keys[i]] === undefined ) {
            k[keys[i]] = '';
          }
        }
      });
      data.forEach( (k) => {
        k['currency'] = k.totalValue;
      });
      this.data = data;
    });
  }

  insertPaymentFromFile(fileupload: any) {
   // console.log(fileupload);
    // console.log(fileupload);
    const input = fileupload.target;
    // console.log(fileupload);
    this.paymentList = [];
    for (let index = 0; index < input.files.length; index++) {
      this.excelService.importFromExcel(fileupload).subscribe( (data) => {
        data.forEach( (el, i, arr) => {
          const payment = {};
         // console.log(el);
          try {
            payment[''] = el.pickup_address;
            // console.log(drAux.status);

          } catch (ex) {
            // console.log('erro ao ler arquivo xlsx ',ex);
            this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
          }

        });
      });
    }
  }

  updateFromFile(fileupload: any) {
    const input = fileupload.target;
    this.excelService.importFromExcel(fileupload).subscribe( (data) => {
      // console.log(data);
      const avisos = [];
      const checkRow = (row) => {
        // status é válido
        if ( 'status' in row
          && !Object.values(PAYMENT_STATUS).includes(row['status']) ) {
          avisos.push(`Linha ${row.__rowNum__} contém status inválido`);
          return false;
        }
        // campos necessários estão presentes
        if ( !('origemId' in row)
          || !('invoice' in row)
          || !('OV' in row)
          || !('origem' in row)) {
          avisos.push(`Linha ${row.__rowNum__} não contém campos obrigatórios [origemId,invoice,OV,origem]`);
          return false;
        }
        // linha já existe, portanto pode ser atualizada
        if ( !this.data.find((el) => {
          return (
            // tslint:disable-next-line:triple-equals
            el['origemId'] == row['origemId']
            // tslint:disable-next-line:triple-equals
            && el['invoice'] == row['invoice']
            // tslint:disable-next-line:triple-equals
            && el['OV'] == row['OV']
            // tslint:disable-next-line:triple-equals
            && el['origem'] == row['origem']
          );
        }) ) {
          avisos.push(`Linha ${row.__rowNum__} não existe no sistema`);
          return false;
        }
        return true;
      };
      // se todas as linhas recebidas são válidas
      if ( data.every(checkRow) ) {
        // atualiza as linhas
        const total = data.length;
        // console.log(total);
        this.mdrService.updatePayment(data).subscribe((response) => {
          this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
        }, (error) => {
          if ( 'status' in error ) {
            this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
          } else {
            this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
          }
        });
      } else {
        this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
        // mostra avisos gerados
        for (let i = 0, len = avisos.length ; i < len ; i++) {
          setTimeout(() => {
            this.snackBar.open(avisos[i], 'OK', { duration: 2000 });
          }, 2000 * (i + 1));
        }
      }
    });
  }

  goToNFe(row, that) {
    if ( row.mdrId !== 0 ) {
      // that.router.navigate(['logistic-order/' + row.mdrId]);
    } else {
      // that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
    }
    const dialogRef = this.dialog.open(EditNFeDialogComponent, {
      data: {
        role: this.role,
        nf: row,
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
    });
  }

  addList(listPayments) {
    this.listPay = listPayments;
  }

  payNF() {
    this.mdrService.payNF(this.listPay).subscribe( (response) => {
      this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
    });
  }

  remove() {
    let removed = 0;
    const total = this.listPay.length;
    this.listPay.forEach( payment => {
      this.mdrService.removePayment(payment.id).subscribe((response) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
        const paymentI = this.data.findIndex(e => e.id === payment.id);
        if (paymentI >= 0) {
          this.data.splice(paymentI, 1);
        }
      }, (error) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
      });
    });
  }

}


@Component({
  selector: 'app-edit-nfe-dialog',
  templateUrl: 'edit-NFe-dialog.html',
  styleUrls: ['./nfe-list.component.scss']
})
export class EditNFeDialogComponent implements OnInit {
  nfe: NF;
  public searchControl: FormControl;
  uploaded = false;
  inputOVs: number;
  dr: DR;
  opened: any;
  public dateCollect: Date;
  minDate = new Date();
  displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
    'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials', 'prolog'];
  columns = [
    {value: 'site', name: 'Site'},
    {value: 'deliveryAddress', name: 'Delivery Address'},
    {value: 'client', name: 'Cliente'},
  ];
  role: number;
  newNfList: NF[] = [];
  clientList: string[] = [];
  update = true;
  attachment: any = null;
  // hubList: HUB[] = [];
  hubId: any;
  imageFile = undefined;
  listAllDrs = [];
  hubObs: any;

  constructor(
    public dialogRef: MatDialogRef<EditNFeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public drService: DrService,
    public nfeService: NfeService,
    public snackBar: MatSnackBar,
    public router: Router,
    public uploadService: UploadFileService,
    public clientUserService: ClientUserService
  ) {
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
    this.nfe = data.nf;
    if (this.nfe.drId) {
      this.drService.getById(this.nfe.drId).subscribe(el => {
        this.dr = el;
      });
    }
    // this.drService.getAllNfeConnections().subscribe(el => {

    // })
    this.nfeService.getHistory(this.nfe.id).subscribe(nf => {
      // console.log(nf);
      this.uploadService.findByEntity(this.nfe.invoiceNumber, 'NFe').subscribe(image => {
        this.imageFile = image;
      });
      this.listAllDrs = nf[0].drs;
      // console.log(nf);
    });
  }

  ngOnInit() {
    // faz uma copia da DR para edição
    // this.current = Object.assign({}, this.data.dr);
  }

  saveNFe() {
    // console.log(this.nfe);
    this.nfeService.update(this.nfe).subscribe(data => {
      this.snackBar.open('Atualizações feitas corretamente', 'Ok', {duration: 2000});
    });
  }

  goTo() {
    if (this.dr.idMDR) {
      this.router.navigate(['logistic-order/' + this.dr.idMDR]);
    } else {
      this.router.navigate(['delivery-optimizer']);
    }
  }

  goToProperDr(row, that) {
    // console.log(row, that);
    if ( row.mdrId !== 0 ) {
      window.open('logistic-order/' + row.idMDR, '_blank').focus();
    } else {
      that.snackBar.open('Não há MDR associada a este item. Já verificou o Otimizador de Entregas?', 'Ok', { duration: 4000 });
    }
  }

  removeNFe() {
    // console.log(this.nfe);
    this.nfeService.delete(this.nfe).subscribe(data => {
      this.snackBar.open('Nota removida corretamente', 'Ok', {duration: 2000});
    });
  }

  attachFile(event) {
    this.snackBar.open('Nota adicionada corretamente', 'Ok', {duration: 2000});
  }

  onYesClick() {
    // compara DR copiada para edição com DR anterior
    const checkChanges = (key) => {
      return ( this.nfe[key] !== this.data.dr[key] );
    };

    // se nao houve alguma modificação
    const keys = Object.keys(this.nfe);
    if ( !keys.some(checkChanges) ) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    // this.drService.update(this.current).subscribe( (response) => {
    //   if ( response === true ) {
    //     this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
    //     // copia valores pra DR anterior
    //     keys.forEach((key) => {
    //       this.data.dr[key] = this.current[key];
    //     });
    //     this.dialogRef.close();
    //   } else {
    //     this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
    //   }
    // });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
