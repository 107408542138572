import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss']
})

export class UserActivityComponent implements OnInit {
  columns = [
    {name: 'USER_ACTIVITY/table/name', value: 'name'},
    {name: 'USER_ACTIVITY/table/email', value: 'email'},
    {name: 'USER_ACTIVITY/table/last-login', value: 'lastLogin'},
    {name: 'USER_ACTIVITY/table/last-reset', value: 'lastPasswordReset'},
    {name: 'USER_ACTIVITY/table/deleted-by', value: 'deletedBy'},
    {name: 'USER_ACTIVITY/table/deleted-on', value: 'deletedOn'},
    {name: 'USER_ACTIVITY/table/delete', value: 'icon', icon: 'icon', color: this.styleIcon},
  ];
  data: any[] = [];
  role = -1;
  toRemove: any[] = [];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    public auth: AuthService,
  ) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    if (this.role !== 1) {
      this.snackBar.open('Você não possui as permissões necessárias para visualizar esta página', 'Ok', {duration: 4000});
      this.router.navigate(['/']);
    }
    this.getData();
  }

  ngOnInit() { }

  getData() {
    this.auth.getUserActivities().subscribe((response) => {
      this.data = response.map((row) => {
        // convert values to Date
        row.lastLogin = (row.lastLogin ? new Date(row.lastLogin) : null);
        row.lastReset = (row.lastReset ? new Date(row.lastReset) : null);
        row.deletedOn = (row.deletedOn ? new Date(row.deletedOn) : null);
        // allow removing only non-role-1
        if (row.user !== null && row.user.role !== 1) {
          row.icon = 'person_remove';
        }
        return row;
      });
    }, (err) => {
      this.snackBar.open('Erro ao carregar dados', 'Ok', {duration: 4000});
    });
  }

  styleIcon(row, that) {
    if (row.icon) {
      return 'red';
    }
  }

  removeUser(row, that) {
    // can't remove already removed users
    // or role-1 users
    if (row.user === null || row.user.role === 1) {
      return;
    }

    // show confirmation modal
    const dialogRef = that.dialog.open(ConfirmRemoveUserComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        that.auth.removeUser(row.user.email)
            .subscribe((response) => {
              that.snackBar.open(`Usuário removido com sucesso [${row.user.email}]`, 'Ok', {duration: 4000});
              that.getData();
            }, (err) => {
              that.snackBar.open(`Erro ao remover usuário [${row.user.email}]`, 'Ok', {duration: 4000});
            });
      }
    });

  }

}

@Component({
  selector: 'app-dialog-confirm-remove-user-dialog',
  templateUrl: 'dialog-confirm-remove-user-dialog.html',
  styleUrls: ['./user-activity.component.scss']
})
export class ConfirmRemoveUserComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRemoveUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onClickOk(): void {
    this.dialogRef.close(true);
  }

  onClickBack(): void {
    this.dialogRef.close(false);
  }

}
