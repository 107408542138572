import { OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DrService } from '../../providers/dr.service';
import { DR } from '../../models/dr';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
var MdrOptimizerComponent = /** @class */ (function () {
    function MdrOptimizerComponent(dialog, mapsAPILoader, drService, mdrService, router, snackBar, dpipe) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.selection = new SelectionModel(true, []);
        this.data = [];
        this.showLightBox = false;
        this.filter = '';
        this.displayedColumns = ['select', 'pickupId', 'dateScheduling'];
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.mdrService.getAllWithHub().subscribe(function (data) {
            // console.log(data);
            for (var i = 0; i < data.length; i++) {
                if (data[i].drList[0].pickupDifAddress === true && data[i].SAD === '' &&
                    (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação')) {
                    _this.data.push(data[i]);
                }
            }
            _this.dataSource = new MatTableDataSource(_this.data);
        });
    }
    MdrOptimizerComponent.prototype.ngOnInit = function () {
    };
    MdrOptimizerComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    };
    /** Whether the number of selected elements matches the total number of rows. */
    MdrOptimizerComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    MdrOptimizerComponent.prototype.masterToggle = function () {
        var _this = this;
        // console.log(this.dataSource.filteredData);
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) {
                if (_this.dataSource.filteredData.indexOf(row) > -1) {
                    _this.selection.select(row);
                }
            });
    };
    MdrOptimizerComponent.prototype.createNewMDRFather = function () {
        var _this = this;
        var mdr = new MDR();
        mdr.status = 'MDR em validação';
        mdr.pickupID = 'EDB-CNS-00';
        mdr.firstHubLeg = true;
        mdr.drList = [];
        var hubId = null;
        var drFake = new DR();
        drFake.idDRidentifier = 'EDB-CNS-0001';
        var listMdrs = this.selection.selected;
        for (var i = 0; i < listMdrs.length; i++) {
            if (i === 0) {
                hubId = listMdrs[i].drList[0].pickupAddress;
            }
            else {
                // console.log(hubId);
                // console.log(listMdrs[i].drList[0].pickupAddress);
                if (hubId !== listMdrs[i].drList[0].pickupAddress) {
                    this.snackBar.open('Não é possível faz consolidação de DRs de Hubs distintos', 'Fechar', {
                        duration: 2000,
                    });
                    return;
                }
            }
        }
        drFake.deliveryAddress = hubId;
        drFake.pickupDifAddress = true;
        drFake.status = 'READY_TO_PICKUP';
        var value = 0;
        var weight = 0;
        var volume = 0;
        var maxWidth = 0;
        var maxLength = 0;
        var maxHeight = 0;
        for (var i = 0; i < listMdrs.length; i++) {
            for (var j = 0; j < listMdrs[i].drList.length; j++) {
                value += parseFloat(listMdrs[i].drList[j].value + '');
                volume += parseFloat(listMdrs[i].drList[j].volume + '');
                weight += parseFloat(listMdrs[i].drList[j].weight + '');
                if (listMdrs[i].drList[j].maxWidth > maxWidth) {
                    maxWidth = listMdrs[i].drList[j].maxWidth;
                }
                if (listMdrs[i].drList[j].maxLength > maxLength) {
                    maxLength = listMdrs[i].drList[j].maxLength;
                }
                if (listMdrs[i].drList[j].maxHeight > maxHeight) {
                    maxHeight = listMdrs[i].drList[j].maxHeight;
                }
            }
        }
        drFake.maxWidth = maxWidth;
        drFake.maxLength = maxLength;
        drFake.maxHeight = maxHeight;
        drFake.volume = volume;
        drFake.weight = weight;
        drFake.value = value;
        this.drService.insertFake(drFake).subscribe(function (el) {
            // console.log(el);
            mdr.drList.push(el);
            _this.mdrService.insert(mdr, false).subscribe(function (data) {
                // console.log('MDR new Service');
                // console.log(data);
                _this.selection.selected.forEach(function (mdrAux) {
                    mdrAux.SAD = data.id + '';
                    _this.mdrService.update(mdrAux, '1').subscribe(function (response) {
                    });
                });
            });
        });
    };
    return MdrOptimizerComponent;
}());
export { MdrOptimizerComponent };
