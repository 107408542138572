import { OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(auth, router, snackBar) {
        var _this = this;
        this.auth = auth;
        this.router = router;
        this.snackBar = snackBar;
        this.auth.logout().subscribe(function (result) {
            localStorage.clear();
            _this.router.navigate(['login']);
        }, function (error) {
            _this.snackBar.open('Erro ao realizar logout', 'Ok', { duration: 2000 });
            window.history.back();
        });
    }
    LogoutComponent.prototype.ngOnInit = function () {
    };
    return LogoutComponent;
}());
export { LogoutComponent };
