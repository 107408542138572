import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DashboardService } from '../../providers/dashboard.service';
import { GraphCardComponent } from '../../components/graph-card/graph-card.component';
import { BarObject } from '../../models/bar-object';
import { TranslateService } from '@ngx-translate/core';
import { ClientUserService } from '../../providers/client-user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  costWeightData: any;
  dateReference = [];
  selectedDate = '';
  clientList = [];
  lspList = [];
  lspSelected = [];
  typeSelected = [];
  placeReference = [
    {name: 'Norte', ufs: ['RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO']},
    {name: 'Nordeste', ufs: ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA']},
    {name: 'Sul', ufs: ['PR', 'SC', 'RS']},
    {name: 'Sudeste', ufs: ['MG', 'ES', 'RJ']},
    {name: 'Centro-oeste', ufs: ['MS', 'MT', 'GO', 'DF']},
    {name: 'São Paulo', ufs: ['SP']}
  ];
  clientSelected = [];
  ufSelected = [];
  ufList = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  typeList = ['EDB', 'ED', 'CMS', 'DTR', 'Cross Doc', 'Cross Docking', 'Cross Docking'];
  colors = ['#8ed4c6', '#b4bae8', '#ebe095', '#9bdeb2', '#addded', '#eedab8'];
  @ViewChild('pieComponentChart') pieComponentChart: GraphCardComponent;
  @ViewChild('mdrByLspChart') mdrByLspChart: GraphCardComponent;
  @ViewChild('drsStatusChart') drsStatusChart: GraphCardComponent;
  @ViewChild('compositeBarsChart') mdrsChart: GraphCardComponent;
  @ViewChild('deliveredOvsChart') deliveredOvsChart: GraphCardComponent;
  @ViewChild('costPerKiloChart') costPerKiloChart: GraphCardComponent;
  @ViewChild('firstTimeRightChart') firstTimeRightChart: GraphCardComponent;
  @ViewChild('wordcloudChart') wordcloudChart: GraphCardComponent;
  @ViewChild('c02Chart') c02Chart: GraphCardComponent;
  @ViewChild('c02CAChart') c02CAChart: GraphCardComponent;
  @ViewChild('searchType') searchType;
  // relativos a busca de data
  @Input() dateSearch: string[] = [];
  dates: any[] = [ null, null ];
  constructor(private dashboardService: DashboardService, private translateService: TranslateService, public clientService: ClientUserService) {
    this.clientService.getClients().subscribe((data) => {
      this.clientList = data;
    });
    this.clientService.getLspList().subscribe( (lsps) => {
      this.lspList = lsps;
      // console.log(this.lspList);
    });
    this.dateReference = [
      'Norte',
      'Sul',
      'Sudeste S/ SP',
      'Nordeste',
      'Centro-oeste',
      'São Paulo'
    ];
  }

  applyFilter() {
    this.loadCharts();
  }

  loadCharts() {
    this.createMdrChart();
    this.createOvsChart();
    this.createDrsByClient();
    this.createCostKgChart();
    this.createDrByStatusChart();
    this.createEmptyBarChartData();
    this.createMdrByShippingChart();

  }

  sortByDate(data) {
    return data.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt <= b.createdAt) {
        return -1;
      }
    });
  }

  setCostPerKiloData(data) {
    return data.map(day => {
      const currentDay = new Date(day.startDate);
      return { 'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(), 'cost': day.cost, 'weight': day.weight };
    });
  }

  changeSavedFilter(name) {
    switch (name) {
      case 'Norte':
        this.ufSelected = ['RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO'];
        break;
      case 'Nordeste':
        this.ufSelected = ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'];
        break;
      case 'Sul':
        this.ufSelected = ['PR', 'SC', 'RS'];
        break;
      case 'Sudeste S/ SP':
        this.ufSelected = ['MG', 'ES', 'RJ'];
        break;
      case 'Centro-oeste':
        this.ufSelected = ['MS', 'MT', 'GO', 'DF'];
        break;
      case 'São Paulo':
        this.ufSelected = ['SP'];
        break;
    }
    this.applyFilter();
  }

  sortDate(data) {
    return data.sort((b, a) => {
      if (a.year > b.year) {
        return 1;
      } else if (a.year < b.year) {
        return -1;
      } else {
        if (a.month > b.month) {
          return 1;
        } else if (a.month < b.month) {
          return -1;
        } else {
          if (a.day > b.day) {
            return 1;
          } else if (a.day < b.day) {
            return -1;
          }
          return 0;
        }
      }
    });
  }

  joinDates(dates: any, numberDays: number) {
    const data = [];
    this.sortDate(dates);
    for (let i = 1; (numberDays > 0) && (i < dates.length); i++) {
      if (dates[i - 1].date === dates[i].date) {
        dates[i - 1].countCreated += dates[i].countCreated;
        dates[i - 1].countFinished += dates[i].countFinished;
        data.push(dates[i - 1]);
        i++;
      } else {
        data.push(dates[i - 1]);
      }
      numberDays--;
    }
    return data;
  }

  formate(data: any) {
    let sum: number;
    let finished;
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < data[i].length; j++) {
        data[i][j].startDate = new Date(data[i][j].startDate);
        data[i][j] = {
          'date': data[i][j].startDate.getDate() + '/' + (data[i][j].startDate.getMonth() + 1) + '/' + data[i][j].startDate.getFullYear(),
          'day': data[i][j].startDate.getDate(),
          'month': data[i][j].startDate.getMonth() + 1,
          'year': data[i][j].startDate.getFullYear(),
          'count': Object.values(data[i][j].shippingCompanies)
        };
        sum = 0;
        data[i][j].count.forEach(elem => sum += elem);
        if (i === 0) {
          data[i][j].countCreated = sum;
          data[i][j].countFinished = 0;
        } else {
          data[i][j].countCreated = 0;
          data[i][j].countFinished = sum;
        }
      }
    }

      finished = this.joinDates(data[0].concat(data[1]), 30);

    data = {
      dates: [],
      valuesCreated: [],
      valuesFinished: [],
      sumCreated: 0,
      sumFinished: 0
    };

    for (let i = 0; i < finished.length; i++) {
      data.dates.push(finished[i].date);
      data.valuesCreated.push(finished[i].countCreated);
      data.valuesFinished.push(finished[i].countFinished);
      data.sumCreated += finished[i].countCreated;
      data.sumFinished += finished[i].countFinished;
    }
    data.dates.reverse();
    data.valuesCreated.reverse();
    data.valuesFinished.reverse();
    return data;
  }

  setOVData(data) {
    return data.map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countRightPathDRs + day.countWrongPathDRs
      };
    });
  }

  setFirstTimeRightData(data) {
    return data.map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countRightPathDRs
      };
    });
  }

  setCostPerKiloPercentage(data) {
    let percentage = 0;
    let numberBefore = data.map((arr) => (arr.cost / arr.weight));
    if (numberBefore.length >= 2) {
      numberBefore = numberBefore[numberBefore.length - 2];
      let number = data.map((arr) => (arr.cost / arr.weight));
      number = number[number.length - 1];
      percentage = (number - numberBefore) / numberBefore * 100;
      percentage = Math.round(percentage * 100) / 100;
    }
    return percentage;
  }

  setDRsPercentage(data) {
    let percentage = 0;
    let numberBefore = data.map((arr) => (arr.total));
    if (numberBefore.length >= 2) {
      numberBefore = numberBefore[numberBefore.length - 2];
      let number = data.map((arr) => (arr.total));
      number = number[number.length - 1];
      percentage = (number - numberBefore) / numberBefore * 100;
      percentage = Math.round(percentage * 100) / 100;
    }
    return percentage;
  }

  createEmptyBarChartData() {
    const chartData: BarObject = {
      number: 0,
      percentage: 0,
      categories: [],
      series: [],
      subtitle: '',
    };
    return chartData;
  }

  createMdrChart() {
    this.dashboardService.getMdrByCreated(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      this.dashboardService.getMdrByDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data2: any) => {
        const finished = {
          aggregate: [],
          // Initial object in 'separate' is used to define column's order
          separate: [{
            id: '',
            shippingCompany: '',
            pickupID: '',
            createdAt: '',
            dr: '',
            macroregion: '',
            quando: '',
            dr_client: '',
            dr_uf: '',
            mdr_type: ''
          } ],
        };
        // console.log(data);
        // console.log(data2);
          finished.aggregate.push(data.aggregate);
          finished.aggregate.push(data2.aggregate);
          finished.separate = finished.separate.concat(data.separate);
          finished.separate = finished.separate.concat(data2.separate);
          const finalData = this.formate(finished.aggregate);
          finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
          finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
          finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
          finalData['finishedTooltip'] = 'Delivered';
          this.setMacroregion(finished.separate, 'dr_uf');
          this.mdrsChart.createChart(finalData, 'composite-bars', finished.separate);
      });
    });
  }

  createMdrByShippingChart() {
    this.dashboardService.getMdrByShippingCompany(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      data['aggregate'] = data['aggregate'].sort((a, b) => a.count > b.count ? -1 : a.count < b.count ? 1 : 0).slice(0, 6);
      const chartData = [];
      data['aggregate'].forEach(shippingCompany => {
        chartData.push({ name: shippingCompany.shippingCompany, y: shippingCompany.count });
      });

      this.setMacroregion(data['separate'], 'uf');
      this.mdrByLspChart.createChart(chartData, 'mdr-by-lsp', data['separate']);
    });
  }

  setMacroregion(data, ufKey) {
    data.forEach(element => {
      const possibleRegions = this.placeReference.filter( (place) => {
        // check if one or more of the uf's passed is include in the reference uf macroregion
        return element[ufKey] ? (element[ufKey].split(',')).some( uf => place.ufs.includes(uf)) : false;
      });

      if (possibleRegions && possibleRegions.length) {
        const macroregion = new Set();

        // Get unique region names
        possibleRegions.forEach( (region) => {
          macroregion.add(region.name);
        });

        element.macroregion = Array.from(macroregion).join();
      } else {
        element.macroregion = '';
      }
    });
  }

  createDrsByClient() {
    this.dashboardService.getDrByClient(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      data['aggregate'] = data['aggregate'].sort((a, b) => a.count > b.count ? -1 : a.count < b.count ? 1 : 0).slice(0, 6);
      const chartData = [];
      data['aggregate'].forEach(client => {
        if (client.client === null) {
          client.client = 'CLIENTE';
        }
        chartData.push({ name: client.client, y: client.count });
      });

      this.setMacroregion(data['separate'], 'uf');
      this.pieComponentChart.createChart(chartData, 'dr-by-client', data['separate']);
    });

  }

  ngOnInit() {
    this.loadCharts();
  }

  // ngOnInit() {
  //   this.dashboardService.getMdrByCreated(this.dates).subscribe((data: any) => {
  //     this.dashboardService.getMdrByDelivered(this.dates).subscribe((data2: any) => {
  //        const finished = [];
  //        console.log(data);
  //        console.log(data2);
  //        finished.push(data);
  //        finished.push(data2);
  //       //  console.log({"final":this.formate(finished)} );
  //        let finalData = this.formate(finished);
  //        finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
  //        finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
  //        finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
  //        finalData['finishedTooltip'] = 'Delivered';

  //        this.mdrsChart.createChart(finalData, 'composite-bars');
  //         console.log({"final":finalData} );
  //     });;
  //   });
  //   this.createDrByStatusChart();
  //   this.createOvsChart();
  //   this.createCostKgChart();
  // }

  createOvsChart() {
    // related to first time right and delivered ovs charts
    this.dashboardService.getOVsDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data) => {
      // delivered ovs
      const ovData = this.setOVData(data['aggregate']);
      const firstTimeData = this.setFirstTimeRightData(data['aggregate']);
      const percentage = [];
      for (let i = 0; i < ovData.length; i++) {
        percentage.push({'day': ovData[i].day, 'total': firstTimeData[i].total * 100 / ovData[i].total});
      }
      if (ovData.length !== 0) {
        const chartData = {
          number: ovData[ovData.length - 1].total,
          percentage: Math.round(firstTimeData[firstTimeData.length - 1 ].total * 100 / ovData[ovData.length - 1].total),
          categories: ovData.map(arr => arr.day),
          series: [{
            name: this.translateService.instant('TOTAL_DRS'),
            type: undefined,
            visible: false,
            data: ovData.map((arr) => (arr.total))
          }, {
            name: this.translateService.instant('FIRST_TIME_RIGHT'),
            type: undefined,
            visible: false,
            data: firstTimeData.map((arr) => (arr.total))
          }, {
            name: 'FTR Percentage',
            type: undefined,
            data: percentage.map((arr) => (arr.total))
          }],
          subtitle: this.translateService.instant('TOTAL_DRS'),
        };
        this.setMacroregion(data['separate'], 'uf');
        this.deliveredOvsChart.createChart(chartData, 'delivered-ovs', data['separate']);
      } else {
        const emptyData = this.createEmptyBarChartData();
        this.deliveredOvsChart.createChart(emptyData, 'delivered-ovs', data['separate']);
      }
      // first time right
      // if (firstTimeData.length !== 0) {
      //   const chartData = {
      //     number: firstTimeData[firstTimeData.length - 1].total,
      //     percentage: this.setDRsPercentage(firstTimeData),
      //     categories: firstTimeData.map(arr => arr.day),
      //     series: [{
      //       name: this.translateService.instant('FIRST_TIME_RIGHT'),
      //       type: undefined,
      //       data: firstTimeData.map((arr) => (arr.total))
      //     },
      //   ],
      //     subtitle: this.translateService.instant('FIRST_TIME_RIGHT'),
      //   };
      //   this.firstTimeRightChart.createChart(chartData, 'first-time-right', data['separate']);
      // } else {
      //   const emptyData = this.createEmptyBarChartData();
      //   this.firstTimeRightChart.createChart(emptyData, 'first-time-right', data['separate']);
      // }
    });
  }
  createDrByStatusChart() {
    this.dashboardService.getC02ByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      // console.log('co2', data);
      const resultObj = {};
      resultObj['dates'] = [];
      resultObj['valuesCreated'] = [];
      resultObj['valuesFinished'] = [];
      resultObj['sumCreated'] = 0;
      resultObj['sumFinished'] = 0;
      for (const [key, value] of Object.entries(data['resultPerVehicle'])) {
        resultObj['dates'].push(key);
        resultObj['valuesCreated'].push(value['kgco2'] / 1000);
        resultObj['valuesFinished'].push(value['kg'] / 1000);
        resultObj['sumCreated'] += value['kgco2'] / 1000;
        resultObj['sumFinished'] += value['kg'] / 1000;
        resultObj['createdTitle'] = 'CO2/created/title';
        resultObj['finishedTitle'] = 'CO2/finished/title';
        resultObj['createdTooltip'] = 'CO2/created/tooltip';
        resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
        resultObj['eco'] = 'eco';
      }
      this.setMacroregion(data.result, 'UF de Destino');
      this.c02Chart.createChart(resultObj, 'c02Chart', data['result']);
    });

    this.dashboardService.getC02CAByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      const resultObj = {};
      resultObj['dates'] = [];
      resultObj['valuesCreated'] = [];
      resultObj['valuesFinished'] = [];
      resultObj['sumCreated'] = 0;
      resultObj['sumFinished'] = 0;
      for (const [key, value] of Object.entries(data['resultPerVehicle'])) {
        resultObj['dates'].push(key);
        resultObj['valuesCreated'].push(value['kgco2Total'] / 1000);
        resultObj['valuesFinished'].push(value['kgco2ShouldBe'] / 1000);
        resultObj['sumCreated'] += value['kgco2Total'] / 1000;
        resultObj['sumFinished'] += value['kgco2Avoidance'] / 1000;
        resultObj['createdTitle'] = 'CO2/created/title';
        resultObj['finishedTitle'] = 'CO2/finished/title';
        resultObj['createdTooltip'] = 'CO2/created/tooltip';
        resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
        resultObj['eco'] = 'eco';
      }
      // console.log({'OBJETO': resultObj});
      this.setMacroregion(data.result, 'UF de Destino');
      this.c02CAChart.createChart(resultObj, 'c02CAChart', data['result']);
    });

    this.dashboardService.getDrByStatus(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      // console.log(data);
      const chartData = [];
      const options = [
        { radius: '112%', innerRadius: '100%', color: '#B6BCE9' },
        { radius: '92%', innerRadius: '80%', color: '#AFDEEE' },
        { radius: '72%', innerRadius: '60%', color: '#ECE196' }
      ];
      const validStatus = ['Delivered', 'InTransit', 'READY_TO_PICKUP'];
      const statusValues = data['aggregate'][0].status;

      // create status object
      const status = {};
      validStatus.forEach((item) => {
        status[item] = statusValues[item] || 0;
      });

      const total = Object.keys(status).reduce((sum, key) => sum + status[key], 0);
      Object.keys(status).forEach((item, index) => {
        chartData.push({
          name: '<span style="font-family: Roboto; font-weight: 100">' + this.translateService.instant(item) +
            '</span>' +
            '<br><span style=\'font-size:20px; font-weight: 900; font-family:Roboto;\'>' + status[item] + '</span>',
          type: 'solidgauge',
          colorByPoint: false,
          color: options[index].color,
          borderColor: options[index].color,
          showInLegend: true,
          data: [{
            y: total > 0 ? Math.ceil((status[item] * 100) / total) : 0,
            color: options[index].color,
            radius: options[index].radius,
            total: total,
            innerRadius: options[index].innerRadius,
          }]
        });
      });
      this.setMacroregion(data['separate'], 'uf');
      this.drsStatusChart.createChart(chartData, 'drs-status', data['separate']);
    });
  }
  createCostKgChart() {

    // related to cost per kilo chart
    this.dashboardService.getCostKg('day', this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data) => {
      const dataDay = this.setCostPerKiloData(data['aggregate']);
      if (dataDay.length !== 0) {
        const dataNumber = dataDay.map((arr) => (arr.cost / arr.weight));
        const chartData: BarObject = {
          number: `R$ ${Math.round(dataNumber[dataNumber.length - 1] * 100) / 100}`,
          percentage: this.setCostPerKiloPercentage(dataDay),
          categories: dataDay.map((arr) => arr.day),
          series: [{
            name: this.translateService.instant('COST_KG'),
            type: undefined,
            data: dataDay.map((costPerKilo) => (costPerKilo.cost / costPerKilo.weight))
          }, {
            name: this.translateService.instant('COST'),
            visible: false,
            type: 'line',
            data: dataDay.map((cost) => cost.cost)
          }, {
            name: this.translateService.instant('WEIGHT'),
            visible: false,
            type: 'line',
            data: dataDay.map((weight) => weight.weight)
          }],
          subtitle: this.translateService.instant('COST_PER')
        };
        this.setMacroregion(data['separate'], 'uf');
        this.costPerKiloChart.createChart(chartData, 'cost-per-kilo', data['separate']);
      } else {
        const emptyData = this.createEmptyBarChartData();
        this.costPerKiloChart.createChart(emptyData, 'cost-per-kilo', data['separate']);
      }
    });

    // Occurrence Word Map
    this.dashboardService.getOccurrenceWords(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe( (elem ) => {
      this.wordcloudChart.createChart(elem, 'wordcloud');
      // console.log({word:elem});
    });
  }
}
