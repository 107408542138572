<div class="mx-5 my-4 row">
    <p class="mat-h1 col-10">Dashboard</p>
     <div class="col-2">
    </div>
    <div class="col-2">
        <div class="row justify-content-md-center">
            <button mat-button color="primary" [matMenuTriggerFor]="menu">
                <mat-icon>search</mat-icon>Buscas Favoritas
            </button>

        </div>
        <div class="row  justify-content-md-center">
            <div>{{selectedDate}}</div>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let date of dateReference" (click)="changeSavedFilter(date)">
                {{date}}</button>
        </mat-menu>
    </div>
</div>
<div class="colored-background table-background px-5 py-2">
    <div class="search-field" fxLayout="column">
        <div class="simple" fxLayout="row" fxLayoutGap="10px" >
          <mat-form-field>
            <mat-label>UF</mat-label>
            <mat-select (selectionChange)="applyFilter()" [(value)]="ufSelected" multiple>
              <mat-option *ngFor="let uf of ufList"  [value]="uf">{{uf}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field >
            <mat-label>Client</mat-label>
            <mat-select (selectionChange)="applyFilter()" [(value)]="clientSelected" multiple>
              <mat-option *ngFor="let client of clientList" [value]="client.clientGroup">{{client.clientGroup}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field >
            <mat-label>Type</mat-label>
            <mat-select (selectionChange)="applyFilter()" [(value)]="typeSelected" multiple>
              <mat-option *ngFor="let type of typeList" [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field >
            <mat-label>LSP</mat-label>
            <mat-select  (selectionChange)="applyFilter()" [(value)]="lspSelected" multiple>
              <mat-option *ngFor="let lsp of lspList" [value]="lsp.name">{{lsp.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <button mat-icon-button
            (click)="toggleColumns()"
            matTooltip="{{ 'TABLE/BUTTON/TOGGLE_COLUMNS' | translate }}"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>

          <button mat-icon-button
            (click)="toggleAdvancedSearch()"
            matTooltip="{{ 'TABLE/BUTTON/ADVANCED_SEARCH' | translate }}"
          >
            <mat-icon>tune</mat-icon>
          </button> -->

          <!-- <mat-form-field floatLabel="never">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let chip of chips"
                [disabled]="showAdvancedSearch"
                [selectable]="selectable"
                [removable]="removable && !showAdvancedSearch"
                (removed)="removeChip(chip)">
                {{chip.name}}
                <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
              </mat-chip>
              <input placeholder="Digite para começar a filtrar os resultados por palavra chave..."
                [disabled]="showAdvancedSearch"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addChip($event)">
            </mat-chip-list>
          </mat-form-field>

          <mat-button-toggle-group
            #searchType="matButtonToggleGroup"
            (change)="applyFilter()"
          >
            <mat-button-toggle value="and">Todos</mat-button-toggle>
            <mat-button-toggle value="or">Alguns</mat-button-toggle>
          </mat-button-toggle-group> -->

          <mat-form-field floatLabel="never" fxFlex="25%">
            <input matInput
              placeholder="Limitar busca por data"
              [(ngModel)]="dates"
              (dateTimeChange)="applyFilter()"
              [selectMode]="'range'"
              [owlDateTimeTrigger]="rangeDatepicker"
              [owlDateTime]="rangeDatepicker">
            <owl-date-time #rangeDatepicker></owl-date-time>
          </mat-form-field>

          <!-- <button mat-icon-button
            *ngIf="downloadAs"
            (click)="download()"
            matTooltip="{{ 'TABLE/BUTTON/DOWNLOAD_XLSX' | translate }}"
          >
            <mat-icon>save_alt</mat-icon>
          </button> -->

        </div>
<!--
        <div class="advanced" *ngIf="showAdvancedSearch" fxLayout="column" fxLayoutGap="10px">

          <form #form="ngForm">
            <div *ngFor="let search of advancedSearchValues; index as i;"
              fxLayout="row"
              fxLayoutAlign="space-around center"
              fxLayoutGap="5px">
              <mat-form-field floatLabel="never">
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let chip of search.chips"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeChipFromAdvanced(search.chips, chip)">
                    {{chip.name}}
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                  </mat-chip>
                  <input placeholder="Busca"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addChipFromAdvanced(search.chips, $event)">
                </mat-chip-list>
              </mat-form-field>
              <mat-form-field fxFlex="35">
                <mat-label>Campo</mat-label>
                <mat-select [(ngModel)]="search.field" [name]="'field-'+i" required>
                  <mat-option *ngFor="let column of columns" [value]="column.value">
                    {{ column.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button (click)="removeSearchField(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </form>

          <button mat-button color="primary" (click)="addSearchField()">Adicionar campo</button>
          <button mat-flat-button color="primary" [disabled]="advancedSearchValues.length && !form.valid" (click)="applyFilter()">Realizar busca</button>

        </div> -->

      </div>

    <div fxLayout="column" fxLayoutAlign="space-between"  fxLayoutGap="20px">
        <div fxFlex="48%" fxLayout.lt-md="column"  fxLayoutGap.lt-md="20px" fxLayoutAlign="space-between space-between">
            <div fxFlex="24%">
                <app-graph-card #drsStatusChart container="drs-status" dates="dates" layout="column" title="{{'DASHBOARD/drsStatus' | translate}}" selector="speedometer"></app-graph-card>
            </div>
            <div fxFlex="49%">
                <app-graph-card #compositeBarsChart container="composite-bars" layout="column" layoutAlign="center"
                    title="{{'DASHBOARD/mdrs' | translate}}" selector="composite-bars"></app-graph-card>
            </div>
            <div fxFlex="24%">
                <app-graph-card #pieComponentChart container="dr-by-client" layout="column"
                    seriesType="{{'DASHBOARD/drs' | translate}}" title="{{'DASHBOARD/drByClient' | translate}}"
                    selector="pie"></app-graph-card>
            </div>
        </div>
        <div fxFlex="48%" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px"
            fxLayoutAlign="space-between space-between">
            <div fxFlex="48%">
              <app-graph-card #deliveredOvsChart container="delivered-ovs" layout="column"
                    title="{{'DASHBOARD/deliveredOvs' | translate}}" selector="bar"></app-graph-card>
            </div>
            <div fxFlex="24%">
                <app-graph-card #costPerKiloChart container="cost-per-kilo" layout="column"
                    title="{{'DASHBOARD/costPerKilo' | translate}}" selector="bar"></app-graph-card>
            </div>
            <!-- <div fxFlex="24%">
                <app-graph-card #firstTimeRightChart container="first-time-right" layout="column"
                    title="{{'FIRST_TIME_RIGHT' | translate}}" selector="bar"></app-graph-card>
            </div> -->
            <div fxFlex="24%">
                <app-graph-card #mdrByLspChart container="mdr-by-lsp" layout="column"
                    seriesType="{{'DASHBOARD/mdrs' | translate}}" title="{{'DASHBOARD/mdrByLsp' | translate}}"
                    selector="pie"></app-graph-card>
            </div>
        </div>
        <div fxFlex="48%" fxLayout="row"  fxLayout.lt-md="column"  fxLayoutGap.lt-md="20px"  fxLayoutAlign="space-between space-between">
            <div fxFlex="49%">
                <app-graph-card #wordcloudChart container="wordcloud" layout="column" title="{{'DASHBOARD/WordOccurrence' | translate}}" selector="wordcloud"></app-graph-card>
            </div>
            <div fxFlex="49%">
                <app-graph-card #c02Chart container="c02Chart" layout="column" title="{{'DASHBOARD/co2' | translate}}" selector="composite-bars"></app-graph-card>
                <!-- <app-graph-card #compositeBarsChart container="composite-bars" layout="column" layoutAlign="center" -->
                    <!-- title="{{'DASHBOARD/mdrs' | translate}}" selector="composite-bars"></app-graph-card> -->
            </div>
        </div>
        <div fxFlex="48%" fxLayout="row"  fxLayout.lt-md="column"  fxLayoutGap.lt-md="20px"  fxLayoutAlign="space-between space-between">
            <div fxFlex="49%">
                <app-graph-card #c02CAChart container="c02CAChart" layout="column" title="CO2 Avoidance" selector="composite-bars"></app-graph-card>
                <!-- <app-graph-card #compositeBarsChart container="composite-bars" layout="column" layoutAlign="center" -->
                    <!-- title="{{'DASHBOARD/mdrs' | translate}}" selector="composite-bars"></app-graph-card> -->
            </div>
        </div>
    </div>
</div>
