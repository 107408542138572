<div class="mx-5 my-4">
   <p class="mat-h1">{{ 'OCCURRENCE-LIST' | translate }}</p>
</div>
<div *ngIf="data.length > 0" class="colored-background table-background">
   <div class="mx-5">

      <app-table
         [columns]="displayedColumns"
         [data]="data"
         [this]="this"
         [clickCallback]="navigateToMDR"
        downloadAs="occurrence-list_%.xlsx"
      ></app-table>
      <!-- <table mat-table class="rounded-corners" #table [dataSource]="dataSource" matSort>
         <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
               <mat-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="selection.hasValue() && isAllSelected()"
                             [indeterminate]="selection.hasValue() && !isAllSelected()">
               </mat-checkbox>
            <th>
            <td mat-cell *matCellDef="let row">
               <mat-checkbox (click)="$event.stopPropagation()"
                             (change)="$event ? selection.toggle(row) : null"
                             [checked]="selection.isSelected(row)">
               </mat-checkbox>
            </td>
         </ng-container>
         <ng-container  matColumnDef="idMDR">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'OCCURRENCE-LIST/table/id' | translate }} <th>
            <td mat-cell (click)="navigateToMDR(element.idMDR)" *matCellDef="let element"> {{element.idMDR}} </td>
         </ng-container>

         <ng-container matColumnDef="uoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'OCCURRENCE-LIST/table/uoc' | translate }} <th>
            <td mat-cell (click)="navigateToMDR(element.idMDR)" *matCellDef="let element"> {{element.uoc}} </td>
         </ng-container>

         <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'OCCURRENCE-LIST/table/description' | translate }}  <th>
            <td mat-cell  (click)="navigateToMDR(element.idMDR)" *matCellDef="let element"> {{ element.text }} </td>
         </ng-container>

        <ng-container matColumnDef="createdAt">
           <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'OCCURRENCE-LIST/table/created' | translate }}  <th>
           <td mat-cell (click)="navigateToMDR(element.idMDR)" *matCellDef="let element"> {{ element.createdAt | date: "dd/MM/yy, h:mm a"}} </td>
        </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
       </table> -->
   </div>
</div>

<div *ngIf="data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'OCCURRENCE-LIST/table/resolved' | translate }}</p>
</div>
