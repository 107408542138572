<div fxLayout="column" fxLayoutGap="7px" fxLayoutAlign="space-around">
  <h1 mat-dialog-title >{{ 'Write_Reprove' | translate }}</h1>
  <div mat-dialog-actions>
    <input matInput placeholder="{{ 'DR_BOOKING/text/reason' | translate }}" [(ngModel)]="reason">
  </div>
  <div *ngIf="data.action === 'pending'">
    <mat-checkbox
      [checked]="notify"
      (change)="notify = !notify">
      Notificar criador
    </mat-checkbox>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onClickBack()">{{ 'Cancel' | translate }}</button>
    <span></span>
    <button mat-button class="primary-button" [disabled]="!reason" (click)="onClickConfirm()">{{ 'Confirm' | translate }}</button>
  </div>
</div>
