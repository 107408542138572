import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {DR} from '../../models/dr';
import {MatSnackBar, MatTableDataSource} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { ERBService } from '../../providers/erb.service';
import {SiteService,
        ESTRUTURA,
        ACESSO} from '../../providers/site.service';
import { ClientUserService } from '../../providers/client-user.service';
import { OVService } from '../../providers/ov.service';
import { UploadFileService } from '../../providers/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpEventType, HttpResponse} from '@angular/common/http';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var google: any;

@Component({
  selector: 'app-dr-booking',
  templateUrl: './dr-booking.component.html',
  styleUrls: ['./dr-booking.component.scss']
})
export class DrBookingComponent implements OnInit {
  public dr: DR = new DR();
  salesOrders;
  ovValidated = null;
  showtable = false;
  public listOV = [];
  drLengthUploaded = 0;
  uploaded = false;
  @ViewChild('addrSearch')
  public searchElementRef: ElementRef;
  public paste_input = '';
  showSiteInfo = false;
  displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'site'];
  newDrList = [];
  minDate = new Date();
  distance = null;
  siteSelected: any;
  uploadedFiles: any[] = [];
  columns = [
    {value: 'ov', name: 'OV'},
    {value: 'solut', name: 'status'},
  ];
  site = {
    'color' : '',
    'tooltips': {
      'grey': 'site não existe',
      'yellow-1': 'site não aprovado',
      'yellow-2': 'site não liberado',
      'yellow-3': 'site não aprovado e não liberado',
      'green': 'site aprovado',
      'undefined': ''
    },
    'icons' : {
      'grey': 'fa-question-circle',
      'yellow-1': 'fa-exclamation-triangle',
      'yellow-2': 'fa-exclamation-triangle',
      'yellow-3': 'fa-exclamation-triangle',
      'green': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'grey' : 'icon-grey',
      'yellow-1': 'icon-yellow',
      'yellow-2': 'icon-yellow',
      'yellow-3': 'icon-yellow',
      'green': 'icon-green',
      'undefined': ''
    }
  };
  clientList: string[] = [];

  public map_show = false;
  public map_data = null;
  public red_marker = {
    url: './assets/images/pin_red.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };
  public blue_marker = {
    url: './assets/images/pin.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };
  dataSourceDR: any;
  allValid: any;

  constructor(
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private clientUserService: ClientUserService,
    private drService: DrService,
    private erbService: ERBService,
    private ovService: OVService,
    private siteService: SiteService,
    private uploadService: UploadFileService,
    public router: Router,
    public excelService: ExcelService,
    private sanitizer: DomSanitizer,
  ) {
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = +lat;
        this.dr.lng = +lng;
        this.drService.getDistanceToLatLng(lat, lng).subscribe(elem => {
          if (elem.distance) {
            const k = elem.distance.value / 600000;
            const dNew = new Date();
            this.minDate = new Date(dNew.setDate(dNew.getDate() + k + 2));
            this.distance = elem.distance.value / 1000;
            this.dr.distance = this.distance;
          }
        });
        return true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        return false;
      }
    });
  }

  validate(): void {
    this.newDrList.forEach(async (elem) => {
      const ovs = String(elem.status).split(',');
      for (let i = 0; i < ovs.length; i++) {
        if (ovs[i] !== undefined) {
          ovs[i] = ovs[i].trim();
        }
      }
      elem.ovList = [];
      const j = [];
      for (const k of ovs) {
        const data = await this.ovService.getAll(parseInt(k, 10)).toPromise();
        elem.prolog = data[0]['assignmentInformation'];
        j.push(data);
      }
      // console.log(j);
      elem.ovList = j.reduce((acc, val) => acc.concat(val), []);
      // console.log(elem);
      this.ovService.validateOVs(ovs).subscribe( (isValid) => {
        this.allValid = isValid;
        // console.log(isValid);
        if ( isValid === false ) {
          // OVs invalidas segundo backend
          elem.idDRidentifier = 'red';
          this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
          this.dataSourceDR =  new MatTableDataSource<DR>(this.newDrList);
        } else if (elem.pickupDifAddress === true) {
          // valida HUB recebido do arquivo
          // console.log(isValid);
          this.ovService.validateHub(elem.pickupAddress).subscribe( (resp) => {
            if (resp !== undefined && resp.text !== 0) {
              // HUB é válido
              elem.pickupAddress = resp.text;
            } else {
              // HUB é inválido
              this.allValid = false;
              elem.idDRidentifier = 'red';
              this.snackBar.open('Erro ao criar requisições a partir de arquivo: HUB não valido', 'Ok', {duration: 4000});
              this.dataSourceDR =  new MatTableDataSource<DR>(this.newDrList);
            }
          });
        }
      });
    });
  }

  validateOV() {
    const ovAuxList = [];
    this.showtable = false;
    const newList = this.salesOrders.split(',');
    let cont = newList.length;
    this.ovValidated = true;
    newList.forEach(async el => {
      this.ovService.validateOVs([el]).subscribe(resp => {
        ovAuxList.push({ ov: el, solut: resp });
        if (!resp) {
          this.ovValidated = false;
        }
        if (cont--) {
          this.listOV = ovAuxList;
          this.showtable = true;
        }
      });
    });
  }

  convertDRAddressToLatLng(dr: DR): boolean {
    const error = false;
    const geocoder = new google.maps.Geocoder();
    const addr = dr.deliveryAddress;
   // console.log(addr);
    return geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        dr.deliveryAddress = results[0].formatted_address;
        dr.lat = results[0].geometry.location.lat();
        dr.lng = results[0].geometry.location.lng();
        return true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        return false;
      }
    });
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    this.uploaded = false;
    let error = false;
    // console.log(fileupload);
    this.newDrList = [];
    this.drLengthUploaded = 0;
    const ovList = [];
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(fileupload).subscribe( (data) => {
          const lengthDRs = data.length;
          data.forEach( (el, i, arr) => {
            const drAux = new DR();
            try {
              drAux.status = el.ov;
              const ovs = String(drAux.status).split(',');
              this.drService.getFromSydle(ovs[0]).subscribe( resp => {
                try {
                  const info = resp['obras'][0];
                  if (info['site']['coordenadaGeografica']['coordinates']) {
                    if ( info['site']['coordenadaGeografica']['coordinates'][0] !== null && info['site']['coordenadaGeografica']['coordinates'][1] !== null) {
                      drAux.lat = info['site']['coordenadaGeografica']['coordinates'][1];
                      drAux.lng = info['site']['coordenadaGeografica']['coordinates'][0];
                      // if (!this.convertLatLngToAddress()) {
                        drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                        // this.convertAddressToLatLng();
                      // }

                    } else {
                      drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                      // this.convertAddressToLatLng();
                    }
                  } else {
                    drAux.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
                      // this.convertAddressToLatLng();
                  }

                  drAux.site = info['site']['nome'];
                  drAux.client = info['cliente'];
                  drAux.prolog = info['prolog'];
                  drAux.supervisorName = info['supervisorEricsson']['nome'];
                  drAux.supervisorPhone = el.supervisor_phone;
                  drAux.receiverPhone = el.receiver_phone;
                  drAux.receiverName = el.receiver_name;
                  drAux.obs = el.obs;
                  drAux.infraType = el.infra;
                  drAux.dateScheduling = el.dateScheduling;
                  // drAux.salesOrders = el.ov;
                  this.newDrList.push(drAux);
                  String(drAux.status).split(',').forEach( part => {
                    ovList.push(+part.trim());
                  });
                  if (++this.drLengthUploaded === lengthDRs) {
                    this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
                    this.uploaded = true;
                  }

                  // this.dr.supervisorPhone = info['supervisorEricsson']['telefoneCelular'];

                } catch (er) {
                  // console.log(er);
                  this.snackBar.open('Não foi possivel validar um ou mais dados no Sydle', 'Ok',  {duration: 2000});
                  drAux.deliveryAddress = el.delivery_address;
                  drAux.site = el.site;
                  drAux.receiverName = el.receiver_name;
                  drAux.receiverPhone = el.receiver_phone;
                  drAux.supervisorName = el.supervisor_name;
                  drAux.supervisorPhone = el.supervisor_phone;
                  drAux.infraType = el.infra;
                  drAux.obs = el.obs;
                  drAux.prolog = el.prolog;
                  drAux.client = el.client;
                  drAux.dateScheduling = el.dateScheduling;
                  if ( this.convertDRAddressToLatLng(drAux) === false ) {
                    this.snackBar.open('Endereço inválido', 'Ok', {duration: 2000});
                    error = true;
                  }
                  drAux.pickupAddress = null;
                  // drAux.salesOrders = el.ov;
                  this.newDrList.push(drAux);
                  if (++this.drLengthUploaded === lengthDRs) {
                    this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
                    this.uploaded = true;
                  }
                  String(drAux.status).split(',').forEach( part => {
                    ovList.push(+part.trim());
                  });
                }
              });
            }  catch (ex) {
              // console.log('erro ao ler arquivo xlsx ',ex);
              this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
            }
            });
            // console.log(this.newDrList);
            // this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
            // console.log(this.dataSourceDR);
            // this.uploaded = true;
          });

        }
      }
  }

  searchSydleInformation() {
    this.drService.getFromSydle(this.salesOrders).subscribe( resp => {
      try {
        const info = resp['obras'][0];
        if (info['site']['coordenadaGeografica']['coordinates']) {
          if ( info['site']['coordenadaGeografica']['coordinates'][0] !== null && info['site']['coordenadaGeografica']['coordinates'][1] !== null) {
            this.dr.lat = info['site']['coordenadaGeografica']['coordinates'][1];
            this.dr.lng = info['site']['coordenadaGeografica']['coordinates'][0];
            // if (!this.convertLatLngToAddress()) {
              this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
              // this.convertAddressToLatLng();
            // }

          } else {
            this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
            // this.convertAddressToLatLng();
          }
        } else {
          this.dr.deliveryAddress = info['site']['endereco'] + ', ' + info['site']['localidade']['cidade'] + ' - ' + info['site']['localidade']['cidade'];
            // this.convertAddressToLatLng();
        }

        this.dr.site = info['site']['nome'];
        this.dr.client = info['cliente'];
        this.dr.prolog = info['prolog'];
        this.dr.supervisorName = info['supervisorEricsson']['nome'];
        // this.dr.supervisorPhone = info['supervisorEricsson']['telefoneCelular'];

      } catch (er) {
        // console.log(er);
        this.snackBar.open('Não foi possivel validar os dados Sydle', 'Ok',  {duration: 2000});
      }
    });
  }

  convertLatLngToAddress(): any {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
        this.drService.getDistanceToLatLng(lat, lng).subscribe(elem => {
          if (elem.distance) {
            const k = elem.distance.value / 600000;
            const dNew = new Date();
            this.minDate = new Date(dNew.setDate(dNew.getDate() + k + 1));
            this.distance = elem.distance.value / 1000;
            this.dr.distance = this.distance;
            // console.log(this.minDate);
          }
        });
        return true;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
        return false;
      }
    });
  }

  createRequisition() {
    const successfullyCreated = 0;
    // console.log(this.newDrList);
    for (const drNew of this.newDrList) {
      // console.log(elem);
      // drNew.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
      drNew.distance = 1;
      drNew.unificationId = null;
      // console.log(drNew);
      drNew.status = drNew.status + '';
      drNew.salesOrders = drNew.status
                                .replace(/ /g, ',')   // troca espaços por vírgulas
                                .split(',')          // quebra em array nas vírgulas
                                .filter(e => e.length); // remove strings vazias
      this.drService.createBooking(drNew).subscribe((response) => {
        // console.log(this.uploadedFiles, this.uploadedFiles.length );
        if (this.uploadedFiles && this.uploadedFiles.length) {
          this.uploadFiles(response.id);
        } else {
          this.snackBar.open('DR Booking criada com sucesso', 'Ok', {duration: 2000});
          // reseta campos
          this.dr = new DR();
          this.salesOrders = '';

          this.router.navigate(['/cockpit']);
        }
      }, (err) => {
        this.snackBar.open('Erro ao criar DR Booking', 'Ok', {duration: 2000});
      });
    }

  }

  createDRBooking() {
    if (this.dr.dateScheduling.getTime() - new Date().getTime() < 172800000) {
      this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora de agora.', 'Ok', {duration: 4000});
      return;
    }

    this.dr.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    this.dr.salesOrders = this.salesOrders
                              .replace(/ /g, ',')   // troca espaços por vírgulas
                              .split(',')          // quebra em array nas vírgulas
                              .filter(e => e.length); // remove strings vazias
    this.drService.createBooking(this.dr).subscribe((response) => {
      // console.log(this.uploadedFiles, this.uploadedFiles.length );
      if (this.uploadedFiles && this.uploadedFiles.length) {
        this.uploadFiles(response.id);
      } else {
        this.snackBar.open('DR Booking criada com sucesso', 'Ok', {duration: 2000});
        // reseta campos
        this.dr = new DR();
        this.salesOrders = '';

        this.router.navigate(['/cockpit']);
      }
    }, (err) => {
      this.snackBar.open('Erro ao criar DR Booking', 'Ok', {duration: 2000});
    });
  }

  uploadFiles(drId) {
    this.uploadService.pushFileToStorage(this.uploadedFiles, drId, 'dr').subscribe(event => {
      if (event.type === HttpEventType.Response) {
        // reseta campos
        this.dr = new DR();
        this.salesOrders = '';
        this.uploadedFiles = [];

        this.snackBar.open('DR Booking criada com sucesso', 'Ok', {duration: 2000});

        this.router.navigate(['/cockpit']);
      }
    }, (err) => {
      this.snackBar.open('Erro ao criar DR Booking', 'Ok', {duration: 2000});
    });
  }

  fillFromClipboard(e) {
    const DMStoDD = (str: string): number => {
      const parts = str.split(/[°'"]/).map((part) => {
        return +part.replace(',', '.');
      });

      const decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
      return parts[0] + decimal;
    };

    const input = e.clipboardData.getData('text');
    const splitted = input.split('\t');
    this.salesOrders = splitted[1];
    this.dr.deliveryAddress = splitted[6];
    this.dr.lat = +DMStoDD(splitted[25]);
    this.dr.lng = +DMStoDD(splitted[26]);
    this.dr.receiverName = splitted[9];
    this.dr.receiverPhone = parseInt(splitted[10], 10);
    this.dr.dateScheduling = new Date(splitted[3]);
    this.dr.site = splitted[4];
    this.dr.infraType = splitted[11];
    this.dr.unificationId = splitted[0];
    this.dr.obs = splitted[12];

    setTimeout(() => {
      this.paste_input = '';
    }, 200);
  }

  toggleMap() {
    if (this.map_show) {
      this.map_show = false;
    } else {
      if (this.dr.lat && this.dr.lng) {
        // this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe((data) => {
          this.dr.lat = +this.dr.lat;
          this.dr.lng = +this.dr.lng;

          // this.map_data = data;
          this.map_show = true;
        // });
      }
    }
  }

  checkSite(): void {
    this.showSiteInfo = false;
    if ( this.dr.site === '' ) {
      this.site.color = '';
      return;
    }

    this.siteService.getSiteFromCode(this.dr.site).subscribe(
      (data) => {
        if ( !data ) {
          this.site.color = 'grey';
          return;
        }

        this.siteSelected = data;
        this.siteSelected.operation = [];
        if (this.siteSelected.form_6_1 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Grua');
        }
        if (this.siteSelected.form_6_2 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Correia');
        }
        if (this.siteSelected.form_6_3 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Veiculo Esp.');
        }
        if (this.siteSelected.form_6_4 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Manual');
        }
        if (this.siteSelected.form_6_4 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Guindaste');
        }

        if (this.siteSelected.operation.length > 0) {
          this.dr.thirdPartInvoice = true;
         // console.log(this.dr.thirdPartInvoice);
        }

        if ( !data.aprovado ) {
          this.site.color = 'yellow-1';
        }
        if ( data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-2';
        }
        if ( !data.aprovado && data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-3';
        }

        if ( data.aprovado && data.acesso === ACESSO.LIBERADO ) {
          this.showSiteInfo = true;
          this.site.color = 'green';
        }
      },
      (error) => {
        this.site.color = 'grey';
      }
    );
  }

  receiveFile(event) {
    if (this.dr.id) {
      this.drService.getFilesFromDrManually(this.dr.id).subscribe((response) => {
        this.uploadedFiles = response;
      });
    }
  }

  removeFile(file: any, index: number) {
    this.uploadedFiles.splice(index, 1);
  }

  selectFile(event) {
    const selectedFiles = event.target.files;

    for (let i = 0; i < selectedFiles.length ; i++) {
      selectedFiles[i].path = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(selectedFiles[i]));
      this.uploadedFiles.push(selectedFiles[i]);
    }
  }
}
