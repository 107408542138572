import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { OVService } from '../../providers/ov.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { parseString } from 'xml2js';
import { NF } from '../../models/nfe';
import { NfeService } from '../../providers/nfe.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';
import { UploadFileService } from '../../providers/upload.service';
var InsertNfeComponent = /** @class */ (function () {
    function InsertNfeComponent(dialog, ovService, translate, excelService, clientUserService, uploadService, router, drService, nfService, snackBar) {
        var _this = this;
        this.dialog = dialog;
        this.ovService = ovService;
        this.translate = translate;
        this.excelService = excelService;
        this.clientUserService = clientUserService;
        this.uploadService = uploadService;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.snackBar = snackBar;
        this.nfe = new NF();
        this.uploaded = false;
        this.minDate = new Date();
        this.displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
            'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials', 'prolog'];
        this.dataSourceNF = new MatTableDataSource();
        this.newNfList = [];
        this.clientList = [];
        this.update = true;
        this.attachment = null;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    InsertNfeComponent.prototype.ngOnInit = function () {
    };
    InsertNfeComponent.prototype.insertNFe = function () {
        var _this = this;
        if (!this.uploaded) { // preenchido na mao
            // sobe as NFes
            this.nfService.create(this.nfe).subscribe(function (data) {
                if (_this.attachment) { // existe anexo
                    // sobe o anexo
                    _this.nfService.attach(data.id, _this.attachment).subscribe(function (response) {
                        _this.snackBar.open('NFe cadastradas corretamente', 'Ok', { duration: 4000 });
                    }, function (error) {
                        _this.snackBar.open('Erro ao anexar arquivo à NFe', 'Ok', { duration: 4000 });
                    });
                }
                else { // sem anexo
                    _this.snackBar.open('NFe cadastradas corretamente', 'Ok', { duration: 4000 });
                }
            }, function (error) {
                _this.snackBar.open('Erro ao cadastrar NFe', 'Ok', { duration: 4000 });
            });
        }
        else { // arquivo(s) xml subido(s)
            this.newNfList.forEach(function (nf) {
                try {
                    _this.nfService.getAll(nf.invoiceNumber).subscribe(function (ans) {
                        if (!ans) {
                            _this.nfService.create(nf).subscribe(function (data) {
                                _this.uploadService.pushFileToStorage(nf['file'], data, 'NFe').subscribe(function (event) {
                                });
                                _this.uploaded = false;
                                nf.status = true;
                                _this.snackBar.open('NFe cadastradas corretamente', 'Ok', { duration: 4000 });
                            });
                        }
                        else {
                            _this.snackBar.open('Algumas NFe já cadastradas no sistema', 'Ok', { duration: 4000 });
                            nf.status = false;
                        }
                    }, function (err) {
                        _this.nfService.create(nf).subscribe(function (data) {
                            _this.uploadService.pushFileToStorage(nf['file'], data.id, 'NFe').subscribe(function (event) {
                            });
                            // this.uploaded = false;
                            nf.status = true;
                            _this.snackBar.open('NFe cadastradas corretamente', 'Ok', { duration: 4000 });
                        });
                    });
                }
                catch (err) {
                    // console.log({ERROR: err});
                }
            });
            this.dataSourceNF = new MatTableDataSource(this.newNfList);
        }
    };
    InsertNfeComponent.prototype.attachFile = function (file) {
        this.attachment = file;
    };
    InsertNfeComponent.prototype.openFile = function (fileupload) {
        var input = fileupload.target;
        var ext = input.files[0].name.split('.').pop();
        if (ext === 'xlsx') {
            this.openXlsx(fileupload);
        }
        else {
            this.openXml(fileupload);
        }
    };
    InsertNfeComponent.prototype.openXml = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var data = [];
        var _loop_1 = function (index) {
            var reader = new FileReader();
            var newFile = [];
            newFile.push(input.files.item(index));
            reader.onload = function () {
                parseString(reader.result, function (err, result) {
                    // console.log(result);
                    var info = result.nfeProc.NFe[0].infNFe[0];
                    var nf = new NF();
                    nf['totalValue'] = info.total['0'].ICMSTot['0'].vNF['0'];
                    // console.log(nf);
                    nf['emissionDate'] = info.ide['0'].dhEmi['0'];
                    // console.log(nf);
                    nf['client'] = info.dest['0'].xNome['0'];
                    // console.log(nf);
                    nf['series'] = info.ide['0'].serie['0'];
                    // console.log(nf);
                    nf['type'] = info.ide['0'].tpNF['0'];
                    // console.log(nf);
                    if (info.transp['0'].vol) {
                        nf['weight'] = info.transp['0'].vol['0'].pesoB['0'];
                    }
                    else {
                        nf['weight'] = '0';
                    }
                    // console.log(nf);
                    nf['materials'] = info.det['0'].prod['0'].xProd['0'];
                    // console.log(nf);
                    nf['invoiceNumber'] = info.ide['0'].nNF['0'] + '-01e';
                    nf['file'] = newFile;
                    // console.log(nf);
                    data.push(nf);
                    if (index === input.files.length - 1) {
                        var keys_1 = data.length ? Object.keys(data[0]) : [];
                        data = data.map(function (el) {
                            for (var i = 0, l = keys_1.length; i < l; i++) {
                                // remove os valores null
                                if (el[keys_1[i]] === null) {
                                    el[keys_1[i]] = '';
                                }
                                // converte em Date
                                if (keys_1[i] === 'emissionDate') {
                                    el[keys_1[i]] = new Date(el[keys_1[i]]);
                                }
                            }
                            return el;
                        });
                        _this.newNfList = data;
                        _this.dataSourceNF = new MatTableDataSource(data);
                        _this.uploaded = true;
                    }
                });
            };
            reader.readAsText(input.files[index]);
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
    };
    InsertNfeComponent.prototype.openXlsx = function (fileupload) {
        var _this = this;
        var data = [];
        this.excelService.importFromExcel(fileupload).subscribe(function (fileContent) {
            fileContent.forEach(function (el, i, arr) {
                var nf = new NF();
                nf['totalValue'] = el['value'];
                nf['emissionDate'] = el['emission date'];
                nf['client'] = el['client'];
                nf['series'] = el['series'];
                nf['type'] = el['type'];
                nf['weight'] = el['weight'];
                nf['materials'] = el['materials'];
                nf['invoiceNumber'] = el['invoice number'];
                nf['prolog'] = el['prolog'];
                data.push(nf);
            });
            _this.newNfList = data;
            _this.dataSourceNF = new MatTableDataSource(data);
            _this.uploaded = true;
        });
    };
    InsertNfeComponent.prototype.sanitizeInputPrice = function (field, value) {
        var input = value.srcElement.value;
        this.update = false;
        input = input.replace('R$', '');
        input = input.replace(' ', '');
        input = input.replace('.', '');
        input = input.replace(',', '.');
        input = input.replace(/[^\d.]/g, '');
        this.nfe[field] = input;
        this.update = true;
    };
    return InsertNfeComponent;
}());
export { InsertNfeComponent };
