<div *ngIf="!uploaded">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'INSERT-NFE/not-uploaded/title' | translate }}</p>
  </div>
  <div class="colored-background table-background">
    <div class="mx-5 py-3">
      <mat-card class="rounded-corners">
        <mat-card-content class="d-flex flex-wrap">
          <div class="col-md-12">
            <input class="hidden" type='file' #fileInput accept='text/xml/xlsx' (change)='openFile($event)' multiple>
            <button mat-raised-button class="button green-button text-white"
              (click)="fileInput.click()">{{ 'INSERT-NFE/not-uploaded/form/select-file' | translate }}</button>
              <div class="title col-md-3">
                <br />
                <a href="../../../assets/extras/modelo_subida_nfe.xlsx" download="">{{ 'NEW-DELIVERY-REQUEST/dowload_model' | translate }} <i matSuffix font class="fa fa-download"></i></a>
              </div>
          </div>
          <form #form="ngForm" class="d-flex flex-wrap">
            <mat-form-field class="col-md-4 col-lg-2">
              <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/invoice-number' | translate" [(ngModel)]="nfe.invoiceNumber" name="invoiceNumber" required>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-lg-2">
              <input matInput name="series" [placeholder]="'INSERT-NFE/not-uploaded/form/series' | translate" [(ngModel)]="nfe.series" required>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-lg-2">
              <mat-label>{{'INSERT-NFE/not-uploaded/form/client' | translate}}</mat-label>
              <mat-select [(ngModel)]="nfe.client" name="client">
                <mat-option *ngFor="let client of clientList" [value]="client">
                  {{client}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-lg-2">
              <input matInput *ngIf="update" type="text" [placeholder]="'INSERT-NFE/not-uploaded/form/total-value' | translate" [ngModel]="nfe.totalValue | currency:'BRL'" (blur)="sanitizeInputPrice('totalValue', $event)" name="totalValue">
            </mat-form-field>
            <mat-form-field class="col-md-4 col-lg-2">
              <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/weight' | translate" [(ngModel)]="nfe.weight" type="number" name="weight" required>
              <span matSuffix> &nbsp;KG</span>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-lg-2">
              <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/type' | translate" [(ngModel)]="nfe.type" name="type" required>
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <textarea matInput [placeholder]="'INSERT-NFE/not-uploaded/form/cla' | translate" [(ngModel)]="nfe.cla" name="cla" required></textarea>
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <textarea matInput [placeholder]="'INSERT-NFE/not-uploaded/form/materials' | translate" [(ngModel)]="nfe.materials" name="materials" required></textarea>
            </mat-form-field>
            <mat-form-field>
              <input matInput [owlDateTimeTrigger]="myDatepicker" [owlDateTime]="myDatepicker" [placeholder]="'INSERT-NFE/not-uploaded/form/collect-date' | translate" [(ngModel)]="nfe.emissionDate" name="Datepicker" required>
              <owl-date-time #myDatepicker pickerType="both"></owl-date-time>
            </mat-form-field>
            <mat-form-field class="col-md-6">
              <input matInput type="text" id="prolog" [placeholder]="'INSERT-NFE/not-uploaded/form/prolog' | translate" [(ngModel)]="nfe.prolog" name="prolog" required>
            </mat-form-field>
          </form>
          <div class="col-md-12 mb-3">
            <app-form-upload name="attachment" [label]="'INSERT-NFE/not-uploaded/form/attach-file' | translate" (setFile)="attachFile($event)"></app-form-upload>
          </div>
          <button mat-raised-button [disabled]="!form.valid" class="button green-button text-white col-md-12" (click)="insertNFe()">{{ 'INSERT-NFE/not-uploaded/button' | translate }}</button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="uploaded">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'INSERT-NFE/uploaded/title' | translate }}</p>
  </div>
  <div class="colored-background table-background">
    <div class="mx-5 py-3">
      <mat-card class="rounded-corners">
        <mat-card-content class="d-flex flex-wrap">
          <form #form2="ngForm" class="d-flex flex-wrap">
            <div *ngIf="dataSourceNF.data.length > 0" style="margin-bottom: 30px;" class="w-100">
              <table mat-table #table [dataSource]="dataSourceNF" matSort>

                <ng-container matColumnDef="invoiceNumber">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/invoice-number' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.invoiceNumber }} </td>
                </ng-container>

                <ng-container matColumnDef="series">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/series' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.series}} </td>
                </ng-container>

                <ng-container matColumnDef="client">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/client' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.client}} </td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/type' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>

                <ng-container matColumnDef="weight">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/weight' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>

                <ng-container matColumnDef="totalValue">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/total-value' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.totalValue}} </td>
                </ng-container>


                <ng-container matColumnDef="prolog">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/prolog' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> <input matInput type="text" id="prolog" [placeholder]="'INSERT-NFE/not-uploaded/form/prolog' | translate" [(ngModel)]="element.prolog" name="prolog" required>  </td>
                </ng-container>

                <ng-container matColumnDef="emissionDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/emission-date' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.emissionDate | date: "dd/MM/yy, h:mm a"}} </td>
                </ng-container>

                <ng-container matColumnDef="width">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/width' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.width}} </td>
                </ng-container>

                <ng-container matColumnDef="height">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/height' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.height}} </td>
                </ng-container>

                <ng-container matColumnDef="depth">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/depth' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.depth}} </td>
                </ng-container>

                <ng-container matColumnDef="materials">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/materials' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{element.materials}} </td>
                </ng-container>

                <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns3;" [ngStyle]="{background: ( row.status === true? 'rgba(0,200,0,0.4)' : (row.status === false? 'rgba(200,0,0,0.4)': 'rgba(213, 255, 66, 0)'))}" ></tr>
              </table>
            </div>
          </form>
          <div class="ml-auto col-md-4">
            <button mat-raised-button [disabled]="!form2.valid" class="button green-button text-white" (click)="insertNFe()">{{ 'INSERT-NFE/uploaded/button' | translate }}</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
