import { Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as i0 from "@angular/core";
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var XlsxService = /** @class */ (function () {
    function XlsxService() {
    }
    XlsxService.prototype.export = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.save(excelBuffer, excelFileName);
    };
    XlsxService.prototype.save = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        // adiciona extensao
        if (fileName.lastIndexOf(EXCEL_EXTENSION) !== (fileName.length - EXCEL_EXTENSION.length)) {
            fileName = fileName + EXCEL_EXTENSION;
        }
        // adiciona timestamp
        if (fileName.indexOf('%') !== -1) {
            fileName = fileName.replace('%', String(new Date().getTime()));
        }
        FileSaver.saveAs(data, fileName);
    };
    XlsxService.prototype.import = function (input) {
        var workbook;
        var excelInJSON;
        var fileReader = new FileReader();
        // init read
        fileReader.readAsArrayBuffer(input.target.files[0]);
        return new Observable(function (observer) {
            // if success
            fileReader.onload = function (ev) {
                var binary = '';
                var bytes = new Uint8Array(ev.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                // Converts the excel data in to json
                workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
                // only first sheet
                excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                observer.next(excelInJSON);
            };
            // if failed
            fileReader.onerror = function (error) { return observer.error(error); };
        });
    };
    XlsxService.ngInjectableDef = i0.defineInjectable({ factory: function XlsxService_Factory() { return new XlsxService(); }, token: XlsxService, providedIn: "root" });
    return XlsxService;
}());
export { XlsxService };
