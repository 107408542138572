export enum OCCURRENCE_STATUS { SENT_TO_LSP = 'SENT_TO_LSP'
                              , OCCURRENCE_TO_RESOLVE = 'OCCURRENCE_TO_RESOLVE'
                              , OCCURRENCE_RESOLVED = 'OCCURRENCE_RESOLVED'
                              , OCCURRENCE_DENIED = 'OCCURRENCE_DENIED'
                              , OCCURRENCE_COST_APPROVAL = 'OCCURRENCE_COST_APPROVAL'
                              , OCCURRENCE_COST_APPROVAL_DENIED = 'OCCURRENCE_COST_APPROVAL_DENIED'
                              , OCCURRENCE_COST_APPROVAL_ACCEPT = 'OCCURRENCE_COST_APPROVAL_ACCEPT'
}

export class Occurrence {
  id: number;
  idMDR: number;
  uoc: string;
  text: string;
  status: string;
  createdAt: Date;
  resolution: string;
  value: number;
  multiplicator: number;
  file: any;

  constructor() {
    this.id = null;
    this.idMDR = null;
    this.uoc = '';
    this.text = '';
    this.status = '';
    this.createdAt = null;
    this.resolution = '';
    this.value = 0;
    this.multiplicator = 1;
    this.file = null;
  }
}
