<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'PRICE_MAKER_TABLE' | translate }}</p>
  <p class="mx-4">{{ 'PRICE_MAKER_TABLE/description' | translate }}<br>{{ 'PRICE_MAKER_TABLE/update' | translate }}</p>
  <button mat-raised-button (click)="addItem()">{{ 'PRICE_MAKER_TABLE/btn/new' | translate }}</button>
  <div class="col-md-12">
    <input #fileInput multiple
      class="hidden"
      type='file'
      accept='text/xlsx, text/txt'
      (change)='openFile($event)'
    >
    <button mat-raised-button
      class="w-100 green-button"
      (click)="fileInput.click()"
    >Insert New Price Maker</button>
  </div>
</div>

<div *ngIf="data.length > 0" class="colored-background table-background px-5 py-2">
  <app-table *ngIf="role === 77"
    [data]="data"
    [columns]="columns"
    [clickCallback]="onRowClick"
    downloadAs="pm_%.xlsx"
    downloadShowId="true"
    [this]="this"
  ></app-table>
  <app-table *ngIf="role !== 77"
    [data]="data"
    [columns]="columns"
    [clickCallback]="onRowClick"
    [this]="this"
  ></app-table>
</div>

<div *ngIf="data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'PRICE_MAKER_TABLE/empty' | translate }}</p>
</div>
