export var DR_STATUS;
(function (DR_STATUS) {
    DR_STATUS["READY_TO_PICKUP"] = "READY_TO_PICKUP";
    DR_STATUS["DELIVERED"] = "Delivered";
    DR_STATUS["INTRANSIT"] = "InTransit";
    DR_STATUS["CANCELED"] = "Canceled";
    DR_STATUS["HUB_RETURN"] = "HubReturn";
    DR_STATUS["REPLAN_RETIDO"] = "Replan - Retido";
    DR_STATUS["REPLAN_ANTECIPACAO"] = "Replan - Antecipa\u00E7\u00E3o";
    DR_STATUS["REPLAN_PERNOITE"] = "Replan - Pernoite";
    DR_STATUS["REPLAN_POSTERGACAO"] = "Replan - Posterga\u00E7\u00E3o";
    DR_STATUS["REPLAN_SEFAZ"] = "Replan - Sefaz";
    DR_STATUS["BOOKING"] = "BOOKING";
    DR_STATUS["DEVOLUCAO_TRANSITO"] = "Devolu\u00E7\u00E3o/Tr\u00E2nsito";
    DR_STATUS["DEVOLUCAO_EDB"] = "Devolvido EDB";
    DR_STATUS["SINISTRO"] = "Sinistro";
})(DR_STATUS || (DR_STATUS = {}));
var DR = /** @class */ (function () {
    function DR() {
        this.id = null;
        this.idMDR = null;
        this.client = null;
        this.pickupDifAddress = null;
        this.idDRidentifier = '';
        this.deliveryAddress = '';
        this.pickupAddress = '';
        this.pickupDate = null;
        this.pickupPhone = '';
        this.site = '';
        this.receiverPhone = null;
        this.supervisorPhone = null;
        this.supervisorName = '';
        this.receiverName = '';
        this.lat = null;
        this.lng = null;
        this.infraType = '';
        this.ovList = new Array();
        this.nfList = new Array();
        this.obs = '';
        this.dateScheduling = null;
        this.idDisplay = null;
        this.createdAt = null;
        this.createdBy = null;
        this.thirdPartInvoice = null;
        this.status = null;
        this.value = null;
        this.weight = null;
        this.maxHeight = null;
        this.maxLength = null;
        this.maxWidth = null;
        this.volume = null;
        this.uf = null;
        this.prolog = null;
        this.siteTeam = '';
        this.unificationId = '';
        this.salesOrders = [];
        this.city = null;
        this.deleteReason = '';
        this.distance = null;
    }
    return DR;
}());
export { DR };
