var HUB = /** @class */ (function () {
    function HUB() {
        this.id = null;
        this.name = null;
        this.shippingCompany = null;
        this.address = null;
        this.lat = null;
        this.lng = null;
    }
    return HUB;
}());
export { HUB };
