import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteService, ESTRUTURA } from '../../providers/site.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Observable';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import 'rxjs/add/operator/map';

@Component({
  selector: 'app-site-investigation-with-token',
  templateUrl: './site-investigation-with-token.component.html',
  styleUrls: ['./site-investigation-with-token.component.scss']
})
export class SiteInvestigationWithTokenComponent implements OnInit {
  @ViewChild('form') form;
  siteid: number = null;
  token: string = null;
  site: any = null;
  siteFromCookies: any = {};
  enums: any = {};
  tipos: any = [];
  SITE_ESTRUTURA = ESTRUTURA;
  keysForCookies = [
    'lat', 'lng', 'regional', 'infra', 'tipo',
    'form_1_1', 'form_1_2', 'form_1_3', 'form_1_4', 'form_1_5', 'form_1_6', 'form_1_7', 'form_1_8', 'form_1_9', 'form_2_1', 'form_2_2', 'form_2_3', 'form_2_4', 'form_2_5', 'form_2_6', 'form_2_7', 'form_2_8', 'form_2_9', 'form_3_1', 'form_3_2', 'form_3_3', 'form_4_1', 'form_4_2', 'form_4_3', 'form_5_1', 'form_5_2', 'form_5_3', 'form_6_1', 'form_6_2', 'form_6_3', 'form_6_4', 'form_6_5', 'form_7_1', 'form_7_2', 'form_7_3',
    'form_1_1_obs', 'form_1_2_obs', 'form_1_3_obs', 'form_1_4_obs', 'form_1_5_obs', 'form_1_6_obs', 'form_1_7_obs', 'form_1_8_obs', 'form_1_9_obs', 'form_2_1_obs', 'form_2_2_obs', 'form_2_3_obs', 'form_2_4_obs', 'form_2_5_obs', 'form_2_6_obs', 'form_2_7_obs', 'form_2_8_obs', 'form_2_9_obs', 'form_3_1_obs', 'form_3_2_obs', 'form_3_3_obs', 'form_4_1_obs', 'form_4_2_obs', 'form_4_3_obs', 'form_5_1_obs', 'form_5_2_obs', 'form_5_3_obs', 'form_6_1_obs', 'form_6_2_obs', 'form_6_3_obs', 'form_6_4_obs', 'form_6_5_obs', 'form_7_1_obs', 'form_7_2_obs', 'form_7_3_obs'
  ];
  fileKeys = [
    'form_1_1_anexo', 'form_1_2_anexo', 'form_1_3_anexo', 'form_1_4_anexo', 'form_1_5_anexo', 'form_1_6_anexo', 'form_1_7_anexo', 'form_1_8_anexo', 'form_1_9_anexo', 'form_2_1_anexo', 'form_2_2_anexo', 'form_2_3_anexo', 'form_2_4_anexo', 'form_2_5_anexo', 'form_2_6_anexo', 'form_2_7_anexo', 'form_2_8_anexo', 'form_2_9_anexo', 'form_3_1_anexo', 'form_3_2_anexo', 'form_3_3_anexo', 'form_4_1_anexo', 'form_4_2_anexo', 'form_4_3_anexo', 'form_5_1_anexo', 'form_5_2_anexo', 'form_5_3_anexo', 'form_6_1_anexo', 'form_6_2_anexo', 'form_6_3_anexo', 'form_6_4_anexo', 'form_6_5_anexo', 'form_7_1_anexo', 'form_7_2_anexo', 'form_7_3_anexo'
  ];

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'printable', // the id of html/table element
  };

  constructor(public aRoute: ActivatedRoute, public snackBar: MatSnackBar,
              public dialog: MatDialog, private exportAsService: ExportAsService,
              public siteService: SiteService, private cookieService: CookieService) {
    this.aRoute.params.subscribe( (params) => {
      this.token = params.token;
      this.siteid = params.siteid;
    });
    this.enums = this.siteService.getEnums();
  }

  onClickPrint() {
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    // this.exportAsService.get(this.config).subscribe(content => {
    //  //console.log(content);
    // });
  }

  ngOnInit() {
    this.siteService.readWithToken(this.siteid, this.token).subscribe((data) => {
      // separa data e hora visita
      data.visitadatahora = new Date(data.visitadatahora);
      data.visitadata = data.visitadatahora;
      const d1 = new Date(data.visitadatahora);
      data.visitahora = ('00' + d1.getHours()).slice(-2) + ':' + ('00' + d1.getMinutes()).slice(-2);
      // separa data e hora vistoria
      data.vistoriadatahora = new Date(data.vistoriadatahora);
      data.vistoriadata = data.vistoriadatahora;
      const d2 = new Date(data.vistoriadatahora);
      data.vistoriahora = ('00' + d2.getHours()).slice(-2) + ':' + ('00' + d2.getMinutes()).slice(-2);
      // separa tipo em objeto
      const tipo = {};
      this.enums.tipo.map( (el) => {
        tipo[el.value] = false;
      });
      data.tipo.map( (el) => {
        tipo[el.tipo] = true;
      });
      data.tipo = tipo;
      // armazena
      this.site = data;
      // recupera possiveis valores salvos anteriormente
      this.checkRestore();
    });
  }

  storeData() {
    this.snackBar.open('Não foi possível salvar as modificações, tente novamente em alguns instantes', 'Ok', {duration: 2000});
    const keys = this.keysForCookies;
    for ( let i = 0, l = keys.length ; i < l ; i++ ) {
      // se existe um valor
      if ( !!this.site[keys[i]] ) {
        const cookie = {
          type: typeof(this.site[keys[i]]),
          value: this.site[keys[i]]
        };
        this.cookieService.set(keys[i], JSON.stringify(cookie));
      }
    }
    const now = new Date();
    this.cookieService.set('when', now.toLocaleString());
  }
  restoreData() {
    const when = this.cookieService.get('when');
    this.snackBar.open('Recuperando modificações de ' + when, 'Ok', {duration: 2000});
    const keys = this.keysForCookies;
    for ( let i = 0, l = keys.length ; i < l ; i++ ) {
      if ( this.cookieService.check(keys[i]) ) {
        const cookie = JSON.parse(this.cookieService.get(keys[i]));
        switch (cookie.type) {
          case 'string':
            this.siteFromCookies[keys[i]] = cookie.value;
            break;
          case 'number':
            this.siteFromCookies[keys[i]] = Number.parseFloat(cookie.value);
            break;
          case 'object':
            this.siteFromCookies[keys[i]] = cookie.value;
            break;
        }
      }
    }
    this.site = Object.assign({}, this.site, this.siteFromCookies);
  }
  clearData() {
    this.cookieService.delete('when');
    const keys = this.keysForCookies;
    for ( let i = 0, l = keys.length ; i < l ; i++ ) {
     this.cookieService.delete(keys[i]);
    }
    this.cookieService.delete('tipo');
  }

  save() {
    const toSave = Object.assign({}, this.site);
    // une data e hora
    toSave.visitadatahora = this.joinDataHora(toSave.visitadata, toSave.visitahora);
    toSave.vistoriadatahora = this.joinDataHora(toSave.vistoriadata, toSave.vistoriahora);
    // unifica tipo em um array
    const tipo = [];
    this.enums.tipo.map( (el) => {
      if ( toSave.tipo[el.value] === true ) {
        tipo.push(el.value);
      }
    });
    toSave.tipo = tipo;
    // separa os anexos
    const files = [];
    this.fileKeys.forEach( (el) => {
      if ( !!toSave[el] && !!toSave[el].name ) {
        const filename = el + '.' + toSave[el].name.split('.').pop();
        files.push({ file: toSave[el], field: filename });
      }
      delete toSave[el];
    });

    // envia pro back
    this.snackBar.open('Enviando... isto pode demorar um pouco', 'Ok', {duration: 2000});
    this.siteService.updateFilesWithToken(this.siteid, this.token, files).subscribe((dataUpdateFiles) => {
      this.siteService.updateWithToken(this.siteid, this.token, toSave).subscribe((dataUpdate) => {
        this.snackBar.open('Site atualizado com sucesso', 'Ok', {duration: 4000});
        this.site = null;
        this.clearData();
      }, (err) => {
        this.storeData();
      });
    }, (err) => {
      this.storeData();
    });
  }

  joinDataHora(d, h) {
    const ano  = 1900 + d.getYear();
    const mesI = d.getMonth(); // mes-1
    const dia  = d.getDate();
    const hora = parseInt(h.replace(/[:].*/, ''), 10);
    const mins = parseInt(h.replace(/.*[:]/, ''), 10);
    const data = new Date(ano, mesI, dia, hora, mins);
    return data;
  }

  getlocation() {
    if ( navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition( (position) => {
        this.site.lat = position.coords.latitude;
        this.site.lng = position.coords.longitude;
      }, (error) => {
        let message = '';
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = 'É necessário liberar o acesso à sua geolocalização';
            break;
          case error.POSITION_UNAVAILABLE:
            message = 'Informação de geolocalização indisponível';
            break;
          case error.TIMEOUT:
            message = 'Timeout ao pegar geolocalização';
            break;
          default:
            message = 'Erro desconhecido';
            break;
        }
        this.snackBar.open(message, 'Ok', {duration: 2000});
      });
    } else {
      this.snackBar.open('Seu navegador não suporta geolocation', 'Ok', {duration: 2000});
    }
  }

  checkStore() {
    // verifica se o form foi modificado
    if ( this.form.form.pristine === true ) {
      return true;
    }
    const dialogRef = this.dialog.open(StoreDataDialogComponent, {
      data: { }
    });
    return dialogRef.afterClosed().map(result => {
      if ( result && result.what === 'store' ) {
        this.save();
        return true;
      } else {
        return true;
      }
    });
  }

  checkRestore() {
    if ( this.cookieService.check('when') === false ) {
      // não há valores salvos
      return;
    }
    const dialogRef = this.dialog.open(RestoreDataDialogComponent, {
      data: {
        when: this.cookieService.get('when')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ( result && result.what === 'restore' ) {
        this.restoreData();
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.checkStore();
  }

}

@Component({
  selector: 'app-dialog-restore-data-dialog',
  templateUrl: 'dialog-restore-data-dialog.html',
  styleUrls: ['./site-investigation-with-token.component.scss']
})
export class RestoreDataDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RestoreDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk(): void {
    this.dialogRef.close({ what: 'restore' });
  }
  onClickNo(): void {
    this.dialogRef.close({ what: '' });
  }

}

@Component({
  selector: 'app-dialog-store-data-dialog',
  templateUrl: 'dialog-store-data-dialog.html',
  styleUrls: ['./site-investigation-with-token.component.scss']
})
export class StoreDataDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<StoreDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk(): void {
    this.dialogRef.close({ what: 'store' });
  }
  onClickNo(): void {
    this.dialogRef.close({ what: '' });
  }

}
