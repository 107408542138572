import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Papa } from 'ngx-papaparse';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { OVService } from '../../providers/ov.service';
import { MdrService } from '../../providers/mdr.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { DR } from '../../models/dr';
import { OV } from '../../models/ov';
import { Zrsdpac } from '../../models/zrsdpac';
import { Zhstatus } from '../../models/zhstatus';
import { MDR } from '../../models/mdr';
var InsertDataComponent = /** @class */ (function () {
    function InsertDataComponent(papa, snackBar, excelService, ovService, mdrService, configService) {
        this.papa = papa;
        this.snackBar = snackBar;
        this.excelService = excelService;
        this.ovService = ovService;
        this.mdrService = mdrService;
        this.configService = configService;
        // relativos ao upload de arquivo
        this.fileUploaded = null;
        this.dataUploaded = [];
        // relativos a generalização
        this.validUploads = [];
        this.columns = {
            'zvsd1': [
                { value: 'billingDocument', name: 'INSERT-DATA/uploaded/table/zvsd1/billing' },
                { value: 'salesDocument', name: 'INSERT-DATA/uploaded/table/zvsd1/ov' },
            ],
            'zrsdpac': [
                { value: 'height', name: 'INSERT-DATA/uploaded/table/zrsdpac/height' },
                { value: 'ourOrderNo', name: 'INSERT-DATA/uploaded/table/zrsdpac/ov' },
            ],
            'zhstatus': [
                { value: 'handlingUnit', name: 'INSERT-DATA/uploaded/table/zhstatus/hu' },
                { value: 'salesDoc', name: 'INSERT-DATA/uploaded/table/zhstatus/ov' },
            ]
        };
    }
    InsertDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configService.getConfiguration('insert-new-data').subscribe(function (response) {
            _this.validUploads = response['validUploads'];
        }, function (error) {
            _this.validUploads = [];
        });
    };
    InsertDataComponent.prototype.convertLatLngToAddressFromUpload = function (latInp, lngInp) {
        var _this = this;
        if (latInp === null || lngInp === null) {
            return { resp: false, data: null };
        }
        var lat = latInp;
        var lng = lngInp;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        // console.log(location);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            // console.log(results);
            // console.log(status);
            if (status === google.maps.GeocoderStatus.OK) {
                return { resp: true, data: results[0].formatted_address };
            }
            else {
                _this.snackBar.open('Não foi possível converter uma ou mais coordenadas em um endereço', 'Ok', { duration: 2000 });
                return { resp: false, data: null };
            }
        });
    };
    InsertDataComponent.prototype.openFile = function (type, fileupload) {
        switch (type) {
            case 'zvsd1':
                this.openZVSD1(fileupload);
                break;
            case 'zrsdpac':
                this.openZRSDPAC(fileupload);
                break;
            case 'zhstatus':
                this.openZHSTATUS(fileupload);
                break;
            case 'latam':
                this.openLATAM(fileupload);
                break;
        }
    };
    InsertDataComponent.prototype.insert = function () {
        switch (this.fileUploaded) {
            case 'zvsd1':
                this.insertZVSD1();
                break;
            case 'zrsdpac':
                this.insertZRSDPAC();
                break;
            case 'zhstatus':
                this.insertZHSTATUS();
                break;
        }
        // reseta para valores iniciais
        this.dataUploaded = [];
        this.fileUploaded = null;
    };
    /***********
     * funções para ler arquivo subido
     */
    InsertDataComponent.prototype.openZVSD1 = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var _loop_1 = function (index) {
            var newOV = new OV();
            var ovList = [];
            var reader = new FileReader();
            reader.onload = function () {
                _this.papa.parse(reader.result, {
                    header: true,
                    encoding: 'ISO-8859-1',
                    complete: function (result) {
                        for (var i = 0; i < result.data.length; i++) {
                            if (Object.keys(result.data[i]).length === 1) {
                                // linha vazia é traduzida para object com somente 1 campo
                                continue;
                            }
                            newOV.assignmentInformation = result.data[i]['Assignment information'];
                            newOV.billingDocument = result.data[i]['Billing document'];
                            newOV.conditionValue = result.data[i]['Condition Value'];
                            newOV.customerAddress = result.data[i]['Customer Address'];
                            newOV.customerCity = result.data[i]['Customer City'];
                            newOV.customerPurchaseOrderNumber = result.data[i]['Customer Purchase Order Number'];
                            newOV.customerName = result.data[i]['Customer name'];
                            newOV.higherLevelItemInBill = result.data[i]['Higher-level item in bill of material structures'];
                            newOV.itemNomeText = result.data[i]['Item Note Text (PT)'];
                            newOV.materialDescription = result.data[i]['Material Description'];
                            newOV.materialCode = result.data[i]['Material code'];
                            newOV.nameOfOrderer = result.data[i]['Name of orderer'];
                            newOV.reasonForRejection = result.data[i]['Reason for rejection of quotations/sales orders'];
                            newOV.referenceDoc = result.data[i]['Reference Doc.'];
                            newOV.referenceNoNf = result.data[i]['Reference No. (N.F)'];
                            newOV.sDDocument = result.data[i]['SD Document Category'];
                            newOV.salesDocument = result.data[i]['Sales Document'];
                            newOV.salesDocumentType = result.data[i]['Sales Document Type'];
                            newOV.salesOffileKam = result.data[i]['Sales Office (KAM)'];
                            newOV.salesOrderQuantity = result.data[i]['Sales Order Quantity'];
                            newOV.salesOrganization = result.data[i]['Sales Organization'];
                            newOV.salesSequence = result.data[i]['Sales Sequence'];
                            newOV.shipToPartyPurchaseOrderNumber = result.data[i]['Ship-to Party\'s Purchase Order Number'];
                            ovList.push(newOV);
                            newOV = new OV();
                        }
                        _this.dataUploaded = ovList;
                        _this.fileUploaded = 'zvsd1';
                    }
                });
            };
            reader.readAsText(input.files[index], 'ISO-8859-1');
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
    };
    InsertDataComponent.prototype.openZRSDPAC = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var _loop_2 = function (index) {
            var newZrsdpac = new Zrsdpac();
            var zrsList = [];
            var reader = new FileReader();
            reader.onload = function () {
                var rows = reader.result.split('\n');
                for (var i = 13; i < rows.length - 4; i++) {
                    var zrsdpac = rows[i].split('|');
                    newZrsdpac.ourOrderNo = zrsdpac[1].trim();
                    newZrsdpac.boxNo = zrsdpac[2].trim();
                    newZrsdpac.box = zrsdpac[3].trim();
                    newZrsdpac.type = zrsdpac[4].trim();
                    newZrsdpac.grossWeight = zrsdpac[5].trim();
                    newZrsdpac.height = zrsdpac[6].trim();
                    newZrsdpac.length = zrsdpac[7].trim();
                    newZrsdpac.width = zrsdpac[8].trim();
                    newZrsdpac.volume = zrsdpac[9].trim();
                    newZrsdpac.agreementId = zrsdpac[10].trim();
                    newZrsdpac.customerPoNumber = zrsdpac[11].trim();
                    newZrsdpac.customerPoItem = zrsdpac[12].trim();
                    newZrsdpac.site = zrsdpac[13].trim();
                    newZrsdpac.soNumber = zrsdpac[14].trim();
                    newZrsdpac.soItem = zrsdpac[15].trim();
                    newZrsdpac.delivery = zrsdpac[16].trim();
                    newZrsdpac.shipment = zrsdpac[17].trim();
                    newZrsdpac.loadingWeight = zrsdpac[18].trim();
                    newZrsdpac.weightUnit = zrsdpac[19].trim();
                    newZrsdpac.uolForDimensions = zrsdpac[20].trim();
                    newZrsdpac.soGroupId = zrsdpac[21].trim();
                    newZrsdpac.netWeight = zrsdpac[22].trim();
                    zrsList.push(newZrsdpac);
                    newZrsdpac = new Zrsdpac();
                }
                _this.dataUploaded = zrsList;
                _this.fileUploaded = 'zrsdpac';
            };
            reader.readAsText(input.files[index]);
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_2(index);
        }
    };
    InsertDataComponent.prototype.openZHSTATUS = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var _loop_3 = function (index) {
            var newZhs = new Zhstatus();
            var zhsList = [];
            var reader = new FileReader();
            reader.onload = function () {
                var rows = reader.result.split('\n');
                for (var i = 4; i < rows.length - 2; i++) {
                    var zhstatus = rows[i].split('|');
                    newZhs.shipment = zhstatus[1].trim();
                    newZhs.delivery = zhstatus[2].trim();
                    newZhs.salesDoc = zhstatus[3].trim();
                    newZhs.storBin = zhstatus[4].trim();
                    newZhs.sorg = zhstatus[5].trim();
                    newZhs.handlingUnit = zhstatus[6].trim();
                    newZhs.shipPoint = zhstatus[7].trim();
                    newZhs.totalWght = zhstatus[8].trim();
                    newZhs.totalVol = zhstatus[9].trim();
                    newZhs.packMatls = zhstatus[10].trim();
                    newZhs.status = zhstatus[11].trim();
                    newZhs.shty = zhstatus[12].trim();
                    newZhs.trspPlanPt = zhstatus[13].trim();
                    newZhs.servcAgent = zhstatus[14].trim();
                    newZhs.spPi = zhstatus[15].trim();
                    newZhs.planned = zhstatus[16].trim();
                    newZhs.loadStart = zhstatus[17].trim();
                    newZhs.loadEnd = zhstatus[18].trim();
                    newZhs.completion = zhstatus[19].trim();
                    newZhs.shmtStart = zhstatus[20].trim();
                    newZhs.shmtEnd = zhstatus[21].trim();
                    newZhs.currShipSt = zhstatus[22].trim();
                    newZhs.gm = zhstatus[23].trim();
                    newZhs.ops = zhstatus[24].trim();
                    newZhs.ps = zhstatus[25].trim();
                    newZhs.podStatus = zhstatus[26].trim();
                    newZhs.poReference = zhstatus[27].trim();
                    newZhs.siteId = zhstatus[28].trim();
                    newZhs.completionIndicator = zhstatus[29].trim();
                    zhsList.push(newZhs);
                    newZhs = new Zhstatus();
                }
                _this.dataUploaded = zhsList;
                _this.fileUploaded = 'zhstatus';
            };
            reader.readAsText(input.files[index]);
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_3(index);
        }
    };
    InsertDataComponent.prototype.openLATAM = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                    data.forEach(function (el, i, arr) {
                        var ovAux = new OV();
                        ovAux.salesDocument = 'a';
                        ovAux.customerName = el.client;
                        ovAux.higherLevelItemInBill = '000000';
                        ovAux.salesSequence = i;
                        ovAux.materialCode = el.code;
                        ovAux.materialDescription = el.description;
                        ovAux.salesOrderQuantity = el.qtd;
                        ovAux.referenceNoNf = '000000';
                        ovList.push(ovAux);
                    });
                    var dr = new DR();
                    dr.site = data[0].siteId;
                    dr.lat = data[0].lat;
                    dr.lng = data[0].lng;
                    dr.receiverName = data[0].receiverName;
                    dr.receiverPhone = data[0].receiverPhone;
                    dr.supervisorName = data[0].receiverName;
                    dr.supervisorPhone = data[0].supervisorPhone;
                    var elem = _this.convertLatLngToAddressFromUpload(data[0].lat, data[0].lng);
                    if (elem && elem.resp === false) {
                        _this.snackBar.open('Endereço inválido', 'Ok', { duration: 2000 });
                        error = true;
                    }
                    else {
                        // console.log(elem)
                    }
                    dr.dateScheduling = new Date(data[0].dateScheduling);
                    dr.value = 1000;
                    dr.weight = 100;
                    dr.volume = 100;
                    dr.maxWidth = 100;
                    dr.maxHeight = 100;
                    dr.maxLength = 100;
                    dr.status = 'READY_TO_PICKUP';
                    dr.prolog = data[0].prolog;
                    var mdr = new MDR();
                    mdr.status = 'MDR em validação';
                    mdr.pickupID = data[0].pickupId;
                    mdr.type = 'MF';
                    mdr.totalNF = '10000';
                    mdr.createBy = data[0].createBy;
                    mdr.shippingCompany = data[0].lsp;
                    _this.mdrService.insertLatam(ovList, dr, mdr).subscribe(function (response) {
                    });
                });
            }
        }
    };
    /***********
     * funções para inserir
     */
    InsertDataComponent.prototype.insertZVSD1 = function () {
        this.ovService.saveMultipleOV(this.dataUploaded).subscribe(function (elem) {
        });
        this.snackBar.open('Dados sendo inseridos. Essa operação pode demorar até 5 minutos.', 'Fechar', {
            duration: 6000,
        });
    };
    InsertDataComponent.prototype.insertZHSTATUS = function () {
        this.ovService.saveMultipleZH(this.dataUploaded).subscribe(function (elem) {
        });
        this.snackBar.open('Dados sendo inseridos. Essa operação pode demorar até 5 minutos.', 'Fechar', {
            duration: 6000,
        });
    };
    InsertDataComponent.prototype.insertZRSDPAC = function () {
        this.ovService.saveMultipleZRS(this.dataUploaded).subscribe(function (elem) {
        });
        this.snackBar.open('Dados sendo inseridos. Essa operação pode demorar até 5 minutos.', 'Fechar', {
            duration: 6000,
        });
    };
    return InsertDataComponent;
}());
export { InsertDataComponent };
