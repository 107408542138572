<div>
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'COCKPIT/title' | translate }}</p>
    <p class="mx-4">{{ 'COCKPIT/sub-title' | translate }}</p>
  </div>
  <div class="colored-background d-flex flex-column h-100">
    <div fxLayout fxLayout.xs="column" class="pt-3 pb-3 mt-1 mx-5 d-flex justify-content-between h-100"
      fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div fxFlex="50%">
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="15px" fxLayoutGap.xs="0">
          <mat-form-field class="rounded-corners search-box" floatLabel="never">
            <div fxFlex="70%">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let chip of searchChips" [selectable]="selectable" [removable]="removable"
                  (removed)="remove(chip)">
                  {{chip.name}}
                  <i matChipRemove class="fa fa-times ml-1" *ngIf="removable"></i>
                </mat-chip>
                <input placeholder=" {{ 'COCKPIT/type' | translate }}" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>

            </div>
            <div fxLayout="30%">
              <div class="all-label">Todos</div>
              <mat-slide-toggle [(ngModel)]="search_toggle" (change)="applyFilter()">Alguns</mat-slide-toggle>
            </div>
          </mat-form-field>
        </div>

      </div>
      <!-- <button mat-icon-button matTooltip='FullScreen' aria-label="Excel Export" type="button" class="export-button" (click)="fullscreen()">
        <i class="fas fa-compress"></i>
      </button> -->
      <div fxFlex="25%">
        <mat-form-field class="rounded-corners">
          <input matInput (dateTimeChange)="dateChanged()" [(ngModel)]="searchDates.start"
            placeholder="{{ 'COCKPIT/date_init' | translate }}" [owlDateTimeTrigger]="pickerStart"
            [owlDateTime]="pickerStart">
          <owl-date-time #pickerStart pickerType="calendar"></owl-date-time>
        </mat-form-field>
      </div>
      <div fxFlex="25%">
        <mat-form-field class="rounded-corners">
          <input matInput (dateTimeChange)="dateChanged()" [(ngModel)]="searchDates.end"
            placeholder="{{ 'COCKPIT/date_end' | translate }}" [owlDateTimeTrigger]="pickerEnd"
            [owlDateTime]="pickerEnd">
          <owl-date-time #pickerEnd pickerType="calendar"></owl-date-time>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout fxLayout.xs="column" class="pt-3 pb-3 mt-1 mx-5 d-flex justify-content-between h-100"
      fxLayoutAlign="center" fxLayoutGap="20px">
      <div fxFlex="50%">
        <agm-map id="map" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
          <ng-container *ngFor="let pos of list">
            <agm-marker *ngIf="pos.show" [iconUrl]="pos.icon" [latitude]="pos.lat" [longitude]="pos.lng"
              (markerClick)="showMDR(pos)" (mouseOver)="onMouseOver(infoWindow,$event)" (mouseOut)="onMouseOut(infoWindow, $event)">
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div class="custom-info-window">
                    <div class="info-window-title"><b>Pickup ID:</b> {{pos.pickupID}}</div>
                    <b>Next site:</b> {{pos.site}}<br />
                    <b>Scheduled date:</b> {{pos.dateScheduling}}
                </div>
              </agm-info-window>
            </agm-marker>
          </ng-container>
        </agm-map>
      </div>
      <div fxFlex="25%" fxLayoutGap="20px">
        <div fxLayout fxLayout="column" fxLayoutGap="20px">
          <div fxFlex="50">
            <mat-card class="card-background green-background">
              <mat-card-title>
                DR em trânsito
              </mat-card-title>
              <mat-card-content>
                <div style="FONT-SIZE: 109px; z-index: 10;">{{drCount}}</div>
                <i class="fas fa-truck"
                  style="font-size: 172px; opacity: 0.2; top: 48px; position: absolute;  right: 12px;"></i>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="50">
            <mat-card class="card-background black-background">
              <mat-card-title>Média de Custo por quilo</mat-card-title>
              <mat-card-content>
                <div style="font-size: 4vw; z-index: 10;">R$ {{kiloData}}</div>
                <i class="fas fa-balance-scale"
                  style="font-size: 172px; opacity: 0.2; top: 48px; position: absolute;  right: 12px;"></i>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <div fxFlex="25%">
        <div fxLayout fxLayout="column" fxLayoutGap="20px">
          <div fxFlex="50">
            <mat-card class="card-background yellow-background">
              <mat-card-title>Ocorrência em aberto</mat-card-title>
              <mat-card-content>
                <div style="FONT-SIZE: 109px; z-index: 10;">{{occurrenceCount}}</div>
                <i class="fas fa-exclamation-circle"
                  style="font-size: 172px; opacity: 0.2; top: 48px; position: absolute;  right: 12px;"></i>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="50">
            <mat-card class="card-background grey-background" >
              <mat-card-title>MDR por transportadora</mat-card-title>
              <mat-card-content>
                <div id="chart"></div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>