import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as jwtDecode from 'jwt-decode';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
        // console.log(this.token);
    }
    AuthService.prototype.isTokenExpired = function (jwt) {
        var token = jwtDecode(jwt);
        var time = {
            now: Date.now(),
            token: token.exp * 1000
        };
        if (time.now < time.token) {
            return false;
        }
        return true;
    };
    AuthService.prototype.isAuthenticated = function () {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        // Check whether the token is expired and return
        // true or false
        if (user === null) {
            return false;
        }
        else if (user.token === null || user.token.message === 'Not a valid email or password') {
            return false;
        }
        return !this.isTokenExpired(user.token);
    };
    AuthService.prototype.ssoLogin = function (email) {
        return this.http.post(base_url + 'sso/login', { email: email });
    };
    AuthService.prototype.login = function (username, password, captchaToken) {
        var header = new HttpHeaders().set('x-api-key', api_key).set('Authorization', 'Bear');
        return this.http.post(base_url + 'JWT/', {
            user: {
                email: username,
                password: password
            },
            captcha: captchaToken
        }, { headers: header }).map(function (response) {
            if (response.user) {
                localStorage.setItem('currentUser', JSON.stringify({ user: response.user, token: response.token }));
                // console.log(JSON.parse(localStorage.getItem('currentUser')));
                if (response.token === 'Not a valid email or password') {
                    return 'cred';
                }
                else {
                    return 'ok';
                }
            }
            else {
                // console.log('Error');
                return 'cap';
            }
        });
    };
    AuthService.prototype.validateForgotPasswordToken = function (username) {
        var header = new HttpHeaders().set('x-api-key', api_key);
        return this.http.post(base_url + 'JWT/validateForgotPasswordToken', { user: { token: username } }, { headers: header })
            .map(function (response) {
            if (response) {
                return response;
            }
            else {
                return false;
            }
        });
    };
    AuthService.prototype.sendEmail = function (username, token) {
        var header = new HttpHeaders().set('x-api-key', api_key);
        return this.http.post(base_url + 'JWT/sendEmail', { user: { email: username }, captcha: token }, { headers: header })
            .map(function (response) {
            // console.log(response)
            if (response) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    AuthService.prototype.changeForgotPassword = function (username, password) {
        var header = new HttpHeaders().set('x-api-key', api_key);
        return this.http.post(base_url + 'JWT/changeForgotPassword', { user: { email: username, password: password } }, { headers: header })
            .map(function (response) {
            if (response) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    AuthService.prototype.validateForgotPasswordMobileToken = function (username) {
        var header = new HttpHeaders().set('x-api-key', api_key);
        return this.http.post(base_url + 'JWT/mobile/password/check', { user: { token: username } }, { headers: header })
            .map(function (response) {
            if (response) {
                return response;
            }
            else {
                return false;
            }
        });
    };
    AuthService.prototype.changeForgotPasswordMobile = function (username, password) {
        var header = new HttpHeaders().set('x-api-key', api_key);
        return this.http.post(base_url + 'JWT/mobile/password/reset', { user: { email: username, password: password } }, { headers: header })
            .map(function (response) {
            if (response) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    AuthService.prototype.logout = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'JWT/logout', {}, { headers: header }).map(function (response) {
            return response;
        });
    };
    AuthService.prototype.checkTokenWithoutLogin = function (token) {
        return this.http.post(base_url + 'no-login/token/check', { token: token });
    };
    AuthService.prototype.getUserActivities = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'JWT/activities', { headers: header });
    };
    AuthService.prototype.removeUser = function (email) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.delete(base_url + 'JWT/' + email, { headers: header });
    };
    return AuthService;
}());
export { AuthService };
