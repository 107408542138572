/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./occurrence-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../misc/table/table.component.ngfactory";
import * as i3 from "../../misc/table/table.component";
import * as i4 from "../../services/xlsx.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "ngx-cookie-service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/common";
import * as i10 from "./occurrence-list.component";
import * as i11 from "../../providers/dr.service";
import * as i12 from "../../providers/mdr.service";
import * as i13 from "@angular/material/snack-bar";
var styles_OccurrenceListComponent = [i0.styles];
var RenderType_OccurrenceListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OccurrenceListComponent, data: {} });
export { RenderType_OccurrenceListComponent as RenderType_OccurrenceListComponent };
function View_OccurrenceListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "colored-background table-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mx-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-table", [["downloadAs", "occurrence-list_%.xlsx"]], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(3, 770048, null, 0, i3.TableComponent, [i4.XlsxService, i5.MatDialog, i6.Router, i7.CookieService], { parent: [0, "parent"], columns: [1, "columns"], data: [2, "data"], clickCallback: [3, "clickCallback"], downloadAs: [4, "downloadAs"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.data; var currVal_3 = _co.navigateToMDR; var currVal_4 = "occurrence-list_%.xlsx"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_OccurrenceListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../assets/images/happy_face.png"], ["style", "width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["style", "color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("OCCURRENCE-LIST/table/resolved")); _ck(_v, 3, 0, currVal_0); }); }
export function View_OccurrenceListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OccurrenceListComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OccurrenceListComponent_2)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.length > 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.data.length === 0); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("OCCURRENCE-LIST")); _ck(_v, 3, 0, currVal_0); }); }
export function View_OccurrenceListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-occurrence-list", [], null, null, null, View_OccurrenceListComponent_0, RenderType_OccurrenceListComponent)), i1.ɵdid(1, 114688, null, 0, i10.OccurrenceListComponent, [i5.MatDialog, i11.DrService, i12.MdrService, i6.Router, i13.MatSnackBar, i9.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OccurrenceListComponentNgFactory = i1.ɵccf("app-occurrence-list", i10.OccurrenceListComponent, View_OccurrenceListComponent_Host_0, {}, {}, []);
export { OccurrenceListComponentNgFactory as OccurrenceListComponentNgFactory };
