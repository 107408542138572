import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
import { NfeService } from '../../providers/nfe.service';
import { ClientUserService } from '../../providers/client-user.service';
import { UploadFileService } from '../../providers/upload.service';
var NFeListComponent = /** @class */ (function () {
    function NFeListComponent(dialog, mdrService, excelService, drService, router, snackBar, nfeService, dpipe) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.excelService = excelService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.nfeService = nfeService;
        this.dpipe = dpipe;
        this.paymentList = [];
        this.data = [];
        this.listPay = [];
        this.columns = [
            { value: 'invoiceNumber', name: 'Nota Fiscal' },
            { value: 'series', name: 'Serie' },
            { value: 'client', name: 'Cliente' },
            { value: 'type', name: 'Tipo' },
            { value: 'weight', name: 'Peso' },
            { value: 'prolog', name: 'Prolog' },
            // {value: 'OV', name: 'FINANCE_LIST/table/ov'},
            // {value: 'type', name: 'FINANCE_LIST/table/type'},
            // {value: 'origem', name: 'FINANCE_LIST/table/origem'},
            { value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL' },
        ];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    }
    NFeListComponent.prototype.ngOnInit = function () {
        var _this = this;
        // console.log('HI');
        this.nfeService.getAllNfe().subscribe(function (data) {
            // console.log(data);
            var keys = data.length ? Object.keys(data[0]) : [];
            _this.data = data.map(function (k) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    if (k[keys[i]] === null || k[keys[i]] === undefined) {
                        k[keys[i]] = '';
                    }
                }
            });
            data.forEach(function (k) {
                k['currency'] = k.totalValue;
            });
            _this.data = data;
        });
    };
    NFeListComponent.prototype.insertPaymentFromFile = function (fileupload) {
        var _this = this;
        // console.log(fileupload);
        // console.log(fileupload);
        var input = fileupload.target;
        // console.log(fileupload);
        this.paymentList = [];
        for (var index = 0; index < input.files.length; index++) {
            this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                data.forEach(function (el, i, arr) {
                    var payment = {};
                    // console.log(el);
                    try {
                        payment[''] = el.pickup_address;
                        // console.log(drAux.status);
                    }
                    catch (ex) {
                        // console.log('erro ao ler arquivo xlsx ',ex);
                        _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                    }
                });
            });
        }
    };
    NFeListComponent.prototype.updateFromFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        this.excelService.importFromExcel(fileupload).subscribe(function (data) {
            // console.log(data);
            var avisos = [];
            var checkRow = function (row) {
                // status é válido
                if ('status' in row
                    && !Object.values(PAYMENT_STATUS).includes(row['status'])) {
                    avisos.push("Linha " + row.__rowNum__ + " cont\u00E9m status inv\u00E1lido");
                    return false;
                }
                // campos necessários estão presentes
                if (!('origemId' in row)
                    || !('invoice' in row)
                    || !('OV' in row)
                    || !('origem' in row)) {
                    avisos.push("Linha " + row.__rowNum__ + " n\u00E3o cont\u00E9m campos obrigat\u00F3rios [origemId,invoice,OV,origem]");
                    return false;
                }
                // linha já existe, portanto pode ser atualizada
                if (!_this.data.find(function (el) {
                    return (
                    // tslint:disable-next-line:triple-equals
                    el['origemId'] == row['origemId']
                        // tslint:disable-next-line:triple-equals
                        && el['invoice'] == row['invoice']
                        // tslint:disable-next-line:triple-equals
                        && el['OV'] == row['OV']
                        // tslint:disable-next-line:triple-equals
                        && el['origem'] == row['origem']);
                })) {
                    avisos.push("Linha " + row.__rowNum__ + " n\u00E3o existe no sistema");
                    return false;
                }
                return true;
            };
            // se todas as linhas recebidas são válidas
            if (data.every(checkRow)) {
                // atualiza as linhas
                var total = data.length;
                // console.log(total);
                _this.mdrService.updatePayment(data).subscribe(function (response) {
                    _this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
                }, function (error) {
                    if ('status' in error) {
                        _this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
                    }
                    else {
                        _this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
                    }
                });
            }
            else {
                _this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
                var _loop_1 = function (i, len) {
                    setTimeout(function () {
                        _this.snackBar.open(avisos[i], 'OK', { duration: 2000 });
                    }, 2000 * (i + 1));
                };
                // mostra avisos gerados
                for (var i = 0, len = avisos.length; i < len; i++) {
                    _loop_1(i, len);
                }
            }
        });
    };
    NFeListComponent.prototype.goToNFe = function (row, that) {
        if (row.mdrId !== 0) {
            // that.router.navigate(['logistic-order/' + row.mdrId]);
        }
        else {
            // that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
        }
        var dialogRef = this.dialog.open(EditNFeDialogComponent, {
            data: {
                role: this.role,
                nf: row,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
        });
    };
    NFeListComponent.prototype.addList = function (listPayments) {
        this.listPay = listPayments;
    };
    NFeListComponent.prototype.payNF = function () {
        var _this = this;
        this.mdrService.payNF(this.listPay).subscribe(function (response) {
            _this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
        });
    };
    NFeListComponent.prototype.remove = function () {
        var _this = this;
        var removed = 0;
        var total = this.listPay.length;
        this.listPay.forEach(function (payment) {
            _this.mdrService.removePayment(payment.id).subscribe(function (response) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
                var paymentI = _this.data.findIndex(function (e) { return e.id === payment.id; });
                if (paymentI >= 0) {
                    _this.data.splice(paymentI, 1);
                }
            }, function (error) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
            });
        });
    };
    return NFeListComponent;
}());
export { NFeListComponent };
var EditNFeDialogComponent = /** @class */ (function () {
    function EditNFeDialogComponent(dialogRef, data, drService, nfeService, snackBar, router, uploadService, clientUserService) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.drService = drService;
        this.nfeService = nfeService;
        this.snackBar = snackBar;
        this.router = router;
        this.uploadService = uploadService;
        this.clientUserService = clientUserService;
        this.uploaded = false;
        this.minDate = new Date();
        this.displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
            'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials', 'prolog'];
        this.columns = [
            { value: 'site', name: 'Site' },
            { value: 'deliveryAddress', name: 'Delivery Address' },
            { value: 'client', name: 'Cliente' },
        ];
        this.newNfList = [];
        this.clientList = [];
        this.update = true;
        this.attachment = null;
        this.imageFile = undefined;
        this.listAllDrs = [];
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
        this.nfe = data.nf;
        if (this.nfe.drId) {
            this.drService.getById(this.nfe.drId).subscribe(function (el) {
                _this.dr = el;
            });
        }
        // this.drService.getAllNfeConnections().subscribe(el => {
        // })
        this.nfeService.getHistory(this.nfe.id).subscribe(function (nf) {
            // console.log(nf);
            _this.uploadService.findByEntity(_this.nfe.invoiceNumber, 'NFe').subscribe(function (image) {
                _this.imageFile = image;
            });
            _this.listAllDrs = nf[0].drs;
            // console.log(nf);
        });
    }
    EditNFeDialogComponent.prototype.ngOnInit = function () {
        // faz uma copia da DR para edição
        // this.current = Object.assign({}, this.data.dr);
    };
    EditNFeDialogComponent.prototype.saveNFe = function () {
        var _this = this;
        // console.log(this.nfe);
        this.nfeService.update(this.nfe).subscribe(function (data) {
            _this.snackBar.open('Atualizações feitas corretamente', 'Ok', { duration: 2000 });
        });
    };
    EditNFeDialogComponent.prototype.goTo = function () {
        if (this.dr.idMDR) {
            this.router.navigate(['logistic-order/' + this.dr.idMDR]);
        }
        else {
            this.router.navigate(['delivery-optimizer']);
        }
    };
    EditNFeDialogComponent.prototype.goToProperDr = function (row, that) {
        // console.log(row, that);
        if (row.mdrId !== 0) {
            window.open('logistic-order/' + row.idMDR, '_blank').focus();
        }
        else {
            that.snackBar.open('Não há MDR associada a este item. Já verificou o Otimizador de Entregas?', 'Ok', { duration: 4000 });
        }
    };
    EditNFeDialogComponent.prototype.removeNFe = function () {
        var _this = this;
        // console.log(this.nfe);
        this.nfeService.delete(this.nfe).subscribe(function (data) {
            _this.snackBar.open('Nota removida corretamente', 'Ok', { duration: 2000 });
        });
    };
    EditNFeDialogComponent.prototype.attachFile = function (event) {
        this.snackBar.open('Nota adicionada corretamente', 'Ok', { duration: 2000 });
    };
    EditNFeDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // compara DR copiada para edição com DR anterior
        var checkChanges = function (key) {
            return (_this.nfe[key] !== _this.data.dr[key]);
        };
        // se nao houve alguma modificação
        var keys = Object.keys(this.nfe);
        if (!keys.some(checkChanges)) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        // this.drService.update(this.current).subscribe( (response) => {
        //   if ( response === true ) {
        //     this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        //     // copia valores pra DR anterior
        //     keys.forEach((key) => {
        //       this.data.dr[key] = this.current[key];
        //     });
        //     this.dialogRef.close();
        //   } else {
        //     this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
        //   }
        // });
    };
    EditNFeDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditNFeDialogComponent;
}());
export { EditNFeDialogComponent };
