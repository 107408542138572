import { OnInit } from '@angular/core';
import { MDR } from '../../../models/mdr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, TooltipPosition } from '@angular/material';
import { MdrObservable } from '../../../services/mdr.service';
import { PriceObservable } from '../../../services/price.service';
import { MdrService } from '../../../providers/mdr.service';
import { TranslateService } from '@ngx-translate/core';
import { Driver } from '../../../models/driver';
import { DrObservable } from '../../../services/dr.service';
import { SiteService } from '../../../providers/site.service';
import { OVService } from '../../../providers/ov.service';
import { DrService } from '../../../providers/dr.service';
var TransportComponent = /** @class */ (function () {
    function TransportComponent(translate, router, priceObservable, mdrService, ovService, mdrObservable, drObservable, aRoute, siteService, drService, dialog, snackBar) {
        this.translate = translate;
        this.router = router;
        this.priceObservable = priceObservable;
        this.mdrService = mdrService;
        this.ovService = ovService;
        this.mdrObservable = mdrObservable;
        this.drObservable = drObservable;
        this.aRoute = aRoute;
        this.siteService = siteService;
        this.drService = drService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.mdr = new MDR;
        this.statusContainer = false;
        this.pickupDateSchedulled = null;
        this.veiculo = [
            { id: '1', name: 'Fiorino' },
            { id: '2', name: 'Sprinter' },
            { id: '3', name: 'VUC' },
            { id: '4', name: 'Leve 3/4' },
            { id: '5', name: 'TOCO' },
            { id: '6', name: 'TRUCK' },
            { id: '7', name: 'CARRETA' }
        ];
        this.transportadora = [];
        this.drivers = [];
        this.leader = null;
        this.tooltip = {
            maxH: '0',
            maxL: '0',
            maxW: '0',
            wei: '0',
            vol: '0',
        };
        this.showVehicleData = false;
        this.positionOptions = 'after';
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
    }
    TransportComponent.prototype.ngOnInit = function () {
        var _this = this;
        var unique = function (value, index, self) {
            return self.indexOf(value) === index;
        };
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            _this.siteService.getShippingCompanys().subscribe(function (shippingCompanies) {
                _this.transportadora = shippingCompanies;
                // this is definitely the lazy way
                _this.mdrService.getAllPriceR(_this.mdr).subscribe(function (data) {
                    var _loop_1 = function (transp) {
                        var transpFreight = data.find(function (v) { return v.sc === transp.name; });
                        transp.freightValue = transpFreight ? transpFreight.total : Infinity;
                    };
                    for (var _i = 0, _a = _this.transportadora; _i < _a.length; _i++) {
                        var transp = _a[_i];
                        _loop_1(transp);
                    }
                    _this.transportadora.sort(function (a, b) { return a.freightValue - b.freightValue; });
                });
            });
            _this.drService.getByMdr(_this.mdr.id).subscribe(function (drs) {
                _this.drService.getHUs(drs.map(function (dr) { return dr.id; })).subscribe(function (data) {
                    _this.HUs = data && data.HUs ? data.HUs : [];
                });
            });
            _this.ovService.getNfByMdr(_this.mdr.id).subscribe(function (data) {
                var ovs = data.map(function (e) { return e.ov; }).filter(unique);
                // Sets the vehicle to the best available acording to the number of OVs
                _this.ovService.getVehicle(ovs).subscribe(function (response) {
                    _this.tooltip.wei = (response.searched.weight).toFixed(2);
                    _this.tooltip.maxH = (response.searched.height / 10).toFixed(3);
                    _this.tooltip.maxW = (response.searched.width / 10).toFixed(3);
                    _this.tooltip.maxL = (response.searched.length / 10).toFixed(3);
                    _this.tooltip.vol = (response.searched.volume / 100).toFixed(2);
                    if ((_this.mdr.status === 'MDR em criação' || _this.mdr.status === 'Nova MDR') && !_this.mdr.vehicle) {
                        _this.mdr.vehicle = (response.vehicle);
                        _this.mdrService.getAllPrice(_this.mdr).subscribe(function (resp) {
                            _this.priceObservable.changePrice(resp);
                        });
                    }
                });
            });
            var date = new Date(_this.mdr.pickupDate);
            if (_this.mdr.pickupDate === null) {
                _this.time = '00:00';
            }
            else {
                _this.time = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
            }
            _this.getDriver();
        });
    };
    TransportComponent.prototype.getTimeList = function () {
        var _this = this;
        console.log('Clicked change Time ');
        this.mdrService.scheduleTimeList(this.mdr.pickupDate).subscribe(function (resp) {
            _this.pickupDateSchedulled = resp;
        });
    };
    TransportComponent.prototype.updateShippingCompany = function (elem) {
        var _this = this;
        this.mdr.shippingCompany = elem.name;
        this.mdrService.getAllPrice(this.mdr).subscribe(function (resp) {
            _this.priceObservable.changePrice(resp);
        });
    };
    TransportComponent.prototype.changeTime = function () {
        var time = this.time.split(':');
        this.mdr.pickupDate = new Date(this.mdr.pickupDate);
        this.mdr.pickupDate.setHours(time[0]);
        this.mdr.pickupDate.setMinutes((time[1]));
    };
    TransportComponent.prototype.updateVehicle = function (elem) {
        var _this = this;
        this.mdr.vehicle = elem.name;
        this.mdrService.getAllPrice(this.mdr).subscribe(function (resp) {
            _this.priceObservable.changePrice(resp);
        });
    };
    TransportComponent.prototype.goToChat = function () {
        this.router.navigate(['chat/' + this.mdr.id + '-driver']);
    };
    TransportComponent.prototype.getVehicleSizes = function () {
        // End Point para pega valores que seta tamanho do veículo
    };
    TransportComponent.prototype.getDriver = function () {
        var _this = this;
        this.mdrService.getDriverByMdrIdAll(this.mdr.id).subscribe(function (response) {
            _this.drivers = response;
            var index = _this.drivers.map(function (driver, i) { return [i, driver]; }).filter(function (driver) { return driver[1]['leader'] === true; });
            if (index.length) {
                _this.leader = index[0][0];
            }
        });
    };
    TransportComponent.prototype.saveDriver = function () {
        var _this = this;
        // se algum dos motoristas estiver incompleto
        if (this.drivers.some(function (el, i, arr) {
            if (el.name === null
                || el.phoneNumber === null
                || el.plate === null) {
                return true;
            }
        })) {
            this.snackBar.open('Favor preencher os detalhes do motorista', 'Ok', { duration: 4000 });
            return;
        }
        this.drivers = this.drivers.map(function (driver) { driver.leader = false; return driver; });
        var driversCompare = this.drivers.filter(function (driver) { return driver.status === true; }).length;
        if (driversCompare >= 2) {
            this.drivers[this.leader].leader = true;
        }
        this.mdrService.updateDriver(this.drivers, this.mdr.id).subscribe(function (response) {
            _this.snackBar.open('Alterações Salvas. Recarregar a página ', 'Ok', { duration: 4000 });
            _this.getDriver();
        });
    };
    TransportComponent.prototype.saveConveyor = function () {
        this.mdrService.update(this.mdr, this.user).subscribe(function (response) {
        });
    };
    TransportComponent.prototype.addDriver = function () {
        var newDriver = new Driver();
        newDriver.phoneNumber = parseInt(this.mdr.id + '' + this.drivers.length, 10);
        this.drivers.push(newDriver);
        var index = this.drivers.map(function (driver, i) { return [i, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
        if (index.length === 2) {
            this.leader = index[0][0];
        }
    };
    TransportComponent.prototype.removerDriver = function (i) {
        this.drivers[i].status = false;
        if (i === this.leader) {
            var index = this.drivers.map(function (driver, idx) { return [idx, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
            if (index.length === 1) {
                this.leader = null;
            }
            else {
                this.leader = index[0][0];
            }
        }
        else {
            var index = this.drivers.map(function (driver, idx) { return [idx, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
            if (index.length === 1) {
                this.leader = null;
            }
        }
    };
    TransportComponent.prototype.getVehicle = function () {
        var _this = this;
        var unique = function (value, index, self) {
            return self.indexOf(value) === index;
        };
        this.ovService.getNfByMdr(this.mdr.id).subscribe(function (responseOvs) {
            if (responseOvs) {
                _this.ovService.getVehicle(responseOvs.map(function (e) { return e.ov; }).filter(unique)).subscribe(function (responseVehicle) {
                    if (responseVehicle) {
                        _this.mdr.vehicle = responseVehicle.vehicle;
                        _this.mdrService.update(_this.mdr, _this.currentUser.user.id).subscribe(function (data) {
                            _this.snackBar.open('Veículo recalculado com sucesso', 'Ok', { duration: 4000 });
                        }, function (error) {
                            _this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
                        });
                    }
                }, function (error) {
                    _this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
                });
            }
        }, function (error) {
            _this.snackBar.open('Não foi possível recalcular o veículo', 'Ok', { duration: 4000 });
        });
    };
    return TransportComponent;
}());
export { TransportComponent };
