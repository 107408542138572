import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
import { MDR_STATUS } from '../../models/mdr';
var MdrManagementComponent = /** @class */ (function () {
    function MdrManagementComponent(dialog, mdrService, router) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.router = router;
        this.columns = [
            { value: 'observation', name: 'MDR_MANAGEMENT/delivery-order' },
            { value: 'status', name: 'status' },
            { value: 'createBy', name: 'MDR_MANAGEMENT/table/userName' },
            { value: 'createdAt', name: 'MDR_MANAGEMENT/table/date' }
        ];
        this.data = [];
        this.mdrStatus = ['MDR em validação', 'MDR em criação', 'Nova MDR'];
    }
    MdrManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getAllMDR().subscribe(function (data) {
            _this.data = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação' || data[i].status === MDR_STATUS.NOVA_MDR) {
                    data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('EDB-TP' + ('0000000' + data[i].id).slice(-6)));
                    _this.data.push(data[i]);
                }
            }
        });
    };
    MdrManagementComponent.prototype.goTo = function (row, that) {
        that.router.navigate(['logistic-order', row.id]);
    };
    return MdrManagementComponent;
}());
export { MdrManagementComponent };
