import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent,
  HttpInterceptor, HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';

const base_url: string = environment.apiUrl;
const dontShowLoader = ['driver/GetLastPositions', 'DR/All'];

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(public spinnerService: Ng4LoadingSpinnerService) { }

  canShowLoader(request: HttpRequest<any>) {
    let counter = 0;
    dontShowLoader.forEach((data) => {
      if (request.url.indexOf(data) === -1) {
        counter++;
      }
    });
    if (counter === dontShowLoader.length) {
      return true;
    }
    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // mostra spinner
    if (this.canShowLoader(request)) {
      this.spinnerService.show();
    }
    // console.log('Hello');

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Response) {
            // oculta spinner
            this.spinnerService.hide();
          }
        },
        (err: any) => {
          // oculta spinner
          this.spinnerService.hide();
        }
      )
    );
  }
}
