import { Subject } from 'rxjs/Subject';
var SideNavService = /** @class */ (function () {
    function SideNavService() {
        // Observable string sources
        this.missionAnnouncedSource = new Subject();
        this.missionConfirmedSource = new Subject();
        // Observable string streams
        this.missionAnnounced$ = this.missionAnnouncedSource.asObservable();
        this.missionConfirmed$ = this.missionConfirmedSource.asObservable();
    }
    // Service message commands
    SideNavService.prototype.announceMission = function (mission) {
        this.missionAnnouncedSource.next(mission);
    };
    SideNavService.prototype.confirmMission = function (astronaut) {
        this.missionConfirmedSource.next(astronaut);
    };
    return SideNavService;
}());
export { SideNavService };
