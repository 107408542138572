import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TowerControlRoutingModule } from './tower-control-routing.module';

import {TranslateModule} from '@ngx-translate/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { TableModule } from '../../misc/table/table.module';

import {PipesModule} from '../../misc/pipes/pipes.module';
import {AgmCoreModule, MarkerManager} from '@agm/core';

import { TowerControlComponent } from './tower-control.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    MatChipsModule,
    MatCardModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSlideToggleModule,
    PipesModule,
    TableModule,
    AgmCoreModule,
    TowerControlRoutingModule,
  ],
  declarations: [
    TowerControlComponent,
  ],
  entryComponents: [
  ],
  exports: []
})
export class TowerControlModule { }
