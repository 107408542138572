<div class="mx-5 my-4">
   <p class="mat-h1">{{ 'SITE_INVESTIGATION' | translate }}</p>
   <p class="mx-4">{{ 'SITE_INVESTIGATION/txt1' | translate }}<br>{{ 'SITE_INVESTIGATION/txt2' | translate }}</p>
   <button *ngIf="role === 1" mat-raised-button
      (click)="addItem()">{{ 'SITE_INVESTIGATION/btn/new' | translate }}</button>
   <!-- <button mat-raised-button (click)="createToken()">{{ 'SITE_INVESTIGATION/btn/token' | translate }}</button> -->
   <input class="hidden" id="input-file-id" #fileInput accept='xlsx' (change)='openFile($event)' class="none" multiple
      type="file" />
   <button mat-raised-button style="width: 200px; margin: 0px 20px;" class="primary-button text-white"
      (click)="fileInput.click()">{{ 'SITE_INVESTIGATION/insert_multiple' | translate }}</button>
   <button mat-raised-button class ="primary-button text-white" matTooltip="Gerar Nova requisição Para Laudo Aware" (click)="generateNewSiteToken()">{{ 'SITE_INVESTIGATION/btn/tokenNew' | translate }}</button>
</div>

<div *ngIf="data.length > 0" class="colored-background table-background px-5 py-2">
  <app-table
    [columns]="columns"
    [data]="data"
    [this]="this"
    [styleCallback]="rowStyle"
    [clickCallback]="onRowClick"
    [dateSearch]="['visitadatahora','vistoriadatahora','createdAt']"
    downloadAs="site-investigation_%.xlsx"
  ></app-table>
</div>

<div *ngIf="data.length === 0"
   style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
   <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;"
      src="../../../assets/images/happy_face.png">
   <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;">
      {{ 'SITE_INVESTIGATION/empty' | translate }}</p>
</div>
