import { OnInit } from '@angular/core';
import { OVService } from '../../providers/ov.service';
import { ReportService } from '../../providers/report.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
var OvsearchComponent = /** @class */ (function () {
    function OvsearchComponent(aRoute, ovService, reportService, excel, router) {
        var _this = this;
        this.aRoute = aRoute;
        this.ovService = ovService;
        this.reportService = reportService;
        this.excel = excel;
        this.router = router;
        this.aRoute.params.subscribe(function (params) {
            _this.ovService.searchByOV(params.input).subscribe(function (data) {
                _this.data = data;
            });
        });
    }
    OvsearchComponent.prototype.ngOnInit = function () {
    };
    OvsearchComponent.prototype.gotoMDR = function (el) {
        this.router.navigate(['logistic-order/' + el.idMDR]);
    };
    OvsearchComponent.prototype.downloadReport = function () {
        var _this = this;
        var drs = [];
        for (var _i = 0, _a = this.data; _i < _a.length; _i++) {
            var result = _a[_i];
            for (var _b = 0, _c = result.mdrs; _b < _c.length; _b++) {
                var dr = _c[_b];
                drs.push(dr.id);
            }
            for (var _d = 0, _e = result.nfs; _d < _e.length; _d++) {
                var dr = _e[_d];
                drs.push(dr.id);
            }
            for (var _f = 0, _g = result.ovs; _f < _g.length; _f++) {
                var dr = _g[_f];
                drs.push(dr.id);
            }
            for (var _h = 0, _j = result.sites; _h < _j.length; _h++) {
                var dr = _j[_h];
                drs.push(dr.id);
            }
        }
        drs = drs.filter(function (elem, index, self) { return index === self.indexOf(elem); });
        this.reportService.getStakeholdersByDR(drs).subscribe(function (data) {
            var worksheet = XLSX.utils.json_to_sheet(data, {
                skipHeader: true
            });
            var workbook = { Sheets: { 'stake': worksheet }, SheetNames: ['stake'] };
            var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            _this.excel.saveAsExcelFile(excelBuffer, 'stake');
        });
    };
    return OvsearchComponent;
}());
export { OvsearchComponent };
