import { Component, OnInit } from '@angular/core';
import {Transportadora, Veiculo} from '../logistic-order/transport/transport.component';
import {AuthService} from '../../providers/auth.service';
import {PriceMakerService} from '../../providers/price-maker.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import { SiteService } from '../../providers/site.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { MdrService } from '../../providers/mdr.service';

@Component({
  selector: 'app-price-maker',
  templateUrl: './price-maker.component.html',
  styleUrls: ['./price-maker.component.scss']
})
export class PriceMakerComponent implements OnInit {
  public price = {};
  public total = '-1';
  public update = true;
  public deliveryRegion = '';
  public pickupRegion = '';
  public uocList = [];
  public airFreightDestinations = [];
  public type;
  public uocSelected;
  transportadora: any[] = [];
  typeList: Veiculo[] = [
    {id: '1', name: 'Frete' },
    {id: '2', name: 'UOC'},
    {id: '3', name: 'Frete Aereo'}];

veiculo: Veiculo[] = [
    {id: '1', name: 'Fiorino' },
    {id: '2', name: 'Sprinter'},
    {id: '3', name: 'VUC'},
    {id: '4', name: 'Leve 3/4'},
    {id: '5', name: 'TOCO'},
    {id: '6', name: 'TRUCK'},
    {id: '7', name: 'CARRETA'}];
  state = [
    {id: 'AC', name: 'AC'},
    {id: 'AL', name: 'AL'},
    {id: 'AP', name: 'AP'},
    {id: 'AM', name: 'AM'},
    {id: 'BA', name: 'BA'},
    {id: 'CE', name: 'CE'},
    {id: 'DF', name: 'DF'},
    {id: 'ES', name: 'ES'},
    {id: 'GO', name: 'GO'},
    {id: 'MA', name: 'MA'},
    {id: 'MT', name: 'MT'},
    {id: 'MS', name: 'MS'},
    {id: 'MG', name: 'MG'},
    {id: 'PA', name: 'PA'},
    {id: 'PB', name: 'PB'},
    {id: 'PR', name: 'PR'},
    {id: 'PE', name: 'PE'},
    {id: 'PI', name: 'PI'},
    {id: 'RJ', name: 'RJ'},
    {id: 'RN', name: 'RN'},
    {id: 'RS', name: 'RS'},
    {id: 'RO', name: 'RO'},
    {id: 'RR', name: 'RR'},
    {id: 'SC', name: 'SC'},
    {id: 'SP', name: 'SP'},
    {id: 'SE', name: 'SE'},
    {id: 'TO', name: 'TO'}];
  subRegion = [
    {id: 'Capital', name: 'Capital' },
    {id: 'Interior', name: 'Interior'},
  ];

  columns = [
    {value: 'shipC', name: 'LSP'},
    {value: 'price', name: 'Valor do Frete', currency: 'BRL'},
    {value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL'},
    {value: 'adValorem', name: 'adValorem', currency: 'BRL'},
    {value: 'addDelivery', name: 'Adicional de Entrega', currency: 'BRL'},
    {value: 'value', name: 'Valor Total', currency: 'BRL'}
  ];

  columnsAir
    = [
    {value: 'shipC', name: 'LSP'},
    {value: 'price', name: 'Valor do Frete Aereo', currency: 'BRL'},
    {value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL'},
    {value: 'adValorem', name: 'adValorem', currency: 'BRL'},
    {value: 'value', name: 'Valor Total', currency: 'BRL'}
  ];

  columnsUOC = [
    {value: 'shipC', name: 'LSP'},
    {value: 'price', name: 'Valor UOC', currency: 'BRL'},
    {value: 'taxedPrice', name: 'Valor com taxas', currency: 'BRL'},
  ];
  data: any[];

  constructor(public priceMaker: PriceMakerService,
    public spinnerService: Ng4LoadingSpinnerService,
    public siteService: SiteService,
    public uocService: MdrService ) {
    this.price['drAmount'] = 0;
    this.uocService.getListUOC().subscribe(elem => {
      this.uocList = elem;
    });
    this.siteService.getShippingCompanys().subscribe((data) => {
      this.transportadora = data.filter(transp => transp.name !== 'A RETIRAR');
    });
    this.priceMaker.getAirFreightDestinations().subscribe( data => {
      this.airFreightDestinations = data;
    });
  }

  ngOnInit() {
  }

  sanitizeInputPrice(field: string, value: any, tag: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(/ /g, '');
    input = input.replace(/\./g, '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.price[field] = input;
    this.update = true;
  }
  getPrice() {
    if (this.type === '1') {
      const vehicle = this.price['vehicle'];
      const distance = this.price['totalDistance'];
      const fromEstate = this.price['deliveryRegion'];
      const toEstate = this.price['pickupRegion'];
      const subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
      const value = this.price['freight'];
  //    const shippingCompany = this.price['shippingCompany'];
      const drAmount = this.price['drAmount'];
      this.spinnerService.show();
      this.priceMaker.getPriceTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount)
          .subscribe(result => {
            this.data = result.filter(transp => transp.shipC !== 'A RETIRAR');
            this.spinnerService.hide();
            // this.total=result.total;
          });
    } else if (this.type ===  '2') {
      const vehicle = this.price['vehicle'];
      const distance = this.price['totalDistance'];
      const fromEstate = this.price['deliveryRegion'];
      const toEstate = this.price['pickupRegion'];
      const subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
      const value = this.price['freight'];
  //    const shippingCompany = this.price['shippingCompany'];
      const drAmount = this.price['drAmount'];
      this.spinnerService.show();
      this.priceMaker.getUOCTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount, this.uocSelected)
          .subscribe(result => {
            this.data = result.filter(transp => transp.shipC !== 'A RETIRAR');
            this.spinnerService.hide();
            // this.total=result.total;
          });

    }  else if (this.type ===  '3') {
      const fromEstate = this.price['deliveryRegion'];
      const toEstate = this.price['pickupRegion'];
      const deliveryDestination = this.price['deliveryDestination'];
      const value = this.price['freight'];
      const weight = this.price['weight'];
      const height = this.price['height'];
      const width = this.price['width'];
      const depth = this.price['depth'];
      this.spinnerService.show();
      this.priceMaker.getAirPriceTable( fromEstate, toEstate, deliveryDestination, height, width, depth, weight, value)
        .subscribe(result => {
          this.data = result;
          this.spinnerService.hide();
        });

    } else {
      // console.log('escolha um Tipo valido');
    }

  }
  getTotal() {
    // console.log(this.price['deliveryRegion'])
    if (typeof this.price['deliveryRegion'] !== 'undefined') {
      this.deliveryRegion = this.price['deliveryRegion'];
    }
    if (typeof this.price['pickupRegion'] !== 'undefined') {
      this.pickupRegion = this.price['pickupRegion'];
    }
    // console.log( this.deliveryRegion)

    this.total = '-1';
  }

}
