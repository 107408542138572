import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var ELEMENT_DATA = [
    { position: 1, name: 'Henrique Fischer', role: 'Role1', symbol: 'H' },
    { position: 2, name: 'Felipe Fontes', role: 'Role1', symbol: 'He' },
    { position: 3, name: 'Maria Antónia', role: 'Role4', symbol: 'Li' },
    { position: 4, name: 'Mario Douglas', role: 'Role1', symbol: 'Be' },
    { position: 5, name: 'Roberto Rodrigues', role: 'Role2', symbol: 'B' },
    { position: 6, name: 'Lucas Bentes', role: 'Role3', symbol: 'C' },
    { position: 7, name: 'Sérgio Nascimento', role: 'Role1', symbol: 'N' },
];
var ShippingCompanyAdminComponent = /** @class */ (function () {
    function ShippingCompanyAdminComponent() {
        this.displayedColumns = ['position', 'name', 'role', 'actions'];
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    }
    ShippingCompanyAdminComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    };
    ShippingCompanyAdminComponent.prototype.ngOnInit = function () {
    };
    return ShippingCompanyAdminComponent;
}());
export { ShippingCompanyAdminComponent };
