import { AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DR } from '../../../models/dr';
import { MatSnackBar } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import { UploadFileService } from '../../../providers/upload.service';
import { MatDialog, MatDialogRef } from '@angular/material';
var DrValidationComponent = /** @class */ (function () {
    function DrValidationComponent(router, aRoute, snackBar, mapsAPILoader, drService, uploadService, dialog) {
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.uploadService = uploadService;
        this.dialog = dialog;
        this.dr = new DR();
        this.uploadedFiles = null;
        // pro icon de salesOrders
        this.status = null;
        this.icons = {
            'status': '',
            'tooltips': {
                'invalid': 'OVs inválidas',
                'incomplete': 'NFs não existentes',
                'valid': 'DR Booking válido',
                'undefined': ''
            },
            'icons': {
                'invalid': 'fa-exclamation-circle',
                'incomplete': 'fa-exclamation-triangle',
                'valid': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'invalid': 'icon-red',
                'incomplete': 'icon-yellow',
                'valid': 'icon-green',
                'undefined': ''
            }
        };
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
    }
    DrValidationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.id = params.id;
            _this.drService.getBooking(_this.id).subscribe(function (response) {
                _this.dr = response;
                _this.salesOrders = _this.dr.salesOrders.join(',');
                _this.receiveFile(null);
            });
            // this.validateDR();
        });
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    DrValidationComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.validateDR = function () {
        var _this = this;
        this.drService.getValidation(this.id).subscribe(function (response) {
            _this.dr = response.dr;
            _this.salesOrders = _this.dr.salesOrders.join(',');
            _this.icons.status = response.status;
            _this.status = {
                status: response.status,
                error: response.error,
                warning: response.warning
            };
            // if ( response.status === 'valid' ) {
            //   this.approveDR();
            // } else {
            //   this.openDialog();
            // }
            _this.openDialog();
        }, function (err) {
            _this.snackBar.open('Erro ao buscar DR Booking', 'Ok', { duration: 4000 });
            _this.router.navigate(['cockpit']);
        });
    };
    DrValidationComponent.prototype.save = function () {
        var _this = this;
        if (this.dr.dateScheduling.getTime() - new Date(this.dr.createdAt).getTime() < 172800000) {
            this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', { duration: 4000 });
            return;
        }
        this.drService.updateBooking(this.id, this.dr).subscribe(function (response) {
            _this.snackBar.open('Atualizado o Booking Corretamente', 'Ok', { duration: 4000 });
        });
    };
    DrValidationComponent.prototype.saveAndValidate = function () {
        var _this = this;
        if (this.dr.dateScheduling.getTime() - new Date(this.dr.createdAt).getTime() < 172800000) {
            this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', { duration: 4000 });
            return;
        }
        this.dr.salesOrders = this.salesOrders
            .replace(/ /g, ',') // troca espaços por vírgulas
            .split(',') // quebra em array nas vírgulas
            .filter(function (e) { return e.length; }); // remove strings vazias
        this.drService.updateBooking(this.id, this.dr).subscribe(function (response) {
            _this.validateDR();
        }, function (err) {
            _this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrValidationComponent.prototype.reassessment = function () {
        var _this = this;
        this.dr.salesOrders = this.salesOrders
            .replace(/ /g, ',') // troca espaços por vírgulas
            .split(',') // quebra em array nas vírgulas
            .filter(function (e) { return e.length; }); // remove strings vazias
        // update status
        this.dr.status = 'BOOKING';
        // update booking
        this.drService.updateBooking(this.id, this.dr).subscribe(function (response) {
            _this.notifyStatusUpdate('REASSIGNED');
            _this.snackBar.open('Booking enviada para reavaliação', 'Ok', { duration: 2000 });
        }, function (err) {
            _this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrValidationComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDrValidationComponent, {
            data: {
                status: this.status
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'approve') {
                _this.approveDR(result.notify);
            }
        });
    };
    DrValidationComponent.prototype.approveDR = function (notify) {
        var _this = this;
        if (notify === void 0) { notify = false; }
        this.drService.updateBookingToDR(this.id).subscribe(function (response) {
            if (notify) {
                _this.notifyStatusUpdate('VALIDATED');
            }
            _this.router.navigate(['cockpit']);
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrValidationComponent.prototype.receiveFile = function (event) {
        var _this = this;
        this.drService.getFilesFromDrManually(this.dr.id).subscribe(function (response) {
            _this.uploadedFiles = response;
        });
    };
    DrValidationComponent.prototype.removeFile = function (file, entity) {
        var _this = this;
        this.uploadService.deleteByEntity(file['id'], entity).subscribe(function (response) {
            _this.uploadedFiles = _this.uploadedFiles.filter(function (f) { return f.id !== file.id; });
        });
    };
    DrValidationComponent.prototype.openReasonDialog = function (action) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDrDeleteComponent, {
            width: '600px',
            height: '200px',
            data: { action: action }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.confirm) {
                // relate colected reason
                _this.dr.deleteReason = result.reason;
                // set booking as pending
                if (action === 'pending') {
                    _this.dr.status = 'BOOKING_PENDING';
                }
                if (_this.dr.dateScheduling.getTime() - new Date(_this.dr.createdAt).getTime() < 172800000) {
                    _this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', { duration: 4000 });
                    return;
                }
                // update booking
                _this.drService.updateBooking(_this.id, _this.dr).subscribe(function (response) {
                    if (action === 'delete') {
                        _this.reproveDR();
                    }
                    else {
                        _this.notifyStatusUpdate('INVALIDATED', _this.dr.deleteReason);
                        _this.snackBar.open('Booking invalidada com sucesso', 'Ok', { duration: 2000 });
                        _this.router.navigate(['cockpit']);
                    }
                }, function (err) {
                    _this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', { duration: 2000 });
                });
            }
        });
    };
    DrValidationComponent.prototype.reproveDR = function () {
        var _this = this;
        this.drService.reproveBooking(this.id).subscribe(function (elem) {
            _this.notifyStatusUpdate('DELETED', _this.dr.deleteReason);
            _this.snackBar.open('Booking Removido com sucesso', 'Ok', { duration: 2000 });
            _this.router.navigate(['cockpit']);
        });
    };
    DrValidationComponent.prototype.notifyStatusUpdate = function (status, actionJustification) {
        var _this = this;
        if (actionJustification === void 0) { actionJustification = null; }
        this.drService.notifyStatusUpdate(this.id, status, this.dr.createdBy, actionJustification).subscribe(function () {
        }, function (err) {
            _this.snackBar.open('Erro ao enviar email de notificação', 'Ok', { duration: 2000 });
        });
    };
    return DrValidationComponent;
}());
export { DrValidationComponent };
var DialogDrValidationComponent = /** @class */ (function () {
    function DialogDrValidationComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.notify = true;
    }
    DialogDrValidationComponent.prototype.onClickOk = function () {
        this.dialogRef.close({ what: 'approve', notify: this.notify });
    };
    DialogDrValidationComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    return DialogDrValidationComponent;
}());
export { DialogDrValidationComponent };
var DialogDrDeleteComponent = /** @class */ (function () {
    function DialogDrDeleteComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.notify = true;
    }
    DialogDrDeleteComponent.prototype.onClickConfirm = function () {
        this.dialogRef.close({ confirm: true, reason: this.reason, notify: this.notify });
    };
    DialogDrDeleteComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ confirm: false });
    };
    return DialogDrDeleteComponent;
}());
export { DialogDrDeleteComponent };
