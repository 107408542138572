var UOCMDR = /** @class */ (function () {
    function UOCMDR() {
        this.id = null;
        this.uoc = '';
        this.idMDR = null;
        this.value = null;
        this.observation = '';
        this.isCopq = null;
        this.drList = [];
        this.needsAttachment = false;
        this.idOccurrence = null;
    }
    return UOCMDR;
}());
export { UOCMDR };
