<div class="mx-5 my-4">
  <p class="mat-h1">{{'USER_ACTIVITY/title'|translate}}</p>

</div>

<div class="colored-background table-background px-5 py-2">

  <!-- table with users' last activities -->
  <app-table
    [columns]="columns"
    [data]="data"
    [this]="this"
    [dateSearch]="['lastLogin','lastPasswordReset','deletedOn']"
    [clickCallback]="removeUser"
  ></app-table>

</div>
