<div class="mx-5 my-4">
  <span class="mat-h1">{{ 'DR_OPTIMIZER/title' | translate }}</span>
</div>

<div class="colored-background table-background">

    <app-table
      [columns]="columns"
      [data]="data"
      showCheckbox='true'
      (check)="onSelection($event)"
    ></app-table>

    <div class="d-flex justify-content-between m-3 pb-3">
      <button class="primary-button" mat-raised-button (click)="openDialog()">{{ 'DR_OPTIMIZER/create' | translate }}</button>
      <!-- <button class="primary-button" mat-raised-button (click)="createConsolidationMDR()">Criar consolidação</button> -->
      <button class="warn-button" mat-raised-button (click)="unmakeDR()">{{ 'DR_OPTIMIZER/undo' | translate }}</button>
    </div>

</div>

<div *ngIf="data.length == 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'DR_OPTIMIZER/empty' | translate }}</p>
</div>
