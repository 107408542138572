var Pin = /** @class */ (function () {
    function Pin() {
        this.lat = null;
        this.lng = null;
        this.label = null;
        this.title = null;
    }
    return Pin;
}());
export { Pin };
var Legs = /** @class */ (function () {
    function Legs() {
        this.id = null;
        this.pointInit = new Pin();
        this.pointEnd = new Pin();
        this.type = null;
        this.createdAt = null;
        this.distance = null;
        this.ETA = null;
        this.status = 1;
        this.order = null;
    }
    return Legs;
}());
export { Legs };
