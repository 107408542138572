/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wordcloud-chart.component.scss.shim.ngstyle";
import * as i1 from "../graph-card.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./wordcloud-chart.component";
import * as i4 from "@ngx-translate/core";
var styles_WordcloudChartComponent = [i0.styles, i1.styles];
var RenderType_WordcloudChartComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_WordcloudChartComponent, data: {} });
export { RenderType_WordcloudChartComponent as RenderType_WordcloudChartComponent };
export function View_WordcloudChartComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵinlineInterpolate(1, "", _co.container, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_WordcloudChartComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-wordcloud-chart", [], null, null, null, View_WordcloudChartComponent_0, RenderType_WordcloudChartComponent)), i2.ɵdid(1, 114688, null, 0, i3.WordcloudChartComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WordcloudChartComponentNgFactory = i2.ɵccf("app-wordcloud-chart", i3.WordcloudChartComponent, View_WordcloudChartComponent_Host_0, { container: "container" }, {}, []);
export { WordcloudChartComponentNgFactory as WordcloudChartComponentNgFactory };
