var COPQMDR = /** @class */ (function () {
    function COPQMDR() {
        this.id = null;
        this.copq = '';
        this.idMDR = null;
        this.value = null;
        this.observation = '';
    }
    return COPQMDR;
}());
export { COPQMDR };
