var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from '@angular/core';
import { MdrService } from '../../../providers/mdr.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { DrService } from '../../../providers/dr.service';
import { HttpClient } from '@angular/common/http';
import { DrObservable } from '../../../services/dr.service';
import { MdrObservable } from '../../../services/mdr.service';
import { Legs, Pin } from '../../../models/legs';
import { MDR } from '../../../models/mdr';
import { of, from } from 'rxjs';
import { delay, concatMap, take } from 'rxjs/operators';
var GeolocationComponent = /** @class */ (function () {
    function GeolocationComponent(drService, mdrService, aRoute, http, drObservable, mdrObservable, snackBar) {
        this.drService = drService;
        this.mdrService = mdrService;
        this.aRoute = aRoute;
        this.http = http;
        this.drObservable = drObservable;
        this.mdrObservable = mdrObservable;
        this.snackBar = snackBar;
        this.distTotalUpdateEvent = new EventEmitter();
        this.lat = -23.152493;
        this.lng = -45.7906075;
        this.latNew = -23.152493;
        this.lngNew = -45.7906075;
        this.distanceTotal = '0';
        this.distanceByLeg = [];
        this.legsWithDistances = false;
        this.i = 0;
        this.typeTransp = [{ name: 'Rodoviário', value: 0 }, { name: 'Balsa', value: 1 }, { name: 'Transporte Aéreo', value: 2 }];
        this.renderOptions = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#AAA', zIndex: 1 },
        };
        this.renderOptionsDriver = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#00f', zIndex: 2 },
        };
        this.show = true;
        this.waypoints = [];
        // when OnDestroy is called.
        this.data = [];
        this.legsSource = [];
        this.legsNewLegs = [];
        this.legsNewLegsDelayed = [];
        this.directions = null;
        this.markers = [];
        this.markersDriver = [];
        this.showMarkers = false;
        this.legsToDirection = [];
        this.dataSource = new MatTableDataSource();
        this.dataSourcePin = new MatTableDataSource();
        this.displayedColumns = ['pointStart', 'pointEnd', 'type', 'remove'];
        this.displayedColumnsPin = ['lat', 'lng', 'label'];
        this.haveDriver = false;
        this.showMap = true;
        this.currentUser = null;
        this.role = null;
        this.icon = {
            url: './assets/images/red_truck.png',
            scaledSize: {
                height: 40,
                width: 40
            },
        };
        // relativos aos pontos do tracking
        this.colorPrefix = 'COLOR/';
        this.coloredPoint = [
            { scaledSize: { height: 5, width: 5 }, color: 'red', url: './assets/images/red_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'blue', url: './assets/images/blue_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'green', url: './assets/images/green_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'pink', url: './assets/images/pink_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'purple', url: './assets/images/purple_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'orange', url: './assets/images/orange_point.png' }
        ];
        this.pickupAddress = 'R. Ambrósio Molina, 1090 - Eugênio de Melo, São José dos Campos - SP, 12247-000';
        // mdr.status para os quais considera-se que mdr foi finalizada
        this.finishedDriveStatus = ['Carga entregue com sucesso',
            'Carga retornada total',
            'Entrega de carga certificada pelo LSP',
            'MDR paga / concluída'
        ];
        // colunas para mostrar posições dos motoristas
        this.driverPositionTableColumns = [
            { value: 'createdAt', name: 'GEOLOCATION/table/time' },
            { value: 'lat', name: 'GEOLOCATION/table/lat' },
            { value: 'lng', name: 'GEOLOCATION/table/lng' },
            { value: 'speed', name: 'GEOLOCATION/table/speed' },
            { value: 'phoneNumber', name: 'GEOLOCATION/table/phone' },
            { value: 'color', name: 'GEOLOCATION/table/color' }
        ];
        this.markersTruck = [];
        this.legInTransitIndex = -1;
        this.showDriverInMap = false;
        this.alive = true;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
    }
    GeolocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drService.getByMdr(this.mdr.id).subscribe(function (drs) {
            _this.drs = drs;
            _this.getLegsDelete();
            _this.getDriverLastPosition();
            _this.getDriverPosition();
        });
    };
    GeolocationComponent.prototype.onResponse = function (event) {
        if (event && event.routes && event.routes.length) {
            this.updateLegs(event);
            this.updateETA(!this.showDriverInMap);
        }
    };
    // Update distance and remaning time based on driver position
    GeolocationComponent.prototype.onResponseDriver = function (event) {
        if (event && event.routes && event.routes.length) {
            var dist_1 = event.routes[0].legs[0].distance.value;
            var timeRemaining_1 = event.routes[0].legs[0].duration.value;
            (this.legsNewLegs.slice(this.legInTransitIndex + 1)).forEach(function (leg) {
                dist_1 = dist_1 + leg.distance;
                timeRemaining_1 = timeRemaining_1 + leg.ETA;
            });
            this.convertTimeAndDistance(dist_1, timeRemaining_1, true, false);
        }
    };
    GeolocationComponent.prototype.updateLegs = function (event) {
        if (event) {
            this.legsNewLegs.forEach(function (leg) {
                if (Math.abs(leg.pointInit.lat - event.routes[0].legs[0].start_location.lat()) < 0.01 &&
                    Math.abs(leg.pointInit.lng - event.routes[0].legs[0].start_location.lng()) < 0.01 &&
                    Math.abs(leg.pointEnd.lat - event.routes[0].legs[0].end_location.lat()) < 0.01 &&
                    Math.abs(leg.pointEnd.lng - event.routes[0].legs[0].end_location.lng()) < 0.01) {
                    leg.distance = event.routes[0].legs[0].distance.value;
                    leg.ETA = event.routes[0].legs[0].duration.value;
                }
            });
            if (this.legsNewLegs.every(function (leg) { return (leg.distance !== null) && (leg.ETA !== null); })) {
                this.legsWithDistances = true;
            }
        }
    };
    // Update total distance and remaning time
    GeolocationComponent.prototype.updateETA = function (updateRemaining, taxUpdate) {
        if (updateRemaining === void 0) { updateRemaining = true; }
        if (taxUpdate === void 0) { taxUpdate = false; }
        var dist = 0;
        var ETA = 0;
        this.legsNewLegs.forEach(function (leg) {
            if (leg.type === 'Rodoviário') {
                dist += leg.distance;
                ETA += leg.ETA;
            }
        });
        this.convertTimeAndDistance(dist, ETA, updateRemaining, true, taxUpdate);
    };
    GeolocationComponent.prototype.convertTimeAndDistance = function (dist, ETA, updateRemaining, updateTotal, taxUpdate) {
        var _this = this;
        if (updateRemaining === void 0) { updateRemaining = true; }
        if (updateTotal === void 0) { updateTotal = true; }
        if (taxUpdate === void 0) { taxUpdate = false; }
        var distance = Math.ceil(dist / 1000).toString();
        if (updateTotal) {
            this.distanceTotal = distance;
            if (taxUpdate) {
                // timer to wait for distance total to properly update
                setTimeout(function () {
                    _this.distTotalUpdateEvent.emit(_this.distanceTotal);
                }, 3000);
            }
        }
        if (updateRemaining) {
            this.distanceRemaining = distance;
            this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.ceil((ETA % 3600) / 60) + ' min';
        }
    };
    GeolocationComponent.prototype.removeLeg = function (legToRemove) {
        var _this = this;
        var flag = -1;
        for (var i = 0; i < this.legsNewLegs.length; i++) {
            if (this.legsNewLegs[i] === legToRemove) {
                flag = i;
                // console.log(flag)
            }
        }
        this.mdrService.removeLeg(legToRemove, this.mdr.id).subscribe(function (response) {
            if (flag - 1 > -1 && flag + 1 < _this.legsNewLegs.length) {
                _this.legsNewLegs[flag - 1].pointEnd = legToRemove.pointEnd;
                _this.mdrService.putLegs(_this.legsNewLegs[flag - 1], _this.mdr.id).subscribe(function (data) {
                    _this.dataSource = new MatTableDataSource(_this.legsNewLegs);
                    _this.getLegsDelete();
                    _this.updateETA();
                });
                _this.legsNewLegs[flag + 1].pointInit = legToRemove.pointInit;
                _this.mdrService.putLegs(_this.legsNewLegs[flag - 1], _this.mdr.id).subscribe(function (data) {
                    _this.dataSource = new MatTableDataSource(_this.legsNewLegs);
                    _this.getLegsDelete();
                    _this.updateETA();
                });
            }
            _this.getLegsDelete();
            _this.updateETA();
        });
    };
    GeolocationComponent.prototype.deletePin = function () {
        var _this = this;
        var flag = -1;
        for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i].label === this.markerDelete) {
                flag = i;
            }
        }
        if (flag !== -1) {
            if (this.legsNewLegs[flag]) {
                this.mdrService.removeLeg(this.legsNewLegs[flag], this.mdr.id).subscribe(function (data) {
                    _this.getLegsDelete();
                    _this.updateETA(true, true);
                    _this.dataSource = new MatTableDataSource(_this.legsNewLegs);
                });
            }
            if (flag - 1 > -1) {
                this.mdrService.removeLeg(this.legsNewLegs[flag - 1], this.mdr.id).subscribe(function (data) {
                    _this.getLegsDelete();
                    _this.updateETA(true, true);
                    _this.dataSource = new MatTableDataSource(_this.legsNewLegs);
                });
            }
        }
    };
    GeolocationComponent.prototype.addPin = function () {
        // console.log(this.latNew);
        // console.log(this.lngNew);
        // console.log(this.markers);
        if (this.latNew !== null && this.lngNew !== null) {
            this.markers.push({ lat: this.latNew, lng: this.lngNew, label: String.fromCharCode(this.markers.length + 65), title: '' });
            this.dataSourcePin = new MatTableDataSource(this.markers);
            this.snackBar.open('Ponto adicionado', 'Fechar', {
                duration: 2000,
            });
        }
    };
    GeolocationComponent.prototype.updateMarker = function (event, mark) {
        var _this = this;
        this.legsToDirection = [];
        this.markers.forEach(function (m) {
            if (m.label === mark.label) {
                m.lat = event.coords.lat;
                m.lng = event.coords.lng;
            }
        });
        this.legsNewLegs.forEach(function (leg) {
            if (leg.pointInit.label === mark.label) {
                leg.pointInit.lat = event.coords.lat;
                leg.pointInit.lng = event.coords.lng;
            }
            if (leg.pointEnd.label === mark.label) {
                leg.pointEnd.lat = event.coords.lat;
                leg.pointEnd.lng = event.coords.lng;
            }
            _this.legsToDirection.push(leg);
        });
        // console.log(this.legsNewLegs);
        // console.log(this.markers);
        this.saveRoute();
    };
    GeolocationComponent.prototype.createLeg = function () {
        // console.log('Version 1.0');
        var nLeg = new Legs();
        // console.log("error");
        // console.log(this.markerInit);
        for (var i = 0; i < this.markers.length; i++) {
            // console.log(this.markers[i]);
            if (this.markers[i].label === this.markerInit) {
                nLeg.pointInit = this.markers[i];
                if (this.legsNewLegs.length > i) {
                    nLeg.order = this.legsNewLegs[i].order + 1;
                }
            }
            else if (this.markers[i].label === this.markerEnd) {
                nLeg.pointEnd = this.markers[i];
                if (this.legsNewLegs.length > i) {
                    nLeg.order = this.legsNewLegs[i].order - 1;
                }
            }
        }
        if (nLeg.order === null) {
            nLeg.order = 0;
        }
        var letterEndtoEnd = this.legsNewLegs.filter(function (legs) { return (nLeg.pointEnd.lat === legs.pointEnd.lat && nLeg.pointEnd.lng === legs.pointEnd.lng); }).map(function (legs) { return legs.pointEnd.label; });
        var letterEndtoInit = this.legsNewLegs.filter(function (legs) { return (nLeg.pointEnd.lat === legs.pointInit.lat && nLeg.pointEnd.lng === legs.pointInit.lng); }).map(function (legs) { return legs.pointInit.label; });
        var letterInittoInit = this.legsNewLegs.filter(function (legs) { return (nLeg.pointInit.lat === legs.pointInit.lat && nLeg.pointInit.lat === legs.pointInit.lng); }).map(function (legs) { return legs.pointInit.label; });
        var letterInittoEnd = this.legsNewLegs.filter(function (legs) { return (nLeg.pointInit.lat === legs.pointEnd.lat && nLeg.pointInit.lat === legs.pointEnd.lng); }).map(function (legs) { return legs.pointEnd.label; });
        // console.log(letterEndtoEnd);
        // console.log(letterEndtoInit);
        // console.log(letterInittoInit);
        // console.log(letterInittoEnd);
        // console.log(nLeg);
        nLeg.type = this.selectedType;
        if (this.legsNewLegs.length === 0) {
            nLeg.type = this.selectedType;
            this.legsNewLegs.push(nLeg);
            // console.log(this.legsNewLegs);
            this.legsToDirection.push(nLeg);
            this.saveRoute();
        }
        else {
            if (nLeg.pointEnd.label === this.legsNewLegs[0].pointInit.label || nLeg.pointInit.label === this.legsNewLegs[this.legsNewLegs.length - 1].pointEnd.label) {
                this.legsNewLegs.push(nLeg);
                // console.log(this.legsNewLegs);
                this.legsToDirection.push(nLeg);
                this.saveRoute();
            }
            else {
                this.snackBar.open('Leg invalida', 'Fechar', {
                    duration: 2000,
                });
            }
        }
    };
    GeolocationComponent.prototype.removeDirection = function () {
        this.showMap = false;
    };
    GeolocationComponent.prototype.showDirection = function () {
        this.showMap = true;
    };
    GeolocationComponent.prototype.getLegs = function () {
        var _this = this;
        this.legsNewLegs = [];
        this.markers = [];
        if (this.legsNewLegs.length === 0) {
            this.mdrService.getLegs(this.mdr.id).subscribe(function (data) {
                data.forEach(function (leg, i) {
                    var legNew = new Legs();
                    legNew.id = leg.id;
                    var letterInittoInit = _this.legsNewLegs.filter(function (legs) { return (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng); }).map(function (legs) { return legs.pointInit.label; });
                    var letterInittoEnd = _this.legsNewLegs.filter(function (legs) { return (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng); }).map(function (legs) { return legs.pointEnd.label; });
                    var letterEndtoEnd = _this.legsNewLegs.filter(function (legs) { return (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng); }).map(function (legs) { return legs.pointEnd.label; });
                    var letterEndtoInit = _this.legsNewLegs.filter(function (legs) { return (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng); }).map(function (legs) { return legs.pointInit.label; });
                    if (letterInittoInit.length > 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = letterInittoInit[0];
                    }
                    else if (letterInittoEnd.length === 0 && i !== 0) {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
                        _this.markers.push({
                            lat: parseFloat(leg.pointInit.lat),
                            lng: parseFloat(leg.pointInit.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    else {
                        legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                        legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                        legNew.pointInit.label = String.fromCharCode(i + 65);
                    }
                    if (letterEndtoEnd.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoEnd[0];
                    }
                    else if (letterEndtoInit.length > 0) {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = letterEndtoInit[0];
                    }
                    else {
                        legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                        legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                        legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
                        _this.markers.push({
                            lat: parseFloat(leg.pointEnd.lat),
                            lng: parseFloat(leg.pointEnd.lng),
                            label: String.fromCharCode(i + 1 + 65),
                            title: '',
                        });
                    }
                    legNew.type = leg.type;
                    legNew.order = leg.order;
                    _this.legsNewLegs.push(legNew);
                });
                _this.formatLegs();
            });
        }
    };
    GeolocationComponent.prototype.getLegsDelete = function () {
        var _this = this;
        this.showMap = false;
        this.showMarkers = true;
        this.mdrService.getLegs(this.mdr.id).subscribe(function (data) {
            _this.legsNewLegs = [];
            _this.markers = [];
            data.forEach(function (leg, i) {
                var legNew = new Legs();
                legNew.id = leg.id;
                var letterInittoInit = _this.legsNewLegs.filter(function (legs) {
                    return (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng);
                }).map(function (legs) { return legs.pointInit.label; });
                var letterInittoEnd = _this.legsNewLegs.filter(function (legs) {
                    return (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng);
                }).map(function (legs) { return legs.pointEnd.label; });
                var letterEndtoEnd = _this.legsNewLegs.filter(function (legs) {
                    return (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng);
                }).map(function (legs) { return legs.pointEnd.label; });
                var letterEndtoInit = _this.legsNewLegs.filter(function (legs) {
                    return (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng);
                }).map(function (legs) { return legs.pointInit.label; });
                if (i === 0) {
                    _this.markers.push({
                        lat: parseFloat(leg.pointInit.lat),
                        lng: parseFloat(leg.pointInit.lng),
                        label: 'A',
                        title: '',
                    });
                }
                if (letterInittoInit.length > 0) {
                    legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                    legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                    legNew.pointInit.label = letterInittoInit[0];
                }
                else if (letterInittoEnd.length === 0 && i !== 0) {
                    legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                    legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                    legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
                    _this.markers.push({
                        lat: parseFloat(leg.pointInit.lat),
                        lng: parseFloat(leg.pointInit.lng),
                        label: String.fromCharCode(i + 1 + 65),
                        title: '',
                    });
                }
                else {
                    legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                    legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                    legNew.pointInit.label = String.fromCharCode(i + 65);
                }
                if (letterEndtoEnd.length > 0) {
                    legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                    legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                    legNew.pointEnd.label = letterEndtoEnd[0];
                }
                else if (letterEndtoInit.length > 0) {
                    legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                    legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                    legNew.pointEnd.label = letterEndtoInit[0];
                }
                else {
                    legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                    legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                    legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
                    _this.markers.push({
                        lat: parseFloat(leg.pointEnd.lat),
                        lng: parseFloat(leg.pointEnd.lng),
                        label: String.fromCharCode(i + 1 + 65),
                        title: '',
                    });
                }
                legNew.type = leg.type;
                legNew.order = leg.order;
                _this.legsNewLegs.push(legNew);
            });
            _this.formatLegs();
        });
    };
    GeolocationComponent.prototype.formatLegs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var initLatLng, geocoder, legIndex;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        initLatLng = [];
                        geocoder = new google.maps.Geocoder();
                        return [4 /*yield*/, Promise.all(this.drs.map(function (dr) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: 
                                        // Get and set lat + lng
                                        return [4 /*yield*/, geocoder.geocode({ 'address': dr.pickupAddress }, function (results, status) {
                                                if (status === google.maps.GeocoderStatus.OK) {
                                                    var lat = results[0].geometry.location.lat();
                                                    var lng = results[0].geometry.location.lng();
                                                    initLatLng.push({ lat: lat, lng: lng, status: dr.status });
                                                }
                                            })];
                                        case 1:
                                            // Get and set lat + lng
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        this.legsNewLegs.sort(function (legA, legB) { return legA.order - legB.order; });
                        // Setup legs table and get distances + time remaining with the updateETA function
                        this.updateETA(!this.showDriverInMap);
                        this.dataSource = new MatTableDataSource(this.legsNewLegs);
                        this.showMap = true;
                        legIndex = this.legsNewLegs.findIndex(function (leg) {
                            var drOfLeg = initLatLng.find(function (dr) { return Math.abs(leg.pointInit.lat - dr.lat) <= 0.01 &&
                                Math.abs(leg.pointInit.lng - dr.lng) <= 0.01; });
                            return (drOfLeg && drOfLeg.status !== 'Delivered');
                        });
                        this.legInTransitIndex = legIndex !== -1 ? legIndex : 0;
                        // Added delay to load legs in order to avoid OVER_QUERY_LIMIT erros of agm-directions
                        // The agm-direction, API component that get distances and time remaining, only allow a total 10 query's per second before raising a error
                        // Because we use a ngFor in html to load the directions, this workaround is needed to not call the query's at the same time
                        // With this, each leg of legsNewLegs with be emitted with the subscribe after 110 ms
                        // 'from' is used to emit send one element of the arrray at a time
                        // 'concatMap' is used to delay the emitting order because it only run's the next element of the pipe after the current one is done
                        // 'take' is used to unsubscribe after a number of elements (equal to legsNewLegs length) are emitted
                        // More info about rxjs operators at https://www.learnrxjs.io/learn-rxjs/operators
                        from(this.legsNewLegs).pipe(concatMap(function (leg) { return of(leg).pipe(delay(110)); }), take(this.legsNewLegs.length)).subscribe(function (leg) {
                            _this.legsNewLegsDelayed.push(leg);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    GeolocationComponent.prototype.saveRoute = function () {
        var _this = this;
        var _loop_1 = function (i) {
            // console.log(this.legsNewLegs[i]);
            this_1.mdrService.putLegs(this_1.legsNewLegs[i], this_1.mdr.id).subscribe(function (data) {
                if (parseInt(i, 10) === _this.legsNewLegs.length - 1) {
                    // console.log('lal');
                    _this.getLegsDelete();
                    _this.updateETA();
                }
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.keys(this.legsNewLegs); _i < _a.length; _i++) {
            var i = _a[_i];
            _loop_1(i);
        }
        this.snackBar.open('Alterações Salvas', 'Fechar', {
            duration: 2000,
        });
    };
    // pega última posição da MDR
    GeolocationComponent.prototype.getDriverLastPosition = function () {
        var _this = this;
        // pega última posição da MDR
        this.mdrService.getDriverLastPosition(this.mdr).subscribe(function (elem) {
            if (elem) {
                _this.driverPosition = new Pin();
                _this.driverPosition.lat = parseFloat(elem.lat);
                _this.driverPosition.lng = parseFloat(elem.lng);
                _this.destination = new Pin();
                _this.haveDriver = true;
            }
            _this.showDriverInMap = (_this.haveDriver && _this.finishedDriveStatus.indexOf(_this.mdr.status) === -1);
        });
    };
    // pega posições do tracking
    GeolocationComponent.prototype.getDriverPosition = function () {
        var _this = this;
        this.markersTruck = [];
        this.mdrService.getDriverPosition(this.mdr.id).subscribe(function (driverList) {
            // monta lista de posições pra tabela e última posição do motorista
            _this.driverPositionList = [];
            driverList.forEach(function (driver, i) {
                // pra cada posição do motorista
                driver['positions'].forEach(function (position) {
                    // adiciona o telefone do motorista às posições
                    position['phoneNumber'] = driver['phoneNumber'];
                    // converte velocidade em km/h
                    position['speed'] = position['speed'] * 3.6;
                    // converte createdAt em Date
                    position['createdAt'] = new Date(position['createdAt']);
                    // escolhe cor para as posições
                    var colorI = i % _this.coloredPoint.length;
                    position['color'] = _this.colorPrefix + _this.coloredPoint[colorI].color;
                    position['icon'] = _this.coloredPoint[colorI];
                });
                // adiciona posições à lista de latlngs
                _this.driverPositionList = [].concat(_this.driverPositionList, driver['positions']);
            });
            // monta lista de markers pro mapa
            _this.driverPositionList.forEach(function (position) {
                _this.markersDriver.push({
                    lat: parseFloat(position['lat']),
                    lng: parseFloat(position['lng']),
                    label: '',
                    title: position['phoneNumber'],
                    icon: position['icon']
                });
            });
        });
    };
    return GeolocationComponent;
}());
export { GeolocationComponent };
var Position = /** @class */ (function () {
    function Position() {
        this.lat = null;
        this.lng = null;
    }
    return Position;
}());
export { Position };
var WayPoint = /** @class */ (function () {
    function WayPoint() {
        this.location = new Position();
        this.stopover = false;
    }
    return WayPoint;
}());
export { WayPoint };
