import { OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DashboardService } from '../../../providers/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
var BasicBarChartComponent = /** @class */ (function () {
    function BasicBarChartComponent(dashboardService, excelService, translate) {
        this.dashboardService = dashboardService;
        this.excelService = excelService;
        this.translate = translate;
        this.downloadData = [];
        this.chartData = {
            number: 0,
            percentage: 0,
            categories: [],
            series: [],
            subtitle: '',
        };
    }
    BasicBarChartComponent.prototype.ngOnInit = function () {
    };
    BasicBarChartComponent.prototype.download = function () {
        this.excelService.exportAsExcelFile(this.downloadData, 'drs');
    };
    BasicBarChartComponent.prototype.createChart = function (data, id, download) {
        if (download) {
            this.downloadData = download;
        }
        this.chartData = data;
        if (data.categories.length === 0) {
            this.chart = 'empty';
        }
        else {
            this.chart = Highcharts.chart(id, {
                chart: {
                    type: 'column',
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: data.categories,
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: data.subtitle
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: false
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: data.series
            });
            this.chart.reflow();
        }
    };
    return BasicBarChartComponent;
}());
export { BasicBarChartComponent };
