import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MdrService } from '../../../providers/mdr.service';
import { MDR } from '../../../models/mdr';
var AirplaneComponent = /** @class */ (function () {
    function AirplaneComponent(route, router, mdrService) {
        this.route = route;
        this.router = router;
        this.mdrService = mdrService;
        this.isAirplane = null;
        this.editMDRAirplane = false;
        this.role = null;
        this.newAirplane = false;
        this.currentUser = null;
        this.mdrAirplane = [
            { name: 'Aéreo', value: true },
            { name: 'Rodoviário', value: false },
        ];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
    }
    AirplaneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id$ = this.route.paramMap.pipe(switchMap(function (params) {
            return _this.mdrService.getMDR(parseInt(params.get('id'), 10));
        }));
        id$.subscribe(function (response) {
            _this.isAirplane = response.airplane;
        });
    };
    AirplaneComponent.prototype.editAirplane = function () {
        this.editMDRAirplane = !this.editMDRAirplane;
    };
    AirplaneComponent.prototype.saveAirplane = function () {
        var _this = this;
        this.editMDRAirplane = !this.editMDRAirplane;
        this.mdr.airplane = this.newAirplane;
        this.mdrService.update(this.mdr, this.currentUser.id).subscribe(function (response) {
            _this.isAirplane = _this.newAirplane;
        });
    };
    return AirplaneComponent;
}());
export { AirplaneComponent };
