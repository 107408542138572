var Driver = /** @class */ (function () {
    function Driver() {
        this.id = null;
        this.name = null;
        this.plate = '';
        this.phoneNumber = null;
        this.mdrId = null;
        this.status = true;
        this.leader = false;
    }
    return Driver;
}());
export { Driver };
