import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { DR } from '../../models/dr';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { TYPE_EXPEDITION } from '../../models/message';
import { UploadFileService } from '../../providers/upload.service';
import { HttpEventType } from '@angular/common/http';
import { ConfigurationService } from '../../providers/configuration.service';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

declare var google: any;

@Component({
  selector: 'app-expedition',
  templateUrl: './expedition.component.html',
  styleUrls: ['./expedition.component.scss']
})

export class ExpeditionComponent implements OnInit {
  data: DR[] = [];
  @ViewChild(BarecodeScannerLivestreamComponent)
  barecodeScanner: BarecodeScannerLivestreamComponent;

  barcodeValue = false;
  @Input() mdr: MDR = null;
  viewOnly = false;
  fileUploaded = null;
  searchBy = null;
  expeditionList = [];
  expeditionFocus = [];
  files: any = {};

  constructor(public dialog: MatDialog,
              private drService: DrService,
              public mdrService: MdrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public uploadService: UploadFileService,
              public configService: ConfigurationService,
              public dpipe: DatePipe) {
              }

  ngOnInit() {
    this.configService.getConfiguration('expedition').subscribe((response) => {
      // console.log(response);
      this.expeditionFocus = response['expedition'];
      // console.log(this.expeditionFocus);
    });
    // console.log(this.mdr);
    if (this.mdr !== null) {
      this.viewOnly = true;
      this.searchBy = this.mdr.pickupID;
      this.search();
    }
  }

  openBarcode() {
    this.barcodeValue = true;
    this.barecodeScanner.start();
  }

  onValueChanges(result) {
      this.searchBy = result.codeResult.code;
      this.search();
      this.barcodeValue = false;
      this.barecodeScanner.stop();
  }

  onStarted(started) {
      // console.log(started);
  }

  receiveFile(res, type) {
    this.uploadService.pushFileToStorage([res], this.mdr.id + '', 'expedition').subscribe(event => {
      if (HttpEventType.UploadProgress === event.type ) {
        // this.progress.percentage = Math.round(100 * event.loaded / event.total);
      } else if ( event.type === HttpEventType.Response)  {
        // Criar File Info;
        // console.log('File is completely uploaded!');
        const k = JSON.parse(event.body + '');
        // console.log(k);
        const fileInfo = {};
        fileInfo['info_name'] = type;
        fileInfo['info_id'] = k['idFile'];
        fileInfo['info_timeDeliveredAt'] = new Date();
        this.uploadService.updateFileInfo(this.mdr.id, fileInfo).subscribe(elem => {
          this.search();
        });
      }
    });
  }

  search() {
    this.mdrService.getMDRbyPickupId(this.searchBy).subscribe(elem => {
      // console.log(elem);
      // TRATAR se tiver mais de uma MDR. (O que fazer?)
      if (elem.mdr !== null) {
        // console.log("HI");
        this.mdr = elem.mdr[0];
        this.expeditionList = [];
        for (const item in this.expeditionFocus) {
          if (item) {
            this.expeditionList.push({name: this.expeditionFocus[item], path: ''});
          }
        }
        elem.file.forEach(element => {
          if (element['fileI'] !== null && element['fileI'].name) {
            const k = this.expeditionList.findIndex( e => e.name === element['fileI'].name);
            if (k !== -1) {
              this.expeditionList[k] = {name: element['fileI'].name, path: element['file'].path};
            }
          }
        });
      } else {
        this.snackBar.open('Expedição não encontrada', 'OK', {duration: 5000});
        this.expeditionList = [];
        this.mdr = null;
      }
    });
  }
}
