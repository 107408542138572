<div class="mx-5 my-4">
  <span class="mat-h1">{{ 'EXPEDITION/title' | translate }}</span>
</div>

<div class="colored-background table-background px-5 py-4 my-20">
  <form #form="ngForm" style="width: 100%" *ngIf="!viewOnly">
    <mat-form-field class="col-md-8">
      <i style="color: white;" class="fas fa-search"></i>
      <input matInput placeholder="{{ 'EXPEDITION/searchBy' | translate }}"
        id="searchMDR"
        name='searchBy'
        (keydown.enter)="search()"
        [(ngModel)]="searchBy">
    </mat-form-field>
    <barcode-scanner-livestream *ngIf="barcodeValue" type="code_128" (valueChanges)="onValueChanges($event)" (started)="onStarted"></barcode-scanner-livestream>
  </form>
  <div *ngIf="mdr !== null">
    <p>{{ 'EXPEDITION/trip' | translate }}: {{mdr.pickupID}}</p>
    <!-- Link para MDR? -->
    <div class="cards">
      <div class='card' *ngFor="let picture of expeditionList"> 
        <mat-card>
          <mat-card-header style="border-bottom: 1px solid gray;">
            <mat-card-title >{{picture.name}}</mat-card-title>
            <!-- <mat-card-subtitle>{{picure}}</mat-card-subtitle> -->
          </mat-card-header>
          <!-- <app-image-lightbox [images]="[file]"></app-image-lightbox> -->
          
          <mat-card-actions>
            <app-form-upload style="margin:20px;" *ngIf="!viewOnly" [showName]="false" [name]="picture.name" (setFile)="receiveFile($event, picture.name)"></app-form-upload>
            <span *ngIf="picture.path != ''">
              <app-image-lightbox [images]="[{id:mdr,url:picture.path}]"></app-image-lightbox>
            </span>
            <span *ngIf="picture.path === ''">
              <img style="max-width: 200px; max-height: 212px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" [src]="'../../assets/images/expedition/'+ picture.name + '.png'">
              <p style="width: 100%;text-align: center;margin-top: 20px;">Insira sua imagem como o exemplo acima</p>
            </span>
            <!-- <button mat-button>Remove</button> -->
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mdr === null" style="height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <div style="max-width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
    <button style="font-size: 40px;height: 83px;" class='col-md-2 button green-button text-white' (click)="openBarcode()"><i class="fas fa-barcode"></i></button>
    <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'EXPEDITION/empty' | translate }}</p>
  </div>
</div>
