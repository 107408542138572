<h1 mat-dialog-title>{{ 'TYPE-MDR-DIALOG/title' | translate}}</h1>
<div mat-dialog-content>
  <form #form="ngForm">
    <!-- fiscal in & out state -->
    <div class="row">
      <!-- inState -->
      <mat-form-field class="col-md-6">
        <mat-label>{{ 'TAX/fiscal-state/in' | translate }}</mat-label>
        <mat-select [(ngModel)]="icmsState.in.current" name="inState" required>
          <mat-option *ngFor="let item of icmsState.in.all" [value]="item">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- outState -->
      <mat-form-field class="col-md-6">
        <mat-label>{{ 'TAX/fiscal-state/out' | translate }}</mat-label>
        <mat-select [(ngModel)]="icmsState.out.current" name="outState" required>
          <mat-option *ngFor="let item of icmsState.out.all" [value]="item">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- type -->
    <mat-select placeholder="Type" [(ngModel)]="typeMDR" name="typeMDR" required>
      <mat-option *ngFor="let elem of types" [value]="elem.id">
        {{ elem.name }}
      </mat-option>
    </mat-select>
    <!-- CMD -->
    <div *ngIf="typeMDR === 'CMS'">
      <mat-checkbox [(ngModel)]="returnLeg" name="returnLeg">{{'TYPE-MDR-DIALOG/return-leg' | translate}}</mat-checkbox>
    </div>
    <!-- DTR -->
    <mat-form-field [ngClass]="{hidden: typeMDR !== 'DTR'}" style="width: calc(100%);">
      <input matInput #pickupSiteSearch type="text" autocapitalize="off" spellcheck="off"
        placeholder="{{ 'NEW-DELIVERY-REQUEST/text/pickup-site' | translate }}" id="pickupSite"
        [(ngModel)]="pickupSite" name="pickupSite">
    </mat-form-field>
    <div class="row"  >
      <div class="col-md-6"  [ngClass]="{hidden: typeMDR !== 'DTR'}">
        <mat-form-field style="width: calc(100%);">
        <input matInput [owlDateTimeTrigger]="myDatepicker" [owlDateTime]="myDatepicker" [placeholder]="'DELIVERY_ROUTE/pickupDate' | translate" [(ngModel)]="pickupDate" name="Datepicker" >
        <owl-date-time #myDatepicker pickerType="both"></owl-date-time>
      </mat-form-field>
      </div>
      <div class="col-md-6" [ngClass]="{hidden: typeMDR !== 'DTR'}">
        <mat-form-field style="width: calc(100%);">
        <input  matInput #tel   mask="00 (00) 00009-0000" id="cellphone" name="cellphone"  [(ngModel)]="cellphone" placeholder="{{ 'DELIVERY_ROUTE/pickupPhone' | translate }}"></mat-form-field>
      </div>

    </div>
    <div class="row">
    <div class="col-md-12">
      <mat-form-field [ngClass]="{hidden: typeMDR !== 'DTR'}" style="width: calc(100%);">
        <input matInput #search type="text" autocapitalize="off" spellcheck="off"
          placeholder="{{ 'NEW-DELIVERY-REQUEST/text/pickup-stops' | translate }}" id="deliveryAddress"
          [(ngModel)]="address" name="address">
      </mat-form-field>
    </div></div>
    <div *ngIf="typeMDR === 'DTR'">
      <p>{{'TYPE-MDR-DIALOG/add-address-title' | translate }}</p>
      <button mat-button class="primary-button w-100"
        (click)="addAddress()">{{'TYPE-MDR-DIALOG/add-address' |translate }}</button>
      <mat-list dense>
        <mat-list-item *ngFor="let elem of addressReturn; let i=index">
          <p matLine>{{ elem.address }}</p>
          <p>lat: {{elem.lat}}, lng: {{elem.lng}}</p>
          <i matListIcon class="fa fa-times" (click)="removeAddress(i)"></i>
        </mat-list-item>
      </mat-list>
    </div>
    <!-- Cross Docking -->
    <div *ngIf="typeMDR === 'Cross Docking'">
      <p>Selecione o HUB:</p>
      <app-table *ngIf="drs.length > 0"
        [columns]="columns"
        showCheckbox="true"
        [data]="drs"
        (check)="addList($event)"
        [this]="this"
      ></app-table>
      <div class="row">
        <mat-select placeholder="HUB" [(ngModel)]="hub" name="hub">
          <mat-option *ngFor="let elem of hubList" [value]="elem.name"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </div>
      <div class="row">
        <button mat-button (click)="addHub()" class="primary-button">Add Selected Hub</button>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onYesClick()" class="primary-button" [disabled]="!form.valid">Sim</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Não</button>
</div>
