import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AgmCoreModule, MarkerManager} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {UploadComponent} from '../../misc/upload/upload.component';

import {MatIconModule,
  MatDatepickerModule,
  MatCardModule,
  MatTooltipModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatPaginator,
  MatExpansionModule,
  MatButtonModule,
  MatTableDataSource,
        MatCheckboxModule} from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';

import { PipesModule} from '../../misc/pipes/pipes.module';
import { UploadModule} from '../../misc/upload/upload.module';

import { DrBookingComponent } from './dr-booking.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    MatDividerModule,
    MatPaginatorModule,
    MatButtonModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    UploadModule,
    MatSelectModule,
    MatExpansionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmDirectionModule,
    MatTabsModule,
    AgmCoreModule,
  ],
  declarations: [
    DrBookingComponent,
  ],
 })
 export class DrBookingModule { }
