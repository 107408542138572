import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MdrService} from '../../providers/mdr.service';
import {MDR} from '../../models/mdr';

@Component({
  selector: 'app-mdr-lsp',
  templateUrl: './mdr-lsp.component.html',
  styleUrls: ['./mdr-lsp.component.scss']
})
export class MdrLspComponent implements OnInit {
  columns = [
    {value: 'id', name: 'id'},
    {value: 'drList', name: 'MDR_LSP/table/mdr/dr'},
    {value: 'status', name: 'status'}
  ];
  mdr: MDR[] = [];

  constructor(public aRoute: ActivatedRoute,
              public mdrService: MdrService,
              public router: Router
  ) { }

  ngOnInit() {
    this.mdrService.getAllLSP().subscribe((data) => {
      this.mdr = data;
    });
  }

  onRowClick(row, that) {
    that.router.navigate(['logistic-order', row.id]);
  }

}
