<p>
  {{ 'MDR/airplane' | translate }}:
  <span *ngIf="!editMDRAirplane" [ngClass]="isAirplane ? 'is-airplane' : 'is-not-airplane' ">{{(isAirplane ? 'MDR/airplane/yes' : 'MDR/airplane/no' ) | translate }}</span>

  <mat-form-field *ngIf="[1, 5, 12].includes(role) && editMDRAirplane" class="col-md-3">
    <mat-select [(ngModel)]="newAirplane" >
      <mat-option *ngFor="let elem of mdrAirplane" [value]="elem.value">
        {{elem.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <i *ngIf="[1, 5, 12].includes(role) && !editMDRAirplane" font class="fa fa-pencil-alt icon-header" (click)="editAirplane()" aria-hidden="true"> </i>
  <i *ngIf="[1, 5, 12].includes(role) && editMDRAirplane" font class="fa fa-save icon-header" (click)="saveAirplane()" aria-hidden="true"> </i>

</p>
