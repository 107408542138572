import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { Router } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
var ResolverService = /** @class */ (function () {
    function ResolverService(service, router) {
        this.service = service;
        this.router = router;
    }
    ResolverService.prototype.resolve = function (route, state) {
        var _this = this;
        var token = route.paramMap.get('token');
        return this.service.getMdrWithToken(token).take(1).map(function (response) {
            if (response) {
                return response;
            }
            else { // mdr not found
                _this.router.navigate(['/login']);
                return null;
            }
        });
    };
    return ResolverService;
}());
export { ResolverService };
