import { Component, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DR} from '../../../models/dr';
import {MatSnackBar} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import { UploadFileService } from '../../../providers/upload.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare var google: any;

@Component({
  selector: 'app-dr-validation',
  templateUrl: './dr-validation.component.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DrValidationComponent implements AfterViewInit {
  id;
  public dr: DR = new DR();
  salesOrders;
  @ViewChild('addrSearch')
  public searchElementRef: ElementRef;
  currentUser: any;
  role: any;
  uploadedFiles: any = null;

  // pro icon de salesOrders
  status = null;
  icons = {
    'status' : '',
    'tooltips': {
      'invalid': 'OVs inválidas',
      'incomplete': 'NFs não existentes',
      'valid': 'DR Booking válido',
      'undefined': ''
    },
    'icons' : {
      'invalid': 'fa-exclamation-circle',
      'incomplete': 'fa-exclamation-triangle',
      'valid': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'invalid' : 'icon-red',
      'incomplete': 'icon-yellow',
      'valid': 'icon-green',
      'undefined': ''
    }
  };

  constructor(
    public router: Router,
    public aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private drService: DrService,
    private uploadService: UploadFileService,
    private dialog: MatDialog
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
  }

  ngAfterViewInit() {
    this.aRoute.params.subscribe( (params) => {
      this.id = params.id;
      this.drService.getBooking(this.id).subscribe((response) => {
        this.dr = response;
        this.salesOrders = this.dr.salesOrders.join(',');
        this.receiveFile(null);
      });
      // this.validateDR();
    });
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = lat;
        this.dr.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: parseFloat(lat), lng: parseFloat(lng)}}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
      }
    });
  }

  validateDR() {
    this.drService.getValidation(this.id).subscribe((response) => {
      this.dr = response.dr;
      this.salesOrders = this.dr.salesOrders.join(',');
      this.icons.status = response.status;
      this.status = {
        status: response.status,
        error: response.error,
        warning: response.warning
      };

      // if ( response.status === 'valid' ) {
      //   this.approveDR();
      // } else {
      //   this.openDialog();
      // }
      this.openDialog();
    }, (err) => {
      this.snackBar.open('Erro ao buscar DR Booking', 'Ok', {duration: 4000});
      this.router.navigate(['cockpit']);
    });
  }

  save() {
    if (this.dr.dateScheduling.getTime() - new Date(this.dr.createdAt).getTime() < 172800000) {
      this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', {duration: 4000});
      return;
    }

    this.drService.updateBooking(this.id, this.dr).subscribe((response) => {
      this.snackBar.open('Atualizado o Booking Corretamente', 'Ok', {duration: 4000});
    });
  }

  saveAndValidate() {
    if (this.dr.dateScheduling.getTime() - new Date(this.dr.createdAt).getTime() < 172800000) {
      this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', {duration: 4000});
      return;
    }

    this.dr.salesOrders = this.salesOrders
                              .replace(/ /g, ',')   // troca espaços por vírgulas
                              .split(',')          // quebra em array nas vírgulas
                              .filter(e => e.length); // remove strings vazias
    this.drService.updateBooking(this.id, this.dr).subscribe((response) => {
      this.validateDR();
    }, (err) => {
      this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', {duration: 2000});
    });
  }

  reassessment() {
    this.dr.salesOrders = this.salesOrders
                              .replace(/ /g, ',')   // troca espaços por vírgulas
                              .split(',')          // quebra em array nas vírgulas
                              .filter(e => e.length); // remove strings vazias
    // update status
    this.dr.status = 'BOOKING';
    // update booking
    this.drService.updateBooking(this.id, this.dr).subscribe((response) => {
      this.notifyStatusUpdate('REASSIGNED');
      this.snackBar.open('Booking enviada para reavaliação', 'Ok', {duration: 2000});
    }, (err) => {
      this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', {duration: 2000});
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogDrValidationComponent, {
      data: {
        status: this.status
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'approve' ) {
        this.approveDR(result.notify);
      }
    });
  }

  approveDR(notify = false) {
    this.drService.updateBookingToDR(this.id).subscribe((response) => {
      if (notify) {
        this.notifyStatusUpdate('VALIDATED');
      }

      this.router.navigate(['cockpit']);
    }, (err) => {
      this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', {duration: 2000});
    });
  }

  receiveFile(event) {
    this.drService.getFilesFromDrManually(this.dr.id).subscribe((response) => {
      this.uploadedFiles = response;
    });
  }

  removeFile(file: any, entity: string) {
    this.uploadService.deleteByEntity(file['id'], entity).subscribe(response => {
      this.uploadedFiles = this.uploadedFiles.filter( f => f.id !== file.id);
    });
  }

  openReasonDialog(action: string) {
    const dialogRef = this.dialog.open(DialogDrDeleteComponent, {
      width: '600px',
      height: '200px',
      data: { action: action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.confirm ) {
        // relate colected reason
        this.dr.deleteReason = result.reason;
        // set booking as pending
        if ( action === 'pending') {
          this.dr.status = 'BOOKING_PENDING';
        }

        if (this.dr.dateScheduling.getTime() - new Date(this.dr.createdAt).getTime() < 172800000) {
          this.snackBar.open('Date Scheduling deve ser maior que 48h da data e hora que o agendamento foi criado.', 'Ok', {duration: 4000});
          return;
        }

        // update booking
        this.drService.updateBooking(this.id, this.dr).subscribe((response) => {
          if ( action === 'delete' ) {
            this.reproveDR();
          } else {
            this.notifyStatusUpdate('INVALIDATED', this.dr.deleteReason);
            this.snackBar.open('Booking invalidada com sucesso', 'Ok', {duration: 2000});
            this.router.navigate(['cockpit']);
          }
        }, (err) => {
          this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', {duration: 2000});
        });
      }
    });
  }

  reproveDR() {
    this.drService.reproveBooking(this.id).subscribe(elem => {
      this.notifyStatusUpdate('DELETED', this.dr.deleteReason);
      this.snackBar.open('Booking Removido com sucesso', 'Ok', {duration: 2000});
      this.router.navigate(['cockpit']);
    });
  }

  notifyStatusUpdate(status, actionJustification = null) {
    this.drService.notifyStatusUpdate(this.id, status, this.dr.createdBy, actionJustification).subscribe(() => {
    }, (err) => {
      this.snackBar.open('Erro ao enviar email de notificação', 'Ok', {duration: 2000});
    });
  }
}

@Component({
  selector: 'app-dialog-dr-validation',
  templateUrl: 'dialog-dr-validation.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DialogDrValidationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDrValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    public notify = true;

  onClickOk(): void {
    this.dialogRef.close({ what: 'approve', notify: this.notify });
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
}

@Component({
  selector: 'app-dialog-dr-delete',
  templateUrl: 'dialog-dr-delete.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DialogDrDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDrDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    public reason;
    public notify = true;

  onClickConfirm(): void {
    this.dialogRef.close({ confirm: true, reason: this.reason, notify: this.notify });
  }
  onClickBack(): void {
    this.dialogRef.close({ confirm: false });
  }

}
