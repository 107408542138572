<h1 mat-dialog-title>
  {{ 'USER_ACTIVITY/dialog/title' | translate }}
</h1>

<div mat-dialog-content>
  <p>
    {{ 'USER_ACTIVITY/dialog/subtitle' | translate }}
  </p>
</div>

<div mat-dialog-actions>
  <button mat-button
    cdkFocusInitial
    (click)="onClickBack()"
  >
    {{ 'USER_ACTIVITY/dialog/btn/back' | translate }}
  </button>
  <span></span>
  <button mat-raised-button
    class="red-button"
    (click)="onClickOk()"
  >
    {{ 'USER_ACTIVITY/dialog/btn/ok' | translate }}
  </button>
</div>
