import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteService, ESTRUTURA } from '../../providers/site.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { ExportAsService } from 'ngx-export-as';
import 'rxjs/add/operator/map';
var SiteInvestigationWithTokenComponent = /** @class */ (function () {
    function SiteInvestigationWithTokenComponent(aRoute, snackBar, dialog, exportAsService, siteService, cookieService) {
        var _this = this;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.exportAsService = exportAsService;
        this.siteService = siteService;
        this.cookieService = cookieService;
        this.siteid = null;
        this.token = null;
        this.site = null;
        this.siteFromCookies = {};
        this.enums = {};
        this.tipos = [];
        this.SITE_ESTRUTURA = ESTRUTURA;
        this.keysForCookies = [
            'lat', 'lng', 'regional', 'infra', 'tipo',
            'form_1_1', 'form_1_2', 'form_1_3', 'form_1_4', 'form_1_5', 'form_1_6', 'form_1_7', 'form_1_8', 'form_1_9', 'form_2_1', 'form_2_2', 'form_2_3', 'form_2_4', 'form_2_5', 'form_2_6', 'form_2_7', 'form_2_8', 'form_2_9', 'form_3_1', 'form_3_2', 'form_3_3', 'form_4_1', 'form_4_2', 'form_4_3', 'form_5_1', 'form_5_2', 'form_5_3', 'form_6_1', 'form_6_2', 'form_6_3', 'form_6_4', 'form_6_5', 'form_7_1', 'form_7_2', 'form_7_3',
            'form_1_1_obs', 'form_1_2_obs', 'form_1_3_obs', 'form_1_4_obs', 'form_1_5_obs', 'form_1_6_obs', 'form_1_7_obs', 'form_1_8_obs', 'form_1_9_obs', 'form_2_1_obs', 'form_2_2_obs', 'form_2_3_obs', 'form_2_4_obs', 'form_2_5_obs', 'form_2_6_obs', 'form_2_7_obs', 'form_2_8_obs', 'form_2_9_obs', 'form_3_1_obs', 'form_3_2_obs', 'form_3_3_obs', 'form_4_1_obs', 'form_4_2_obs', 'form_4_3_obs', 'form_5_1_obs', 'form_5_2_obs', 'form_5_3_obs', 'form_6_1_obs', 'form_6_2_obs', 'form_6_3_obs', 'form_6_4_obs', 'form_6_5_obs', 'form_7_1_obs', 'form_7_2_obs', 'form_7_3_obs'
        ];
        this.fileKeys = [
            'form_1_1_anexo', 'form_1_2_anexo', 'form_1_3_anexo', 'form_1_4_anexo', 'form_1_5_anexo', 'form_1_6_anexo', 'form_1_7_anexo', 'form_1_8_anexo', 'form_1_9_anexo', 'form_2_1_anexo', 'form_2_2_anexo', 'form_2_3_anexo', 'form_2_4_anexo', 'form_2_5_anexo', 'form_2_6_anexo', 'form_2_7_anexo', 'form_2_8_anexo', 'form_2_9_anexo', 'form_3_1_anexo', 'form_3_2_anexo', 'form_3_3_anexo', 'form_4_1_anexo', 'form_4_2_anexo', 'form_4_3_anexo', 'form_5_1_anexo', 'form_5_2_anexo', 'form_5_3_anexo', 'form_6_1_anexo', 'form_6_2_anexo', 'form_6_3_anexo', 'form_6_4_anexo', 'form_6_5_anexo', 'form_7_1_anexo', 'form_7_2_anexo', 'form_7_3_anexo'
        ];
        this.exportAsConfig = {
            type: 'pdf',
            elementId: 'printable',
        };
        this.aRoute.params.subscribe(function (params) {
            _this.token = params.token;
            _this.siteid = params.siteid;
        });
        this.enums = this.siteService.getEnums();
    }
    SiteInvestigationWithTokenComponent.prototype.onClickPrint = function () {
        // download the file using old school javascript method
        this.exportAsService.save(this.exportAsConfig, 'report');
        // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
        // this.exportAsService.get(this.config).subscribe(content => {
        //  //console.log(content);
        // });
    };
    SiteInvestigationWithTokenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.siteService.readWithToken(this.siteid, this.token).subscribe(function (data) {
            // separa data e hora visita
            data.visitadatahora = new Date(data.visitadatahora);
            data.visitadata = data.visitadatahora;
            var d1 = new Date(data.visitadatahora);
            data.visitahora = ('00' + d1.getHours()).slice(-2) + ':' + ('00' + d1.getMinutes()).slice(-2);
            // separa data e hora vistoria
            data.vistoriadatahora = new Date(data.vistoriadatahora);
            data.vistoriadata = data.vistoriadatahora;
            var d2 = new Date(data.vistoriadatahora);
            data.vistoriahora = ('00' + d2.getHours()).slice(-2) + ':' + ('00' + d2.getMinutes()).slice(-2);
            // separa tipo em objeto
            var tipo = {};
            _this.enums.tipo.map(function (el) {
                tipo[el.value] = false;
            });
            data.tipo.map(function (el) {
                tipo[el.tipo] = true;
            });
            data.tipo = tipo;
            // armazena
            _this.site = data;
            // recupera possiveis valores salvos anteriormente
            _this.checkRestore();
        });
    };
    SiteInvestigationWithTokenComponent.prototype.storeData = function () {
        this.snackBar.open('Não foi possível salvar as modificações, tente novamente em alguns instantes', 'Ok', { duration: 2000 });
        var keys = this.keysForCookies;
        for (var i = 0, l = keys.length; i < l; i++) {
            // se existe um valor
            if (!!this.site[keys[i]]) {
                var cookie = {
                    type: typeof (this.site[keys[i]]),
                    value: this.site[keys[i]]
                };
                this.cookieService.set(keys[i], JSON.stringify(cookie));
            }
        }
        var now = new Date();
        this.cookieService.set('when', now.toLocaleString());
    };
    SiteInvestigationWithTokenComponent.prototype.restoreData = function () {
        var when = this.cookieService.get('when');
        this.snackBar.open('Recuperando modificações de ' + when, 'Ok', { duration: 2000 });
        var keys = this.keysForCookies;
        for (var i = 0, l = keys.length; i < l; i++) {
            if (this.cookieService.check(keys[i])) {
                var cookie = JSON.parse(this.cookieService.get(keys[i]));
                switch (cookie.type) {
                    case 'string':
                        this.siteFromCookies[keys[i]] = cookie.value;
                        break;
                    case 'number':
                        this.siteFromCookies[keys[i]] = Number.parseFloat(cookie.value);
                        break;
                    case 'object':
                        this.siteFromCookies[keys[i]] = cookie.value;
                        break;
                }
            }
        }
        this.site = Object.assign({}, this.site, this.siteFromCookies);
    };
    SiteInvestigationWithTokenComponent.prototype.clearData = function () {
        this.cookieService.delete('when');
        var keys = this.keysForCookies;
        for (var i = 0, l = keys.length; i < l; i++) {
            this.cookieService.delete(keys[i]);
        }
        this.cookieService.delete('tipo');
    };
    SiteInvestigationWithTokenComponent.prototype.save = function () {
        var _this = this;
        var toSave = Object.assign({}, this.site);
        // une data e hora
        toSave.visitadatahora = this.joinDataHora(toSave.visitadata, toSave.visitahora);
        toSave.vistoriadatahora = this.joinDataHora(toSave.vistoriadata, toSave.vistoriahora);
        // unifica tipo em um array
        var tipo = [];
        this.enums.tipo.map(function (el) {
            if (toSave.tipo[el.value] === true) {
                tipo.push(el.value);
            }
        });
        toSave.tipo = tipo;
        // separa os anexos
        var files = [];
        this.fileKeys.forEach(function (el) {
            if (!!toSave[el] && !!toSave[el].name) {
                var filename = el + '.' + toSave[el].name.split('.').pop();
                files.push({ file: toSave[el], field: filename });
            }
            delete toSave[el];
        });
        // envia pro back
        this.snackBar.open('Enviando... isto pode demorar um pouco', 'Ok', { duration: 2000 });
        this.siteService.updateFilesWithToken(this.siteid, this.token, files).subscribe(function (dataUpdateFiles) {
            _this.siteService.updateWithToken(_this.siteid, _this.token, toSave).subscribe(function (dataUpdate) {
                _this.snackBar.open('Site atualizado com sucesso', 'Ok', { duration: 4000 });
                _this.site = null;
                _this.clearData();
            }, function (err) {
                _this.storeData();
            });
        }, function (err) {
            _this.storeData();
        });
    };
    SiteInvestigationWithTokenComponent.prototype.joinDataHora = function (d, h) {
        var ano = 1900 + d.getYear();
        var mesI = d.getMonth(); // mes-1
        var dia = d.getDate();
        var hora = parseInt(h.replace(/[:].*/, ''), 10);
        var mins = parseInt(h.replace(/.*[:]/, ''), 10);
        var data = new Date(ano, mesI, dia, hora, mins);
        return data;
    };
    SiteInvestigationWithTokenComponent.prototype.getlocation = function () {
        var _this = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.site.lat = position.coords.latitude;
                _this.site.lng = position.coords.longitude;
            }, function (error) {
                var message = '';
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        message = 'É necessário liberar o acesso à sua geolocalização';
                        break;
                    case error.POSITION_UNAVAILABLE:
                        message = 'Informação de geolocalização indisponível';
                        break;
                    case error.TIMEOUT:
                        message = 'Timeout ao pegar geolocalização';
                        break;
                    default:
                        message = 'Erro desconhecido';
                        break;
                }
                _this.snackBar.open(message, 'Ok', { duration: 2000 });
            });
        }
        else {
            this.snackBar.open('Seu navegador não suporta geolocation', 'Ok', { duration: 2000 });
        }
    };
    SiteInvestigationWithTokenComponent.prototype.checkStore = function () {
        var _this = this;
        // verifica se o form foi modificado
        if (this.form.form.pristine === true) {
            return true;
        }
        var dialogRef = this.dialog.open(StoreDataDialogComponent, {
            data: {}
        });
        return dialogRef.afterClosed().map(function (result) {
            if (result && result.what === 'store') {
                _this.save();
                return true;
            }
            else {
                return true;
            }
        });
    };
    SiteInvestigationWithTokenComponent.prototype.checkRestore = function () {
        var _this = this;
        if (this.cookieService.check('when') === false) {
            // não há valores salvos
            return;
        }
        var dialogRef = this.dialog.open(RestoreDataDialogComponent, {
            data: {
                when: this.cookieService.get('when')
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.what === 'restore') {
                _this.restoreData();
            }
        });
    };
    SiteInvestigationWithTokenComponent.prototype.canDeactivate = function () {
        return this.checkStore();
    };
    return SiteInvestigationWithTokenComponent;
}());
export { SiteInvestigationWithTokenComponent };
var RestoreDataDialogComponent = /** @class */ (function () {
    function RestoreDataDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    RestoreDataDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close({ what: 'restore' });
    };
    RestoreDataDialogComponent.prototype.onClickNo = function () {
        this.dialogRef.close({ what: '' });
    };
    return RestoreDataDialogComponent;
}());
export { RestoreDataDialogComponent };
var StoreDataDialogComponent = /** @class */ (function () {
    function StoreDataDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    StoreDataDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close({ what: 'store' });
    };
    StoreDataDialogComponent.prototype.onClickNo = function () {
        this.dialogRef.close({ what: '' });
    };
    return StoreDataDialogComponent;
}());
export { StoreDataDialogComponent };
