import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
import { take, concatMap } from 'rxjs/operators';
import { from } from 'rxjs';

interface PaymentData { origemId: string; invoice: string; OV: any; origem: string; }
interface FailData { line: number; errorCode: number; payment: PaymentData; }
interface ErrorData { text: string; fails: FailData[]; }

@Component({
  selector: 'app-finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.scss']
})

export class FinanceListComponent implements OnInit, OnDestroy {
  paymentList: any[] = [];
  data: any[] = null;
  cachedData: any[] = null;
  listPay: any[] = [];
  role: number;

  columns = [
    {value: 'status', name: 'status'},
    {value: 'client', name: 'FINANCE_LIST/table/client'},
    {value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp'},
    {value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr'},
    {value: 'prolog', name: 'FINANCE_LIST/table/prolog'},
    {value: 'costProject', name: 'FINANCE_LIST/table/costProject'},
    {value: 'OV', name: 'FINANCE_LIST/table/ov'},
    {value: 'type', name: 'type'},
    {value: 'origem', name: 'FINANCE_LIST/table/origem'},
    {value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL'},
    {value: 'createdAt', name: 'FINANCE_LIST/table/date'}
  ];

  filterDates: any = {
    start: null,
    end: null,
  };

  subscriptions: any[] = [];

  constructor(public dialog: MatDialog,
              private mdrService: MdrService,
              public excelService: ExcelService,
              private drService: DrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;

    this.filterDates.start = new Date();
    this.filterDates.start.setDate(this.filterDates.start.getDate() - 15);
    this.filterDates.end = new Date();
    console.log(this.filterDates);
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getData(rangeDates: Date[] = null) {

    const body: Date[] = rangeDates && rangeDates.length >= 2 ? rangeDates : [];
    this.subscriptions.push(this.mdrService.getAllPaymentList(...body).subscribe( (data) => {
      // console.log(data);
      const keys = data.length ? Object.keys(data[0]) : [];

      if (!data) {
        data = [];
      }

      this.data = data.map( (k) => {
        for (let i = 0 , l = keys.length; i < l; i++) {
          if ( k[keys[i]] === null || k[keys[i]] === undefined ) {
            k[keys[i]] = '';
          }
        }
      });
      data.forEach( (k) => {
        k.currency = k.value / 100;
        k.createdAt = new Date(k.createdAt);
        k.unique = k.origem + '|' + k.origemId + '|' + k.invoice + '|' + k.OV;
      });
      this.data = data;

      if (rangeDates) {
        this.snackBar.open('Novos dados carregandos com sucesso', 'Ok', {
          duration: 4000,
        });
      }
    }));
  }

  updateFromCachedData(data = null) {
    this.snackBar.open('Carregando novos dados, por favor aguarde um instante', 'Ok', {
      duration: 4000,
    });

    this.getData(data);
  }

  insertPaymentFromFile(fileupload: any) {
   // console.log(fileupload);
    // console.log(fileupload);
    const input = fileupload.target;
    // console.log(fileupload);
    this.paymentList = [];
    for (let index = 0; index < input.files.length; index++) {
      this.subscriptions.push(this.excelService.importFromExcel(fileupload).subscribe( (data) => {
        data.forEach( (el, i, arr) => {
          const payment = {};
         // console.log(el);
          try {
            payment[''] = el.pickup_address;
            // console.log(drAux.status);

          } catch (ex) {
            // console.log('erro ao ler arquivo xlsx ',ex);
            this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
          }

        });
      }));
    }
  }

  updateFromFile(fileupload: any) {
    const input = fileupload.target;
    this.subscriptions.push(this.excelService.importFromExcel(fileupload).subscribe( (data) => {
      // console.log(data);
      const avisos = [];
      const checkRow = (row) => {
        // status é válido
        if ( 'status' in row
          && !Object.values(PAYMENT_STATUS).includes(row['status']) ) {
          avisos.push(`Linha ${row.__rowNum__} contém status inválido`);
          return false;
        }
        // campos necessários estão presentes
        if ( !('origemId' in row)
          || !('invoice' in row)
          || !('OV' in row)
          || !('origem' in row)) {
          avisos.push(`Linha ${row.__rowNum__} não contém campos obrigatórios [origemId,invoice,OV,origem]`);
          return false;
        }
        // linha já existe, portanto pode ser atualizada
        // if ( !this.data.find((el) => {
        //   return (
        //     // tslint:disable-next-line:triple-equals
        //     el['origemId'] == row['origemId']
        //     // tslint:disable-next-line:triple-equals
        //     && el['invoice'] == row['invoice']
        //     // tslint:disable-next-line:triple-equals
        //     && el['OV'] == row['OV']
        //     // tslint:disable-next-line:triple-equals
        //     && el['origem'] == row['origem']
        //   );
        // }) ) {
        //   avisos.push(`Linha ${row.__rowNum__} não existe no sistema`);
        //   console.log(row);
        //   return false;
        // }
        return true;
      };
      // se todas as linhas recebidas são válidas
      if ( data.every(checkRow) ) {
        // atualiza as linhas
        const total = data.length;
        // console.log(total);
        //
        const groupSize = 1000;
        const aux = Math.ceil( total / groupSize);
        const dataDividedByGroup: any = {};

        for (let i = 0 ; i < aux ; i++ ) {
          dataDividedByGroup[ String( i * groupSize ) ] = data.slice( i * groupSize , ( ( i + 1 ) * groupSize ) );
        }

        const groupedDataArray: any[] = Object.values(dataDividedByGroup);

        const splittedQuery = from(groupedDataArray).pipe(
          concatMap( (group) => this.mdrService.updatePayment(group).pipe( take(1) )),
          take(groupedDataArray.length)
        );

        let linesCounter = 0;
        this.subscriptions.push(splittedQuery.subscribe((response) => {
          linesCounter++;
          console.log(response);
        }, (error) => {
          console.log(linesCounter);
          const errorData = error.error as ErrorData;
          if (errorData && errorData.text) {
            const count = (errorData.text.split(',').length);
            const problems = `${count} problema${count > 1 ? 's' : ''}`;
            console.log(errorData.text);
            const errorsSplitted = (errorData.text).split(',').map( elem => String(Number(elem) + (linesCounter * groupSize))).join(',');
            const errorMsg = `Erro ao subir arquivo, ${problems} nas linhas: ${errorsSplitted }`;
            this.snackBar.open(errorMsg, 'OK', { duration: 120000 });
            if (errorData.fails && errorData.fails.length > 0) {
              this.openErrorsDialog(errorData, (linesCounter * groupSize));
            }
          } else if ( 'status' in error ) {
            this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
          } else {
            this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
          }
        }, () => {
          this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
        }));
      } else {
        this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
        // mostra avisos gerados
        for (let i = 0, len = avisos.length ; i < len ; i++) {
          setTimeout(() => {
            this.snackBar.open(avisos[i], 'OK', { duration: 10000 });
          }, 2000 * (i + 1));
        }
      }
    }));
  }

  goToMDR(row, that) {
    if ( row.mdrId !== 0 ) {
      that.router.navigate(['logistic-order/' + row.mdrId]);
    } else {
      that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
    }
  }

  addList(listPayments) {
    this.listPay = listPayments;
  }

  payNF() {
    this.subscriptions.push(this.mdrService.payNF(this.listPay).subscribe( (response) => {
      this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
    }));
  }

  remove() {
    let removed = 0;
    const total = this.listPay.length;
    this.listPay.forEach( payment => {
      this.subscriptions.push(this.mdrService.removePayment(payment.id).subscribe((response) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
        const paymentI = this.data.findIndex(e => e.id === payment.id);
        if (paymentI >= 0) {
          this.data.splice(paymentI, 1);
        }
      }, (error) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
      }));
    });
  }

  openErrorsDialog(errorData: ErrorData, rowBase: number) {
    const lineErrors = [];
    errorData.fails.forEach((fail) => {
      let errorText = `Erro ${fail.errorCode} na linha ${fail.line}...`;
      if (fail.errorCode === 500) {
        errorText = `Informação inválida ou mal formatada.`;
      } else if (fail.errorCode === 404) {
        errorText = `Pagamento não encontrado para OV, invoice, origem, origemId.`;
      } else if (fail.errorCode === 403) {
        errorText = `Faltou informar OV, invoice, origem ou origemId corretamente.`;
      }
      lineErrors.push({
        'line': fail.line + rowBase,
        'errorCode': fail.errorCode,
        'errorText': errorText
      });
    });

    const count = (errorData.text.split(',').length);
    const problems = `${count} problema${count > 1 ? 's' : ''}`;
    let summaryText = `Ao todo ${problems} nas linhas: ${errorData.text}.`;
    const maxLength = 300;
    if (summaryText.length > maxLength) {
      summaryText = summaryText.substring(0, maxLength) + '...';
    }

    const dialogRef = this.dialog.open(FinanceErrorDialogComponent, {
      // width: '80vw',
      maxHeight: '80vh',
      data: {
          'summaryText': summaryText,
          'lineErrors': lineErrors
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      // console.log(`resp = ${JSON.stringify(response)}`);
    });
  }

}

@Component({
  selector: 'app-finance-error',
  templateUrl: 'finance-error-dialog.html',
  styleUrls: ['./finance-list.component.scss']
})
export class FinanceErrorDialogComponent {

  title = 'Erros no Arquivo';

  summaryText = '';

  lineErrors = [];

  columns = [
    {value: 'line', name: 'Linha'},
    {value: 'errorCode', name: 'Código do Erro'},
    {value: 'errorText', name: 'Descrição do Erro'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FinanceErrorDialogComponent>
  ) {
    this.summaryText = data.summaryText;
    this.lineErrors = data.lineErrors;
  }

  submit() {
    this.dialogRef.close();
  }

}
