import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MapsAPILoader} from '@agm/core';
import {FormControl} from '@angular/forms';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {Occurrence} from '../../models/occurrence';

@Component({
  selector: 'app-occurrence-list',
  templateUrl: './occurrence-list.component.html',
  styleUrls: ['./occurrence-list.component.scss']
})
export class OccurrenceListComponent implements OnInit {
  public searchControl: FormControl;
  inputOVs: number;
  opened: any;
  data: Occurrence[];
  displayedColumns = [
    {value: 'idMDR', name: 'ID MDR'},
    {value: 'uoc', name: 'Tipo de Ocorrência'},
    {value: 'mdrCode', name: 'MDR'},
    {value: 'text', name: 'Explicação'},
    {value: 'client', name: 'Client'},
    {value: 'shipping', name: 'LSP'},
    {value: 'dateScheduling', name: 'Cirado Em'},
  ];
  selected: DR[] = [];
  // displayedColumns = ['idMDR', 'uoc', 'text', 'dr.client', 'mdr.shippingCompany', 'createdAt'];
  dataSource = new MatTableDataSource< Occurrence >(this.data);

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(public dialog: MatDialog, private drService: DrService,
              public mdrService: MdrService, public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
  }

  ngOnInit() {
    this.mdrService.getOccurencesList().subscribe(
      (data2) => {
        // console.log("HI");
        data2.forEach(el => {
          el['client'] = el['dr'] ? el['dr'].client :  'Sem Cliente';
          el['shipping'] = el['mdr'] ? el['mdr'].shippingCompany : 'Sem LSP';
          el['mdrCode'] = el['mdr'] ? el['mdr'].pickupID : 'Sem Nome';
          el['dateScheduling'] = new Date( el['createdAt']);
        });
        this.data = data2;
      }
    );
  }

  navigateToMDR(row, that) {
    const url = that.router.serializeUrl(
      that.router.createUrlTree(['logistic-order/' + row.idMDR])
    );

    window.open(url, '_blank');
  }

  // unmakeDR() {
  //   this.selection.selected.forEach((el) => {
  //     this.drService.unmakeDR(el).subscribe(
  //       (data) => {
  //         //console.log('Removed');
  //       });
  //   });
  //
  //   this.drService.getAllOpen().subscribe(
  //     (data2) => {
  //       this.data = data2;
  //       this.dataSource = new MatTableDataSource<DR>(this.data);
  //       this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
  //         duration: 4000,
  //       });
  //     }
  //   );
  // }

  // createNewMDR() {
  //   const mdr = new MDR();
  //   mdr.status = 'MDR em criação';
  //   this.selection.selected.forEach((el: DR) => {
  //     mdr.drList.push(el);
  //   });
  //   this.mdrService.insert(mdr).subscribe( (data) => {
  //     this.drService.getAllOpen().subscribe(
  //       (data2) => {
  //         this.data = data2;
  //         this.dataSource = new MatTableDataSource<DR>(this.data);
  //         this.snackBar.open('Ordens de entrega liberada com sucesso', 'Ok', {
  //           duration: 4000,
  //         });
  //       }
  //     );
  //   });
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
