import {Component, OnInit, ViewChild, Inject, ElementRef, Input, SimpleChange, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource, MatSort, MatSnackBar, MatMenuTrigger, MatMenuModule} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {Occurrence} from '../../../models/occurrence';
import {environment} from '../../../../environments/environment';
import {MDR} from '../../../models/mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {NfeService} from '../../../providers/nfe.service';
import {MdrService} from '../../../providers/mdr.service';
import {DrService} from '../../../providers/dr.service';
import {DomSanitizer} from '@angular/platform-browser';
import {OVService} from '../../../providers/ov.service';
import { saveAs, encodeBase64 } from 'file-saver/FileSaver';
import {MdrObservable} from '../../../services/mdr.service';
import {UOCObservable} from '../../../services/uoc.service';
import * as JSZip from 'jszip';
import { forkJoin, of } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { HubService } from '../../../providers/hub.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})

export class AttachmentsComponent implements OnInit {
  @Input() mdr: MDR;
  user: any;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Output() updateValue = new EventEmitter<boolean>();

  _mdr: MDR;
  nfList: any[];
  nfFiles = [];
  rgAux = '';
  nameAux = '';
  dateAux = '';
  hourAux = '';
  podFiles = 0;
  uocList = [];
  uocFiles = [];
  disablePODEditing = false;
  sendStatus = false;
  statusContainer = false;
  totalNfCost: number;
  fakeUoc = 'Anexo de Confirmação';

  savePod = false;

  // se existe uma NF que foi emitida a mais de 30 dias
  existeNfVermelha = false;
  today = new Date();

  // contagem de NFs e PODs
  uniqueCount = {
    relation: null,
    nfs: null,
    pods: null,
  };

  // relations ausentes
  missingFiles = [];

  // novo modo de pegar anexos
  manuallyUploadedFiles = [];
  invoiceFiles = [];
  proofOfDeliveryFiles = [];
  podList = {
    nfs: [],
    ovs: [],
    cte: null
  };
  // pra icone de ha quanto tempo os PODs foram emitidos
  icons = {
    'green': 'fas fa-check-circle',
    'yellow': 'fas fa-exclamation-triangle',
    'red': 'fas fa-times-circle',
    'undefined': 'fas fa-hourglass-half',
  };
  tooltips = {
    'green': 'Nota Fiscal emitida menos de 15 dias atrás',
    'yellow': 'Nota Fiscal emitida entre 15 e 30 dias atrás',
    'red': 'Nota Fiscal emitida mais de 30 dias atrás',
    'undefined': 'Sem data de emissão da Nota Fiscal',
  };

  constructor(public ovService: OVService,
              public uploadService: UploadFileService,
              private sanitizer: DomSanitizer,
              public mdrService: MdrService,
              public drService: DrService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public nfeservice: NfeService,
              public uocObservable: UOCObservable,
              public mdrObservable: MdrObservable,
              public hubService: HubService,
  ) {
    this.user = JSON.parse(localStorage.getItem('currentUser')).user;
    this.nfList = [];
    this.ngOnInit();
  }
  ngOnInit() {
    // ao trocar a MDR
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      const PODS_BLOCKED_STATUS = [
        'Entrega de carga certificada pelo LSP',
        'Entrega certificada-LSP',
        'MDR paga / concluída',
        'MDR paid / completed'
      ];
      this.disablePODEditing = PODS_BLOCKED_STATUS.includes(this.mdr['status']);
      this.updateNF();
    });
    // ao trocarem os UOCs
    this.uocObservable.currentUOC.subscribe((data) => {
      this.uocList = data.filter(e => e.needsAttachment && e.uoc !== this.fakeUoc ) || [];
      this.updateUOCFiles();
    });
  }

  receiveFile(event) {
    this.savePod = true;
    this.updateNF();
    this.updateUOCFiles();
  }

  updateInfo() {
    this.nfList.forEach( (nf) => {
      if (typeof nf['file'] !== 'undefined' && nf['protected'] === false) {
        nf['file'].forEach( (file) => {
          this.uploadService.updateFileInfo(this.mdr.id, file).subscribe((data) => {
            // console.log(data);
          });
        }, this);
      }
    }, this);
  }

  removeFile(file: any, entity: string) {
    this.uploadService.deleteByEntity(file['id'], entity).subscribe(response => {
      this.updateNF();
      this.updateUOCFiles();
    });
  }

  savePOD(nf: any, ans = null) {
    const info = {
      'info_id' : nf.id,
      'info_deliveredAt': nf.fileInfo.deliveredAt,
      'info_name': nf.fileInfo.name,
      'info_rg': nf.fileInfo.rg,
      'info_timeDeliveredAt': nf.fileInfo.timeDeliveredAt,
    };
    this.uploadService.updateFileInfo( this.mdr.id, info ).subscribe( (response) => {
      if (ans) {
        this.podList.cte = ans;
      }
      this.snackBar.open('Dados do Recebedor atualizados', 'Fechar', {
        duration: 4000,
      });

    });
  }

  allPODsUploaded() {
    for (let i = 0; i < this.uocList.length; i++) {
      if (this.uocList[i].files.length === 0) {
        return false;
      }
    }

    for (let i = 0; i < this.nfList.length; i++) {
      if (this.nfList[i].dr === this.nfList[i].drSelected) {
        if (this.nfList[i].file === undefined) {
          return false;
        }
      }
    }

    let neededPods = this.podList.nfs.length + this.podList.ovs.length;
    let allPods = [].concat(
      ...this.podList.nfs.map((nf) => nf.files),
      ...this.podList.ovs.map((ov) => ov.files)
    );
    if (this.podList.cte !== undefined && this.podList.cte !== null) {
      neededPods += this.podList.cte.length;
      allPods = allPods.concat(this.podList.cte);
    }

    let flag = true;
    this.podList.ovs.forEach(el => {
      // console.log(el, el.files.length);
      if (el.files.length === 0) {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    if (this.uniqueCount.pods > neededPods) {
      return false;
    }

    // check that all PODs contain the needed information
    let missingInformation = false;

    allPods.forEach((pod) => {
      if (pod.fileInfo.name === null || pod.fileInfo.rg === null || pod.fileInfo.deliveredAt === null || pod.fileInfo.timeDeliveredAt === null) {
        missingInformation = true;
      }
    });

    if (missingInformation) {
      return false;
    }

    // all is good
    return true;
  }

  public updateNF(updateMDR = false) {

    // busca arquivos subidos manualmente
    this.ovService.getFilesFromMdrManually(this.mdr.id).subscribe((response) => {
      const files = response;

      files.mdrFiles.forEach( (file) => {
        file.type = 'mdr';
      });
      files.drFiles.forEach( (file) => {
        file.type = 'dr';
      });

      this.manuallyUploadedFiles = files.mdrFiles.concat(files.drFiles);
    });

    // busca anexos de OVs e NFs de terceiros
    // busca tambem relations
    forkJoin({
      ovs: this.ovService.getFilesFromMdrByOv(this.mdr.id),
      nfs: this.ovService.getFilesFromMdrByNf(this.mdr.id),
      relations: this.ovService.getNfRelationsByMdr(this.mdr.id),
    }).subscribe((response) => {
      // console.log({ovs: response.ovs, nfs: response.nfs});

      // conta relations
      this.uniqueCount.relation = response.relations.ovs.length + response.relations.nfs.length;

      this.invoiceFiles = [].concat(response.ovs, response.nfs)
                            .filter((value, index, self) => value != null && self.map((file) => file ? file.fileEntity.idEntity : null).indexOf(value.fileEntity.idEntity) === index);
      // console.log({invoices: this.invoiceFiles});

      // conta OVs e NFs únicas
      const existingFiles = this.invoiceFiles
                                .map((file) => file.fileEntity.idEntity)
                                .filter((value, index, self) => self.indexOf(value) === index);
      this.uniqueCount.nfs = existingFiles.length;

      // salva quais relations estão ausentes
      this.missingFiles = [].concat(response.relations.ovs, response.relations.nfs)
                            .filter((value) => {
                              return (
                                existingFiles.indexOf(value) === -1 &&
                                existingFiles.indexOf(value.replace(/^[0]*/, '').replace(/[-].*/, '')) === -1
                              );
                            });

      this.totalNfCost = 0;
      this.invoiceFiles
      .forEach(file => {
        if ( file.fileEntity.entity === 'nfe_xml' ) {
          this.totalNfCost += parseFloat(file.nfe_xml.totalValue);
        } else if ( file.fileEntity.entity === 'NFe' ) {
          this.totalNfCost += parseFloat(file.nfe.totalValue);
        }
      });
      this.updateValue.next(updateMDR);
    });

    // busca PODs de OVs e NFs de terceiros
    forkJoin({
      ovs: this.ovService.getFilesFromMdrByOvForPOD(this.mdr.id),
      nfs: this.ovService.getFilesFromMdrByNfForPOD(this.mdr.id),
    }).subscribe((response) => {
      // console.log({response: response});

      // se nao possui nenhum dos dois
      if (response.ovs === null && response.nfs === null) {
        // busca PODs de CTE
        this.ovService.getFilesFromMdrByCteForPOD(this.mdr.id).subscribe((ans) => {
          if (ans) {
            if (this.mdr.type === 'Cross Docking' && this.mdr.SAD !== '' && this.savePod === true) {
              for (const elem of ans) {
                if (elem.fileInfo.name === null && elem.fileInfo.rg === null && elem.fileInfo.deliveredAt === null && elem.fileInfo.timeDeliveredAt === null) {
                  elem.fileInfo.name = `HUB-${this.mdr.shippingCompany}`;
                  elem.fileInfo.rg = `HUB-${this.mdr.shippingCompany}`;
                  elem.fileInfo.deliveredAt = elem.fileEntity.createdAt;
                  elem.fileInfo.timeDeliveredAt = elem.fileEntity.createdAt;
                  this.savePOD(elem, ans);
                  break;
                }
              }
            }
            this.podList.cte = ans;
          }
        });
        // pra CTE é sempre só 1 POD
        this.uniqueCount.pods = 1;
        return;
      }

      const now = new Date().getTime();
      // calcula tempo desde a emissão da nota
      function getColor(item) {
        // sem data de emissão
        if ( !item.emissionDate ) {
          item['color'] = 'undefined';
          return item;
        }

        const emissionDate = new Date(item.emissionDate).getTime();
        if ( (now - emissionDate) <= (15 * 24 * 60 * 60 * 1000) ) {
          // menos de 15 dias
          item['color'] = 'green';
        } else if ( (15 * 24 * 60 * 60 * 1000) < (now - emissionDate)
                 && (now - emissionDate) <= (30 * 24 * 60 * 60 * 1000) ) {
          // entre 15 e 30 dias
          item['color'] = 'yellow';
        } else if ( (30 * 24 * 60 * 60 * 1000) < (now - emissionDate) ) {
          // mais de 30 dias
          item['color'] = 'red';
        }
        return item;
      }
      if (response.ovs !== null) {
        this.podList.ovs = response.ovs.map(getColor);
      }
      if (response.nfs !== null) {
        this.podList.nfs = response.nfs.map(getColor);
      }
      // console.log({podList: this.podList});

      // conta PODs de OVs e NFs únicas
      this.uniqueCount.pods =
        this.podList.ovs
            .map((ov) => ov.referenceNoNf)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length +
        this.podList.nfs
            .map((nf) => nf.invoiceNumber)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length;

    });

  }

  updateUOCFiles() {
    // atualiza anexos dos UOCs
    this.uocList.forEach( (uoc, i, arr) => {
      // busca os arquivos associados
      this.mdrService.getUOCWithInfo(uoc.id).subscribe( (response) => {
        uoc['files'] = response;
      });
    });
  }

  copyData(file) {
    this.rgAux = file.fileInfo.rg;
    this.nameAux = file.fileInfo.name;
    this.dateAux = file.fileInfo.deliveredAt;
    this.hourAux = file.fileInfo.timeDeliveredAt;
  }

  pasteData(file) {
    file.fileInfo.rg = this.rgAux;
    file.fileInfo.name = this.nameAux;
    file.fileInfo.deliveredAt = this.dateAux;
    file.fileInfo.timeDeliveredAt = this.hourAux;
  }

  async dowloadAll() {
    const zip = new JSZip();
    const img = zip.folder('images');
    const nffiles = this.invoiceFiles;
    const uploadService = this.uploadService;
    for (const i of nffiles) {
      const image = i;
      const url = i.path;
      await uploadService.getImage(url).subscribe((result) => {
        const reader = new FileReader();
        reader.readAsText(result);
        reader.onloadend = function () {
          const imgData = reader.result;
          img.file(image['fileOriginalName'], result, {base64: true});
          if (i === nffiles[nffiles.length - 1]) {
            zip.generateAsync({type: 'blob'})
               .then(function (content) {
                 // see FileSaver.js
                 saveAs(content, 'notas_fiscais.zip');
               });
          }
        };
      });
    }
  }

  removeNfs( toReturn: boolean ) {
    const isHubReturn: boolean = toReturn;
    // busca por drId
    this.ovService.getNfNotRelationsByMdr(this.mdr.id).subscribe((existing) => {
      // abre dialog
      this.dialog.open(RemoveNfsDialogComponent, {
        data: {
          existing: existing,
          isHubReturn: isHubReturn
        }
      }).afterClosed().subscribe(({ toRemove, selectedHub }) => {
        console.log(isHubReturn, selectedHub);
        if ( isHubReturn === true && selectedHub !== null )  {
          if (toRemove.length > 1) {
            this.snackBar.open('Favor selecionar apesar uma Nota', 'Ok', { duration: 4000 });
          }
          this.ovService.sendHubReturn(toRemove, selectedHub).subscribe((resp) => {
            this.snackBar.open('Notas fiscais desassociadas com sucesso', 'Ok', { duration: 4000 });
            this.updateNF(true);
          }, (error) => {
            this.snackBar.open('Erro ao desassociar notas fiscais', 'Ok', { duration: 4000 });
          });
        } else if ( isHubReturn === false && !selectedHub ) {
        // envia pra remoção
          this.ovService.removeFromMDR(this.mdr.id, toRemove).subscribe((response) => {
            // remoção feita com sucesso
            this.snackBar.open('Notas fiscais desassociadas com sucesso', 'Ok', { duration: 4000 });
            this.updateNF(true);
          }, (err) => {
            // erro ao remover
            this.snackBar.open('Erro ao desassociar notas fiscais', 'Ok', { duration: 4000 });
          });
        }
      });
    });
  }

  addNfs() {
    this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
      this.dialog.open(AddNfsDialogComponent, {
        width: '60vw',
        data: {
          drList: drList,
        }
      }).afterClosed().subscribe((response) => {
        // envia pra adição
        this.ovService.addToDR(this.mdr.id, response.dr.id, response.toAdd).subscribe(() => {
          // adição feita com sucesso
          this.snackBar.open('Notas fiscais associadas com sucesso', 'Ok', { duration: 4000 });
          this.updateNF(true);
        }, (err) => {
          // erro ao adicionar
          this.snackBar.open('Erro ao associar notas fiscais', 'Ok', { duration: 4000 });
        });
      });
    });
  }

}

@Component({
  selector: 'app-remove-nfs-dialog',
  templateUrl: 'remove-nfs-dialog.html',
  styleUrls: ['./attachments.component.scss']
})
export class RemoveNfsDialogComponent {
  existing = [];
  toRemove = [];
  columns = [
    {value: 'id', name: 'OV/NF'},
    {value: 'text', name: 'tipo'},
  ];
  valid = true;
  hubList: any[];
  // hubId
  selectedHub: any;

  // refers to dialog
  title = '';
  description = '';

  // refers to dialog header if is hubReturn
  returnModalInfo = {
    titleInfo: 'Enviar Nota para Hub',
    descriptionInfo: 'Selecione a nota que deseja desassociar e o HUB que deseja enviar a nota. Após isso, uma nova DR com status HubReturn será criada com a nota que foi desassociada.'
  };

  // refers to dialog header if is not hub return
  notReturnModalInfo = {
    titleInfo: 'Desassociar Nota Fiscal',
    descriptionInfo: 'Selecione a nota que deseja desassociar da MDR, essa nota ficará disponível para outro Agendamento.'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { existing: any, isHubReturn: boolean },
    public dialogRef: MatDialogRef<RemoveNfsDialogComponent>,
    public hubService: HubService
  ) {
    this.existing = [].concat(
      this.data.existing.nfs.map((nf) => {
        return {
          id: nf,
          type: 'nfe',
          text: 'Nota Fiscal de Terceiro',
        };
      }),
      this.data.existing.ovs.map((ov) => {
        return {
          id: ov,
          type: 'zvsd1',
          text: 'Nota Fiscal',
        };
      }),
    );
  }

  ngOnInit() {
    // setting modal title and description
    if ( this.data.isHubReturn === true) {
      // filter rows by type
      // this.data.existing.filter( (d) => d.type === 'zvsd1');
      this.title = this.returnModalInfo.titleInfo;
      this.description = this.returnModalInfo.descriptionInfo;
      // get hubs
      this.hubService.getAll(this.valid).subscribe( (hubsResponse) => {
        if ( hubsResponse ) {
          this.hubList = hubsResponse;
        }
      });
    } else {
      this.title = this.notReturnModalInfo.titleInfo;
      this.description = this.notReturnModalInfo.descriptionInfo;
    }
  }

  submit() {
    this.dialogRef.close({
      toRemove: this.toRemove,
      selectedHub: this.selectedHub
    });
  }
  addToList(checkedItems) {
    this.toRemove = checkedItems;
  }

  checkDisability() {
    // return true if the button is disable
    if ( this.data.isHubReturn === false && this.toRemove.length === 0 ) {
      return true;
    } else if ( this.data.isHubReturn === true && this.toRemove.length !== 1 ) {
      return true;
    } else {
      return false;
    }
  }

}

@Component({
  selector: 'app-add-nfs-dialog',
  templateUrl: 'add-nfs-dialog.html',
  styleUrls: ['./attachments.component.scss']
})
export class AddNfsDialogComponent {
  toAdd = [];
  columns = [
    {value: 'column1', name: 'MDR'},
    {value: 'column2', name: 'Sales Order'},
    {value: 'column3', name: 'Invoice'},
    {value: 'column4', name: 'Client'},
    {value: 'column5', name: 'Equipment'},
  ];
  searchedValues = [];
  searchInput;
  drList = [];
  selectedDr = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddNfsDialogComponent>,
    public ovService: OVService,
    public nfService: NfeService,
    public snackBar: MatSnackBar,
  ) {
    this.drList = this.data.drList;
  }

  search() {
    if (this.searchInput === '') { return; }

    // search OVs and NFs
    forkJoin({
      ovs: this.ovService.getAll(this.searchInput).pipe(map((res) => res), catchError(e => of([]))),
      nfs: this.nfService.getAll(this.searchInput).pipe(map((res) => res), catchError(e => of(null)))
    }).subscribe((response) => {
      // add types to difference between the 2
      // and extract the info to show on the table
      let data = [];
      if (response.ovs.length > 0) {
        data = [].concat(data,
                         response.ovs.map((ov) => {
                           return Object.assign(ov, {
                             type: 'zvsd1',
                             column1: (!ov.mdr ? null : (ov.mdr.pickupID ? ov.mdr.pickupID : 'EDB-TP' + ('000000' + ov.mdr.id).slice(-6))),
                             column2: ov.salesDocument,
                             column3: ov.referenceNoNf,
                             column4: ov.customerName,
                             column5: ov.materialDescription,
                           });
                         })
        );
      }
      if (response.nfs !== undefined && response.nfs !== null) {
        data = [].concat(data,
                         [response.nfs].map((nf) => {
                           return Object.assign(nf, {
                             type: 'nfe',
                             column1: (!nf.mdr ? null : (nf.mdr.pickupID ? nf.mdr.pickupID : 'EDB-TP' + ('000000' + nf.mdr.id).slice(-6))),
                             column2: null,
                             column3: nf.invoiceNumber,
                             column4: nf.client,
                             column5: nf.materials,
                           });
                         })
        );
      }

      // still no results
      if (!data || data.length === 0) {
        this.snackBar.open('NF/OV já utilizada ou fora do sistema', 'Ok', {duration: 4000});
        return;
      }

      // extract the values to show and add to table
      this.searchedValues = data.map((row) => {
        return Object.assign(row, {

        });
      });
    }, (err) => {
      this.snackBar.open('NF/OV já utilizada ou fora do sistema', 'Ok', {duration: 4000});
    });
  }

  rowStyle(row) {
    if (!!row.drId) {
      return 'grey';
    }
    return null;
  }

  addToList(checkedItems) {
    // nao adiciona se tiver drId!=null
    this.toAdd = checkedItems.filter((row) => row.drId === null);
  }

  removeFromList(rowToBeRemoved) {
    this.toAdd = this.toAdd.filter((row) =>
      row.type === rowToBeRemoved.type && row.id !== rowToBeRemoved.id
    );
  }

  submit() {
    this.dialogRef.close({
      dr: this.selectedDr,
      toAdd: this.toAdd,
    });
  }

}
