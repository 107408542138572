import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { groupBy } from 'rxjs/operators';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class DashboardService {
  private token: string;
  private header;
  private url: string;
  public dateRange = {
    startDate: new Date,
    endDate: new Date
  };

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    this.url = base_url + 'statistics/';
    this.token = JSON.parse(localStorage.getItem('currentUser')).token;
    this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);
  }

  parseDates(dates?) {
    let stDate, fnDate;
    const now = new Date();
    if (dates === undefined) {
      // no Dates received:
      // use 1st day of current month as start
      // and now as end
      stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      stDate = new Date(stDate.setDate(0));
      fnDate = now;
    } else {
      // start date received
      if (dates[0] !== null) {
        // convert the value to Date
        stDate = new Date(dates[0]);
      } else {
        // no start date received
        // use 1st day of current month as start
        stDate = new Date(now.getFullYear(), now.getMonth(),  now.getDate());
        stDate = new Date(stDate.setDate(0));
      }

      // end date received
      if (dates[1] !== null) {
        // convert the value to Date
        fnDate = new Date(dates[1]);
      } else {
        // use today
        fnDate = now;
      }
    }

    return { start: stDate.toISOString(), end: fnDate.toISOString() };
  }

  setBody(start?, end?, ufSelected?, typeSelected?, lspSelected?, clientSelected?, group?, status?) {
    return {
      headers: this.header,
      params: {
        ...( start          && { startDate: start }),
        ...( end            && { endDate: end }),
        ...( typeSelected   && { type: typeSelected }),
        ...( lspSelected    && { lsp: lspSelected }),
        ...( ufSelected     && { uf: ufSelected }),
        ...( clientSelected && { client: clientSelected }),
        ...( group          && { groupBy: group }),
        ...( status         && { status: status }),
      }
    };
  }

  getMdrByShippingCompany(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/shippingCompany',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month')
    );
  }

  getDrByClient(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/client',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected)
    );
  }

  getMdrByCreated(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/createdAt/shippingCompany',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected)
    );
  }

  getMdrByDelivered(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);
    const status = ['MDR paga / concluída', 'Entrega de carga certificada pelo LSP', 'Carga entregue com sucesso'];

    return this.http.get(this.url + 'mdr/status/shippingCompany',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, null, status)
    );
  }

  getDrByStatus(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/status',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month')
    );
  }

  getC02ByCreatedAt(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/createdAt/c02',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month')
    );
  }

  getC02CAByCreatedAt(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/createdAt/c02CostAvoidance',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month')
    );
  }

  getDrByDriverPosition(dates?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/driverPosition', this.setBody());
  }

  getOccurrence() {
    const start = this.dateRange.startDate.toISOString();
    const end = this.dateRange.endDate.toISOString();

    return this.http.get(this.url + 'occurrence', this.setBody(start, end) );
  }

  getOccurrenceWords(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    const startDate = new Date(new Date().setDate(1)).toISOString(); // beginning of current month

    return this.http.get(this.url + 'occurrence/text',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected)
    );
  }

  getPods() {
    const start = this.dateRange.startDate.toISOString();
    const end = this.dateRange.endDate.toISOString();

    return this.http.get(this.url + 'pod', this.setBody(start, end) );
  }

  getCostKg(group: string, dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/costPerKilo',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, group)
    );
  }

  getOVsDelivered(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/status/delivered',
      this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected)
    );
  }
}
