import { Routes } from '@angular/router';
import { CheckTokenGuard } from './check-token.guard';
import { ResolverService } from './resolver.service';
import { ViewMdrComponent } from './view-mdr/view-mdr.component';
var routes = [
    {
        path: 'view/:token',
        canActivate: [CheckTokenGuard],
        resolve: {
            mdr: ResolverService
        },
        children: [
            { path: '', redirectTo: 'mdr', pathMatch: 'full' },
            { path: 'mdr', component: ViewMdrComponent }
        ]
    }
];
var WithoutLoginRoutingModule = /** @class */ (function () {
    function WithoutLoginRoutingModule() {
    }
    return WithoutLoginRoutingModule;
}());
export { WithoutLoginRoutingModule };
