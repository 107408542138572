import { OnChanges } from '@angular/core';
import { OVService } from '../../../providers/ov.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
var TermResultComponent = /** @class */ (function () {
    function TermResultComponent(aRoute, ovService, router) {
        this.aRoute = aRoute;
        this.ovService = ovService;
        this.router = router;
        this.OVDataSource = new MatTableDataSource([]);
        this.NFDataSource = new MatTableDataSource([]);
        this.MDRDataSource = new MatTableDataSource([]);
        this.SiteDataSource = new MatTableDataSource([]);
        this.displayedColumns = ['idDRidentifier', 'mdrName', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'action'];
    }
    TermResultComponent.prototype.ngOnChanges = function () {
        if (this.data) {
            this.search_term = this.data.term;
            this.OVDataSource.data = this.data.ovs;
            this.NFDataSource.data = this.data.nfs;
            this.MDRDataSource.data = this.data.mdrs;
            this.SiteDataSource.data = this.data.sites;
        }
    };
    TermResultComponent.prototype.gotoMDR = function (el) {
        var typeMdr = el.mdrType;
        if (typeMdr === 'Cross Docking') {
            window.open('logistic-order/' + el.idMDR, '_blank').focus();
        }
        else {
            this.router.navigate(['logistic-order/' + el.idMDR]).then(function () {
                window.location.reload();
            });
        }
    };
    return TermResultComponent;
}());
export { TermResultComponent };
