<div class="mx-5 my-4">
  <p class="mat-h1">Edição de NFe de Terceiros</p>
</div>
<div class="colored-background table-background">
  <div class="mx-5 py-3">
    <mat-card class="rounded-corners">
      <mat-card-content class="d-flex flex-wrap">
        <div class="col-md-12">
          <!-- <input class="hidden" type='file' #fileInput accept='text/xml' (change)='openFile($event)' multiple> -->
          <!-- <button mat-raised-button class="button green-button text-white"
            (click)="fileInput.click()">{{ 'INSERT-NFE/not-uploaded/form/select-file' | translate }}</button> -->
        </div>
        <br/>
        <form #form="ngForm" class="d-flex flex-wrap">
          <mat-form-field class="col-md-4 col-lg-2">
            <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/invoice-number' | translate" [(ngModel)]="nfe.invoiceNumber" name="invoiceNumber" required>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-lg-2">
            <input matInput name="series" [placeholder]="'INSERT-NFE/not-uploaded/form/series' | translate" [(ngModel)]="nfe.series" required>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-lg-2">
            <mat-label>{{'INSERT-NFE/not-uploaded/form/client' | translate}}</mat-label>
            <mat-select [(ngModel)]="nfe.client" name="client">
              <mat-option *ngFor="let client of clientList" [value]="client">
                {{client}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-lg-2">
            <input matInput *ngIf="update" type="text" [placeholder]="'INSERT-NFE/not-uploaded/form/total-value' | translate" [ngModel]="nfe.totalValue | currency:'BRL'" (blur)="sanitizeInputPrice('totalValue', $event)" name="totalValue">
          </mat-form-field>
          <mat-form-field class="col-md-4 col-lg-2">
            <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/weight' | translate" [(ngModel)]="nfe.weight" type="number" name="weight" required>
            <span matSuffix> &nbsp;KG</span>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-lg-2">
            <input matInput [placeholder]="'INSERT-NFE/not-uploaded/form/type' | translate" [(ngModel)]="nfe.type" name="type" required>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <textarea matInput [placeholder]="'INSERT-NFE/not-uploaded/form/materials' | translate" [(ngModel)]="nfe.materials" name="materials" required></textarea>
          </mat-form-field>
          <mat-form-field>
            <input matInput [owlDateTimeTrigger]="myDatepicker" [owlDateTime]="myDatepicker" [placeholder]="'INSERT-NFE/not-uploaded/form/collect-date' | translate" [(ngModel)]="nfe.emissionDate" name="Datepicker" required>
            <owl-date-time #myDatepicker pickerType="both"></owl-date-time>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <input matInput type="text" id="prolog" [placeholder]="'INSERT-NFE/not-uploaded/form/prolog' | translate" [(ngModel)]="nfe.prolog" name="prolog" required>
          </mat-form-field>
        </form>
        <div class="col-md-12 mb-3">
          <div class="title center-text col-md-6">
            Subir Arquivo
            <app-upload-files class="right" [entity]="'NFe'" [id]="nfe.id" (messageEvent)="attachFile($event)" > </app-upload-files>
          </div>
          <div class="col-md-6" style="max-width: 500px;" *ngIf="imageFile !== undefined">
            <app-image-lightbox style="width: 200px" [images]="imageFile"></app-image-lightbox>
          </div>
        </div>
       

        
        <button mat-raised-button [disabled]="!form.valid" class="button green-button text-white col-md-4" (click)="saveNFe()">Salvar</button>
        <button mat-raised-button *ngIf="!nfe.drId" style="margin-left: 20px;" class="button red-button text-white col-md-4" (click)="removeNFe()">Remover</button>
        <button mat-raised-button *ngIf="nfe.drId" style="margin-left: 20px;" class="button green-button text-white col-md-4" (click)="goTo()">Link para DR</button>

        <div class="col-md-12 mb-3">
          Lista de Entregas já associadas
          <app-table
            [data]="listAllDrs"
            [columns]="columns"
            [dateSearch]="['createdAt']"
            [this]="this"
            [clickCallback]="goToProperDr"
          ></app-table>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>