import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {DR} from '../models/dr';
import {environment} from '../../environments/environment';
import {MDR} from '../models/mdr';
import {Occurrence} from '../models/occurrence';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class DrService {
    public token: string;

    constructor(private http: HttpClient) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

  public insert(dr: DR): Observable<DR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/', {
      dr: dr,
    }, {headers: header}).map((response: DR) => {
      return response;
    });
  }

  public recalculateLeg(lat: number, lng: number, dr: DR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/legs', {
      dr: dr,
      lat: lat,
      lng: lng,
    }, {headers: header}).map((response: any) => {
      return response;
    });
  }

  public insertFake(dr: DR): Observable<DR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(dr);
    return this.http.post(base_url + 'DR/fake', {
      dr: dr,
    }, {headers: header}).map((response: DR) => {
      // console.log(response);
      return response;
    });
  }

  public getCostsByMdrList(mdr: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(dr);
    return this.http.post(base_url + 'DR/getCostsByMdrList', {
      mdr: mdr,
    }, {headers: header}).map((response: any) => {
      // console.log(response);
      return response;
    });
  }

  public getById(drId: number): Observable<DR> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this.http.get(base_url + 'DR/' + drId, {headers: header}).map( (response: DR) => {
        return response;
      });
  }

  public  getAllInfo(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'DR/allInfo/' + id, {headers: header}).map( (response) => {
      return response;
    });
  }

  public getHUs(ids: number[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/HUs', {
      ids: ids,
    }, {headers: header}).map((response: any) => {
      return response;
    });
  }

  public  getAll(): Observable<DR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'DR/All', {headers: header}).map( (response: DR[]) => {
      return response;
    });
  }

  public  getForCockpit(): Observable<DR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/forCockpit', {headers: header}).map( (response: DR[]) => {
      return response;
    });
  }

  public  getAllFromLSP(lsp: string): Observable<DR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.post(base_url + 'DR/LSPAll', {lsp: lsp}, {headers: header}).map( (response: DR[]) => {
      return response;
    });
  }

  public  getAllOpen(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/All/open', {headers: header}).map( (response: DR[]) => {
      return response;
    });
  }

  public getStatus(drId: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/status', { drId: drId }, {headers: header}).map( (response) => {
      return response;
    });
  }


    public  getLastInserted(): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      // console.log(header);
      return this.http.get(base_url + 'DR/getLastInserted', {headers: header}).map( (response: {date}) => {
        // console.log(response);
        return response.date;
      });
    }

  public  getLastIdInserted(): Observable<number> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'DR/getLastIdInserted', {headers: header}).map( (response: {id}) => {
      // console.log(response);
      return response.id;
    });
  }

  public getTower(startDate: Date = null, endDate: Date = null): Observable<any> {
    let params = new HttpParams();

    if (startDate || endDate) {
      if (startDate) {
        params = params.set('startDate', startDate.toISOString());
      }

      if (endDate) {
        params = params.set('endDate', endDate.toISOString());
      }

    } else {
      params = params.set('limitData', 'true');
    }

    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'DR/tower', {headers: header, params: params}).map( (response) => {
      // console.log(response);
      return response;
    });
  }

  public getLSPTower(lsp: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.post(base_url + 'DR/lspTower', { lsp: lsp }, {headers: header}).map( (response) => {
      // console.log(response);
      return response;
    });
  }

  public getAllMDR(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'DR/mdr', {headers: header}).map( (response) => {
      // console.log(response);
      return response;
    });
  }

    public  getByMdr(id: number): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      // console.log(header);
      return this.http.get(base_url + 'DR/ByMDR/' + id, {headers: header}).map( (response) => {
        return response;
      });
    }


    public  unmakeDR(dr: DR[]): Observable<DR[]> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this.http.post(base_url + 'DR/unmakeDR', {
        drList: dr,
      }, {headers: header}).map( (response: DR[]) => {
        return response;
      });
    }

    public updateAddress(dr: DR): Observable<boolean> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.put(base_url + '/DR/', {
        dr: DR,
      }, {headers: header}).map((response) => {
        // console.log(response);
        return true;
      });
    }
  public update(dr: DR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(dr)
    return this.http.put(base_url + 'DR/', {
      dr: dr
    }, {headers: header}).map((response) => {
      // console.log(response);
      return true;
    });
  }

  public getDrWithToken(token: string): Observable<any> {
    return this.http.get(base_url + 'no-login/DR/' + token).map( (response) => {
      return response;
    });
  }

  public createBooking(dr: DR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/booking', {
      dr: dr,
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  public getFromSydle(ov: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/sydle', {
      ov: ov,
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  public getDistanceToLatLng(lat: string, lng: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'DR/booking/distance', {
      lat: lat,
      lng: lng,
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  public getBookings(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/booking', {headers: header}).map((response) => {
      return response;
    });
  }

  public getBooking(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/booking/' + id, {headers: header}).map((response) => {
      return response;
    });
  }

  public getValidation(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/booking/' + id + '/validate', {headers: header}).map((response) => {
      return response;
    });
  }

  public updateBooking(id: number, dr: DR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'DR/booking/' + id, {
      dr: dr,
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  public reproveBooking(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'DR/booking/' + id, {headers: header}).map((response) => {
      return response;
    });
  }

  public updateBookingToDR(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'DR/booking/' + id + '/create', { }, {headers: header}).map((response) => {
      return response;
    });
  }

  public notifyStatusUpdate(id: number, drStatus: string, createdBy: string, justification: string = null): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const body = {
      status: drStatus,
      createdBy: createdBy,
      justification: justification
    };

    return this.http.post(base_url + 'DR/booking/' + id + '/notify', body, {headers: header}).map((response) => {
      return response;
    });
  }

  public getHistory(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'DR/history/' + id, {headers: header}).map((response) => {
      return response;
    });
  }

  public getFilesFromDrManually(idDr: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'file/dr/' + idDr + '/manual', {headers: header});
  }
}
