import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    NotificationService.prototype.createMessage = function (idMDR, idUser) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        // let params = new HttpParams().set("token",token);
        return this.http.post(base_url + 'Notification/create', {
            idMDR: idMDR,
            idUser: idUser,
        }, { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    return NotificationService;
}());
export { NotificationService };
