/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./speedometer-chart.component.scss.shim.ngstyle";
import * as i1 from "../graph-card.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./speedometer-chart.component";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../misc/export-xlsx/export-xlsx";
var styles_SpeedometerChartComponent = [i0.styles, i1.styles];
var RenderType_SpeedometerChartComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SpeedometerChartComponent, data: {} });
export { RenderType_SpeedometerChartComponent as RenderType_SpeedometerChartComponent };
export function View_SpeedometerChartComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 16777216, null, null, 1, "i", [["class", "fa fa-file-pdf click-button"], ["matTooltip", "Download em excel"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i2.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i2.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.download() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i2.ɵdid(1, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i2.ElementRef, i5.ScrollDispatcher, i2.ViewContainerRef, i2.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "Download em excel"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i2.ɵinlineInterpolate(1, "", _co.container, ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_SpeedometerChartComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-speedometer-chart", [], null, null, null, View_SpeedometerChartComponent_0, RenderType_SpeedometerChartComponent)), i2.ɵdid(1, 114688, null, 0, i10.SpeedometerChartComponent, [i11.TranslateService, i12.ExcelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpeedometerChartComponentNgFactory = i2.ɵccf("app-speedometer-chart", i10.SpeedometerChartComponent, View_SpeedometerChartComponent_Host_0, { container: "container" }, {}, []);
export { SpeedometerChartComponentNgFactory as SpeedometerChartComponentNgFactory };
