import { ElementRef, EventEmitter } from '@angular/core';
var FormUploadComponent = /** @class */ (function () {
    function FormUploadComponent(host) {
        this.host = host;
        this.showName = true;
        this.setFile = new EventEmitter();
        host.nativeElement.value = '';
    }
    FormUploadComponent.prototype.emitFiles = function (event) {
        var file = event && event.item(0);
        this.selecionado = file.name;
        this.setFile.emit(file);
    };
    FormUploadComponent.prototype.onClick = function () {
        document.getElementById('fileInput-' + this.name).click();
    };
    return FormUploadComponent;
}());
export { FormUploadComponent };
