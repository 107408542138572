<div *ngIf="selectedHub === undefined">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'HUB' | translate }}</p>
  </div>
  <div *ngIf="dataSource.data.length > 0" class="colored-background table-background">
    <div class="mx-5">
      <mat-form-field class="my-3 pt-1 pl-4 rounded-corners col-md-8" floatLabel="never">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let chip of searchChips"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chip)">
            {{chip.name}}
            <i matChipRemove class="fa fa-times ml-1" *ngIf="removable"></i>
          </mat-chip>
          <!-- <mat-label><i class="fa fa-search mr-1"></i>{{ 'CONTROL_TOWER/search' | translate }}</mat-label> -->
          <input placeholder="{{ 'HUB/type' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
      <button *ngIf="hubUserAdmin" mat-raised-button class="rounded-corners my-3 pt-1 pl-4 col-md-3 primary-button" (click)="openAddHubDialog()">{{ 'HUB/add' | translate }}</button>
      <div class="col-md-2">
        <span (click)='changeShowDeleted()'><i *ngIf="showDeleted" class="fa fa-eye" style="color: gray;" matTooltip="Mostrar Hubs ativos"></i> </span>
        <span (click)='changeShowDeleted()'><i *ngIf="!showDeleted" class="fa fa-eye-slash" style="color: gray;" matTooltip="Mostrar Hubs deletados"></i> </span>
      </div>
      <table mat-table #table [dataSource]="dataSource" matSort class="rounded-corners col-md-12">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'status' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/table/name' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="sc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/table/lsp' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{ element.sc ? element.sc.name : '' }} </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/table/address' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
        </ng-container>
        <ng-container matColumnDef='latitude'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>lat<th>
            <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
        </ng-container>
        <ng-container matColumnDef='longitude'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>lng<th>
            <td mat-cell *matCellDef="let element"> {{element.lng}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToHub(row)"></tr>
      </table>
    </div>
  </div>
  <div *ngIf="dataSource.data.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
    <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
    <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'HUB/empty' | translate }}</p>
  </div>
</div>

<div *ngIf="selectedHub">
  <div class="mx-5 my-4 row">
    <div>
      <i class="mr-1 fas fa-arrow-circle-left" (click)="return()"></i>
    </div>
    <p class="mat-h1">{{ 'HUB/SELECT' | translate }} {{selectedHub.name}}</p>
    <!--<p class="mx-4">{{ 'HUB/SELECT/txt1' | translate }}<br>{{ 'HUB/SELECT/txt2' | translate }}</p>-->
  </div>
  <div class="mat-drawer-container">
    <div class="blue-background back-container">
    </div>
    <div class="row">
      <div class="white-background container-row center-container container-border col-md-10">
        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->
        <div class="row container-title">
          <div class="col-md-12">
            {{ 'HUB/card/data' | translate }}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-12">
              <span class="title-info">{{ 'HUB/card/hub' | translate }}</span> <span class="data-info">{{selectedHub.name}} </span>
            </div>
            <div class="col-md-12">
              <span class="title-info">{{ 'HUB/card/dr_stored' | translate }} </span> <span class="data-info">{{ hubSource.data.length }} </span>
            </div>
            <div class="col-md-12">
              <span class="title-info">{{ 'HUB/card/value_stored' | translate }} </span> <span class="data-info">{{totalHub/ 100 | currency : 'BRL' : 'symbol' : '.2' : 'pt-br'}} </span>
            </div>
            <div class="col-md-12">
              <span class="title-info">{{ 'HUB/card/address' | translate }} </span> <span class="data-info">{{selectedHub.address}} </span> <span (click)="viewMap()"><i class="fa fa-map"></i></span>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <button *ngIf="hubUserAdmin" class="button warn-button" (click)="removeHub()">{{ 'HUB/card/remove' | translate }}</button>
          </div>
          <!-- <div *ngIf="hubSource.data.length === 0 && hubUserAdmin" class="col-md-4">
            <button class="button green-button" style="margin-bottom: 10px;" (click)="editHub()">{{ 'HUB/card/edit' | translate }}</button>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-12">
            <div [class]="getMapClass()">
              <agm-map [latitude]="lat" [longitude]="lng" [mapTypeControl]="true" >
                <span *ngIf="marker">
                  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                </span>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hubSource.data.length > 0" class="mx-5">
      <table mat-table #table [dataSource]="hubSource" matSort class="rounded-corners col-md-12">
        <ng-container matColumnDef="dr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/SELECT/table/id' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{ element.dr.idDRidentifier }} </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/SELECT/table/value' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{element.dr.value / 100 | currency : 'BRL' : 'symbol' : '.2' : 'pt-br'}} </td>
        </ng-container>
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'HUB/SELECT/table/date' | translate }}<th>
            <td mat-cell *matCellDef="let element"> {{ element.when | date: "dd/MM/yy, h:mm a"}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="hubColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: hubColumns;" (click)="goToMDR(row)"></tr>
      </table>
    </div>

  </div>
</div>
