import { Subject } from 'rxjs/Subject';
var UOCObservable = /** @class */ (function () {
    function UOCObservable() {
        this.uoc = new Subject();
        this.currentUOC = this.uoc.asObservable();
    }
    UOCObservable.prototype.changeUOC = function (getUOC) {
        this.uoc.next(getUOC);
        // console.log(this.uoc);
    };
    return UOCObservable;
}());
export { UOCObservable };
