import { Component, OnInit } from '@angular/core';
import { OVService } from '../../providers/ov.service';
import { ReportService } from '../../providers/report.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { DR } from '../../models/dr';
import { Router } from '@angular/router';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ovsearch',
  templateUrl: './ovsearch.component.html',
  styleUrls: ['./ovsearch.component.scss']
})
export class OvsearchComponent implements OnInit {
  data: any;

  constructor(public aRoute: ActivatedRoute,
              public ovService: OVService,
              public reportService: ReportService,
              public excel: ExcelService,
              public router: Router) {
    this.aRoute.params.subscribe( (params) => {
      this.ovService.searchByOV(params.input).subscribe( data => {
        this.data = data;
      });
    });
  }

  ngOnInit() {
  }

  gotoMDR(el) {
    this.router.navigate(['logistic-order/' + el.idMDR]);
  }

  downloadReport() {
    let drs = [];

    for (const result of this.data) {
      for (const dr of result.mdrs) {
        drs.push(dr.id);
      }
      for (const dr of result.nfs) {
        drs.push(dr.id);
      }
      for (const dr of result.ovs) {
        drs.push(dr.id);
      }
      for (const dr of result.sites) {
        drs.push(dr.id);
      }
    }

    drs = drs.filter( (elem, index, self) => index === self.indexOf(elem) );

    this.reportService.getStakeholdersByDR(drs).subscribe( data => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
        skipHeader: true
      });
      const workbook: XLSX.WorkBook = { Sheets: { 'stake': worksheet }, SheetNames: ['stake'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.excel.saveAsExcelFile(excelBuffer, 'stake');
    });
  }
}
