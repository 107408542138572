import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';

@Component({
   selector: 'app-composite-bars-chart',
   templateUrl: './composite-bars-chart.component.html',
   styleUrls: ['./composite-bars-chart.component.scss']
})

export class CompositeBarsChartComponent implements OnInit {
   @Input() container: string;
   downloadData = [];
   createdTitle = this.translate.instant('COMPOSITE_BARS/created/title');
   finishedTitle = this.translate.instant('COMPOSITE_BARS/finished/title');
   createdTooltip = this.translate.instant('COMPOSITE_BARS/created/tooltip');
   finishedTooltip = this.translate.instant('Delivered');

   constructor(public translate: TranslateService, private excelService: ExcelService) { }

   ngOnInit() { }


   download() {
      this.excelService.exportAsExcelFile(this.downloadData, 'drs');
   }

   createChart(data: any, id: string, download?) {
      // console.log(data);
      this.createdTitle = this.translate.instant(data['createdTitle']);
      this.finishedTitle = this.translate.instant(data['finishedTitle']);
      this.createdTooltip = this.translate.instant(data['createdTooltip']);
      this.finishedTooltip = this.translate.instant(data['finishedTooltip']);

      // assumedly, this is a hack; and also a sign of very bad design
      const isCO2 = ['c02CAChart', 'c02Chart'].includes(id);

      if (download) {
        this.downloadData = download;
      }

      if (data) {
         Highcharts.chart({
            chart: {
               type: 'column',
               renderTo: id
            },
            title: {
               text: ''
            },
            xAxis: {
               categories: data.dates,
               crosshair: true
            },
            yAxis: {
               title: {
                  text: ''
               },
               min: 0,
               allowDecimals: false
            },
            tooltip: {
               headerFormat: '<span style="font-size:20px;font-family: Roboto;">{point.key}</span><table>',
               pointFormat:
               '<tr><td style="color:{series.color};padding:0;font-family: Roboto;">{series.options.custom.name}: </td>' +
               `<td style="padding:0;font-family: Roboto;"><b>&nbsp;{point.y:.${isCO2 ? 2 : 0}f}</b></td></tr>`,
               footerFormat: '</table>',
               shared: true,
               useHTML: true
            },
            credits: {
               enabled: false
            },
            legend: {
               layout: 'vertical',
               align: 'left',
               verticalAlign: 'middle',
               floating: false,
               borderWidth: 0,
               shadow: false,
               backgroundColor: '#FFFFFF',
               itemMarginBottom: 75,
               itemStyle: {
                  width: 200,
                  fontSize: '20px'
               },
               squareSymbol: false,
               symbolHeight: 15,
               symbolWidth: 25,
               symbolRadius: 8,
               symbolPadding: 10
            },
            responsive: {
               rules: [{
                  condition: {
                     maxWidth: 500
                  },
                  chartOptions: {
                     legend: {
                        align: 'right',
                        verticalAlign: 'top',
                        layout: 'horizontal',
                        squareSymbol: true,
                        symbolHeight: 10,
                        symbolWidth: 10,
                        floating: true,
                        itemMarginBottom: 0,
                        itemMarginTop: 0,
                        itemStyle: {
                           fontSize: '10px',
                           width: 150,
                           height: 50
                        },
                     },
                     series: [{
                        name: this.createdTitle,
                        data: data.valuesCreated,
                        color: '#ffcc66',
                        opacity : 0.8,
                        custom: {
                           name: this.createdTooltip
                        },
                        type: 'column'
                     }  as Highcharts.SeriesColumnOptions,
                        {
                           name: this.finishedTitle,
                           data: data.valuesFinished,
                           color: '#00aa69',
                           opacity : 0.7,
                           custom: {
                              name: this.finishedTooltip
                           },
                           type: 'column'
                        }  as Highcharts.SeriesColumnOptions
                     ]

                  }
               }, {
                  condition: {
                     maxWidth: 700,
                     minWidth: 501
                  },
                  chartOptions: {
                     legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        floating: false,
                        borderWidth: 0,
                        itemMarginBottom: 0,
                        shadow: false,
                        backgroundColor: '#FFFFFF',
                        itemStyle: {
                           width: 200,
                           fontSize: '20px'
                        }
                     },
                     series: [{
                        name: this.createdTitle,
                        data: data.valuesCreated,
                        color: '#ffcc66',
                        opacity : 0.8,
                        custom: {
                           name: this.createdTooltip
                        },
                        type: 'column'
                     }  as Highcharts.SeriesColumnOptions,
                        {
                           name: this.finishedTitle,
                           data: data.valuesFinished,
                           color: '#00aa69',
                           opacity : 0.7,
                           custom: {
                              name: this.finishedTooltip
                           },
                           type: 'column'
                        }  as Highcharts.SeriesColumnOptions
                     ]
                  }
               }

               ]
            },
            plotOptions: {
               column: {
                  pointPadding: 0.2,
                  borderWidth: 0
               }
            },
            series: [{
               name: this.createdTitle + '<br><span style=\'font-size:45px;font-family:Roboto;\'>' + data.sumCreated.toFixed(isCO2 ? 2 : 0) + '</span>',
               data: data.valuesCreated,
               color: '#ffcc66',
               opacity : 0.8,
               custom: {
                  name: this.createdTooltip
               },
               type: 'column'
            }  as Highcharts.SeriesColumnOptions,
               {
                  name: this.finishedTitle + '<br><span style=\'font-size:45px;font-family:Roboto;\'>' + data.sumFinished.toFixed(isCO2 ? 2 : 0) + '</span>',
                  data: data.valuesFinished,
                  color: '#00aa69',
                  opacity : 0.7,
                  custom: {
                     name: this.finishedTooltip
                  },
                  type: 'column'
               }  as Highcharts.SeriesColumnOptions
            ]
         });
      }
   }
}
