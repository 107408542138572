import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OVService } from '../../providers/ov.service';
import { MatSnackBar } from '@angular/material';
var VehicleCalculatorComponent = /** @class */ (function () {
    function VehicleCalculatorComponent(ovservice, snackBar) {
        this.ovservice = ovservice;
        this.snackBar = snackBar;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.columns = [
            { value: 'ourOrderNo', name: 'VEHICLE_CALCULATOR/table/ov' },
            { value: 'boxNo', name: 'VEHICLE_CALCULATOR/table/box' },
            { value: 'grossWeight', name: 'VEHICLE_CALCULATOR/table/weight' },
            { value: 'height', name: 'VEHICLE_CALCULATOR/table/height' },
            { value: 'length', name: 'VEHICLE_CALCULATOR/table/lenght' },
            { value: 'width', name: 'VEHICLE_CALCULATOR/table/widht' },
            { value: 'volume', name: 'VEHICLE_CALCULATOR/table/volume' },
            { value: 'site', name: 'VEHICLE_CALCULATOR/table/site' },
            { value: 'delivery', name: 'VEHICLE_CALCULATOR/table/delivery' },
            { value: 'shipment', name: 'VEHICLE_CALCULATOR/table/shipment' }
        ];
        this.ovs = [];
        this.vehicle = null;
    }
    VehicleCalculatorComponent.prototype.getVehicle = function () {
        var _this = this;
        this.ovservice.getVehicle(this.ovs.map(function (e) { return e.value; })).subscribe(function (data) {
            _this.setData(data);
        });
    };
    VehicleCalculatorComponent.prototype.recalculateVehicle = function () {
        var _this = this;
        var productList = this.selected.map(function (ov) { return ov.boxNo; });
        if (productList && productList.length) {
            this.ovservice.getVehicle(this.ovs.map(function (e) { return e.value; }), productList).subscribe(function (data) {
                _this.setData(data, productList);
            });
        }
        else {
            this.snackBar.open('Selecione ao menos uma linha da tabela e tente novamente', 'Ok', { duration: 2000 });
        }
    };
    VehicleCalculatorComponent.prototype.setData = function (data, selectedElements) {
        if (selectedElements === void 0) { selectedElements = null; }
        for (var _i = 0, _a = this.ovs; _i < _a.length; _i++) {
            var ov = _a[_i];
            if (data.validOVs.indexOf(ov.value.toString()) !== -1) {
                ov.found = true;
            }
            else {
                ov.found = false;
            }
        }
        if (data.validOVs.length > 0) {
            data.searched.height = data.searched.height / 10;
            data.searched.width = data.searched.width / 10;
            data.searched.length = data.searched.length / 10;
            data.searched.volume = data.searched.volume / 100;
            data.zrsdpacs.forEach(function (ov) {
                // By default all ovs starts selected
                if (!selectedElements) {
                    ov.selected = true;
                }
                else {
                    ov.selected = !!selectedElements.find(function (productNumber) { return ov.boxNo === productNumber; });
                }
            });
            this.vehicle = data;
        }
    };
    VehicleCalculatorComponent.prototype.add = function (event) {
        var _this = this;
        var input = event.input;
        var listValue = event.value.split(',');
        listValue.forEach(function (val) {
            var value = +val.trim();
            if (value) {
                var duplicate = false;
                for (var _i = 0, _a = _this.ovs; _i < _a.length; _i++) {
                    var ov = _a[_i];
                    if (ov.value === value) {
                        duplicate = true;
                        break;
                    }
                }
                if (!duplicate) {
                    _this.ovs.push({ value: value, found: true });
                }
            }
            if (input) {
                input.value = '';
            }
        });
    };
    VehicleCalculatorComponent.prototype.remove = function (ov) {
        var index = this.ovs.indexOf(ov);
        if (index >= 0) {
            this.ovs.splice(index, 1);
        }
    };
    VehicleCalculatorComponent.prototype.onSelection = function (selected) {
        this.selected = selected;
    };
    return VehicleCalculatorComponent;
}());
export { VehicleCalculatorComponent };
