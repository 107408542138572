<mat-card>
  <mat-form-field style="width: 100%;">
    <mat-chip-list #chipList aria-label="OV list">
      <mat-chip [ngClass]="{ 'red': !elem.found }" *ngFor="let elem of ovs" [selectable]="false"
        [removable]="true" (removed)="remove(elem)">
        {{elem.value}}
        <i matChipRemove font class="fa fa-times-circle"></i>
      </mat-chip>
      <input placeholder="OVs para incluir no calculo"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
  </mat-form-field>
  <button mat-raised-button style="float: none;" class='button primary-button' (click)="getVehicle()">Buscar veiculo</button>
  <div *ngIf="vehicle">
    <br>
    <mat-card>
      <p class="mat-h1">{{vehicle.vehicle}}</p>
      <p>Peso total: {{vehicle.searched.weight.toFixed(2)}} Kg</p>
      <p>Volume total: {{vehicle.searched.volume.toFixed(2)}} m³</p>
      <p>Largura maxima: {{vehicle.searched.width.toFixed(2)}} m</p>
      <p>Comprimento maximo: {{vehicle.searched.length.toFixed(2)}} m</p>
      <p>Altura maxima: {{vehicle.searched.height.toFixed(2)}} m</p>
      <p>
        Valor total das notas: R$ {{ vehicle.searched.totalValue.toFixed(2) }}
        <mat-icon class="bottom-icon" [matTooltip]="'Calculado com base nas OVs retornadas pela busca, independente dos itens selecionados'">
          info
        </mat-icon>
      </p>
    </mat-card>
  </div>
</mat-card>

<div *ngIf="vehicle && vehicle.zrsdpacs.length > 0" class="colored-background table-background px-5 py-2">
  <app-table
    [data]="vehicle.zrsdpacs"
    [columns]="columns"
    (check)="onSelection($event)"
    [showCheckbox]="true"
    downloadAs="vehicle-calculator_%.xlsx"
  ></app-table>

  <button mat-raised-button style="float: none;" class='button primary-button' (click)="recalculateVehicle()">Recalcular</button>
</div>
