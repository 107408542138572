<mat-card fxLayout="{{ layout }}" class="card-height"fxLayoutAlign="{{ layoutAlign }}">
  <mat-card-content>
    <mat-card-title class="chart-title"> {{title}}<i *ngIf="eco" class="fa fa-leaf" style="color: green;" matTooltip="Green Initiative"></i> </mat-card-title>
    <div *ngIf="selector === 'composite-bars'">
       <app-composite-bars-chart #compositeBarsChartComponent container="{{container}}"></app-composite-bars-chart>
    </div>
    <div *ngIf="selector === 'pie'">
      <app-pie-chart #pieChartComponent seriesType="{{seriesType}}" container="{{container}}"></app-pie-chart>
    </div>
    <div *ngIf="selector === 'speedometer'">
      <app-speedometer-chart #speedometerChartComponent container="{{container}}"></app-speedometer-chart>
    </div>
    <div *ngIf="selector === 'bar'">
      <app-basic-bar-chart #basicBarChartComponent container="{{container}}"></app-basic-bar-chart>
    </div>
    <div *ngIf="selector === 'wordcloud'">
      <app-wordcloud-chart #wordcloudChartComponent container="{{container}}"></app-wordcloud-chart>
    </div>
  </mat-card-content>
</mat-card>
