import { ElementRef, OnInit } from '@angular/core';
import { MatChipInputEvent, MatDialog, MatDialogRef, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ClientUserService } from '../../providers/client-user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DrService } from '../../providers/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../models/user';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
var LspUserAdminComponent = /** @class */ (function () {
    function LspUserAdminComponent(userService, dialog, clientUserService, spinnerService, drService, router, snackBar, dpipe) {
        this.userService = userService;
        this.dialog = dialog;
        this.clientUserService = clientUserService;
        this.spinnerService = spinnerService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.selection = new SelectionModel(true, []);
        this.data = [];
        this.aware = false;
        this.listOfLSPRoles = [5, 12, 14];
        this.listOfRoles = [1, 19, 77];
        this.validUsers = ['clara.gama@awarelog.com', 'vitor.marge@awarelog.com', 'nicole@awarelog.com', 'israel.alves@ericsson.com', 'juliane.turman@ericsson.com', 'rafael.aparecido@ericsson.com'];
        // displayedColumns = ['status', 'client', 'mdrShippingCompany', 'mdrID', 'site', 'uf', 'city', 'dateScheduling'];
        this.displayedColumns = ['name', 'email', 'company', 'role', 'edit'];
        this.dataSource = new MatTableDataSource(this.data);
        this.searchChips = [];
        this.removable = true;
        this.selectable = true;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        this.searchTerm = '';
        this.tokenSeparator = '@@@@@@@@@';
        this.dataSource.sort = this.sort;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.email = currentUser.user.email;
    }
    LspUserAdminComponent.prototype.ngOnInit = function () {
        this.reloadData();
        if (this.validUsers.includes(this.email)) {
            this.canCreateUser = true;
        }
        else {
            this.canCreateUser = false;
        }
    };
    LspUserAdminComponent.prototype.reloadData = function () {
        var _this = this;
        this.spinnerService.show();
        this.userService.getAllUsers().subscribe(function (elem) {
            elem.forEach(function (k) {
                k.company = k.name;
                k.name = k.u_name;
                k.email = k.u_email;
                k.role = k.u_role;
            });
            _this.data = elem;
            _this.dataSource = new MatTableDataSource(_this.data);
            _this.dataSource.filterPredicate = function (data, mergedFilter) {
                var filter = _this.splitSearchTerms(mergedFilter);
                var display = false;
                _this.displayedColumns.forEach(function (el, i, arr) {
                    // atualmente é feita a comparação com OR lógico
                    // para mudar para AND lógico: trocar .some() por .every()
                    if (filter.length === 0) {
                        display = true;
                    }
                    else if (filter.some(function (chip) {
                        if (el in data &&
                            data[el] !== null) {
                            if (typeof data[el] === 'string' &&
                                data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                                return true;
                            }
                            else if (typeof data[el] === 'number' &&
                                data[el] === parseInt(chip, 10)) {
                                return true;
                            }
                        }
                        return false;
                    })) {
                        display = true;
                    }
                });
                return display;
            };
            _this.spinnerService.hide();
        });
    };
    LspUserAdminComponent.prototype.applyFilter = function () {
        this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
    };
    LspUserAdminComponent.prototype.deleteUser = function (user) {
        var _this = this;
        if (this.listOfRoles.includes(user.u_role)) {
            this.clientUserService.deleteUser(user.u_id).subscribe(function (data) {
                _this.snackBar.open('Usuário deletado com sucesso', 'OK', { duration: 4000 });
                _this.reloadData();
            });
        }
        else if (this.listOfLSPRoles.includes(user.u_role)) {
            console.log(user);
            this.clientUserService.deleteUserLSP(user.ush_id).subscribe(function (data) {
                _this.snackBar.open('Usuário Transportadora deletado com sucesso', 'OK', { duration: 4000 });
                _this.reloadData();
            });
        }
    };
    LspUserAdminComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.searchChips.push({ 'name': value.trim() });
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    LspUserAdminComponent.prototype.remove = function (chip) {
        var index = this.searchChips.indexOf(chip);
        if (index >= 0) {
            this.searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    LspUserAdminComponent.prototype.mergeSearchTerms = function (terms) {
        var _this = this;
        var merged = '';
        terms.forEach(function (el, i, arr) {
            if (typeof el.name === 'string') {
                merged = merged + _this.tokenSeparator + '"' + el.name + '"';
            }
            else if (typeof el.name === 'number') {
                merged = merged + _this.tokenSeparator + String(el.name);
            }
        });
        merged = merged.substring(this.tokenSeparator.length, merged.length);
        return merged;
    };
    LspUserAdminComponent.prototype.splitSearchTerms = function (terms) {
        var split = [];
        terms.split(this.tokenSeparator).forEach(function (el, i, arr) {
            if (el[0] === '"' && el[el.length - 1] === '"') {
                split.push(el.substring(1, el.length - 1));
            }
            else {
                split.push(parseInt(el, 10));
            }
        });
        return split;
    };
    LspUserAdminComponent.prototype.openDialog = function (user) {
        var _this = this;
        if (user === undefined) {
            user = 'Ola';
        }
        var dialogRef = this.dialog.open(EditUserDialogComponent, {
            width: '600px',
            data: user,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.reloadData();
            }
        });
    };
    LspUserAdminComponent.prototype.openCreateAdmUserDialog = function (user) {
        var _this = this;
        if (user === undefined) {
            user = 'Ola';
        }
        var dialogRef = this.dialog.open(EditUserAdminDialogComponent, {
            width: '600px',
            data: user,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.reloadData();
            }
        });
    };
    LspUserAdminComponent.prototype.openDeleteDialog = function (user) {
        var _this = this;
        if (user === undefined) {
            user = 'Ola';
        }
        var dialogRef = this.dialog.open(DeleteUserDialogComponent, {
            width: '600px',
            data: user,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.deleteUser(response);
            }
        });
    };
    return LspUserAdminComponent;
}());
export { LspUserAdminComponent };
var EditUserDialogComponent = /** @class */ (function () {
    function EditUserDialogComponent(dialogRef, clientUserService, data, translate, snackBar) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.clientUserService = clientUserService;
        this.data = data;
        this.translate = translate;
        this.snackBar = snackBar;
        this.user = new User();
        this.listOfLSPRoles = [
            5, 12, 14
        ];
        this.lspList = [];
        this.clientUserService.getLspList().subscribe(function (lsps) {
            _this.lspList = lsps;
        });
    }
    EditUserDialogComponent.prototype.ngOnInit = function () {
    };
    EditUserDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        if (this.user.name === '' || this.user.email === '' || this.user.role === null || this.lsp === null) {
            this.snackBar.open('Preencha todos os campos obrigatórios', 'Ok', { duration: 5000 });
        }
        else {
            this.clientUserService.createUser(this.user.name, this.user.email, this.lsp, this.user.role).subscribe(function (data) {
                if (data && data.text === 'User saved correctly') {
                    _this.snackBar.open('Usuário criado com sucesso', 'OK', { duration: 5000 });
                }
                _this.dialogRef.close(data);
            }, function (error) {
                if (error && error.error.text === 'User already exists') {
                    _this.snackBar.open('Erro: Usuário já está cadastrado no sistema', 'OK', { duration: 5000 });
                }
                else {
                    _this.snackBar.open('Erro ao criar usuário', 'Ok', { duration: 5000 });
                }
            });
        }
    };
    EditUserDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditUserDialogComponent;
}());
export { EditUserDialogComponent };
var DeleteUserDialogComponent = /** @class */ (function () {
    function DeleteUserDialogComponent(dialogRef, clientUserService, data, translate, snackBar) {
        this.dialogRef = dialogRef;
        this.clientUserService = clientUserService;
        this.data = data;
        this.translate = translate;
        this.snackBar = snackBar;
        this.user = new User();
        this.lspList = [];
        this.data = data;
    }
    DeleteUserDialogComponent.prototype.ngOnInit = function () {
    };
    DeleteUserDialogComponent.prototype.onYesClick = function () {
        this.dialogRef.close(this.data);
    };
    DeleteUserDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return DeleteUserDialogComponent;
}());
export { DeleteUserDialogComponent };
var EditUserAdminDialogComponent = /** @class */ (function () {
    function EditUserAdminDialogComponent(dialogRef, clientUserService, data, translate, snackBar) {
        this.dialogRef = dialogRef;
        this.clientUserService = clientUserService;
        this.data = data;
        this.translate = translate;
        this.snackBar = snackBar;
        this.listOfRoles = [
            1, 19, 77
        ];
        this.user = new User();
    }
    EditUserAdminDialogComponent.prototype.ngOnInit = function () {
    };
    EditUserAdminDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        if (this.user.name === '' || this.user.email === '' || this.user.role === null) {
            this.snackBar.open('Preencha todos os campos obrigatórios', 'Ok', { duration: 5000 });
        }
        else {
            this.clientUserService.createAdminUser(this.user.name, this.user.email, this.user.role).subscribe(function (data) {
                if (data && data.text === 'User saved correctly') {
                    _this.snackBar.open('Usuário criado com sucesso', 'OK', { duration: 5000 });
                }
                _this.dialogRef.close(data);
            }, function (error) {
                if (error && error.error.text === 'User already exists') {
                    _this.snackBar.open('Erro: Usuário já está cadastrado no sistema', 'OK', { duration: 5000 });
                }
                else {
                    _this.snackBar.open('Erro ao criar usuário', 'Ok', { duration: 5000 });
                }
            });
        }
    };
    EditUserAdminDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditUserAdminDialogComponent;
}());
export { EditUserAdminDialogComponent };
