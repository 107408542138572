import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { SiteService } from '../providers/site.service';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var MdrService = /** @class */ (function () {
    function MdrService(http, siteService) {
        this.http = http;
        this.siteService = siteService;
        this.uid = 'MdrService-';
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    MdrService.prototype.insertLatam = function (ovList, dr, mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/insertLatam', {
            mdr: mdr,
            ovList: ovList,
            dr: dr
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateCoronaVirus = function (drList) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'DR/corona', {
            drList: drList
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.insert = function (mdr, type) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/', {
            mdr: mdr,
            returnLeg: type
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.insertConsolidation = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/consolidation', {
            mdr: mdr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.insertNewConsolidation = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/newConsolidation', {
            mdr: mdr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllPriceR = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + "PM/getAllPrice?vehicle=" + mdr.vehicle + "&id=" + mdr.id, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getOccurrenceHistory = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/getOccurrenceHistory/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.scheduleTimeList = function (date) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/scheduleTimeList/' + date, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getPickupID = function (mdrId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/' + mdrId, { headers: header }).map(function (response) {
            if ('pickupID' in response) {
                return response.pickupID ? response.pickupID.trim() : response.pickupID;
            }
            return null;
        });
    };
    MdrService.prototype.getMDRbyPickupId = function (pickupId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/pickupId/' + pickupId, { headers: header }).map(function (response) {
            return response;
            //  return null;
        });
    };
    MdrService.prototype.getAllPrice = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'PM/getPrice?'
            + 'vehicle=' + mdr.vehicle +
            '&shippingCompany=' + mdr.shippingCompany +
            '&id=' + mdr.id +
            '&isAll=true', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllPriceWithKm = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'PM/getPriceWithKMAndPrice?'
            + 'vehicle=' + mdr.vehicle +
            '&shippingCompany=' + mdr.shippingCompany +
            '&id=' + mdr.id +
            '&km=' + mdr.totalKM +
            '&value=' + mdr.totalNF +
            '&isAll=true', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getVehicleData = function (mdrId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/vehicle/' + mdrId, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverByMdrId = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'driver/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverByMdrIdAll = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'driver/findAll/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getTransport = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'mdr/transport/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.createDriver = function (driver) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'driver/', {
            driver: driver,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.createOccurrence = function (occurrence) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'occurrence/', {
            occurrence: occurrence,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.deleteOccurrence = function (occurrence) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'occurrence/' + occurrence.id, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.getUOC = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', String(mdr.id));
        return this.http.get(base_url + 'UOC/AllFromMDR', { headers: header, params: params }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getUOCWithInfo = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'UOC/AllFromMDRWithInfo', { id: id, entity: 'uoc' }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getCostAvoidance = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', String(mdr.id));
        return this.http.get(base_url + 'costavoidance/AllFromMDR', { headers: header, params: params }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getCoPQ = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', String(mdr.id));
        return this.http.get(base_url + 'copq/AllFromMDR', { headers: header, params: params }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getUOCValue = function (uoc, mdr, valueWithTax) {
        if (valueWithTax === void 0) { valueWithTax = true; }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('mdr', JSON.stringify(mdr)).append('uoc', JSON.stringify(uoc)).append('addTax', JSON.stringify(valueWithTax));
        return this.http.get(base_url + 'UOC/getUOCBaseValue', { headers: header, params: params }).map(function (response) {
            if (response === undefined ||
                response === null ||
                Number(response.price) === NaN) {
                return 0;
            }
            return Number(response.price);
        });
    };
    MdrService.prototype.createUOC = function (uocmdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'UOC/', {
            uocmdr: uocmdr,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.createCoPQ = function (copqmdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'copq/', {
            copqmdr: copqmdr,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.createCostAvoidance = function (camdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'costavoidance/', {
            camdr: camdr,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateOccurrence = function (occurrence) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'occurrence/', {
            occurrence: occurrence,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.chageStatusOccurence = function (occurrence) {
        return null;
    };
    MdrService.prototype.getOccurences = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', String(mdr.id));
        return this.http.get(base_url + 'occurrence/getByMDR', { headers: header, params: params }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.editOccurrence = function (occurrence) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'occurrence/', { occurrence: occurrence }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getUOCList = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'uoc/list', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getOccurencesList = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'occurrence/all', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAlreadyExistentPrice = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'price/getPrice?' + 'id=' + mdr.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllPaymentList = function (startDate, endDate) {
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        var params = new HttpParams();
        console.log(startDate, endDate);
        if (startDate || endDate) {
            if (startDate) {
                params = params.set('startDate', startDate.toISOString());
            }
            if (endDate) {
                params = params.set('endDate', endDate.toISOString());
            }
        }
        else {
            params = params.set('limitData', 'true');
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/getAllPaymentsAvailable', { headers: header, params: params }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getPaymentList = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/payments', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getReport = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'MDR/report', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.payNF = function (entityInfo) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/payments/pay', { pay: entityInfo }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.updatePayment = function (payment) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/payments/update', payment, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.removeOccurrence = function (uoc) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'UOC/' + uoc.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.removeOccurrenceCOPQ = function (copq) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'copq/' + copq.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.createPay = function (entityInfo) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/payments/create', { entityInfo: entityInfo }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.savePrice = function (price, mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var prMDR = { addEntrega: 0, adValorem: 0, frete: 0, gris: 0, total: 0, freteTax: 0, mdrID: 0, id: 0 };
        prMDR.adValorem = price.adValorem;
        prMDR.frete = price.price;
        prMDR.gris = 0;
        prMDR.total = price.total;
        prMDR.freteTax = price.taxedPrice;
        prMDR.mdrID = mdr.id;
        return this.http.put(base_url + 'price/new', {
            price: prMDR,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.getLSPUsers = function (lsp) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/GetLSPUsers/' + lsp, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllPriceChanges = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'price/allChanges/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.sendLSPEmail = function (mdr, users) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/SendLSPEmail', { mdr: mdr, toUsers: users }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.sendApproveCostEmail = function (mdr, users) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/sendApproveCostEmail', { mdr: mdr, toUsers: [{ email: 'felipe.freitas@ericsson.com' }, { email: 'manuelle.silvestre@ericsson.com' }] }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.sendReproveEmail = function (mdr, users) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/sendReproveCostEmail', { mdr: mdr, toUsers: users }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.update = function (mdr, userID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'MDR/', {
            mdr: mdr,
            user: userID,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.giveBack = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'MDR/giveBack', {
            mdr: mdr
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.liberateDR = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'MDR/liberateDR', {
            mdr: mdr,
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateRessalva = function (mdr, ressalva) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'MDR/ressalva', {
            mdr: mdr,
            ressalva: ressalva
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateReturn = function (mdr, text) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'MDR/shipmentReturned', {
            mdr: mdr,
            text: text
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateReprove = function (mdr, reprove) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'MDR/reprove', {
            mdr: mdr,
            reprove: reprove
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.updateDriver = function (drivers, mdrId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'driver/updateDriver/', {
            drivers: drivers, mdrId: mdrId
        }, { headers: header }).map(function (response) {
            return true;
        });
    };
    MdrService.prototype.haveNewOccurrence = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'occurrence/HaveOccurrence', { headers: header }).map(function (response) {
            if (response.count > 0) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    MdrService.prototype.haveNewMDR = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/HaveMDR', { headers: header }).map(function (response) {
            if (response.count > 0) {
                return true;
            }
            else {
                return false;
            }
        });
    };
    MdrService.prototype.getAllWithHub = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/AllWithHub', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAll = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/All', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllWithClient = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/AllWithClient', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllDR = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'MDR/AllDR', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllLSP = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/AllLSP', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDocsInfo = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/spreadsheet', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getAllMDR = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/AllMdr', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.createLegs = function (id, ret) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'Legs/', { mdrId: id, returnLeg: ret }, { headers: header })
            .map(function (response) {
        });
    };
    MdrService.prototype.getLegs = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'Legs/' + id, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    MdrService.prototype.putLegs = function (leg, mdrId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'Legs/', { leg: leg, mdrId: mdrId }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    MdrService.prototype.removeLeg = function (leg, mdrId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'Legs/delete', { leg: leg, mdrId: mdrId }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getMDR = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/' + id, { headers: header }).map(function (response) {
            if ('pickupID' in response) {
                response.pickupID = response.pickupID ? response.pickupID.trim() : response.pickupID;
            }
            return response;
        });
    };
    MdrService.prototype.getNf = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/nf/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getMDRReservation = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/Reservation/' + id, { headers: header }).map(function (response) {
            return response['reservation'];
        });
    };
    MdrService.prototype.getMDRReprove = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'MDR/reprove/' + id, { headers: header }).map(function (response) {
            return response['text'];
        });
    };
    MdrService.prototype.getMDReturned = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'MDR/shipmentReturned/' + id, { headers: header }).map(function (response) {
            return response['text'];
        });
    };
    MdrService.prototype.unmakeDR = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'DR/' + dr.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getOccurrenceEnum = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if ((this.uid + 'getOccurrenceEnum') in localStorage) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(localStorage.getItem(_this.uid + 'getOccurrenceEnum')));
            });
        }
        else {
            return this.http.get(base_url + 'occurrence/enum', { headers: header }).map(function (response) {
                localStorage.setItem(_this.uid + 'getOccurrenceEnum', JSON.stringify(response));
                return response;
            });
        }
    };
    MdrService.prototype.getListUOC = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if ((this.uid + 'getListUOC') in localStorage) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(localStorage.getItem(_this.uid + 'getListUOC')));
            });
        }
        else {
            return this.http.get(base_url + 'UOC/AllUoc', { headers: header }).map(function (response) {
                localStorage.setItem(_this.uid + 'getListUOC', JSON.stringify(response));
                return response;
            });
        }
    };
    MdrService.prototype.getListCoPQ = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if ((this.uid + 'getListCoPQ') in localStorage) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(localStorage.getItem(_this.uid + 'getListCoPQ')));
            });
        }
        else {
            return this.http.get(base_url + 'copq/All', { headers: header }).map(function (response) {
                localStorage.setItem(_this.uid + 'getListCoPQ', JSON.stringify(response));
                return response;
            });
        }
    };
    MdrService.prototype.removeCostAvoidance = function (ca) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'costavoidance/' + ca.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getListCostAvoidance = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'costavoidance/All', { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverLastPosition = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'driver/GetDriverLastPosition/' + mdr.id, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getLastPositions = function (mdrIdList) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'driver/GetLastPositions', { mdrIdList: mdrIdList }, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverPosition = function (mdrID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'driver/GetDriverPositions/' + mdrID, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.isSLA = function (mdrID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'sla/mdr/' + mdrID, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.unsetSLA = function (mdrID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'sla/mdr/' + mdrID, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.setSLA = function (mdrID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'sla/mdr/' + mdrID, {}, { headers: header }).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getPDF = function (id) {
        var utc = -((new Date()).getTimezoneOffset() / 60); // o oposto do offset para converter de UTC para local
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'MDR/pdf/' + id + '?utc=' + utc, {
            headers: header,
            responseType: 'blob'
        });
    };
    MdrService.prototype.getStatusPayment = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/payments/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    /*******************
     *
     * Funções pra acesso sem login
     *
     */
    MdrService.prototype.createToken = function (idMDR) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'MDR/token/create', { mdrID: idMDR }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getMdrWithToken = function (token) {
        return this.http.get(base_url + 'no-login/MDR/' + token);
    };
    MdrService.prototype.getLegsWithToken = function (id, token) {
        return this.http.get(base_url + 'no-login/Legs/' + id + '/' + token).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverLastPositionWithToken = function (mdrID, token) {
        return this.http.get(base_url + 'no-login/driver/GetDriverLastPosition/' + mdrID + '/' + token).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getDriverPositionWithToken = function (mdrID, token) {
        return this.http.get(base_url + 'no-login/driver/GetDriverPositions/' + mdrID + '/' + token).map(function (response) {
            return response;
        });
    };
    MdrService.prototype.getHistory = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/history/' + id, { headers: header }).map(function (response) {
            return response.map(function (e) {
                if (e.quando) {
                    e.quando = new Date(e.quando);
                }
                if (e.pickupDate) {
                    e.pickupDate = new Date(e.pickupDate);
                }
                return e;
            });
        });
    };
    MdrService.prototype.getMdrFinance = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'MDR/mdrFinance/' + id, { headers: header }).map(function (response) {
            return response.map(function (e) {
                if (e.createdAt) {
                    e.createdAt = new Date(e.createdAt);
                }
                return e;
            });
        });
    };
    MdrService.prototype.removePayment = function (payId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'mdr/payments/' + payId, { headers: header }).map(function (response) {
            return response;
        });
    };
    return MdrService;
}());
export { MdrService };
