import { OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.version = environment.VERSION;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
