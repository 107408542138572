import { OnInit } from '@angular/core';
import { Veiculo } from '../logistic-order/transport/transport.component';
import { PriceMakerService } from '../../providers/price-maker.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SiteService } from '../../providers/site.service';
import { MdrService } from '../../providers/mdr.service';
var PriceMakerComponent = /** @class */ (function () {
    function PriceMakerComponent(priceMaker, spinnerService, siteService, uocService) {
        var _this = this;
        this.priceMaker = priceMaker;
        this.spinnerService = spinnerService;
        this.siteService = siteService;
        this.uocService = uocService;
        this.price = {};
        this.total = '-1';
        this.update = true;
        this.deliveryRegion = '';
        this.pickupRegion = '';
        this.uocList = [];
        this.airFreightDestinations = [];
        this.transportadora = [];
        this.typeList = [
            { id: '1', name: 'Frete' },
            { id: '2', name: 'UOC' },
            { id: '3', name: 'Frete Aereo' }
        ];
        this.veiculo = [
            { id: '1', name: 'Fiorino' },
            { id: '2', name: 'Sprinter' },
            { id: '3', name: 'VUC' },
            { id: '4', name: 'Leve 3/4' },
            { id: '5', name: 'TOCO' },
            { id: '6', name: 'TRUCK' },
            { id: '7', name: 'CARRETA' }
        ];
        this.state = [
            { id: 'AC', name: 'AC' },
            { id: 'AL', name: 'AL' },
            { id: 'AP', name: 'AP' },
            { id: 'AM', name: 'AM' },
            { id: 'BA', name: 'BA' },
            { id: 'CE', name: 'CE' },
            { id: 'DF', name: 'DF' },
            { id: 'ES', name: 'ES' },
            { id: 'GO', name: 'GO' },
            { id: 'MA', name: 'MA' },
            { id: 'MT', name: 'MT' },
            { id: 'MS', name: 'MS' },
            { id: 'MG', name: 'MG' },
            { id: 'PA', name: 'PA' },
            { id: 'PB', name: 'PB' },
            { id: 'PR', name: 'PR' },
            { id: 'PE', name: 'PE' },
            { id: 'PI', name: 'PI' },
            { id: 'RJ', name: 'RJ' },
            { id: 'RN', name: 'RN' },
            { id: 'RS', name: 'RS' },
            { id: 'RO', name: 'RO' },
            { id: 'RR', name: 'RR' },
            { id: 'SC', name: 'SC' },
            { id: 'SP', name: 'SP' },
            { id: 'SE', name: 'SE' },
            { id: 'TO', name: 'TO' }
        ];
        this.subRegion = [
            { id: 'Capital', name: 'Capital' },
            { id: 'Interior', name: 'Interior' },
        ];
        this.columns = [
            { value: 'shipC', name: 'LSP' },
            { value: 'price', name: 'Valor do Frete', currency: 'BRL' },
            { value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL' },
            { value: 'adValorem', name: 'adValorem', currency: 'BRL' },
            { value: 'addDelivery', name: 'Adicional de Entrega', currency: 'BRL' },
            { value: 'value', name: 'Valor Total', currency: 'BRL' }
        ];
        this.columnsAir = [
            { value: 'shipC', name: 'LSP' },
            { value: 'price', name: 'Valor do Frete Aereo', currency: 'BRL' },
            { value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL' },
            { value: 'adValorem', name: 'adValorem', currency: 'BRL' },
            { value: 'value', name: 'Valor Total', currency: 'BRL' }
        ];
        this.columnsUOC = [
            { value: 'shipC', name: 'LSP' },
            { value: 'price', name: 'Valor UOC', currency: 'BRL' },
            { value: 'taxedPrice', name: 'Valor com taxas', currency: 'BRL' },
        ];
        this.price['drAmount'] = 0;
        this.uocService.getListUOC().subscribe(function (elem) {
            _this.uocList = elem;
        });
        this.siteService.getShippingCompanys().subscribe(function (data) {
            _this.transportadora = data.filter(function (transp) { return transp.name !== 'A RETIRAR'; });
        });
        this.priceMaker.getAirFreightDestinations().subscribe(function (data) {
            _this.airFreightDestinations = data;
        });
    }
    PriceMakerComponent.prototype.ngOnInit = function () {
    };
    PriceMakerComponent.prototype.sanitizeInputPrice = function (field, value, tag) {
        var input = value.srcElement.value;
        this.update = false;
        input = input.replace('R$', '');
        input = input.replace(/ /g, '');
        input = input.replace(/\./g, '');
        input = input.replace(',', '.');
        input = input.replace(/[^\d.]/g, '');
        this.price[field] = input;
        this.update = true;
    };
    PriceMakerComponent.prototype.getPrice = function () {
        var _this = this;
        if (this.type === '1') {
            var vehicle = this.price['vehicle'];
            var distance = this.price['totalDistance'];
            var fromEstate = this.price['deliveryRegion'];
            var toEstate = this.price['pickupRegion'];
            var subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
            var value = this.price['freight'];
            //    const shippingCompany = this.price['shippingCompany'];
            var drAmount = this.price['drAmount'];
            this.spinnerService.show();
            this.priceMaker.getPriceTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount)
                .subscribe(function (result) {
                _this.data = result.filter(function (transp) { return transp.shipC !== 'A RETIRAR'; });
                _this.spinnerService.hide();
                // this.total=result.total;
            });
        }
        else if (this.type === '2') {
            var vehicle = this.price['vehicle'];
            var distance = this.price['totalDistance'];
            var fromEstate = this.price['deliveryRegion'];
            var toEstate = this.price['pickupRegion'];
            var subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
            var value = this.price['freight'];
            //    const shippingCompany = this.price['shippingCompany'];
            var drAmount = this.price['drAmount'];
            this.spinnerService.show();
            this.priceMaker.getUOCTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount, this.uocSelected)
                .subscribe(function (result) {
                _this.data = result.filter(function (transp) { return transp.shipC !== 'A RETIRAR'; });
                _this.spinnerService.hide();
                // this.total=result.total;
            });
        }
        else if (this.type === '3') {
            var fromEstate = this.price['deliveryRegion'];
            var toEstate = this.price['pickupRegion'];
            var deliveryDestination = this.price['deliveryDestination'];
            var value = this.price['freight'];
            var weight = this.price['weight'];
            var height = this.price['height'];
            var width = this.price['width'];
            var depth = this.price['depth'];
            this.spinnerService.show();
            this.priceMaker.getAirPriceTable(fromEstate, toEstate, deliveryDestination, height, width, depth, weight, value)
                .subscribe(function (result) {
                _this.data = result;
                _this.spinnerService.hide();
            });
        }
        else {
            // console.log('escolha um Tipo valido');
        }
    };
    PriceMakerComponent.prototype.getTotal = function () {
        // console.log(this.price['deliveryRegion'])
        if (typeof this.price['deliveryRegion'] !== 'undefined') {
            this.deliveryRegion = this.price['deliveryRegion'];
        }
        if (typeof this.price['pickupRegion'] !== 'undefined') {
            this.pickupRegion = this.price['pickupRegion'];
        }
        // console.log( this.deliveryRegion)
        this.total = '-1';
    };
    return PriceMakerComponent;
}());
export { PriceMakerComponent };
