<mat-toolbar class="float-right">
  <mat-toolbar-row class="d-flex">
    <div class="mr-auto p-2">
      <img (click)="goToHome()" id="logo" class="ml-5 clickable" src="../../assets/images/logo-name.png">
      <span style="font-size: 8px; color: white;">v{{version}}</span>
    </div>
    <mat-form-field class="d-none d-sm-block mr-auto">
      <i style="color: white;" class="fas fa-search"></i>
      <input (keydown.enter)="search()" style="color: white;" matInput [(ngModel)]="searchValue">
    </mat-form-field>
    <div class="text-white p-2">
      <b>{{ 'HEADER/hi' | translate }},</b> {{ currentUser.user.name }}
    </div>
    <div class="text-white p-2">
      <button mat-icon-button routerLink="/logout">
        <i class="fa fa-sign-out-alt"></i>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
