import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialog, MatDialogRef, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {HubService} from '../../providers/hub.service';
import {DrService} from '../../providers/dr.service';
import {HUB} from '../../models/hub';
import {TranslateService} from '@ngx-translate/core';
import {HUBDR} from '../../models/hub__dr';
import {DR} from '../../models/dr';
import {User} from '../../models/user';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { timeStamp } from 'console';

@Component({
  selector: 'app-user-admin',
  templateUrl: './lsp-user-admin.component.html',
  styleUrls: ['./lsp-user-admin.component.scss']
})

export class LspUserAdminComponent implements OnInit {
  public searchControl: FormControl;
  inputOVs: number;
  selection = new SelectionModel<DR>(true, []);
  data: DR[] = [];
  aware = false;
  listOfLSPRoles = [5, 12, 14];
  listOfRoles = [1, 19, 77];
  validUsers = ['clara.gama@awarelog.com', 'vitor.marge@awarelog.com', 'nicole@awarelog.com', 'israel.alves@ericsson.com', 'juliane.turman@ericsson.com', 'rafael.aparecido@ericsson.com'];
  email: string;
  canCreateUser: boolean;

  // displayedColumns = ['status', 'client', 'mdrShippingCompany', 'mdrID', 'site', 'uf', 'city', 'dateScheduling'];
  displayedColumns = ['name', 'email', 'company', 'role', 'edit'];
  dataSource = new MatTableDataSource<any>(this.data);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  public searchChips: any[] = [];
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  searchTerm = '';
  tokenSeparator = '@@@@@@@@@';

  constructor(public userService: ClientUserService, public dialog: MatDialog,
              public clientUserService: ClientUserService,
              private spinnerService: Ng4LoadingSpinnerService, private drService: DrService,
              public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.dataSource.sort = this.sort;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.email = currentUser.user.email;
  }

  ngOnInit() {
    this.reloadData();
    if (this.validUsers.includes(this.email)) {
      this.canCreateUser = true;
    } else {
      this.canCreateUser = false;
    }
  }

  reloadData() {
    this.spinnerService.show();
    this.userService.getAllUsers().subscribe( (elem) => {
      elem.forEach( (k) => {
        k.company = k.name;
        k.name = k.u_name;
        k.email = k.u_email;
        k.role = k.u_role;
      });
      this.data = elem;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data: any, mergedFilter: string ) => {
        const filter = this.splitSearchTerms(mergedFilter);
        let display = false;
        this.displayedColumns.forEach( (el, i, arr) => {
          // atualmente é feita a comparação com OR lógico
          // para mudar para AND lógico: trocar .some() por .every()
          if ( filter.length === 0 ) {
            display = true;
          } else if (filter.some( chip => {
            if (el in data &&
               data[el] !== null) {
              if (typeof data[el] === 'string' &&
                 data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                return true;
              } else if (typeof data[el] === 'number' &&
                        data[el] === parseInt(chip, 10)) {
                return true;
              }
            }
            return false;
          })) {
            display = true;
          }
        });
        return display;
      };
      this.spinnerService.hide();
    });
  }

  applyFilter() {
    this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
  }

  deleteUser(user) {
    if (this.listOfRoles.includes(user.u_role)) {
      this.clientUserService.deleteUser(user.u_id).subscribe( (data) => {
        this.snackBar.open('Usuário deletado com sucesso', 'OK', {duration: 4000});
        this.reloadData();
      });
    } else if (this.listOfLSPRoles.includes(user.u_role)) {
      console.log(user);
      this.clientUserService.deleteUserLSP(user.ush_id).subscribe( (data) => {
        this.snackBar.open('Usuário Transportadora deletado com sucesso', 'OK', {duration: 4000});
        this.reloadData();
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchChips.push({'name': value.trim()});
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  mergeSearchTerms(terms): string {
    let merged = '';
    terms.forEach( (el, i, arr) => {
      if (typeof el.name === 'string') {
        merged = merged + this.tokenSeparator + '"' + el.name + '"';
      } else if (typeof el.name === 'number') {
        merged = merged + this.tokenSeparator + String(el.name);
      }
    });
    merged = merged.substring(this.tokenSeparator.length, merged.length);
    return merged;
  }

  splitSearchTerms(terms) {
    const split = [];
    terms.split(this.tokenSeparator).forEach( (el, i, arr) => {
      if (el[0] === '"' && el[el.length - 1] === '"') {
        split.push(el.substring(1, el.length - 1));
      } else {
        split.push(parseInt(el, 10));
      }
    });
    return split;
  }

  openDialog(user: any): void {
    if (user === undefined) {
      user = 'Ola';
    }
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: '600px',
      data: user,
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        this.reloadData();
      }
    });
  }

  openCreateAdmUserDialog(user: any): void {
    if (user === undefined) {
      user = 'Ola';
    }
    const dialogRef = this.dialog.open(EditUserAdminDialogComponent, {
      width: '600px',
      data: user,
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        this.reloadData();
      }
    });
  }

  openDeleteDialog(user: any): void {
    if (user === undefined) {
      user = 'Ola';
    }
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '600px',
      data: user,
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        this.deleteUser(response);
      }
    });
  }
}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: 'edit-user-dialog.html',
  styleUrls: ['./lsp-user-admin.component.scss']
})
export class EditUserDialogComponent implements OnInit {
  name: any;
  user: any = new User();
  lsp: any;
  role: number;
  listOfLSPRoles = [
    5, 12, 14
  ];
  lspList = [];
  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>, public clientUserService: ClientUserService,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public snackBar: MatSnackBar) {
    this.clientUserService.getLspList().subscribe( (lsps) => {
      this.lspList = lsps;
    });
  }

  ngOnInit() {
  }

  onYesClick() {
    if (this.user.name === '' || this.user.email === '' || this.user.role === null || this.lsp === null ) {
      this.snackBar.open('Preencha todos os campos obrigatórios', 'Ok', {duration: 5000});
    } else {
      this.clientUserService.createUser(this.user.name, this.user.email, this.lsp, this.user.role).subscribe( (data) => {
          if (data && data.text === 'User saved correctly') {
            this.snackBar.open('Usuário criado com sucesso', 'OK', {duration: 5000});
          }
          this.dialogRef.close(data);
        }, (error) => {
          if (error && error.error.text === 'User already exists') {
            this.snackBar.open('Erro: Usuário já está cadastrado no sistema', 'OK', {duration: 5000} );
          } else {
            this.snackBar.open('Erro ao criar usuário', 'Ok', {duration: 5000});
          }
        });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: 'delete-user-dialog.html',
  styleUrls: ['./lsp-user-admin.component.scss']
})
export class DeleteUserDialogComponent implements OnInit {
  name: any;
  user: any = new User();
  lsp: any;
  lspList = [];
  role: number;
  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>, public clientUserService: ClientUserService,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public snackBar: MatSnackBar) {
    this.data = data;
  }

  ngOnInit() {
  }

  onYesClick() {
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-edit-adm-user-dialog',
  templateUrl: 'edit-adm-user-dialog.html',
  styleUrls: ['./lsp-user-admin.component.scss']
})
export class EditUserAdminDialogComponent implements OnInit {
  name: string;
  email: string;
  role: number;
  listOfRoles = [
    1, 19, 77
  ];
  user: any = new User();
  constructor(
    public dialogRef: MatDialogRef<EditUserAdminDialogComponent>, public clientUserService: ClientUserService,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onYesClick() {
    if (this.user.name === '' || this.user.email === '' || this.user.role === null ) {
      this.snackBar.open('Preencha todos os campos obrigatórios', 'Ok', {duration: 5000});
    } else {
      this.clientUserService.createAdminUser(this.user.name, this.user.email, this.user.role).subscribe( (data) => {
        if (data && data.text === 'User saved correctly') {
          this.snackBar.open('Usuário criado com sucesso', 'OK', {duration: 5000});
        }
        this.dialogRef.close(data);
      }, (error) => {
        if (error && error.error.text === 'User already exists') {
          this.snackBar.open('Erro: Usuário já está cadastrado no sistema', 'OK', {duration: 5000} );
        } else {
          this.snackBar.open('Erro ao criar usuário', 'Ok', {duration: 5000});
        }
      });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
