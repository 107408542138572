import { OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import wordCloud from 'highcharts/modules/wordcloud';
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import { TranslateService } from '@ngx-translate/core';
HC_solidgauge(Highcharts);
// Now init modules:
// HighchartsSolidGauge(Highcharts);
wordCloud(Highcharts);
more(Highcharts);
var WordcloudChartComponent = /** @class */ (function () {
    function WordcloudChartComponent(translateService) {
        this.translateService = translateService;
        this.colors = ['#B6BCE9', '#AFDEEE', '#ECE196'];
        this.data = [];
    }
    WordcloudChartComponent.prototype.ngOnInit = function () { };
    WordcloudChartComponent.prototype.getStatusColor = function (i) {
        return this.colors[i];
    };
    WordcloudChartComponent.prototype.createChart = function (data, id, translate) {
        var heatMapWords = [];
        Object.keys(data).forEach(function (key) {
            if (key.length >= 3) {
                heatMapWords.push({ name: key, weight: data[key] });
            }
        });
        heatMapWords.sort(function (a, b) { return b.weight - a.weight; }).splice(20);
        Highcharts.chart(id, {
            tooltip: {
                enabled: true,
            },
            chart: {
                type: 'wordcloud',
                renderTo: id,
            },
            title: {
                text: ''
            },
            series: [{
                    type: 'wordcloud',
                    data: heatMapWords,
                    name: 'id'
                }],
        });
    };
    return WordcloudChartComponent;
}());
export { WordcloudChartComponent };
