import { OnInit, ElementRef } from '@angular/core';
import { Action, Event } from '../../models/events';
import { Message } from '../../models/message';
import { SocketService } from '../../providers/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../providers/chat.service';
import { NotificationService } from '../../providers/notification.service';
import { MdrService } from '../../providers/mdr.service';
var ChatComponent = /** @class */ (function () {
    function ChatComponent(socketService, router, aRoute, mdrService, chat, notificationService) {
        this.socketService = socketService;
        this.router = router;
        this.aRoute = aRoute;
        this.mdrService = mdrService;
        this.chat = chat;
        this.notificationService = notificationService;
        this.action = Action;
        this.messages = [];
        this.users = [];
        this.pickupID = null;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
    ChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.channel = params.id;
            _this.chatType = params.type;
            _this.mdrService.getPickupID(_this.channel).subscribe(function (pid) {
                _this.pickupID = pid;
            });
            _this.initIoConnection(_this.channel);
        });
    };
    ChatComponent.prototype.getUsers = function () {
        var _this = this;
        this.users.push(this.currentUser.user.name);
        this.messages.forEach(function (el, i, arr) {
            if (_this.users.indexOf(el.user.name) === -1) {
                _this.users.push(el.user.name);
            }
        });
    };
    ChatComponent.prototype.initIoConnection = function (id) {
        var _this = this;
        this.socketService.initSocket('chat/' + id + '/' + this.chatType);
        this.user = this.currentUser.user;
        this.chat.getByChannel(id, this.chatType).subscribe(function (data) {
            data.forEach(function (el) {
                var k = new Message({ id: el.userId, name: el.userName }, el.message, _this.channel, el.createdAt, _this.chatType);
                _this.messages.push(k);
            });
            _this.getUsers();
            _this.scrollToBottom();
        });
        // this.socketService.joinConversation(id);
        this.ioConnection = this.socketService.onMessage()
            .subscribe(function (message) {
            // console.log(message);
            _this.messages.push(message);
        });
        this.socketService.onEvent(Event.CONNECT)
            .subscribe(function () {
            // console.log('connected');
        });
        this.socketService.onEvent(Event.NEW_MESSAGE)
            .subscribe(function (message) {
            _this.scrollToBottom();
            // this.messages.push(message);
        });
        this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(function () {
            // console.log('disconnected');
        });
    };
    ChatComponent.prototype.scrollToBottom = function () {
        var _this = this;
        try {
            setTimeout(function () {
                _this.myScrollContainer.nativeElement.scrollTop = _this.myScrollContainer.nativeElement.scrollHeight;
            }, 500);
        }
        catch (err) {
            // console.log(err);
        }
    };
    ChatComponent.prototype.sendMessage = function (message) {
        var _this = this;
        this.scrollToBottom();
        if (!message) {
            return;
        }
        this.socketService.send(new Message(this.user, message, this.channel, new Date(), this.chatType));
        this.messageContent = null;
        this.notificationService.createMessage(this.channel, this.currentUser.user.id).subscribe(function (data) {
            _this.notification(data);
        });
    };
    ChatComponent.prototype.notification = function (data) {
        var _this = this;
        this.socketService.initSocketNotification(Number(this.user));
        var message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
        this.socketService.sendNotification(message);
        // console.log(data);
        data.forEach(function (value) {
            var nmessage = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), _this.chatType);
            _this.socketService.sendNotification(nmessage);
        });
    };
    ChatComponent.prototype.sendNotification = function (params, action) {
        var message;
        if (action === Action.JOINED) {
            message = new Message(this.user, action, this.channel, new Date(), this.chatType);
        }
        else if (action === Action.RENAME) {
            // console.log('Nada a fazer');
        }
        this.socketService.send(message);
    };
    return ChatComponent;
}());
export { ChatComponent };
