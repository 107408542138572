<div *ngIf="!uploaded">
   <div class="mx-5 my-4">
      <p class="mat-h1">{{ 'INSERT-NFE/not-uploaded/title' | translate }}</p>
   </div>
   <div class="colored-background table-background">
      <div class="mx-5 py-3">
         <mat-card class="rounded-corners">
            <mat-card-content class="d-flex flex-wrap">
               <div class="col-md-12">
                  <input class="hidden" type='file' #fileInput accept='text/xml' (change)='openFile($event)' multiple>
                  <button mat-raised-button class="button green-button text-white"
                          (click)="fileInput.click()">{{ 'INSERT-NFE/not-uploaded/form/select-file' | translate }}</button>
               </div>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</div>

<div *ngIf="uploaded">
   <div class="mx-5 my-4">
      <p class="mat-h1">{{ 'INSERT-NFE/uploaded/title' | translate }}</p>
   </div>
   <div class="colored-background table-background">
      <div class="mx-5 py-3">
         <mat-card class="rounded-corners">
            <mat-card-content class="d-flex flex-wrap">
               <div *ngIf="dataSourceNF.data.length > 0" style="margin-bottom: 30px;" class="w-100">
                  <table mat-table #table [dataSource]="dataSourceNF" matSort>

                     <ng-container matColumnDef="invoiceNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/invoice-number' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.invoiceNumber }} </td>
                     </ng-container>

                     <ng-container matColumnDef="series">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/series' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.series}} </td>
                     </ng-container>

                     <ng-container matColumnDef="client">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/client' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.client}} </td>
                     </ng-container>

                     <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/type' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                     </ng-container>

                     <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/weight' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                     </ng-container>

                     <ng-container matColumnDef="totalValue">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/total-value' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.totalValue}} </td>
                     </ng-container>

                     <ng-container matColumnDef="emissionDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/emission-date' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.emissionDate | date: "dd/MM/yy, h:mm a"}} </td>
                     </ng-container>

                     <ng-container matColumnDef="width">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/width' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.width}} </td>
                     </ng-container>

                     <ng-container matColumnDef="height">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/height' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.height}} </td>
                     </ng-container>

                     <ng-container matColumnDef="depth">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/depth' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.depth}} </td>
                     </ng-container>

                     <ng-container matColumnDef="materials">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'INSERT-NFE/uploaded/table/materials' | translate }} <th>
                        <td mat-cell *matCellDef="let element"> {{element.materials}} </td>
                     </ng-container>

                     <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns3;" [ngStyle]="{background: ( row.status === true? 'rgba(0,200,0,0.4)' : (row.status === false? 'rgba(200,0,0,0.4)': 'rgba(213, 255, 66, 0)'))}" ></tr>
                  </table>
                  <mat-paginator #paginator
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10]"
                     [showFirstLastButtons]="true">
                  </mat-paginator>
               </div>
               <div class="ml-auto col-md-4">
                  <button mat-raised-button class="button green-button text-white" (click)="insertNFe()">{{ 'INSERT-NFE/uploaded/button' | translate }}</button>
               </div>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</div>
