import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var NfeService = /** @class */ (function () {
    function NfeService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    NfeService.prototype.getAllNfe = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'nf/', { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    NfeService.prototype.getById = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'nf/id/' + id, { headers: header });
    };
    NfeService.prototype.getHistory = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'nf/history/' + id, { headers: header });
    };
    NfeService.prototype.getAll = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'nf/' + id, { headers: header })
            .pipe(map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        }), catchError(function (err, caught) {
            // console.log({error: err, caught: caught});
            throw [];
            // return [];
        }));
    };
    NfeService.prototype.handleError = function (error) {
        // console.log('error');
        // console.log(error);
        return throwError(error);
    };
    NfeService.prototype.attach = function (nfeid, file) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        var formData = new FormData();
        formData.append('file', file, file.name);
        var param = new HttpParams().set('nfeid', String(nfeid));
        return this.http.post(base_url + 'nf/attachment', formData, {
            headers: header,
            params: param,
            reportProgress: true
        }).map(function (response) {
            return response;
        });
    };
    NfeService.prototype.delete = function (nf) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'nf/' + nf.invoiceNumber, { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    NfeService.prototype.update = function (nf) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'nf/' + nf.invoiceNumber, { nfe: nf }, { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    NfeService.prototype.create = function (nf) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'nf/', { nfe: nf }, { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    NfeService.prototype.create_xml = function (nf, file) {
        var formdata = new FormData();
        formdata.append('nfe', JSON.stringify(nf));
        file.forEach(function (subfile) {
            formdata.append('upload', subfile);
        });
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // const param = new HttpParams().set('nfe', JSON.stringify(nf));
        return this.http.post(base_url + 'nf/xml', formdata, {
            headers: header,
        });
    };
    NfeService.prototype.getAll_xml = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'nf/xml/' + id, {
            headers: header,
        })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    return NfeService;
}());
export { NfeService };
