import { OnInit } from '@angular/core';
import { PriceMakerService } from '../../../providers/price-maker.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { PriceMaker } from '../../../models/price-maker';
var PriceMakerTableComponent = /** @class */ (function () {
    function PriceMakerTableComponent(pmService, dialog, snackBar, excelService) {
        this.pmService = pmService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.excelService = excelService;
        this.role = 0;
        this.columns = [
            { name: 'id', value: 'id' },
            { name: 'PRICE_MAKER_TABLE/table/shippingCompany', value: 'shippingCompany' },
            { name: 'PRICE_MAKER_TABLE/table/service', value: 'service' },
            { name: 'PRICE_MAKER_TABLE/table/region', value: 'region' },
            { name: 'vehicle', value: 'vehicle' },
            { name: 'PRICE_MAKER_TABLE/table/price', value: 'price' },
            { name: 'PRICE_MAKER_TABLE/table/absolute', value: 'absolute' },
            { name: 'PRICE_MAKER_TABLE/table/sapCode', value: 'sapCode' },
            { name: 'createdAt', value: 'createdAt' },
            { name: 'createdBy', value: 'createdBy' },
            { name: 'updatedAt', value: 'updatedAt' },
            { name: 'updatedBy', value: 'updatedBy' }
        ];
        var role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.role = role;
        this.data = [];
    }
    PriceMakerTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pmService.tableRead().subscribe(function (data) {
            // pega as keys dos objetos retornados
            var keys = data.length ? Object.keys(data[0]) : [];
            // remove os valores null
            _this.data = data.map(function (el) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    if (el[keys[i]] === null) {
                        el[keys[i]] = '';
                    }
                }
                // Removing internal/unnecessary info
                delete el.sc.active;
                delete el.sc.clientCnpj;
                el.createdAt = new Date(el.createdAt);
                el.updatedAt = new Date(el.updatedAt);
                return el;
            });
        });
    };
    PriceMakerTableComponent.prototype.addItem = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NewPriceMakerTableDialogComponent, {
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result.what === 'insert') {
                _this.pmService.tableCreate([result.data]).subscribe(function (data) {
                    _this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                }, function (er) {
                    if (er.status === 403) {
                        _this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', { duration: 4000 });
                    }
                    else if (er.status === 200) {
                        _this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                    }
                });
            }
        });
    };
    PriceMakerTableComponent.prototype.onRowClick = function (row, that) {
        // that aponta para this, mas é necessário para garantir que não é o this do app-table
        that.openDialog(row);
    };
    PriceMakerTableComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                    // console.log(data);
                    var multiple = [];
                    data.forEach(function (el, i, arr) {
                        var newPrice = new PriceMaker();
                        if (el['id'] !== null) {
                            newPrice.id = el['id'];
                        }
                        newPrice.absolute = el['absolute'];
                        newPrice.price = el['price'];
                        newPrice.region = el['region'];
                        newPrice.sapCode = el['sapCode'];
                        newPrice.service = el['service'];
                        newPrice.shippingCompany = el['shippingCompany'];
                        newPrice.vehicle = el['vehicle'];
                        multiple.push(newPrice);
                    });
                    _this.pmService.tableCreate(multiple).subscribe(function (el) {
                        _this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                    }, function (er) {
                        // console.log(er);
                        if (er.status === 403) {
                            _this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', { duration: 4000 });
                        }
                    });
                });
            }
        }
    };
    PriceMakerTableComponent.prototype.openDialog = function (row) {
        var _this = this;
        var dialogRef = this.dialog.open(EditPriceMakerTableDialogComponent, {
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result.what === 'delete') {
                _this.pmService.tableDelete(result.data.id).subscribe(function (data) {
                    _this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                }, function (er) {
                    // console.log(er);
                    if (er.status === 403) {
                        _this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', { duration: 4000 });
                    }
                });
            }
            else if (result.what === 'update') {
                _this.pmService.tableUpdate(result.data).subscribe(function (data) {
                }, function (er) {
                    // console.log(er);
                    if (er.status === 403) {
                        _this.snackBar.open('Você não possui autorização para fazer alterações nesse sistema', 'Ok', { duration: 4000 });
                    }
                });
            }
        });
    };
    return PriceMakerTableComponent;
}());
export { PriceMakerTableComponent };
var EditPriceMakerTableDialogComponent = /** @class */ (function () {
    function EditPriceMakerTableDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    EditPriceMakerTableDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    EditPriceMakerTableDialogComponent.prototype.onClickRemove = function () {
        this.dialogRef.close({ what: 'delete', data: this.data });
    };
    EditPriceMakerTableDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'update', data: this.data });
    };
    return EditPriceMakerTableDialogComponent;
}());
export { EditPriceMakerTableDialogComponent };
var NewPriceMakerTableDialogComponent = /** @class */ (function () {
    function NewPriceMakerTableDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    NewPriceMakerTableDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    NewPriceMakerTableDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'insert', data: this.data });
    };
    return NewPriceMakerTableDialogComponent;
}());
export { NewPriceMakerTableDialogComponent };
