import { Subject } from 'rxjs/Subject';
var DrObservable = /** @class */ (function () {
    function DrObservable() {
        this.dr = new Subject();
        this.currentDR = this.dr.asObservable();
    }
    DrObservable.prototype.changeDR = function (getDR) {
        this.dr.next(getDR);
        // console.log(this.dr);
    };
    return DrObservable;
}());
export { DrObservable };
