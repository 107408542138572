<div fxLayout="column" fxLayoutAlign="space-around stretch">

  <!-- dropdown para selecionar DR -->
  <mat-form-field>
    <mat-label>{{ 'ATTACHMENTS/add-nf/select-dr' | translate }}:</mat-label>
    <mat-select [(value)]="selectedDr">
      <mat-option *ngFor="let dr of drList" [value]="dr">
        {{dr.idDRidentifier}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- search bar -->
  <mat-form-field>
    <input matInput placeholder="{{ 'ATTACHMENTS/add-nf/search' | translate }}"
      [(ngModel)]="searchInput"
      (keyup.enter)="search()"
    >
    <i matSuffix font class="fa fa-search" (click)="search()"></i>
  </mat-form-field>

</div>

<!-- tabs with the tables -->
<mat-tab-group mat-stretch-tabs>

  <mat-tab label="{{'ATTACHMENTS/add-nf/search-tab'|translate}}">
    <!-- searched OV/NF table -->
    <app-table
      [data]="searchedValues"
      [columns]="columns"
      [this]="this"
      showCheckbox="true"
      (check)="addToList($event)"
      [styleCallback]="rowStyle"
    ></app-table>
  </mat-tab>

  <mat-tab label="{{'ATTACHMENTS/add-nf/add-tab'|translate}} ({{toAdd.length}})">
    <!-- OV/NF to be added table -->
    <app-table
      [data]="toAdd"
      [columns]="columns"
      [this]="this"
      [clickCallback]="removeFromList"
    ></app-table>
  </mat-tab>

</mat-tab-group>

<!-- buttons -->
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button mat-button class="red-button" (click)="submit()" [disabled]="toAdd.length === 0 || !selectedDr">
    {{ 'ATTACHMENTS/add-nf' | translate }}
  </button>
</div>
