import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
var DrCardComponent = /** @class */ (function () {
    function DrCardComponent(snackBar, router) {
        this.snackBar = snackBar;
        this.router = router;
        this.tooltip = [];
        this.pickupID = null;
        this.shippingCompany = '';
        this.truckSaw = false;
        this.novasMensagens = {
            5: false,
            6: false
        };
        this.modalAereo = false;
        this.occAgendamento = false;
        this.rescheduledLegs = false;
        this.icons = {
            'red': 'fa-exclamation-circle',
            'yellow': 'fa-exclamation-triangle',
            'green': '',
            'undefined': 'fa-hourglass-half'
        };
        this.show = true;
    }
    DrCardComponent.prototype.getStatusClass = function () {
        return 'dr-status-' + this.color;
    };
    DrCardComponent.prototype.ngOnInit = function () {
        this.color = this.dr['color'];
        this.tooltip = this.dr['tooltip'];
        this.truckSaw = this.dr['truckSaw'];
        this.novasMensagens = this.dr['novasMensagens'];
        if (this.dr.mdr !== undefined) {
            this.shippingCompany = this.dr.mdr.shippingCompany;
            this.pickupID = this.dr.mdr.pickupID;
            this.modalAereo = this.dr.mdr.airplane;
        }
        if (this.dr.occurrences.length > 0) {
            for (var _i = 0, _a = this.dr.occurrences; _i < _a.length; _i++) {
                var occ = _a[_i];
                if (occ.status === 'OCCURRENCE_TO_RESOLVE') {
                    if (occ.uoc === 'Consolidação e Roteirização de viagens filhas') {
                        this.rescheduledLegs = true;
                    }
                    if (occ.uoc === 'Agendamento') {
                        this.occAgendamento = true;
                    }
                }
            }
        }
    };
    DrCardComponent.prototype.setRedirectUrl = function () {
        if (this.dr.status === 'BOOKING' || this.dr.status === 'BOOKING_PENDING') {
            return (window.location.origin + '/dr-validation/' + this.dr.id);
        }
        else if (this.dr.idMDR !== null && this.dr.idMDR !== 0) {
            return (window.location.origin + '/logistic-order/' + this.dr.idMDR);
        }
        else {
            return (window.location.origin + '/delivery-optimizer');
        }
    };
    return DrCardComponent;
}());
export { DrCardComponent };
