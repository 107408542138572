import { PipeTransform } from '@angular/core';
/*
 * Formata o endereço recebido
 * Recebe uma string de formatação cujo valor default é '%A'
 * Formato esperado:
 *
 * Uso:
 *   endereço | address:formatação
 * Exempo:
 *   {{ 'Rua, Número - Bairro, Cidade - Estado, País' | address:'%c/%E' }}
 *   resulta em: 'Cidade/Estado'
 * Campos:
 *  %r  - rua, sem o número
 *  %N  - número
 *  %b  - bairro
 *  %c  - cidade
 *  %E  - estado
 *  %P  - país
 *  %A  - endereço completo
 */
var AddressPipe = /** @class */ (function () {
    function AddressPipe() {
    }
    AddressPipe.prototype.transform = function (address, format) {
        if (format === void 0) { format = '%A'; }
        if (address === null) {
            address = '';
        }
        var addr = {
            '%r': 'sem rua',
            '%N': 'sem número',
            '%b': 'sem bairro',
            '%c': 'sem cidade',
            '%E': '??',
            '%P': 'sem país',
            '%A': address
        };
        var splt = address.split('-');
        if (splt.length >= 3 && splt[2].includes(',')) {
            addr['%E'] = splt[2].split(',')[0];
            addr['%P'] = splt[2].split(',')[1];
        }
        if (splt.length >= 2 && splt[1].includes(',')) {
            addr['%b'] = splt[1].split(',')[0];
            addr['%c'] = splt[1].split(',')[1];
        }
        if (splt.length >= 1 && splt[0].includes(',')) {
            addr['%r'] = splt[0].split(',')[0];
            addr['%N'] = splt[0].split(',')[1];
        }
        // substitui os valores na string de saida conforme format
        var output = format;
        for (var _i = 0, _a = Object.keys(addr); _i < _a.length; _i++) {
            var el = _a[_i];
            output = output.replace(el, addr[el].trim());
        }
        // POG TODO remover essa gambiarra
        if (output === 'sem cidade/??') {
            output = 'Ericsson';
        } // POG-fim
        return output;
    };
    return AddressPipe;
}());
export { AddressPipe };
