import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ERBService = /** @class */ (function () {
    function ERBService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ERBService.prototype.getNearERB = function (lat, lng) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'erb/getNearERB/' + lat + '/' + lng, { headers: header });
    };
    return ERBService;
}());
export { ERBService };
