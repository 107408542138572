import { Subject } from 'rxjs/Subject';
var MdrObservable = /** @class */ (function () {
    function MdrObservable() {
        this.mdr = new Subject();
        this.currentMDR = this.mdr.asObservable();
    }
    MdrObservable.prototype.changeMDR = function (getMDR) {
        this.mdr.next(getMDR);
        // console.log(this.mdr);
    };
    return MdrObservable;
}());
export { MdrObservable };
