import {User} from './user';
import {Action} from './events';

export enum TYPE_EXPEDITION  {
                          PICTURE_1 = 'MDR lateral Esquerda'
                        , PICTURE_2 = 'MDR traseira'
                        , PICTURE_3 = 'MDR frontal'
                        , PICTURE_4 = 'MDR lateral '
                        , PICTURE_5 = 'MDR carregado aéreo'
                        , PICTURE_6 = 'MDR carregado frontal'
                        , PICTURE_7 = 'MDR carregado placa'
}

export class Message {
  public user: User;
  public content: string;
  public action: Action;
  public channel: number;
  public createdAt: Date;
  public chatType: number;

  constructor(user,  content, channel, createdAt, chatType) {
    this.user = user;
    this.content = content;
    this.channel = channel;
    this.createdAt = createdAt;
    this.chatType = chatType;
  }

  public getUser() {
    return this.user;
  }

  public getContent() {
    return this.content;
  }
}
