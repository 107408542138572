var HUBDR = /** @class */ (function () {
    function HUBDR() {
        this.id = null;
        this.observation = null;
        this.drID = null;
        this.hubID = null;
        this.when = null;
    }
    return HUBDR;
}());
export { HUBDR };
