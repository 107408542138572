<span *ngIf="label">{{ label }}:</span>

<input [id]="'fileInput-'+name" class="file-input" type="file" [name]="name" value="selecionado">

<span class="input">
  <button mat-raised-button class="blue" (click)="onClick()">{{ 'FORM_UPLOAD/button' |translate }}</button>
  <div *ngIf="showName">
    <span *ngIf="!selecionado">{{ 'FORM_UPLOAD/txt/no-file' | translate }}</span>
    <span *ngIf="selecionado">{{ selecionado }}</span>
  </div>
</span>
