import { Component, OnInit, ViewChild, Inject, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { interval } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { Router } from '@angular/router';

import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { SocketService } from '../../providers/socket.service';

import { Event } from '../../models/events';

import { DrCardComponent } from './dr-card/dr-card.component';

import { ExcelService } from '../../misc/export-xlsx/export-xlsx';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DR } from '../../models/dr';

import { CookieService } from 'ngx-cookie-service';
import { MDR_STATUS } from '../../models/mdr';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit, OnDestroy {
  undefinedStatus = 'Outros Status';
  displayList: string[] = [
    'BOOKING',
    'BOOKING_REPROVED',
    'Returned by LSP',
    'Reproved By WAM',
    'New MDR',
    'Planning MDR',
    'Waiting LSP',
    'READY_TO_PICKUP',
    'InTransit',
    'Delivered',
    'HubReturn',
    'Retido / Replan',
    'Approved by LSP',
    'MDR bloqueada por Avaria'
  ];
  statusList: string[] = [
    'BOOKING',
    'BOOKING_PENDING',
    'Waiting LSP',
    'Approved by LSP',
    'Reproved By WAM',
    'Planning MDR',
    'Nova MDR',
    'Returned by LSP',
    'READY_TO_PICKUP',
    'InTransit',
    'Delivered',
    'Replan - Antecipação',
    'Replan - Postergação',
    'Replan - Pernoite',
    'Replan - Retido',
    'Replan - Sefaz',
    'HubReturn',
    'Devolução/Trânsito',
    'MDR bloqueada por Avaria'
  ]; // todas as strings aqui deve estar presentes em statusNameMap !!
  statusNameMap = {
    'BOOKING': 'BOOKING',
    'BOOKING_PENDING': 'BOOKING_REPROVED',
    'Waiting LSP': 'Waiting LSP',
    'Planning MDR': 'Planning MDR',
    'Reproved By WAM': 'Reproved By WAM',
    'Nova MDR': 'New MDR',
    'Approved by LSP': 'Approved by LSP',
    'Returned by LSP': 'Returned by LSP',
    'Replan - Antecipação': 'Retido / Replan',
    'Replan - Postergação': 'Retido / Replan',
    'Replan - Retido': 'Retido / Replan',
    'Replan - Pernoite': 'InTransit',
    'Replan - Sefaz': 'Retido / Replan',
    'READY_TO_PICKUP': 'READY_TO_PICKUP',
    'HubReturn': 'HubReturn',
    'InTransit': 'InTransit',
    'Delivered': 'Delivered',
    'MDR bloqueada por Avaria': 'MDR bloqueada por Avaria',
    'Devolução/Trânsito': 'InTransit'
  }; // key = status do card ; value = coluna na qual o card será colocado

  drLists: any = {};
  colors: any = {};
  selectedColor: string = null;
  colorList = ['green', 'red', 'yellow', 'undefined'];
  colorStarter = {};
  @ViewChildren(DrCardComponent) drCardList: QueryList<DrCardComponent>;
  drList = [];

  searchDates: any[] = [null, null];

  @ViewChild('search')
  public searchElementRef: ElementRef;
  public searchChips: any[] = [];
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  searchTerm = '';
  validSearchFields: string[] = ['dr.idMDR', 'pickupID', 'dr.dateScheduling', 'dr.client', 'dr.site', 'shippingCompany', 'dr.createdBy'];
  public ioConnection: any;
  public role: number;
  public chatTypes: number[];
  public search_toggle = false;

  uid = 'cockpit-';

  subscriptions: any[] = [];

  constructor(
    private drService: DrService,
    public pDate: DatePipe,
    private socketService: SocketService,
    public snackBar: MatSnackBar,
    private excelService: ExcelService,
    public dialog: MatDialog,
    public cookie: CookieService
  ) {
    // inicializa cores
    this.colorList.forEach((color) => {
      this.colorStarter[color] = 0;
    });

    const statusList = [].concat(this.displayList, this.undefinedStatus);
    statusList.forEach((el, i, arr) => {
      // inicializa drLists
      this.drLists[el] = [];

      // incializa colors
      this.colors[el] = Object.assign({}, this.colorStarter);
    });

    const role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.role = role;
    if (role === 5 || role === 12 || role === 14) { // lsp
      this.chatTypes = [6]; // embarcadora <-> transportadora
    } else if (role === 1 || role === 2 || role === 3) { // ericsson
      this.chatTypes = [5, 6]; // motorista <-> transportadora ; embarcadora <-> transportadora
    }
  }

  ngOnInit() {
    this.initIoConnection();
    this.subscriptions.push(this.drService.getForCockpit().subscribe((data) => {
      if (this.role === 1 || this.role === 19 || this.role === 35 || this.role === 3) {
        this.subscriptions.push(this.drService.getBookings().subscribe((bookings) => {
          data = [].concat(data, bookings);
          this.organizeData(data);
        }, (error) => {
          this.organizeData(data);
        }));
      } else { // role !== 1
        this.organizeData(data);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public checkDrCards() {
    const sleepAmount = 500; // 0.5s
    if (this.drList.length > 0
      && this.drList.length === this.drCardList.length) {
      this.loadSearches();
    } else {
      setTimeout(() => { this.checkDrCards(); }, sleepAmount);
    }
  }

  public getStatusName(status: string) {
    if (this.statusList.indexOf(status) !== -1) {
      return this.statusNameMap[status];
    } else {
      return this.undefinedStatus;
    }
  }

  private initIoConnection(): void {
    this.socketService.initSocket('cockpit');

    this.subscriptions.push(this.ioConnection = this.socketService.onEvent(Event.UPDATE_DR)
      .subscribe((data) => {
        if (data.what === 'dr created') { // o backend não retorna valores se a DR não puder ser lida pelo usuário logado
          this.subscriptions.push(this.drService.getById(data.status.id).subscribe((dr) => {
            this.drLists[this.getStatusName(dr.status)].push(dr);
          }));
        } else if (data.what === 'dr updated') { // atualiza somente se o card antigo já estiver sendo mostrado
          let oldStatus = '';
          // encontra a coluna do card antigo
          this.displayList.forEach((el, i, arr) => {
            if (this.drLists[el].map((e) => e.id).indexOf(data.status.id) !== -1) {
              oldStatus = el;
            }
          });
          const position = this.drLists[this.getStatusName(oldStatus)].map((e) => e.id).indexOf(data.status.id);
          if (oldStatus !== '') {
            // remove o card antigo
            this.drLists[this.getStatusName(oldStatus)].splice(position, 1);
            // adiciona o card novo
            this.drLists[this.getStatusName(data.status.status)].unshift(data.status);
          }
        } else if (data.what === 'new occurrence') { // atualiza somente se o card antigo já estiver sendo mostrado
          let oldStatus = '';
          // encontra a coluna do card antigo
          this.displayList.forEach((el, i, arr) => {
            if (this.drLists[el].map((e) => e.id).indexOf(data.status.id) !== -1) {
              oldStatus = el;
            }
          });
          const position = this.drLists[this.getStatusName(data.status.status)].map((e) => e.id).indexOf(data.status.id);
          if (oldStatus !== '') {
            // remove o card antigo
            this.drLists[this.getStatusName(oldStatus)].splice(position, 1);
            // adiciona o card novo
            this.drLists[this.getStatusName(data.status.status)].unshift(data.status);
          }
        } else if (data.what === 'new message') { // não está sendo enviado pelo back
          if (this.chatTypes.indexOf(Number(data.status.chatType)) !== -1 && data.status.userRole !== this.role) {
            // console.log('atualiza mensagens no card',data.status.mdrId);//DEBUG
            const updated = this.drCardList.find((el, i, arr) => {
              if (parseInt(el.dr.idMDR, 10) === parseInt(data.status.mdrId, 10)) {
                return true;
              }
              return false;
            });
            updated.novasMensagens[data.status.chatType] = true;
          }
        }
      }));

    this.subscriptions.push(this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        // console.log('connected');
      }));

    this.subscriptions.push(this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        // console.log('disconnected');
      }));
  }

  organizeData(data) {
    // reseta contagem de cores
    this.resetColors();

    // pra cada DR
    data.forEach((el, i, arr) => {

      // corrige campos que são Date
      ['dateScheduling'].forEach((key) => {
        el[key] = new Date(el[key]);
      });

      // setta dr.status de acordo com status da MDR associada
      if (el.mdr !== undefined) {
        if (el.mdr.status === 'MDR aguardando LSP') {
          el.status = 'Waiting LSP';
        } else if (el.mdr.status === 'Entrega de carga certificada pelo LSP') {
          el.status = 'Approved by LSP';
        } else if (el.mdr.status === 'MDR devolvida por LSP') {
          el.status = 'Returned by LSP';
        } else if (el.mdr.status === 'Nova MDR') {
          el.status = 'Nova MDR';
        } else if (el.mdr.status === 'MDR retornada pelo WAM') {
          el.status = 'Reproved By WAM';
        } else if (el.mdr.status === MDR_STATUS.MDR_COM_AVARIA) {
          el.status = 'MDR bloqueada por Avaria';
        }
      }
      const status = this.getStatusName(el.status);

      // setta cor
      this.setStatusColor(el);

      // da push na coluna de acordo com mapa de status
      this.drLists[status].push(el);

      // atualiza cores
      this.colors[status][el.color]++;

    });

    // salva DRs para referencia
    this.drList = data;
    // aguarda DRs carregarem para carregar busca salva
    this.checkDrCards();

  }

  resetColors() {
    // inicializa contadores de cores
    const statusList = [].concat(this.displayList, this.undefinedStatus);
    statusList.forEach((el, i, arr) => {
      this.colors[el] = Object.assign({}, this.colorStarter);
    });
  }

  setColor(color: string) {
    if (this.selectedColor === color) {
      this.selectedColor = null;
    } else {
      this.selectedColor = color;
    }
    return this.selectedColor;
  }

  getColor() {
    return this.selectedColor;
  }

  dateChanged() {
    this.applyFilter();
  }

  applyFilter() {
    this.saveSearches();

    // valores da busca
    const dates = {
      start: this.searchDates[0],
      end: this.searchDates[1]
    };
    const chips = this.searchChips;
    const chipsSearchFields = this.validSearchFields;

    // reseta cores
    this.resetColors();

    // pra cada <dr-card>
    this.drCardList.forEach((drcard, i, arr) => {
      const dr = drcard.dr;
      // console.log(dr);

      // valida datas
      let checkDates = (searchDates) => true;
      if (dates.start || dates.end) {
        checkDates = (searchDates) => {
          const drTime = dr.dateScheduling.getTime();
          if (searchDates.start && drTime < searchDates.start.getTime()) {
            return false;
          }
          if (searchDates.end && searchDates.end.getTime() <= drTime) {
            return false;
          }
          return true;
        };
      }
      const displayFromDate = checkDates(dates);

      // valida chips
      let checkChip = (chip) => true;
      if (chipsSearchFields.length) {
        checkChip = (chip) => {
          return chipsSearchFields.some((el) => {
            // se for um campo da dr
            if (el.indexOf('dr.') === 0) {
              el = el.split('.')[1];
              if (el in dr && dr[el] !== null) {
                if (typeof dr[el] === 'string' &&
                  dr[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof dr[el] === 'number' &&
                  dr[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            } else { // se for um campo do card
              if (drcard[el] !== null) {
                if (typeof drcard[el] === 'string' &&
                  drcard[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof drcard[el] === 'number' &&
                  drcard[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            }
          });
        };
      }
      const displayFromChips = this.search_toggle
        ? chips.map(el => el.name).some(checkChip)
        : chips.map(el => el.name).every(checkChip);

      // oculta/mostra <dr-card>
      drcard.show = displayFromChips && displayFromDate;

      // atualiza cores
      if (drcard.show) {
        this.colors[this.getStatusName(dr.status)][dr.color]++;
      }

    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      for (const text of value.split(',')) {
        this.searchChips.push({ 'name': text.trim() });
      }
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  trackByFn(index, item) {
    return index;
  }

  exportBookingAsXlsx() {
    const drs = {
      booking: [],
      pendentes: []
    };

    this.drCardList.forEach((drCard) => {
      if (drCard.show === true && drCard.dr.status === 'BOOKING') {
        const card = drCard;
        const dr = {};
        dr['N° da DR'] = card.dr.idDRidentifier;
        dr['Cliente'] = card.dr.client;
        dr['salesOrders'] = card.dr.salesOrders.join(', ');
        dr['Data de Agendamento'] = this.pDate.transform(card.dr.dateScheduling, 'dd/MM/yyyy HH:mm');
        dr['site'] = card.dr.site;
        dr['Prolog'] = card.dr.prolog;
        dr['Endereço de entrega'] = card.dr.deliveryAddress;
        dr['Distância KM'] = card.dr.distance;
        dr['Cidade de entrega'] = card.dr.city ? card.dr.city : 'Sem cidade';
        dr['UF entrega'] = card.dr.uf ? card.dr.uf : 'Sem estado';
        dr['Nome do recebedor (MDR)'] = card.dr.receiverName;
        dr['Telefone do recebedor'] = card.dr.receiverPhone;
        dr['Infra do Site'] = card.dr.infraType;
        dr['Observações'] = card.dr.obs;
        dr['Nome do Supervisor'] = card.dr.supervisorName;
        dr['Telefone do Supervisor'] = card.dr.supervisorPhone;
        dr['LAT/LNG'] = `${card.dr.lat}, ${card.dr.lng}`;
        drs.booking.push(dr);
      }
    });

    this.drCardList.forEach((drCard) => {
      if (drCard.show === true && drCard.dr.status === 'BOOKING_PENDING') {
        const card = drCard;
        const dr = {};
        dr['N° da DR'] = card.dr.idDRidentifier;
        dr['Cliente'] = card.dr.client;
        dr['salesOrders'] = card.dr.salesOrders.join(', ');
        dr['Data de Agendamento'] = this.pDate.transform(card.dr.dateScheduling, 'dd/MM/yyyy HH:mm');
        dr['site'] = card.dr.site;
        dr['Prolog'] = card.dr.prolog;
        dr['Endereço de entrega'] = card.dr.deliveryAddress;
        dr['Distância KM'] = card.dr.distance;
        dr['Cidade de entrega'] = card.dr.city ? card.dr.city : 'Sem cidade';
        dr['UF entrega'] = card.dr.uf ? card.dr.uf : 'Sem estado';
        dr['Nome do recebedor (MDR)'] = card.dr.receiverName;
        dr['Telefone do recebedor'] = card.dr.receiverPhone;
        dr['Infra do Site'] = card.dr.infraType;
        dr['Observações'] = card.dr.obs;
        dr['Nome do Supervisor'] = card.dr.supervisorName;
        dr['Telefone do Supervisor'] = card.dr.supervisorPhone;
        dr['LAT/LNG'] = `${card.dr.lat}, ${card.dr.lng}`;
        dr['Motivo'] = card.dr.deleteReason;
        drs.pendentes.push(dr);
      }
    });

    this.excelService.exportAsExcelFileWithMultipleSheets(drs, 'report_dr_booking');
  }


  exportAsXlsx2() {
    const mdrs = [];
    this.drCardList.forEach((drCard) => {
      if (drCard.show === true && drCard.dr.status !== 'BOOKING') {
        mdrs.push(drCard.dr.idMDR);
      }
    });
    this.subscriptions.push(this.drService.getCostsByMdrList(mdrs).subscribe((el) => {
      // console.log(el);
      el.forEach(row => {
        row['pickupDate'] = this.pDate.transform(row['pickupDate'], 'dd/MM/yyyy HH:mm');
      });
      this.excelService.exportAsExcelFile(el, 'relatorios');
    }));
  }

  exportAsXlsx() {
    const list = [];
    const drs = [];

    this.drCardList.forEach((drCard) => {
      if (drCard.show === true && drCard.dr.status !== 'BOOKING') {
        const card = drCard;
        const dr = {};
        dr['client'] = card.dr.client;
        dr['dateScheduling'] = this.pDate.transform(card.dr.dateScheduling, 'dd/MM/yyyy HH:mm');
        dr['deliveryAddress'] = card.dr.deliveryAddress;
        dr['deliveryAddressCity'] = card.dr.city ? card.dr.city : 'Sem cidade';
        dr['deliveryAddressUf'] = card.dr.uf ? card.dr.uf : 'Sem estado';
        dr['driverPositionCreated'] = card.dr.driverPosition ? card.dr.driverPosition.createdAt : '';
        dr['shippingCompany'] = card.dr.mdr ? card.dr.mdr.shippingCompany : '';
        dr['createdBy'] = card.dr.createdBy;
        dr['pickupID'] = card.dr.mdr.pickupID;
        dr['pickupAddress'] = card.dr.pickupAddress;
        dr['site'] = card.dr.site;
        dr['status'] = card.dr.status;
        dr['salesOrders'] = card.dr.nfsAndOvs.join(', ');
        dr['OccurrenceLastStatus'] = card.dr.occurrences.length > 0 ? card.dr.occurrences[0].status : '';
        dr['mdrStatus'] = card.dr.mdr ? card.dr.mdr.status : '';
        dr['SLA'] = card.dr.mdr ? card.dr.mdr.aeroDelivery : '';
        dr['vehicle'] = card.dr.mdr ? card.dr.mdr.vehicle : '';
        dr['pickupDate'] = card.dr.mdr ? this.pDate.transform(card.dr.mdr.pickupDate , 'dd/MM/yyyy HH:mm') : '';
        drs.push(dr);
      }
    });

    this.excelService.exportAsExcelFile(drs, 'relatorios');
  }

  openMap(): void {
    const drList = this.drCardList.filter((e) => {
      return e.show === true && e.dr.status === 'InTransit';
    });
    const dialogRef = this.dialog.open(MapDialogComponent, {
      width: '800px',
      data: drList,
    });
  }

  setStatusColor(dr) {
    const tooltip = [];
    let color = 'green';
    let truckSaw = true;
    const hora = 1000 * 60 * 60;
    const now = Date.now();
    const novasMensagens = {
      5: false,
      6: false
    };

    if (dr === null) {
      return;
    }

    if ('status' in dr && dr.status === 'BOOKING_PENDING') {
      color = 'red';
      tooltip.push('Booking pendente');
    }

    if ('status' in dr && dr.status === 'Approved by LSP') {
      if ('mdr' in dr && 'updatedAt' in dr.mdr && dr.mdr.updatedAt === null) {
        // se a MDR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else if ('mdr' in dr && 'updatedAt' in dr.mdr) {
        const d = new Date(dr.mdr.updatedAt);
        if (now - d.getTime() < 12 * hora) {
          // se a última atualização ocorreu a menos de 1h
          color = 'green';
        } else if (now - d.getTime() < 24 * hora) {
          // se a última atualização ocorreu a menos de 2h
          color = 'yellow';
          tooltip.push('última atualização a mais de 12h');
        } else {
          // se a última atualização ocorreu a mais de 2h
          color = 'red';
          tooltip.push('última atualização a mais de 24h');
        }
      }
    }

    // se existe um caminhao para verificar
    if ('status' in dr && dr.status === 'InTransit') {
      if (!('driverPosition' in dr) || !dr.driverPosition || !dr.driverPosition.createdAt) {
        // se o caminhao ainda nao foi visto
        // color = 'red';
        truckSaw = false;
        tooltip.push('caminhão não encontrado');
      } else {
        const d = new Date(dr.driverPosition.createdAt);
        if (now - d.getTime() > 1 * hora) {
          // se o caminhao nao é visto a mais de 1h
          // color = 'red';
          truckSaw = false;
          tooltip.push('caminhão não é visto a mais de 1 hora');
        } else if (now - d.getTime() > 0.5 * hora) {
          // se o caminhao nao é visto a mais de 30min
          // color = (color === 'red') ? color : 'yellow';
          tooltip.push('caminhão não é visto a mais de 30 minutos');
        }
      }
    }

    // se está aguardando atualizações da LSP
    if ('status' in dr && dr.status === 'Waiting LSP') {
      if ('updatedAt' in dr && dr.updatedAt === null) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if (now - d.getTime() < 1 * hora) {
          // se a última atualização ocorreu a menos de 1h
          color = 'green';
        } else if (now - d.getTime() < 2 * hora) {
          // se a última atualização ocorreu a menos de 2h
          color = 'yellow';
          tooltip.push('última atualização a mais de 1h');
        } else {
          // se a última atualização ocorreu a mais de 2h
          color = 'red';
          tooltip.push('última atualização a mais de 2h');
        }
      }
    }

    // se está pronto para coleta
    if ('status' in dr && dr.status === 'READY_TO_PICKUP') {
      if ('mdr.pickupDate' in dr && dr.mdr.pickupDate === null) {
        color = 'undefined';
        tooltip.push('data de coleta ausente');
      } else {
        const d = new Date(dr['mdr']['pickupDate']);
        if (d.getTime() - now > 1 * hora) {
          // mais de 1h até a hora de coleta
          color = 'green';
        } else if (d.getTime() - now > 0.5 * hora) {
          // mais de 30min até a hora de coleta
          color = 'yellow';
          tooltip.push('menos de 1h até a hora de coleta');
        } else {
          // menos de 30min até a hora de coleta
          color = 'red';
          tooltip.push('menos de 30 minutos até a hora de coleta');
        }
      }
    }

    // se já foi entregue
    if ('status' in dr && dr.status === 'Delivered') {
      if ('updatedAt' in dr && dr.updatedAt === null) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if (now - d.getTime() < 6 * hora) {
          // se a última atualização ocorreu a menos de 6h
          color = 'green';
        } else if (now - d.getTime() < 12 * hora) {
          // se a última atualização ocorreu a menos de 12h
          color = 'yellow';
          tooltip.push('última atualização a mais de 6h');
        } else {
          // se a última atualização ocorreu a mais de 12h
          color = 'red';
          tooltip.push('última atualização a mais de 12h');
        }
      }
    }

    if ('status' in dr &&
      ['HubReturn',
        'Replan - Retido',
        'Replan - Sefaz',
        'Replan - Antecipação',
        'Replan - Postergação',
        'Replan - Pernoite'].indexOf(dr.status) !== -1) {
      if ('updated' in dr && dr.updatedAt === null) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if (now - d.getTime() < 5 * 24 * hora) {
          // se a última atualização ocorreu a menos de 5d
          color = 'green';
        } else if (now - d.getTime() < 10 * 24 * hora) {
          // se a última atualização ocorreu a menos de 10d
          color = 'yellow';
          tooltip.push('última atualização a mais de 5 dias');
        } else {
          // se a última atualização ocorreu a mais de 10d
          color = 'red';
          tooltip.push('última atualização a mais de 10 dias');
        }
      }
    }

    // verifica se ha mensagens nao lidas
    if ('idMDR' in dr && dr.idMDR !== null) {
      if (this.role === 5) { // lsp
        novasMensagens[6] = dr.messages.filter(msg => msg.chatType === 6).some(msg => !msg.seen);
      } else if (this.role === 1 || this.role === 2 || this.role === 3) { // ericsson
        if ('messages' in dr && dr.messages !== undefined) {
          novasMensagens[5] = dr.messages.filter(msg => msg.chatType === 5).some(msg => !msg.seen);
          novasMensagens[6] = dr.messages.filter(msg => msg.chatType === 6).some(msg => !msg.seen);
        }
      }
    }

    // verifica as ocorrencias da MDR
    if ('occurrences' in dr && dr.occurrences.length > 0) {
      const occurrencesNotSolved = [];
      for (let i = 0; i < dr.occurrences.length; i++) {
        if (dr.occurrences[i].status === 'OCCURRENCE_TO_RESOLVE') {
          occurrencesNotSolved.push(dr.occurrences[i]);
        }
      }

      if (occurrencesNotSolved.length > 0) {
        const s = ((occurrencesNotSolved.length === 1) ? '' : 's');
        if (occurrencesNotSolved.some((el) => {
          const d = new Date(el.createdAt);
          return (now - d.getTime() > 2 * hora);
        })) {
          // se alguma ocorrencia foi criada mais de 2h atrás
          color = 'red';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        } else {
          // se alguma ocorrencia foi criada, e todas menos de 2h atrás
          color = (color === 'red') ? color : 'yellow';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        }
      }
    } // fim verificação ocorrencias

    dr['tooltip'] = tooltip;
    dr['color'] = color;
    dr['truckSaw'] = truckSaw;
    dr['novasMensagens'] = novasMensagens;
  }

  /********************
   * auxiliares a salvar buscas
   */
  saveSearches() {
    this.cookie.set(this.uid + 'chips', JSON.stringify(this.searchChips));
    this.cookie.set(this.uid + 'search_toggle', JSON.stringify(this.search_toggle));
  }
  loadSearches() {
    if (this.cookie.check(this.uid + 'chips')) {
      try {
        this.searchChips = JSON.parse(this.cookie.get(this.uid + 'chips'));
      } catch (ex) { }
    }
    if (this.cookie.check(this.uid + 'search_toggle')) {
      try {
        this.search_toggle = JSON.parse(this.cookie.get(this.uid + 'search_toggle'));
      } catch (ex) { }
    }
    this.applyFilter();
  }

}

@Component({
  selector: 'app-cockpit-map-dialog',
  templateUrl: './cockpit-map-dialog.html',
  styleUrls: ['./cockpit.component.scss']
})
export class MapDialogComponent implements OnDestroy {
  lat = 0;
  lng = 0;
  n = 0;

  list = [];

  subscriptions: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    public mdrService: MdrService,
    private router: Router,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    const errors = 0;
    const mdrIdList = data.map(e => e.dr.idMDR);
    this.subscriptions.push(mdrService.getLastPositions(mdrIdList).subscribe((result) => {
      for (let i = 0, len = result.length; i < len; i++) {
        const dataI = data.find(e => e.dr.idMDR === result[i].mdrId);
        let marker_url: string;
        if (dataI.color === 'red') {
          marker_url = './assets/images/red_truck.png';
        } else if (dataI.color === 'yellow') {
          marker_url = './assets/images/blue_truck.png';
        } else {
          marker_url = './assets/images/green_truck.png';
        }
        this.list.push({
          lat: result[i].lat,
          lng: result[i].lng,
          icon: {
            url: marker_url,
            scaledSize: {
              width: 40,
              height: 40
            }
          },
          mdrId: result[i].mdrId
        });
      }
      const latSUM = this.list.map(e => e.lat).reduce(function (a, b) { return a + b; }, 0);
      this.lat = latSUM / this.list.length;
      const lngSUM = this.list.map(e => e.lng).reduce(function (a, b) { return a + b; }, 0);
      this.lng = lngSUM / this.list.length;
      if (this.list.length === 0) {
        this.snackBar.open('Nenhuma localização disponível', 'Ok', { duration: 2000 });
        this.dialogRef.close();
      }
    }, (error) => {
      this.snackBar.open('Erro ao carregar localizações', 'Ok', { duration: 2000 });
      this.dialogRef.close();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  goToMDR(point) {
    this.router.navigate(['logistic-order/' + point.mdrId]);
    this.dialogRef.close();
  }
}
