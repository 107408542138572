import { OnInit } from '@angular/core';
import { DashboardService } from '../../providers/dashboard.service';
import { GraphCardComponent } from '../../components/graph-card/graph-card.component';
import { TranslateService } from '@ngx-translate/core';
import { ClientUserService } from '../../providers/client-user.service';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(dashboardService, translateService, clientService) {
        var _this = this;
        this.dashboardService = dashboardService;
        this.translateService = translateService;
        this.clientService = clientService;
        this.dateReference = [];
        this.selectedDate = '';
        this.clientList = [];
        this.lspList = [];
        this.lspSelected = [];
        this.typeSelected = [];
        this.placeReference = [
            { name: 'Norte', ufs: ['RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO'] },
            { name: 'Nordeste', ufs: ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'] },
            { name: 'Sul', ufs: ['PR', 'SC', 'RS'] },
            { name: 'Sudeste', ufs: ['MG', 'ES', 'RJ'] },
            { name: 'Centro-oeste', ufs: ['MS', 'MT', 'GO', 'DF'] },
            { name: 'São Paulo', ufs: ['SP'] }
        ];
        this.clientSelected = [];
        this.ufSelected = [];
        this.ufList = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
        this.typeList = ['EDB', 'ED', 'CMS', 'DTR', 'Cross Doc', 'Cross Docking', 'Cross Docking'];
        this.colors = ['#8ed4c6', '#b4bae8', '#ebe095', '#9bdeb2', '#addded', '#eedab8'];
        // relativos a busca de data
        this.dateSearch = [];
        this.dates = [null, null];
        this.clientService.getClients().subscribe(function (data) {
            _this.clientList = data;
        });
        this.clientService.getLspList().subscribe(function (lsps) {
            _this.lspList = lsps;
            // console.log(this.lspList);
        });
        this.dateReference = [
            'Norte',
            'Sul',
            'Sudeste S/ SP',
            'Nordeste',
            'Centro-oeste',
            'São Paulo'
        ];
    }
    DashboardComponent.prototype.applyFilter = function () {
        this.loadCharts();
    };
    DashboardComponent.prototype.loadCharts = function () {
        this.createMdrChart();
        this.createOvsChart();
        this.createDrsByClient();
        this.createCostKgChart();
        this.createDrByStatusChart();
        this.createEmptyBarChartData();
        this.createMdrByShippingChart();
    };
    DashboardComponent.prototype.sortByDate = function (data) {
        return data.sort(function (a, b) {
            if (a.createdAt > b.createdAt) {
                return 1;
            }
            if (a.createdAt <= b.createdAt) {
                return -1;
            }
        });
    };
    DashboardComponent.prototype.setCostPerKiloData = function (data) {
        return data.map(function (day) {
            var currentDay = new Date(day.startDate);
            return { 'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(), 'cost': day.cost, 'weight': day.weight };
        });
    };
    DashboardComponent.prototype.changeSavedFilter = function (name) {
        switch (name) {
            case 'Norte':
                this.ufSelected = ['RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO'];
                break;
            case 'Nordeste':
                this.ufSelected = ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'];
                break;
            case 'Sul':
                this.ufSelected = ['PR', 'SC', 'RS'];
                break;
            case 'Sudeste S/ SP':
                this.ufSelected = ['MG', 'ES', 'RJ'];
                break;
            case 'Centro-oeste':
                this.ufSelected = ['MS', 'MT', 'GO', 'DF'];
                break;
            case 'São Paulo':
                this.ufSelected = ['SP'];
                break;
        }
        this.applyFilter();
    };
    DashboardComponent.prototype.sortDate = function (data) {
        return data.sort(function (b, a) {
            if (a.year > b.year) {
                return 1;
            }
            else if (a.year < b.year) {
                return -1;
            }
            else {
                if (a.month > b.month) {
                    return 1;
                }
                else if (a.month < b.month) {
                    return -1;
                }
                else {
                    if (a.day > b.day) {
                        return 1;
                    }
                    else if (a.day < b.day) {
                        return -1;
                    }
                    return 0;
                }
            }
        });
    };
    DashboardComponent.prototype.joinDates = function (dates, numberDays) {
        var data = [];
        this.sortDate(dates);
        for (var i = 1; (numberDays > 0) && (i < dates.length); i++) {
            if (dates[i - 1].date === dates[i].date) {
                dates[i - 1].countCreated += dates[i].countCreated;
                dates[i - 1].countFinished += dates[i].countFinished;
                data.push(dates[i - 1]);
                i++;
            }
            else {
                data.push(dates[i - 1]);
            }
            numberDays--;
        }
        return data;
    };
    DashboardComponent.prototype.formate = function (data) {
        var sum;
        var finished;
        for (var i = 0; i < 2; i++) {
            for (var j = 0; j < data[i].length; j++) {
                data[i][j].startDate = new Date(data[i][j].startDate);
                data[i][j] = {
                    'date': data[i][j].startDate.getDate() + '/' + (data[i][j].startDate.getMonth() + 1) + '/' + data[i][j].startDate.getFullYear(),
                    'day': data[i][j].startDate.getDate(),
                    'month': data[i][j].startDate.getMonth() + 1,
                    'year': data[i][j].startDate.getFullYear(),
                    'count': Object.values(data[i][j].shippingCompanies)
                };
                sum = 0;
                data[i][j].count.forEach(function (elem) { return sum += elem; });
                if (i === 0) {
                    data[i][j].countCreated = sum;
                    data[i][j].countFinished = 0;
                }
                else {
                    data[i][j].countCreated = 0;
                    data[i][j].countFinished = sum;
                }
            }
        }
        finished = this.joinDates(data[0].concat(data[1]), 30);
        data = {
            dates: [],
            valuesCreated: [],
            valuesFinished: [],
            sumCreated: 0,
            sumFinished: 0
        };
        for (var i = 0; i < finished.length; i++) {
            data.dates.push(finished[i].date);
            data.valuesCreated.push(finished[i].countCreated);
            data.valuesFinished.push(finished[i].countFinished);
            data.sumCreated += finished[i].countCreated;
            data.sumFinished += finished[i].countFinished;
        }
        data.dates.reverse();
        data.valuesCreated.reverse();
        data.valuesFinished.reverse();
        return data;
    };
    DashboardComponent.prototype.setOVData = function (data) {
        return data.map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countRightPathDRs + day.countWrongPathDRs
            };
        });
    };
    DashboardComponent.prototype.setFirstTimeRightData = function (data) {
        return data.map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countRightPathDRs
            };
        });
    };
    DashboardComponent.prototype.setCostPerKiloPercentage = function (data) {
        var percentage = 0;
        var numberBefore = data.map(function (arr) { return (arr.cost / arr.weight); });
        if (numberBefore.length >= 2) {
            numberBefore = numberBefore[numberBefore.length - 2];
            var number = data.map(function (arr) { return (arr.cost / arr.weight); });
            number = number[number.length - 1];
            percentage = (number - numberBefore) / numberBefore * 100;
            percentage = Math.round(percentage * 100) / 100;
        }
        return percentage;
    };
    DashboardComponent.prototype.setDRsPercentage = function (data) {
        var percentage = 0;
        var numberBefore = data.map(function (arr) { return (arr.total); });
        if (numberBefore.length >= 2) {
            numberBefore = numberBefore[numberBefore.length - 2];
            var number = data.map(function (arr) { return (arr.total); });
            number = number[number.length - 1];
            percentage = (number - numberBefore) / numberBefore * 100;
            percentage = Math.round(percentage * 100) / 100;
        }
        return percentage;
    };
    DashboardComponent.prototype.createEmptyBarChartData = function () {
        var chartData = {
            number: 0,
            percentage: 0,
            categories: [],
            series: [],
            subtitle: '',
        };
        return chartData;
    };
    DashboardComponent.prototype.createMdrChart = function () {
        var _this = this;
        this.dashboardService.getMdrByCreated(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            _this.dashboardService.getMdrByDelivered(_this.dates, _this.ufSelected, _this.typeSelected, _this.lspSelected, _this.clientSelected).subscribe(function (data2) {
                var finished = {
                    aggregate: [],
                    // Initial object in 'separate' is used to define column's order
                    separate: [{
                            id: '',
                            shippingCompany: '',
                            pickupID: '',
                            createdAt: '',
                            dr: '',
                            macroregion: '',
                            quando: '',
                            dr_client: '',
                            dr_uf: '',
                            mdr_type: ''
                        }],
                };
                // console.log(data);
                // console.log(data2);
                finished.aggregate.push(data.aggregate);
                finished.aggregate.push(data2.aggregate);
                finished.separate = finished.separate.concat(data.separate);
                finished.separate = finished.separate.concat(data2.separate);
                var finalData = _this.formate(finished.aggregate);
                finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
                finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
                finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
                finalData['finishedTooltip'] = 'Delivered';
                _this.setMacroregion(finished.separate, 'dr_uf');
                _this.mdrsChart.createChart(finalData, 'composite-bars', finished.separate);
            });
        });
    };
    DashboardComponent.prototype.createMdrByShippingChart = function () {
        var _this = this;
        this.dashboardService.getMdrByShippingCompany(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            data['aggregate'] = data['aggregate'].sort(function (a, b) { return a.count > b.count ? -1 : a.count < b.count ? 1 : 0; }).slice(0, 6);
            var chartData = [];
            data['aggregate'].forEach(function (shippingCompany) {
                chartData.push({ name: shippingCompany.shippingCompany, y: shippingCompany.count });
            });
            _this.setMacroregion(data['separate'], 'uf');
            _this.mdrByLspChart.createChart(chartData, 'mdr-by-lsp', data['separate']);
        });
    };
    DashboardComponent.prototype.setMacroregion = function (data, ufKey) {
        var _this = this;
        data.forEach(function (element) {
            var possibleRegions = _this.placeReference.filter(function (place) {
                // check if one or more of the uf's passed is include in the reference uf macroregion
                return element[ufKey] ? (element[ufKey].split(',')).some(function (uf) { return place.ufs.includes(uf); }) : false;
            });
            if (possibleRegions && possibleRegions.length) {
                var macroregion_1 = new Set();
                // Get unique region names
                possibleRegions.forEach(function (region) {
                    macroregion_1.add(region.name);
                });
                element.macroregion = Array.from(macroregion_1).join();
            }
            else {
                element.macroregion = '';
            }
        });
    };
    DashboardComponent.prototype.createDrsByClient = function () {
        var _this = this;
        this.dashboardService.getDrByClient(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            data['aggregate'] = data['aggregate'].sort(function (a, b) { return a.count > b.count ? -1 : a.count < b.count ? 1 : 0; }).slice(0, 6);
            var chartData = [];
            data['aggregate'].forEach(function (client) {
                if (client.client === null) {
                    client.client = 'CLIENTE';
                }
                chartData.push({ name: client.client, y: client.count });
            });
            _this.setMacroregion(data['separate'], 'uf');
            _this.pieComponentChart.createChart(chartData, 'dr-by-client', data['separate']);
        });
    };
    DashboardComponent.prototype.ngOnInit = function () {
        this.loadCharts();
    };
    // ngOnInit() {
    //   this.dashboardService.getMdrByCreated(this.dates).subscribe((data: any) => {
    //     this.dashboardService.getMdrByDelivered(this.dates).subscribe((data2: any) => {
    //        const finished = [];
    //        console.log(data);
    //        console.log(data2);
    //        finished.push(data);
    //        finished.push(data2);
    //       //  console.log({"final":this.formate(finished)} );
    //        let finalData = this.formate(finished);
    //        finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
    //        finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
    //        finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
    //        finalData['finishedTooltip'] = 'Delivered';
    //        this.mdrsChart.createChart(finalData, 'composite-bars');
    //         console.log({"final":finalData} );
    //     });;
    //   });
    //   this.createDrByStatusChart();
    //   this.createOvsChart();
    //   this.createCostKgChart();
    // }
    DashboardComponent.prototype.createOvsChart = function () {
        var _this = this;
        // related to first time right and delivered ovs charts
        this.dashboardService.getOVsDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // delivered ovs
            var ovData = _this.setOVData(data['aggregate']);
            var firstTimeData = _this.setFirstTimeRightData(data['aggregate']);
            var percentage = [];
            for (var i = 0; i < ovData.length; i++) {
                percentage.push({ 'day': ovData[i].day, 'total': firstTimeData[i].total * 100 / ovData[i].total });
            }
            if (ovData.length !== 0) {
                var chartData = {
                    number: ovData[ovData.length - 1].total,
                    percentage: Math.round(firstTimeData[firstTimeData.length - 1].total * 100 / ovData[ovData.length - 1].total),
                    categories: ovData.map(function (arr) { return arr.day; }),
                    series: [{
                            name: _this.translateService.instant('TOTAL_DRS'),
                            type: undefined,
                            visible: false,
                            data: ovData.map(function (arr) { return (arr.total); })
                        }, {
                            name: _this.translateService.instant('FIRST_TIME_RIGHT'),
                            type: undefined,
                            visible: false,
                            data: firstTimeData.map(function (arr) { return (arr.total); })
                        }, {
                            name: 'FTR Percentage',
                            type: undefined,
                            data: percentage.map(function (arr) { return (arr.total); })
                        }],
                    subtitle: _this.translateService.instant('TOTAL_DRS'),
                };
                _this.setMacroregion(data['separate'], 'uf');
                _this.deliveredOvsChart.createChart(chartData, 'delivered-ovs', data['separate']);
            }
            else {
                var emptyData = _this.createEmptyBarChartData();
                _this.deliveredOvsChart.createChart(emptyData, 'delivered-ovs', data['separate']);
            }
            // first time right
            // if (firstTimeData.length !== 0) {
            //   const chartData = {
            //     number: firstTimeData[firstTimeData.length - 1].total,
            //     percentage: this.setDRsPercentage(firstTimeData),
            //     categories: firstTimeData.map(arr => arr.day),
            //     series: [{
            //       name: this.translateService.instant('FIRST_TIME_RIGHT'),
            //       type: undefined,
            //       data: firstTimeData.map((arr) => (arr.total))
            //     },
            //   ],
            //     subtitle: this.translateService.instant('FIRST_TIME_RIGHT'),
            //   };
            //   this.firstTimeRightChart.createChart(chartData, 'first-time-right', data['separate']);
            // } else {
            //   const emptyData = this.createEmptyBarChartData();
            //   this.firstTimeRightChart.createChart(emptyData, 'first-time-right', data['separate']);
            // }
        });
    };
    DashboardComponent.prototype.createDrByStatusChart = function () {
        var _this = this;
        this.dashboardService.getC02ByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // console.log('co2', data);
            var resultObj = {};
            resultObj['dates'] = [];
            resultObj['valuesCreated'] = [];
            resultObj['valuesFinished'] = [];
            resultObj['sumCreated'] = 0;
            resultObj['sumFinished'] = 0;
            for (var _i = 0, _a = Object.entries(data['resultPerVehicle']); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                resultObj['dates'].push(key);
                resultObj['valuesCreated'].push(value['kgco2'] / 1000);
                resultObj['valuesFinished'].push(value['kg'] / 1000);
                resultObj['sumCreated'] += value['kgco2'] / 1000;
                resultObj['sumFinished'] += value['kg'] / 1000;
                resultObj['createdTitle'] = 'CO2/created/title';
                resultObj['finishedTitle'] = 'CO2/finished/title';
                resultObj['createdTooltip'] = 'CO2/created/tooltip';
                resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
                resultObj['eco'] = 'eco';
            }
            _this.setMacroregion(data.result, 'UF de Destino');
            _this.c02Chart.createChart(resultObj, 'c02Chart', data['result']);
        });
        this.dashboardService.getC02CAByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            var resultObj = {};
            resultObj['dates'] = [];
            resultObj['valuesCreated'] = [];
            resultObj['valuesFinished'] = [];
            resultObj['sumCreated'] = 0;
            resultObj['sumFinished'] = 0;
            for (var _i = 0, _a = Object.entries(data['resultPerVehicle']); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                resultObj['dates'].push(key);
                resultObj['valuesCreated'].push(value['kgco2Total'] / 1000);
                resultObj['valuesFinished'].push(value['kgco2ShouldBe'] / 1000);
                resultObj['sumCreated'] += value['kgco2Total'] / 1000;
                resultObj['sumFinished'] += value['kgco2Avoidance'] / 1000;
                resultObj['createdTitle'] = 'CO2/created/title';
                resultObj['finishedTitle'] = 'CO2/finished/title';
                resultObj['createdTooltip'] = 'CO2/created/tooltip';
                resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
                resultObj['eco'] = 'eco';
            }
            // console.log({'OBJETO': resultObj});
            _this.setMacroregion(data.result, 'UF de Destino');
            _this.c02CAChart.createChart(resultObj, 'c02CAChart', data['result']);
        });
        this.dashboardService.getDrByStatus(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // console.log(data);
            var chartData = [];
            var options = [
                { radius: '112%', innerRadius: '100%', color: '#B6BCE9' },
                { radius: '92%', innerRadius: '80%', color: '#AFDEEE' },
                { radius: '72%', innerRadius: '60%', color: '#ECE196' }
            ];
            var validStatus = ['Delivered', 'InTransit', 'READY_TO_PICKUP'];
            var statusValues = data['aggregate'][0].status;
            // create status object
            var status = {};
            validStatus.forEach(function (item) {
                status[item] = statusValues[item] || 0;
            });
            var total = Object.keys(status).reduce(function (sum, key) { return sum + status[key]; }, 0);
            Object.keys(status).forEach(function (item, index) {
                chartData.push({
                    name: '<span style="font-family: Roboto; font-weight: 100">' + _this.translateService.instant(item) +
                        '</span>' +
                        '<br><span style=\'font-size:20px; font-weight: 900; font-family:Roboto;\'>' + status[item] + '</span>',
                    type: 'solidgauge',
                    colorByPoint: false,
                    color: options[index].color,
                    borderColor: options[index].color,
                    showInLegend: true,
                    data: [{
                            y: total > 0 ? Math.ceil((status[item] * 100) / total) : 0,
                            color: options[index].color,
                            radius: options[index].radius,
                            total: total,
                            innerRadius: options[index].innerRadius,
                        }]
                });
            });
            _this.setMacroregion(data['separate'], 'uf');
            _this.drsStatusChart.createChart(chartData, 'drs-status', data['separate']);
        });
    };
    DashboardComponent.prototype.createCostKgChart = function () {
        var _this = this;
        // related to cost per kilo chart
        this.dashboardService.getCostKg('day', this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            var dataDay = _this.setCostPerKiloData(data['aggregate']);
            if (dataDay.length !== 0) {
                var dataNumber = dataDay.map(function (arr) { return (arr.cost / arr.weight); });
                var chartData = {
                    number: "R$ " + Math.round(dataNumber[dataNumber.length - 1] * 100) / 100,
                    percentage: _this.setCostPerKiloPercentage(dataDay),
                    categories: dataDay.map(function (arr) { return arr.day; }),
                    series: [{
                            name: _this.translateService.instant('COST_KG'),
                            type: undefined,
                            data: dataDay.map(function (costPerKilo) { return (costPerKilo.cost / costPerKilo.weight); })
                        }, {
                            name: _this.translateService.instant('COST'),
                            visible: false,
                            type: 'line',
                            data: dataDay.map(function (cost) { return cost.cost; })
                        }, {
                            name: _this.translateService.instant('WEIGHT'),
                            visible: false,
                            type: 'line',
                            data: dataDay.map(function (weight) { return weight.weight; })
                        }],
                    subtitle: _this.translateService.instant('COST_PER')
                };
                _this.setMacroregion(data['separate'], 'uf');
                _this.costPerKiloChart.createChart(chartData, 'cost-per-kilo', data['separate']);
            }
            else {
                var emptyData = _this.createEmptyBarChartData();
                _this.costPerKiloChart.createChart(emptyData, 'cost-per-kilo', data['separate']);
            }
        });
        // Occurrence Word Map
        this.dashboardService.getOccurrenceWords(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (elem) {
            _this.wordcloudChart.createChart(elem, 'wordcloud');
            // console.log({word:elem});
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
