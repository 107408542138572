var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from '@sentry/browser';
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { MatSort, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
import { MDR, MDR_TYPE, MDR_STATUS } from '../../models/mdr';
import { MdrObservable } from '../../services/mdr.service';
import { PriceObservable } from '../../services/price.service';
import { DrObservable } from '../../services/dr.service';
import { UOCObservable } from '../../services/uoc.service';
import { DrService } from '../../providers/dr.service';
import { DatePipe } from '@angular/common';
import { UOCMDR } from '../../models/uoc__mdr';
import { Driver } from '../../models/driver';
import 'rxjs/add/operator/takeWhile';
import { TranslateService } from '@ngx-translate/core';
import { TransportComponent } from './transport/transport.component';
import { Message } from '../../models/message';
import { SocketService } from '../../providers/socket.service';
import { ChatService } from '../../providers/chat.service';
import { Event } from '../../models/events';
import { ExportAsService } from 'ngx-export-as';
import { Title } from '@angular/platform-browser';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
var LogisticOrderComponent = /** @class */ (function () {
    function LogisticOrderComponent(dialog, titleService, mdrService, router, aRoute, snackBar, mdrObservable, priceObservable, drObservable, drService, uocObservable, socketService, exportAsService, chat) {
        this.dialog = dialog;
        this.titleService = titleService;
        this.mdrService = mdrService;
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mdrObservable = mdrObservable;
        this.priceObservable = priceObservable;
        this.drObservable = drObservable;
        this.drService = drService;
        this.uocObservable = uocObservable;
        this.socketService = socketService;
        this.exportAsService = exportAsService;
        this.chat = chat;
        this.chatType = 5; // transportadora <-> motorista
        this.exportAsConfig = {
            type: 'pdf',
            elementId: 'printable',
            options: {
                pagespilt: true,
                html2canvas: {
                    useCORS: true,
                },
                pagebreak: {
                    mode: 'avoid-all',
                }
            }
        };
        this.drivers = [];
        this.print = false;
        this.mdr = new MDR();
        this.editable = true;
        this.driverEditable = false;
        this.role = 0;
        this.editMDRStatus = false;
        this.editMDRType = false;
        // use *ngIf="display" in your html to take
        // advantage of this
        this.reservation = '';
        this.reprove = '';
        this.shipmentReturned = '';
        this.positionOptions = 'after';
        this.tooltip = 'Esta MDR esta sendo editada por outra pessoa';
        this.price = { price: 0, total: 0, adValorem: 0, addDelivery: 0, taxedPrice: 0, sc: '', vehi: '' };
        this.hiddenMap = true;
        this.lock = false;
        this.valueNfStatus = false;
        this.warning1641Antenna = false;
        this.warning5gAntenna = false;
        this.antenna_air_1641_codes = ['KRD901801/1', 'NTB1011005/7'];
        this.antenna_5g_codes = [
            'KRD901214/11',
            'KRD901208/11',
            'KRD901220/11',
            'KRD901153/11',
            'KRD901801/1',
            'KRD901188/11'
        ];
        this.fieldsToCheck = {
            required: {
                'shippingCompany': 'Transportadora',
                'vehicle': 'Veiculo',
                'pickupDate': 'Data de Coleta',
                'pickupID': 'Código de Coleta',
            },
            nonRequired: {
                'weight': 'Peso',
            }
        };
        this.mdrStatus = [
            { value: 'Nova MDR', name: 'Nova MDR' },
            { value: 'MDR em validação', name: 'MDR em validação' },
            { value: 'MDR em criação', name: 'MDR em criação' },
            { value: 'MDR devolvida por LSP', name: 'MDR devolvida por LSP' },
            { value: 'MDR aguardando LSP', name: 'MDR aguardando LSP' },
            { value: 'MDR aprovada LSP', name: 'MDR aprovada LSP' },
            { value: 'Motorista e veiculo definidos', name: 'Motorista e veiculo definidos' },
            { value: 'Veículo carregado', name: 'Veículo carregado' },
            { value: 'Carga entregue com sucesso', name: 'Carga entregue com sucesso' },
            { value: 'Carga retornada total', name: 'Carga retornada total' },
            { value: 'MDR bloqueada por Avaria', name: 'MDR bloqueada por Avaria' },
            { value: 'Entrega de carga certificada pelo LSP', name: 'Entrega de carga certificada pelo LSP' },
            { value: 'MDR paga / concluída', name: 'MDR paga / concluída' },
        ];
        this.mdrType = [
            { value: MDR_TYPE.EDB, name: 'EDB' },
            { value: MDR_TYPE.ED, name: 'ED' },
            { value: MDR_TYPE.CMS, name: 'CMS' },
            { value: MDR_TYPE.DRT, name: 'DRT' },
            { value: MDR_TYPE.CROSS_DOCKING, name: 'Cross Docking' },
        ];
        this.hasPbBattery = false;
        this.canSaveMDR = true;
        this.subscriptions = [];
        this.driver = new Driver();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        if (this.role === 5 || this.role === 12 || this.role === 14) {
            this.mdrStatus = [
                { value: 'MDR devolvida por LSP', name: 'MDR devolvida por LSP' },
                { value: 'MDR aguardando LSP', name: 'MDR aguardando LSP' },
                { value: 'MDR aprovada LSP', name: 'MDR aprovada LSP' },
                { value: 'Motorista e veiculo definidos', name: 'Motorista e veiculo definidos' },
                { value: 'Veículo carregado', name: 'Veículo carregado' },
                { value: 'Carga entregue com sucesso', name: 'Carga entregue com sucesso' },
                { value: 'Carga retornada total', name: 'Carga retornada total' },
            ];
        }
        this.display = false;
        this.alive = true;
    }
    LogisticOrderComponent.prototype.ngOnDestroy = function () {
        this.titleService.setTitle('Bluebee');
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    LogisticOrderComponent.prototype.ngOnInit = function () {
        this.getMDR();
    };
    LogisticOrderComponent.prototype.getMDR = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.channel = params.id;
            // busca MDR
            _this.mdrService.getMDR(params.id).pipe(take(1)).subscribe(function (data) {
                _this.mdr = data;
                // busca NFe
                _this.mdrService.getNf(params.id).pipe(take(1)).subscribe(function (nfs) {
                    var totalValue = nfs.filter(function (nf) { return parseFloat(nf.totalValue) >= 500000; });
                    if (totalValue.length > 0) {
                        _this.valueNfStatus = true;
                    }
                });
                // setta o titulo da página
                if (_this.mdr.pickupID !== null) {
                    _this.titleService.setTitle(_this.mdr.pickupID);
                }
                if (_this.mdr.pickupID) {
                    var mdrSplit = _this.mdr.pickupID.split('-');
                    if (mdrSplit.length === 3) {
                        var splitted = mdrSplit[2];
                        if (splitted.endsWith('B')) {
                            _this.hasPbBattery = true;
                        }
                    }
                    else if (mdrSplit.length === 4) {
                        var splitCrossDocking = mdrSplit[3];
                        if (splitCrossDocking.endsWith('B')) {
                            _this.hasPbBattery = true;
                        }
                    }
                }
                // de acordo com mdr.status
                switch (_this.mdr.status) {
                    case 'MDR aprovada com ressalvas LSP':
                        _this.mdrService.getMDRReservation(params.id).pipe(take(1)).subscribe(function (elem) {
                            _this.reservation = elem;
                        });
                        break;
                    case 'MDR em criação':
                    case 'MDR devolvida por LSP':
                        _this.mdrService.getMDRReprove(params.id).pipe(take(1)).subscribe(function (elem) {
                            _this.reprove = elem;
                        });
                        break;
                    case 'Carga retornada total':
                        _this.mdrService.getMDRReprove(params.id).pipe(take(1)).subscribe(function (elem) {
                            _this.shipmentReturned = elem;
                        });
                        break;
                    case 'MDR retornada pelo WAM':
                        _this.mdrService.getMDRReservation(params.id).pipe(take(1)).subscribe(function (elem) {
                            _this.reservation = elem;
                        });
                        break;
                }
                // pega DRs associadas
                _this.subscriptions.push(_this.drService.getByMdr(params.id).subscribe(function (newDr) {
                    _this.drObservable.changeDR(newDr);
                    for (var i = 0; i < newDr.length; i++) {
                        if (newDr[i].ovList) {
                            for (var j = 0; j < newDr[i].ovList.length; j++) {
                                if (newDr[i].ovList[j]) {
                                    if (_this.antenna_air_1641_codes.includes(newDr[i].ovList[j].materialCode)) {
                                        _this.warning1641Antenna = true;
                                    }
                                    if (_this.antenna_5g_codes.includes(newDr[i].ovList[j].materialCode)) {
                                        _this.warning5gAntenna = true;
                                    }
                                }
                            }
                        }
                    }
                }));
                // pega UOCs da MDR
                _this.subscriptions.push(_this.mdrService.getUOC(_this.mdr).subscribe(function (uoc) {
                    _this.uocObservable.changeUOC(uoc);
                }));
                // atualiza Observable da MDR
                _this.mdrObservable.changeMDR(_this.mdr);
                // inicializa socket
                _this.initIoConnection(_this.channel);
                // de acordo com mdr.status
                switch (_this.mdr.status) {
                    case 'MDR em validação':
                        _this.mdrService.createLegs(_this.mdr.id, false).subscribe(function (legs) {
                            //
                        });
                        break;
                    case 'Nova MDR':
                        _this.subscriptions.push(_this.mdrService.getAllPriceR(_this.mdr).subscribe(function (elem) {
                            var prices = elem.sort(function (a, b) { return a.total - b.total; });
                            var price = _this.mdr.shippingCompany ? prices.find(function (pc) { return pc.sc === _this.mdr.shippingCompany; }) : prices[0];
                            // se nao receber km ou valor da NF, envia pro Sentry
                            if (!price || !price.km || !price.val) {
                                Sentry.captureException({
                                    where: 'logistic-order.component',
                                    sent: _this.mdr,
                                    received: elem,
                                    user: _this.currentUser,
                                });
                            }
                            _this.price = price.price;
                            _this.mdr.totalNF = price.val;
                            _this.mdr.sapCode = price.sapCd;
                            _this.mdr.totalKM = price.km;
                            _this.mdr.shippingCompany = price.sc;
                            _this.mdr.vehicle = price.vehi;
                            _this.priceObservable.changePrice(price);
                            _this.mdrObservable.changeMDR(_this.mdr);
                        }, function (error) {
                            // erro ao pegar valores, envia pro Sentry
                            Sentry.captureException(error);
                            _this.snackBar.open('Erro ao pegar valores, tente atualizar a página e tentar novamente', 'Ok', { duration: 4000 });
                        }));
                        break;
                    case 'MDR em criação':
                        _this.subscriptions.push(_this.mdrService.getAllPriceR(_this.mdr).subscribe(function (elem) {
                            var prices = elem.sort(function (a, b) { return a.total - b.total; });
                            var price = _this.mdr.shippingCompany ? prices.find(function (pc) { return pc.sc === _this.mdr.shippingCompany; }) : prices[0];
                            // se nao receber km ou valor da NF, envia pro Sentry
                            if (!price || !price.km || !price.val) {
                                Sentry.captureException({
                                    where: 'logistic-order.component',
                                    sent: _this.mdr,
                                    received: elem,
                                    user: _this.currentUser,
                                });
                            }
                            _this.price = price.price;
                            _this.mdr.totalNF = price.val;
                            _this.mdr.sapCode = price.sapCd;
                            _this.mdr.totalKM = price.km;
                            _this.mdr.shippingCompany = price.sc;
                            _this.mdr.vehicle = price.vehi;
                            _this.priceObservable.changePrice(price);
                            _this.mdrObservable.changeMDR(_this.mdr);
                        }, function (error) {
                            // erro ao pegar valores, envia pro Sentry
                            Sentry.captureException(error);
                            _this.snackBar.open('Erro ao pegar valores, tente atualizar a página e tentar novamente', 'Ok', { duration: 4000 });
                        }));
                        break;
                    default: // todos os outros status
                        _this.subscriptions.push(_this.mdrService.getAlreadyExistentPrice(_this.mdr).subscribe(function (elem) {
                            _this.price = elem;
                            _this.price.addDelivery = elem.addEntrega;
                            _this.price.adValorem = elem.adValorem;
                            _this.price.price = elem.frete;
                            _this.price.total = elem.total;
                            _this.price.taxedPrice = elem.freteTax;
                            _this.priceObservable.changePrice(elem);
                        }));
                        break;
                }
            }, function (error) {
                _this.snackBar.open('Erro ao carregar MDR.', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['/']);
            });
        });
    };
    LogisticOrderComponent.prototype.updateNFCost = function (updateMDR) {
        if (updateMDR === void 0) { updateMDR = false; }
        this.tax.nfePrice = this.attachments.totalNfCost;
        if (updateMDR) {
            this.mdr.totalNF = this.attachments.totalNfCost;
            this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function () { }));
        }
    };
    LogisticOrderComponent.prototype.mapButtonClick = function () {
        this.hiddenMap = !this.hiddenMap;
    };
    LogisticOrderComponent.prototype.showMap = function () {
        switch (this.mdr.status) {
            case 'Nova MDR':
            case 'MDR em validação':
            case 'MDR em criação':
            case 'MDR devolvida por LSP':
            case 'MDR aguardando LSP':
            case 'MDR aprovada LSP':
            case 'Motorista e veiculo definidos':
            case 'Veículo carregado':
                return true;
            default:
                return false;
        }
    };
    LogisticOrderComponent.prototype.onClickPrint = function () {
        var mdrID = this.mdr.id;
        this.subscriptions.push(this.mdrService.getPDF(this.mdr.id).subscribe(function (data) {
            FileSaver.saveAs(data, "Report-Logistic_Order-" + mdrID + ".pdf");
        }));
    };
    LogisticOrderComponent.prototype.editStatus = function () {
        this.editMDRStatus = !this.editMDRStatus;
    };
    LogisticOrderComponent.prototype.checkFieldsToSendLSP = function () {
        var emptyFields = { requiredFields: [], nonRequiredFields: [] };
        for (var _i = 0, _a = Object.keys(this.fieldsToCheck.required); _i < _a.length; _i++) {
            var field = _a[_i];
            if (this.mdr[field] === null || this.mdr[field] === '') {
                emptyFields.requiredFields.push(this.fieldsToCheck.required[field]);
            }
        }
        if (this.transport.tooltip.wei === '0.00') {
            emptyFields.nonRequiredFields.push(this.fieldsToCheck.nonRequired['weight']);
        }
        if (emptyFields.requiredFields.length) {
            this.canSaveMDR = false;
            this.snackBar.open("N\u00E3o foi poss\u00EDvel enviar para LSP, pois os campos " + emptyFields.requiredFields.join(', ') + " precisam de preenchimento", 'Ok', {
                duration: 10000,
            });
        }
        else if (emptyFields.nonRequiredFields.length) {
            this.canSaveMDR = true;
            this.snackBar.open("MDR enviada para LSP. Informa\u00E7\u00F5es sem preenchimento: " + emptyFields.nonRequiredFields.join(', '), 'Ok', {
                duration: 10000,
            });
        }
        else {
            this.canSaveMDR = true;
        }
    };
    LogisticOrderComponent.prototype.saveStatus = function () {
        var _this = this;
        this.editMDRStatus = !this.editMDRStatus;
        if (!this.editMDRStatus && this.mdr.status === MDR_STATUS.MDR_AGUARDANDO_LSP) {
            this.checkFieldsToSendLSP();
        }
        if (this.canSaveMDR === true) {
            this.subscriptions.push(this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe(function (data) {
                _this.snackBar.open('Status atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
                    duration: 4000,
                });
            }));
        }
    };
    LogisticOrderComponent.prototype.editMDRTypeAllowed = function (status) {
        if (status === MDR_STATUS.MDR_EM_CRIACAO) {
            return true;
        }
        return false;
    };
    LogisticOrderComponent.prototype.editType = function () {
        this.editMDRType = !this.editMDRType;
    };
    LogisticOrderComponent.prototype.saveType = function (new_type) {
        var _this = this;
        this.editMDRType = !this.editMDRType;
        this.subscriptions.push(this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe(function (data) {
            _this.snackBar.open('Tipo de MDR atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
                duration: 4000,
            });
        }, function (error) {
            _this.snackBar.open('Não foi possivel atualizar a MDR.', 'Ok', {
                duration: 4000,
            });
        }));
    };
    LogisticOrderComponent.prototype.createDrivers = function () {
        var _this = this;
        this.transport.drivers.forEach(function (el, i, arr) {
            _this.createDriver(el);
        });
    };
    LogisticOrderComponent.prototype.createDriver = function (driver) {
        driver.mdrId = this.mdr.id;
        this.subscriptions.push(this.mdrService.createDriver(driver).subscribe(function (response) {
            // console.log(response);
        }));
    };
    LogisticOrderComponent.prototype.initIoConnection = function (id) {
        this.user = this.currentUser.user.id;
        this.socketService.initSocketMDR(id, this.user);
        // this.ioConnection = this.socketService.onComeMDRLists()
        //   .subscribe((message) => {
        //    //console.log(message);
        //     this.idsocket= message['user'][this.user];
        //     if(message['socket'][0]!==this.idsocket){
        //       this.lock =false;
        //     }else{
        //       this.lock =true;
        //     }
        //     this.aRoute.params.subscribe( params => {
        //       this.mdrService.getMDR(params.id).subscribe((data) => {
        //         this.mdr = data;
        //         this.mdr.lock = this.lock;
        //         this.mdrObservable.changeMDR(this.mdr);
        //       })
        //     });
        //
        //   });
        this.subscriptions.push(this.socketService.onEvent(Event.CONNECT)
            .subscribe(function () {
            // console.log('connected');
        }));
        this.subscriptions.push(this.socketService.onEvent(Event.NEW_MESSAGE)
            .subscribe(function (message) {
            // console.log(message);
            // this.messages.push(message);
        }));
        this.subscriptions.push(this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(function () {
            // console.log('disconnected');
        }));
    };
    LogisticOrderComponent.prototype.cancelMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR aguardando LSP';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.deleteMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR Reprovado';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mdrService.liberateDR(this.mdr).subscribe(function (data) {
                            _this.snackBar.open(_this.mdr.status, 'Ok', {
                                duration: 4000,
                            });
                        })];
                    case 1:
                        _a.sent();
                        this.router.navigate(['delivery-order']);
                        return [2 /*return*/];
                }
            });
        }); }));
    };
    LogisticOrderComponent.prototype.createToken = function () {
        var _this = this;
        this.subscriptions.push(this.mdrService.createToken(this.mdr.id).subscribe(function (response) {
            var link = 'view/' + response.token + '/mdr';
            var dialogRef = _this.dialog.open(CreateTokenDialogComponent, {
                data: {
                    link: link
                }
            });
        }));
    };
    LogisticOrderComponent.prototype.reproveMDR = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReproveDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.shippingCompany = '';
                _this.mdrService.updateReprove(_this.mdr, response[1]).subscribe(function (data) {
                    _this.snackBar.open(_this.mdr.status, 'Ok', {
                        duration: 4000,
                    });
                    _this.router.navigate(['delivery-order']);
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        }));
    };
    LogisticOrderComponent.prototype.openConfirmationDialog = function (template) {
        var _this = this;
        var dialogRef = this.dialog.open(this.confirmationDialog, {
            width: '80vw',
            maxHeight: '80vh',
            data: {}
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.saveType(_this.mdr.type = MDR_TYPE.CROSS_DOCKING);
            }
        }));
    };
    LogisticOrderComponent.prototype.openHistory = function () {
        var _this = this;
        this.subscriptions.push(this.mdrService.getHistory(this.mdr.id).subscribe(function (history) {
            var dialogRef = _this.dialog.open(MdrHistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: history }
            });
        }));
    };
    LogisticOrderComponent.prototype.giveBackMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR devolvida por LSP';
        this.mdr.shippingCompany = '';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (response) {
            _this.mdrService.giveBack(_this.mdr).pipe(take(1)).subscribe(function (data) {
            });
        }));
    };
    LogisticOrderComponent.prototype.cancelTake = function () {
        this.mdr.status = 'MDR em criação';
        this.mdr.shippingCompany = '';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
        }));
    };
    LogisticOrderComponent.prototype.allShipmentReturned = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReturnDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.status = 'Carga retornada total';
                _this.notification(response);
                _this.mdrService.updateReturn(_this.mdr, response[1]).subscribe(function (data) {
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        }));
    };
    LogisticOrderComponent.prototype.updateMDR = function () {
        this.transport.saveDriver();
        this.transport.saveConveyor();
        // this.tax.saveConveyor();
        this.tax.saveUOC();
        this.tax.saveCOPQ();
        this.tax.saveCA();
        this.attachments.updateInfo();
    };
    LogisticOrderComponent.prototype.approveWithConditionsMDR = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReservationDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.status = 'MDR aprovada com ressalvas LSP';
                _this.notification(response);
                _this.mdrService.updateRessalva(_this.mdr, response[1]).subscribe(function (data) {
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        }));
    };
    LogisticOrderComponent.prototype.returnTodeliveryAgain = function () {
        var _this = this;
        this.mdr.status = 'Carga retornada para re-entrega';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.updateTotalKM = function (newTotalDistance) {
        this.tax.updateTotalKM(newTotalDistance);
    };
    LogisticOrderComponent.prototype.sendLSP = function () {
        // verifica se as informações de transportadora estão preenchidas
        // if ( ['shippingCompany', 'vehicle', 'pickupDate', 'pickupCode'].some( e => {
        //   if (this.transportInfo.mdr[e] === null ||
        //      this.transportInfo.mdr[e] === '' ) {
        //     return true;
        //   }
        //   return false;
        // })){
        //   this.snackBar.open('Complete as informações da transportadora antes de prosseguir', 'Ok', { duration: 4000 });
        //   return;
        // }
        var _this = this;
        // verifica se a data de coleta é válida
        // const pickupDate = new Date(this.transportInfo.mdr.pickupDate);
        // this.drService.getByMdr(this.transportInfo.mdr.id).subscribe((drList) => {
        //   if( drList.some( e => {
        //     let dateScheduling = new Date(e.dateScheduling);
        //     if(pickupDate.getTime() > dateScheduling.getTime()) {
        //       // se a data de coleta for maior que a data da entrega
        //       return true;
        //     }
        //     return false;
        //   })){
        //     this.snackBar.open("Data de coleta deve ser menor que a data de entrega",'Ok', { duration: 4000 });
        //   } else {
        // data é válida, prossegue
        this.checkFieldsToSendLSP();
        if (this.canSaveMDR === true) {
            this.subscriptions.push(this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe(function (users) {
                if (users !== null) {
                    if (users.length > 0) {
                        var dialogRef = _this.dialog.open(LSPEmailDialogComponent, {
                            data: {
                                users: users,
                                existeNfVermelha: _this.attachments.existeNfVermelha,
                            }
                        });
                        _this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
                            if (result[0]) {
                                // this.tax.savePrice();
                                if (_this.mdr.type === 'CMS' && _this.mdr.SAD === 'true') {
                                    var uoc = new UOCMDR();
                                    uoc.idMDR = _this.mdr.id;
                                    uoc.value = _this.tax.getTotal() * 0.5 * 100;
                                    uoc.uoc = 'Retorno Ericsson';
                                    _this.mdrService.createUOC(uoc).pipe(take(1)).subscribe(function (resp) {
                                        // console.log(resp);
                                    });
                                }
                                _this.mdr.status = 'MDR aguardando LSP';
                                _this.mdrService.update(_this.mdr, _this.user).pipe(take(1)).subscribe(function (data) {
                                    // this.router.navigate(['cockpit']);
                                });
                                var selectedUsers = result[1].value;
                                if (selectedUsers !== null) {
                                    _this.mdrService.sendLSPEmail(_this.mdr, selectedUsers).pipe(take(1)).subscribe();
                                }
                            }
                        }));
                    }
                    else {
                        if (_this.mdr.type === 'CMS' && _this.mdr.SAD === 'true') {
                            var uoc = new UOCMDR();
                            uoc.idMDR = _this.mdr.id;
                            uoc.value = _this.tax.getTotal() * 0.5 * 100;
                            uoc.uoc = 'Retorno Ericsson';
                            // console.log(uoc);
                            _this.mdrService.createUOC(uoc).pipe(take(1)).subscribe(function (resp) {
                                // console.log(resp);
                            });
                        }
                        // this.tax.savePrice();
                        _this.mdr.status = 'MDR aguardando LSP';
                        _this.mdrService.update(_this.mdr, _this.user).pipe(take(1)).subscribe(function (data) {
                            _this.router.navigate(['cockpit']);
                        });
                    }
                }
            }));
        }
        // this.mdrService.savePrice(this.price, this.mdr).subscribe((el) => {
        // console.log('chegou aqui!');
        // });
    };
    LogisticOrderComponent.prototype.saveAddressInMapp = function () {
        var _this = this;
        this.mdr.status = 'MDR em criação';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
            _this.router.navigate(['list-mdrs']);
        }));
    };
    LogisticOrderComponent.prototype.reproveRessalva = function () {
        var _this = this;
        this.mdr.status = 'MDR em criação';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.aproveRessalva = function () {
        var _this = this;
        this.mdr.status = 'MDR aprovada LSP';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.acceptMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR aprovada LSP';
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.defineDriver = function () {
        if (this.transport.drivers.length === 0) {
            this.snackBar.open('Favor definir um motorista', 'Ok', { duration: 4000 });
            return;
        }
        this.mdr.status = 'Motorista e veiculo definidos';
        this.createDrivers();
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            // this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.vehicleLoaded = function () {
        var _this = this;
        this.mdr.status = 'Veículo carregado';
        // //console.log(this.mdr);
        this.subscriptions.push(this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            for (var i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP') {
                    drList[i].status = 'InTransit';
                    _this.drService.update(drList[i]).subscribe(function (k) {
                        // console.log("UPDATED");
                    });
                }
            }
        }));
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.delivered = function () {
        var _this = this;
        this.mdr.status = 'Carga entregue com sucesso';
        this.subscriptions.push(this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            for (var i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP' || drList[i].status === 'InTransit') {
                    drList[i].status = 'Delivered';
                    _this.drService.update(drList[i]).subscribe(function (k) {
                        // console.log("UPDATED");
                    });
                }
            }
        }));
        this.subscriptions.push(this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        }));
    };
    LogisticOrderComponent.prototype.deliveredCertified = function () {
        var _this = this;
        if (this.attachments.allPODsUploaded()) {
            var dialogRef = this.dialog.open(DeliveredCertifiedDialogComponent, {
                width: '600px',
                data: {
                    text: '',
                }
            });
            this.subscriptions.push(dialogRef.afterClosed().subscribe(function (response) {
                if (response[0] === true) {
                    var oldStatus_1 = _this.mdr.status;
                    _this.mdr.status = 'Entrega de carga certificada pelo LSP';
                    _this.subscriptions.push(_this.mdrService.update(_this.mdr, _this.user).subscribe(function (data) {
                        _this.router.navigate(['delivery-order']);
                    }, function (err) {
                        _this.mdr.status = oldStatus_1;
                        _this.snackBar.open('Erro ao certificar carga, recarregue a página e tente novamente', 'Ok', {
                            duration: 4000,
                        });
                    }));
                }
            }));
        }
        else {
            this.snackBar.open('Faltam inserir PODs ou Foto de UOC', 'Ok', {
                duration: 4000,
            });
        }
    };
    LogisticOrderComponent.prototype.pay = function () {
        var _this = this;
        this.subscriptions.push(zip(this.mdrService.getListUOC(), // allUOCs
        this.mdrService.getUOC(this.mdr)).subscribe(function (response) {
            var allUOCs = response[0].map(function (uoc) { return uoc.name; });
            var myUOCs = response[1];
            var wrongUOCs = [];
            // check that all my UOCs exist on the allUOCs list
            myUOCs.forEach(function (myUOC) {
                // my UOC was not found
                if (allUOCs.indexOf(myUOC.uoc) === -1) {
                    wrongUOCs.push(myUOC.uoc);
                }
            });
            // if any UOC was not found, show snackbar and exit
            if (wrongUOCs.length > 0) {
                _this.snackBar.open(wrongUOCs.length + " UOC" + (wrongUOCs.length > 1 ? 's' : '') + " n\u00E3o " + (wrongUOCs.length > 1 ? 'estão' : 'está') + " cadastrado" + (wrongUOCs.length > 1 ? 's' : '') + " no sistema: " + wrongUOCs.join(', '), 'Ok', {
                    duration: 4000,
                });
                return;
            }
            // since all UOCs exist, can continue
            _this.mdr.status = 'MDR paga / concluída';
            _this.drService.getByMdr(_this.mdr.id).pipe(take(1)).subscribe(function (drList) {
                var k = true;
                drList.forEach(function (dr) {
                    if (!dr.prolog) {
                        k = false;
                    }
                });
                // if (k) {
                _this.mdrService.update(_this.mdr, _this.user).pipe(take(1)).subscribe(function (data) {
                    if (data) {
                        _this.snackBar.open('MDR enviada para pagamento', 'Ok', { duration: 5000 });
                    }
                    _this.notification(data);
                }, function (error) {
                    if (error && error.error.text === 'error: special occurrence not solved') {
                        _this.snackBar.open('Existem ocorrências que precisam ser resolvidas antes do envio para o Finance', 'Ok', { duration: 5000 });
                    }
                    else {
                        _this.snackBar.open('Não foi possivel enviar a MDR para o Finance', 'Ok', { duration: 5000 });
                    }
                });
            });
        }));
    };
    LogisticOrderComponent.prototype.goToChat = function (chatType) {
        this.router.navigate(['chat/' + this.mdr.id + '/' + chatType]);
    };
    LogisticOrderComponent.prototype.notification = function (data) {
        var _this = this;
        this.socketService.initSocketNotification(Number(this.user));
        var message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
        this.socketService.sendNotification(message);
        // console.log(data);
        data.forEach(function (value) {
            var notification = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), _this.chatType);
            _this.socketService.sendNotification(notification);
        });
        this.snackBar.open(this.mdr.status, 'Ok', {
            duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
    };
    return LogisticOrderComponent;
}());
export { LogisticOrderComponent };
var AcceptMdrReservationDialogComponent = /** @class */ (function () {
    function AcceptMdrReservationDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReservationDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReservationDialogComponent;
}());
export { AcceptMdrReservationDialogComponent };
var AcceptMdrReturnDialogComponent = /** @class */ (function () {
    function AcceptMdrReturnDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReturnDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReturnDialogComponent;
}());
export { AcceptMdrReturnDialogComponent };
var AcceptMdrReproveDialogComponent = /** @class */ (function () {
    function AcceptMdrReproveDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReproveDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReproveDialogComponent;
}());
export { AcceptMdrReproveDialogComponent };
var DeliveredCertifiedDialogComponent = /** @class */ (function () {
    function DeliveredCertifiedDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    DeliveredCertifiedDialogComponent.prototype.ngOnInit = function () {
    };
    return DeliveredCertifiedDialogComponent;
}());
export { DeliveredCertifiedDialogComponent };
var LSPEmailDialogComponent = /** @class */ (function () {
    function LSPEmailDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        this.selected = new FormControl();
        // console.log(translate);
    }
    LSPEmailDialogComponent.prototype.ngOnInit = function () {
    };
    return LSPEmailDialogComponent;
}());
export { LSPEmailDialogComponent };
var CreateTokenDialogComponent = /** @class */ (function () {
    function CreateTokenDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    CreateTokenDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close();
    };
    return CreateTokenDialogComponent;
}());
export { CreateTokenDialogComponent };
var MdrHistoryDialogComponent = /** @class */ (function () {
    function MdrHistoryDialogComponent(data, datepipe) {
        var _this = this;
        this.data = data;
        this.datepipe = datepipe;
        this.history = [];
        this.columns = [
            { value: 'uid', name: 'id' },
            { value: 'status', name: 'LOGISTIC-ORDER/history/status/' },
            { value: 'quem', name: 'LOGISTIC-ORDER/history/by/' },
            { value: 'quando', name: 'LOGISTIC-ORDER/history/on/' },
            { value: 'pickupDate', name: 'LOGISTIC-ORDER/history/date/' },
            { value: 'pickupID', name: 'LOGISTIC-ORDER/history/pickupId/' },
            { value: 'observation', name: 'LOGISTIC-ORDER/history/observation/' },
            { value: 'vehicle', name: 'vehicle' },
        ];
        this.history = data.history;
        this.history.forEach(function (info) {
            info.quando = _this.datepipe.transform(info.quando, 'dd/MM/yyyy HH:mm:ss');
            info.pickupDate = _this.datepipe.transform(info.pickupDate, 'dd/MM/yyyy HH:mm:ss');
        });
    }
    MdrHistoryDialogComponent.prototype.ngOnInit = function () {
    };
    return MdrHistoryDialogComponent;
}());
export { MdrHistoryDialogComponent };
