<h3>{{title}}</h3>
<p>{{summaryText}}</p>
<br>

<!-- OV/NF table -->
<app-table
  [data]="lineErrors"
  [columns]="columns"
  [this]="this"
  uidSufix = "lineErrorsDialog"
></app-table>
<br>

<!-- buttons -->
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button mat-button class="button" (click)="submit()">
    OK
  </button>
</div>
