import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSnackBar, MatDatepicker } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../providers/report.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
import { ConfigurationService } from '../../providers/configuration.service';
import { Observable } from 'rxjs';
import { ClientUserService } from '../../providers/client-user.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit, OnDestroy {
    @ViewChild(MatDatepicker) start: MatDatepicker<Date>;
    @ViewChild(MatDatepicker) end: MatDatepicker<Date>;

    startDate: Date = null;
    endDate: Date = null;
    filters: String = null;
    role: number;

    minDate = new Date(2000, 0, 1);
    maxDate = new Date(new Date().getFullYear() + 10, 0, 1);

    reportList = [
        { type: 'LSP', name: 'LSP' },
        { type: 'LSPKPI', name: 'LSP KPI' },
        { type: 'perfLSP', name: 'Performance' },
        { type: 'operation', name: 'Operação' },
        { type: 'operational', name: 'Operacional' },
        { type: 'cockpit', name: 'MDRs e DRs' },
        { type: 'stakeholders', name: 'Stakeholders' },
        { type: 'cmdr', name: 'CMDR' },
        { type: 'cov', name: 'COV' },
        { type: 'poddp', name: 'POD DP' },
        { type: 'hub', name: 'Hub' },
        { type: 'inHub', name: 'inHub' },
        { type: 'dpunion', name: 'DP Union' },
        { type: 'uoc', name: 'UOC' },
        { type: 'copq', name: 'CoPQ' },
        { type: 'atachmentsUOC', name: 'atachmentsUOC' },
        { type: 'getDR', name: 'Entregas Criadas'},
        { type: 'getHubs', name: 'Relatório de Hubs'},
    ];
    report = null;

    loading = false;

    subscriptions: any[] = [];

    email: string;
    validUser = ['clara.gama+role5@awarelog.com', 'andressa.aquino@awarelog.com', 'mayara.porto@awarelog.com', 'nadine.blair@awarelog.com'];

    clientList = [];

    byClient;

    constructor(public translate: TranslateService,
        public reportService: ReportService,
        public excel: ExcelService,
        public router: Router,
        public configService: ConfigurationService,
        public clientUserService: ClientUserService,
        public snackBar: MatSnackBar) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        this.email = currentUser.user.email;
    }

    ngOnInit() {
        if (this.role !== 1) {
            this.reportList = [{ type: 'stakeholders', name: 'Stakeholders' }, { type: 'perfLSP', name: 'Performance' }];
            if (this.role === 5 || this.role === 12 || this.role === 14) {
                this.reportList.push({ type: 'operational', name: 'Operacional' });
            }
        }
        if (this.validUser.includes(this.email)) {
            this.reportList = [{ type: 'getDR', name: 'Entregas Criadas' }];
        }
        if (this.role === 1) {
            this.reportList.push({type: 'financial', name: 'Financeiro'});
        }
        if (this.role === 3) {
            this.reportList = [{ type: 'operational', name: 'Operacional'}];
        }
        this.getClientList();
    }

    ngOnDestroy() {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    getClientList() {
        this.clientUserService.getClients().subscribe((client) => {
            this.clientList = client.map(c => c.clientGroup);
        });
    }

    resetField() {
        this.byClient = null;
    }

    checkAvailability(report: string) {
        return new Observable((observer) => {
            this.subscriptions.push(this.configService.getConfiguration('reports', true).subscribe(configuration => {
                // console.log(report, configuration[report], configuration);
                if (report in configuration && 'available' in configuration[report]) {
                    // se o relatório existe na configuração
                    //   => retorna sua disponibilidade
                    observer.next(configuration[report].available);
                } else {
                    // caso contrário
                    //   => permite baixar
                    observer.next(true);
                }
            }, (err) => {
                // não existe esta configuração/restrição
                //   => permite baixar
                observer.next(true);
            }));
        });
    }

    async download() {
        if (!this.startDate || (!this.endDate && this.report.type !== 'getHubs')) {
            this.snackBar.open('Selecione as datas a consultar', 'Fechar', {
                duration: 2000,
            });
            return;
        }
        this.subscriptions.push(this.checkAvailability(this.report.type).subscribe((available) => {
            if (available === true) {
                this.loading = true;
                let filterWillSend = null;
                let filterClient = null;
                if (this.report.type === 'financial') {
                    filterWillSend = this.filters.split(',');
                } else if (this.report.type === 'operational' || this.report.type === 'stakeholders') {
                    filterClient = this.byClient;
                }
                this.subscriptions.push(this.reportService.getReport(this.startDate, this.endDate, this.report.type, filterWillSend, filterClient ).subscribe(data => {
                    let workbook: XLSX.WorkBook;
                    let sheets;
                    let names;
                    switch (this.report.type) {
                        case 'stakeholders': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'stake': worksheet };
                            names = ['stake'];
                            break;
                        }
                        case 'lsp': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'LSP': worksheet };
                            names = ['LSP'];
                            break;
                        }
                        case 'getHubs': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'Hubs': worksheet };
                            names = ['Hubs'];
                            break;
                        }
                        case 'LSPKPI': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true,
                                header: ['19', '3', '15', '7', '8', '9', '10', '22', '20', '21', '18', '23', '11', '12', '13', '14', '6', '4', '16', '17', '0', '5', '1', '2']
                            });
                            sheets = { 'KPI': worksheet };
                            names = ['KPI'];
                            break;
                        }
                        case 'perfLSP': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                                // console.log(title);
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                        case 'operation': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                        case 'operational': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'LSP': worksheet };
                            names = ['LSP'];
                            break;
                        }
                        case 'bat': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'LSP': worksheet };
                            names = ['LSP'];
                            break;
                        }
                        case 'cmdr': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'CMDR': worksheet };
                            names = ['CMDR'];
                            break;
                        }
                        case 'cov': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'COV': worksheet };
                            names = ['COV'];
                            break;
                        }
                        case 'poddp': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'PODDP': worksheet };
                            names = ['PODDP'];
                            break;
                        }
                        case 'hub': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'Hub': worksheet };
                            names = ['Hub'];
                            break;
                        }
                        case 'inHub': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'Hub': worksheet };
                            names = ['Hub'];
                            break;
                        }
                        case 'dpunion': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'DPUnion': worksheet };
                            names = ['DPUnion'];
                            break;
                        }
                        case 'uoc': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'UOC': worksheet };
                            names = ['UOC'];
                            break;
                        }
                        case 'atachmentsUOC': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'atachmentsUOC': worksheet };
                            names = ['atachmentsUOC'];
                            break;
                        }
                        case 'copq': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'CoPQ': worksheet };
                            names = ['CoPQ'];
                            break;
                        }
                        case 'getDR': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'Entregas': worksheet };
                            names = ['Entregas'];
                            break;
                        }
                        case 'cockpit': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                        case 'financial': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets = { 'financial': worksheet };
                            names = ['financial'];
                            break;
                        }
                    }
                    workbook = { Sheets: sheets, SheetNames: names };

                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                    this.excel.saveAsExcelFile(excelBuffer, this.report.name);
                    this.loading = false;
                }));
            } else {
                this.subscriptions.push(this.configService.getConfiguration('reports', true).subscribe());
                this.snackBar.open('Estamos em manutenção, por favor tente em outro momento', 'Fechar', {
                    duration: 2000,
                });
            }
        }));
    }
}
