<h1 mat-dialog-title>{{ 'PRICE_MAKER_TABLE/table/new' | translate }}</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-wrap">
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'PRICE_MAKER_TABLE/table/shippingCompany' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.shippingCompany" name="shippingCompany">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'PRICE_MAKER_TABLE/table/region' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.region" name="region">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'vehicle' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.vehicle" name="vehicle">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'PRICE_MAKER_TABLE/table/price' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.price" name="price">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'PRICE_MAKER_TABLE/table/absolute' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.absolute" name="absolute">
    </mat-form-field>
    <mat-form-field class="col-md-4">
      <mat-label>{{ 'PRICE_MAKER_TABLE/table/sapCode' | translate }}</mat-label>
      <input matInput [(ngModel)]="data.sapCode" name="sapCode">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickBack()" cdkFocusInitial>{{ 'PRICE_MAKER_TABLE/dialog/btn/back' | translate }}</button>
  <span></span>
  <button mat-raised-button (click)="onClickSave()">{{ 'PRICE_MAKER_TABLE/dialog/btn/save' | translate }}</button>
</div>
