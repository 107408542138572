<h1 mat-dialog-title>{{ 'id' | translate }}: {{data.id}}</h1>
<a [href]="data && data.reportlink">{{ 'SITE_INVESTIGATION/dialog/report' | translate }}</a>
<div id="printable" mat-dialog-content>

  <div class="d-flex col-md-12 mt-3 px-0">
    <div class="col-md-6">
      <p>{{ 'SITE_INVESTIGATION/table/token' | translate }}:
        <span *ngIf="data.accesstokenlink">
          <a [href]="data.accesstokenlink">{{ 'YES' | translate }}</a>
        </span>
        <span *ngIf="!data.accesstokenlink">{{ 'NO' | translate }}</span>
      </p>
    </div>

    <div class="col-md-6">
      <p>{{ 'SITE_INVESTIGATION/table/aprovado' | translate }}: {{ (data.aprovado ? 'YES' : 'NO') | translate }}</p>
    </div>
  </div>

  <form #form="ngForm" class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
      <input matInput name="visitadatahora" [owlDateTimeTrigger]="dt" name="visitaTime" [owlDateTime]="dt" [placeholder]="'SITE_INVESTIGATION/table/visitadatahora' | translate" [(ngModel)]="data.visitadatahora" disabled>
      <owl-date-time disabled #dt></owl-date-time>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput name="vistoriadatahora" [owlDateTimeTrigger]="dt2" name="vistoriaTime" [owlDateTime]="dt2" [placeholder]="'SITE_INVESTIGATION/table/vistoriadatahora' | translate" [(ngModel)]="data.vistoriadatahora" disabled>
      <owl-date-time disabled #dt2></owl-date-time>
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/acesso' | translate }}</mat-label>
      <mat-select disabled [(ngModel)]="data.acesso" name="acesso">
        <mat-option *ngFor="let item of tipos['acesso']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/obs' | translate }}</mat-label>
      <textarea disabled matInput [(ngModel)]="data.obs" name="obs"></textarea>
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/code' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.code" name="code">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/operadora' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.operadora" name="operadora">
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/address' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.address" name="address">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/lat' | translate }}</mat-label>
      <input disabled matInput type="number" [(ngModel)]="data.lat" name="lat">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/lng' | translate }}</mat-label>
      <input disabled matInput type="number" [(ngModel)]="data.lng" name="lng">
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/municipio' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.municipio" name="municipio">
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/rsu' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.rsu" name="rsu">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/site' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.site" name="site">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/regional' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.regional" name="regional">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/visitanumero' | translate }}</mat-label>
      <input disabled matInput type="number" [(ngModel)]="data.visitanumero" name="visitanumero">
    </mat-form-field>

    <div>
      {{ 'SITE_INVESTIGATION/table/tipo' | translate }}:
      <p *ngFor="let item of tipos['tipo']">
        <mat-checkbox
          disabled
          [checked]="data.tipo[item.value]"
          (change)="data.tipo[item.value] = !data.tipo[item.value]"
          name="item.show">
          {{item.show | translate}}</mat-checkbox>
      </p>
    </div>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/infra' | translate }}</mat-label>
      <mat-select disabled [(ngModel)]="data.infra" name="infra">
        <mat-option *ngFor="let item of tipos['infra']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_nome' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.vistoriador_nome" name="vistoriador_nome">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_rg' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.vistoriador_rg" name="vistoriador_rg">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_cpf' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.vistoriador_cpf" name="vistoriador_cpf">
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_nome' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.responsavel_nome" name="responsavel_nome">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_rg' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.responsavel_rg" name="responsavel_rg">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_cpf' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.responsavel_cpf" name="responsavel_cpf">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_contato' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.responsavel_contato" name="responsavel_contato">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_funcao' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.responsavel_funcao" name="responsavel_funcao">
    </mat-form-field>

    <mat-divider class="col-md-12"></mat-divider>
    <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_1' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_1_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_1" name="form_1_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_1_obs" name="form_1_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_1_anexo.path">{{data.form_1_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_2" name="form_1_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_2_obs" name="form_1_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_2_anexo.path">{{data.form_1_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_3" name="form_1_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_3_obs" name="form_1_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_3_anexo.path">{{data.form_1_3_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_4' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_4" name="form_1_4">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_4_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_4_obs" name="form_1_4_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_4_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_4_anexo.path">{{data.form_1_4_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_5' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_5" name="form_1_5">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_5_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_5_obs" name="form_1_5_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_5_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_5_anexo.path">{{data.form_1_5_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_6' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_6" name="form_1_6">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_6_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_6_obs" name="form_1_6_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_6_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_6_anexo.path">{{data.form_1_6_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_7' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_7" name="form_1_7">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_7_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_7_obs" name="form_1_7_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_7_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_7_anexo.path">{{data.form_1_7_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_8' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_8" name="form_1_8">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_8_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_8_obs" name="form_1_8_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_8_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_8_anexo.path">{{data.form_1_8_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_1_9' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_1_9" name="form_1_9">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_1_9_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_1_9_obs" name="form_1_9_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_1_9_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_1_9_anexo.path">{{data.form_1_9_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_2' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_2_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_1" name="form_2_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_1_obs" name="form_2_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_1_anexo.path">{{data.form_2_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_2" name="form_2_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_2_obs" name="form_2_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_2_anexo.path">{{data.form_2_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_3" name="form_2_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_3_obs" name="form_2_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_3_anexo.path">{{data.form_2_3_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_4' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_4" name="form_2_4">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_4_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_4_obs" name="form_2_4_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_4_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_4_anexo.path">{{data.form_2_4_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_5' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_5" name="form_2_5">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_5_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_5_obs" name="form_2_5_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_5_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_5_anexo.path">{{data.form_2_5_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_6' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_6" name="form_2_6">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_6_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_6_obs" name="form_2_6_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_6_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_6_anexo.path">{{data.form_2_6_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_7' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_7" name="form_2_7">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_7_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_7_obs" name="form_2_7_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_7_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_7_anexo.path">{{data.form_2_7_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_8' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_8" name="form_2_8">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_8_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_8_obs" name="form_2_8_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_8_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_8_anexo.path">{{data.form_2_8_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_2_9' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_2_9" name="form_2_9">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_2_9_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_2_9_obs" name="form_2_9_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_2_9_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_2_9_anexo.path">{{data.form_2_9_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_3' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_3_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_3_1" name="form_3_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_3_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_3_1_obs" name="form_3_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_3_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_3_1_anexo.path">{{data.form_3_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_3_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_3_2" name="form_3_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_3_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_3_2_obs" name="form_3_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_3_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_3_2_anexo.path">{{data.form_3_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_3_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_3_3" name="form_3_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_3_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_3_3_obs" name="form_3_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_3_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_3_3_anexo.path">{{data.form_3_3_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_4' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_4_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_4_1" name="form_4_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_4_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_4_1_obs" name="form_4_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_4_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_4_1_anexo.path">{{data.form_4_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_4_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_4_2" name="form_4_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_4_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_4_2_obs" name="form_4_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_4_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_4_2_anexo.path">{{data.form_4_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_4_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_4_3" name="form_4_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_4_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_4_3_obs" name="form_4_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_4_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_4_3_anexo.path">{{data.form_4_3_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_5' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_5_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_5_1" name="form_5_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_5_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_5_1_obs" name="form_5_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_5_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_5_1_anexo.path">{{data.form_5_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_5_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_5_2" name="form_5_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_5_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_5_2_obs" name="form_5_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_5_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_5_2_anexo.path">{{data.form_5_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_5_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_5_3" name="form_5_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_5_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_5_3_obs" name="form_5_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_5_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_5_3_anexo.path">{{data.form_5_3_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_6' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_6_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_6_1" name="form_6_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_6_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_6_1_obs" name="form_6_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_6_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_6_1_anexo.path">{{data.form_6_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_6_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_6_2" name="form_6_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_6_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_6_2_obs" name="form_6_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_6_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_6_2_anexo.path">{{data.form_6_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_6_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_6_3" name="form_6_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_6_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_6_3_obs" name="form_6_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_6_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_6_3_anexo.path">{{data.form_6_3_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_6_4' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_6_4" name="form_6_4">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_6_4_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_6_4_obs" name="form_6_4_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_6_4_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_6_4_anexo.path">{{data.form_6_4_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_6_5' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_6_5" name="form_6_5">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_6_5_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_6_5_obs" name="form_6_5_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_6_5_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_6_5_anexo.path">{{data.form_6_5_anexo.fileOriginalName}}</a></span>
    </div>

    <mat-divider class="col-md-12"></mat-divider>
    <h5>{{ 'SITE_INVESTIGATION/table/form_7' | translate }}</h5>

    <p>{{ 'SITE_INVESTIGATION/table/form_7_1' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_7_1" name="form_7_1">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_7_1_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_7_1_obs" name="form_7_1_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_7_1_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_7_1_anexo.path">{{data.form_7_1_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_7_2' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_7_2" name="form_7_2">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_7_2_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_7_2_obs" name="form_7_2_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_7_2_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_7_2_anexo.path">{{data.form_7_2_anexo.fileOriginalName}}</a></span>
    </div>
    <mat-divider class="col-md-12"></mat-divider>
    <p>{{ 'SITE_INVESTIGATION/table/form_7_3' | translate }}</p>
    <mat-form-field class="col-md-12">
      <mat-select disabled [(ngModel)]="data.form_7_3" name="form_7_3">
        <mat-option *ngFor="let item of tipos['estrutura']" [value]="item.value">
          {{item.show | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <mat-label>{{ 'SITE_INVESTIGATION/table/form_7_3_obs' | translate }}</mat-label>
      <input disabled matInput [(ngModel)]="data.form_7_3_obs" name="form_7_3_obs">
    </mat-form-field>
    <div class="col-md-12">
      <span *ngIf="data.form_7_3_anexo">{{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="data.form_7_3_anexo.path">{{data.form_7_3_anexo.fileOriginalName}}</a></span>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="primary-button full-width" (click)="onClickNewToken()">{{ 'SITE_INVESTIGATION/dialog/btn/token' | translate }}</button>
</div>
