import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './providers/auth.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(auth, translate) {
        this.auth = auth;
        this.translate = translate;
        this.title = 'bluebee';
        translate.setDefaultLang('en');
        // Uncomment for set language based on the browser preferences
        var browserLang = translate.getBrowserLang();
        // console.log(browserLang);
        if (browserLang !== undefined) {
            translate.use(browserLang);
        }
        else {
            translate.use('pt');
        }
        // (<any>window).Conpass.init();
    }
    return AppComponent;
}());
export { AppComponent };
