var CostAvoidanceMDR = /** @class */ (function () {
    function CostAvoidanceMDR() {
        this.id = null;
        this.costAvoidance = '';
        this.idMDR = null;
        this.value = null;
        this.observation = '';
    }
    return CostAvoidanceMDR;
}());
export { CostAvoidanceMDR };
