<div>
  <div class="mx-5 my-4">
    <p class="mat-h1">Gerenciamento de Usuarios</p>
    <!--<p class="mx-4">{{ 'HUB/txt1' | translate }}<br>{{ 'HUB/txt2' | translate }}</p>-->
  </div>
  <div *ngIf="dataSource.data.length > 0" class="colored-background table-background">
    <div class="mx-5" style="white-space: nowrap; align-content: center;">
      <div style="display: block;">
        <button *ngIf="canCreateUser === true" mat-raised-button class="rounded-corners2 mb-3 pt-1 pl-4 col-md-2 primary-button" (click)="openDialog()">{{ 'Criar Usuário LSP' | translate }}</button>
        <button *ngIf="canCreateUser === true" mat-raised-button style="margin-left: 30px;" class="rounded-corners2 mb-3 pt-1 pl-4 col-md-2 primary-button" (click)="openCreateAdmUserDialog()">{{ 'Criar Usuário Ericsson' | translate }}</button>
      </div>
      <mat-form-field class="mb-3 pt-1 pl-4 rounded-corners col-md-6" floatLabel="never">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let chip of searchChips"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chip)">
            {{chip.name}}
            <i matChipRemove class="fa fa-times ml-1" *ngIf="removable"></i>
          </mat-chip>
          <!-- <mat-label><i class="fa fa-search mr-1"></i>{{ 'CONTROL_TOWER/search' | translate }}</mat-label> -->
          <input placeholder="Digite para começar a filtrar os resultados por palavra chave..."
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
      <span class="mb-3 pt-1 pl-4 col-md-2" style="top: 40px;">LSP <mat-slide-toggle [(ngModel)]="aware">ASP</mat-slide-toggle></span>
      <table mat-table #table *ngIf="!aware" [dataSource]="dataSource" matSort class="rounded-corners col-md-12">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transportadora</th>
          <td mat-cell *matCellDef="let element"> {{ element.company }} </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let element"> {{ element.role }} </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Remover</th>
          <td mat-cell *matCellDef="let element" (click)="openDeleteDialog(element)"><i font class="fa fa-trash" aria-hidden="true"></i></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <table mat-table #table *ngIf="aware" [dataSource]="dataSource2" matSort class="rounded-corners col-md-12">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transportadora</th>
          <td mat-cell *matCellDef="let element"> {{ element.company }} </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Remover</th>
          <td mat-cell *matCellDef="let element" (click)="openDeleteDialog(element)"><i font class="fa fa-trash" aria-hidden="true"></i></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
