var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.dateRange = {
            startDate: new Date,
            endDate: new Date
        };
        // set token if saved in local storage
        this.url = base_url + 'statistics/';
        this.token = JSON.parse(localStorage.getItem('currentUser')).token;
        this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);
    }
    DashboardService.prototype.parseDates = function (dates) {
        var stDate, fnDate;
        var now = new Date();
        if (dates === undefined) {
            // no Dates received:
            // use 1st day of current month as start
            // and now as end
            stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            stDate = new Date(stDate.setDate(0));
            fnDate = now;
        }
        else {
            // start date received
            if (dates[0] !== null) {
                // convert the value to Date
                stDate = new Date(dates[0]);
            }
            else {
                // no start date received
                // use 1st day of current month as start
                stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                stDate = new Date(stDate.setDate(0));
            }
            // end date received
            if (dates[1] !== null) {
                // convert the value to Date
                fnDate = new Date(dates[1]);
            }
            else {
                // use today
                fnDate = now;
            }
        }
        return { start: stDate.toISOString(), end: fnDate.toISOString() };
    };
    DashboardService.prototype.setBody = function (start, end, ufSelected, typeSelected, lspSelected, clientSelected, group, status) {
        return {
            headers: this.header,
            params: __assign({}, (start && { startDate: start }), (end && { endDate: end }), (typeSelected && { type: typeSelected }), (lspSelected && { lsp: lspSelected }), (ufSelected && { uf: ufSelected }), (clientSelected && { client: clientSelected }), (group && { groupBy: group }), (status && { status: status }))
        };
    };
    DashboardService.prototype.getMdrByShippingCompany = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/shippingCompany', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month'));
    };
    DashboardService.prototype.getDrByClient = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/client', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected));
    };
    DashboardService.prototype.getMdrByCreated = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/createdAt/shippingCompany', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected));
    };
    DashboardService.prototype.getMdrByDelivered = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        var status = ['MDR paga / concluída', 'Entrega de carga certificada pelo LSP', 'Carga entregue com sucesso'];
        return this.http.get(this.url + 'mdr/status/shippingCompany', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, null, status));
    };
    DashboardService.prototype.getDrByStatus = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/status', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month'));
    };
    DashboardService.prototype.getC02ByCreatedAt = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/createdAt/c02', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month'));
    };
    DashboardService.prototype.getC02CAByCreatedAt = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/createdAt/c02CostAvoidance', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, 'month'));
    };
    DashboardService.prototype.getDrByDriverPosition = function (dates) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/driverPosition', this.setBody());
    };
    DashboardService.prototype.getOccurrence = function () {
        var start = this.dateRange.startDate.toISOString();
        var end = this.dateRange.endDate.toISOString();
        return this.http.get(this.url + 'occurrence', this.setBody(start, end));
    };
    DashboardService.prototype.getOccurrenceWords = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        var startDate = new Date(new Date().setDate(1)).toISOString(); // beginning of current month
        return this.http.get(this.url + 'occurrence/text', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected));
    };
    DashboardService.prototype.getPods = function () {
        var start = this.dateRange.startDate.toISOString();
        var end = this.dateRange.endDate.toISOString();
        return this.http.get(this.url + 'pod', this.setBody(start, end));
    };
    DashboardService.prototype.getCostKg = function (group, dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/costPerKilo', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected, group));
    };
    DashboardService.prototype.getOVsDelivered = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/status/delivered', this.setBody(start, end, ufSelected, typeSelected, lspSelected, clientSelected));
    };
    return DashboardService;
}());
export { DashboardService };
