import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
HC_solidgauge(Highcharts);

// Now init modules:
HighchartsSolidGauge(Highcharts);
more(Highcharts);

@Component({
  selector: 'app-speedometer-chart',
  templateUrl: './speedometer-chart.component.html',
  styleUrls: ['./speedometer-chart.component.scss', '../graph-card.component.scss']
})
export class SpeedometerChartComponent implements OnInit {
  @Input() container: string;
  colors = ['#B6BCE9', '#AFDEEE', '#ECE196'];
  data = [];
  downloadData = [];

  constructor(private translateService: TranslateService, private excelService: ExcelService) { }

  ngOnInit() {}

  getStatusColor(i) {
    return this.colors[i];
  }

  download() {
    this.excelService.exportAsExcelFile(this.downloadData, 'drs');
  }

  createChart(data: any[], id: string, translate: TranslateService, download?) {
    if (download) {
      this.downloadData = download;
    }
    Highcharts.chart({
      tooltip: {
        enabled: false
      },
      chart: {
        type: 'solidgauge',
        renderTo: id,
      },
      title: {
        text: ''
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        layout: 'horizontal',
        align: 'center',
        squareSymbol: false,
        symbolHeight: 5,
        symbolWidth: 15,
        symbolRadius: 5,
        symbolPadding: 10,
        itemStyle: {
          fontFamily: 'Roboto',
          fontSize: '13px',
          fontWeight: 'normal',
          useHtml: true,
          width: 80
        }
      },
      pane: {
        startAngle: -130,
        endAngle: 130,
        background: [{
          outerRadius: '112%',
          innerRadius: '100%',
          backgroundColor: '#EEEEEE',
          shape: 'arc',
          borderWidth: 0
        }, {
          outerRadius: '92%',
          innerRadius: '80%',
          shape: 'arc',
          backgroundColor: '#EEEEEE',
          borderWidth: 0
        }, {
          outerRadius: '72%',
          backgroundColor: '#EEEEEE',
          innerRadius: '60%',
          shape: 'arc',
          borderWidth: 0
        },
        ]
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            y: 50,
            borderWidth: 0,
            style: {
              fontSize: '13px',
              textAlign: 'center'
            },
            useHTML: true,
            align: 'center',
             formatter: function ()  {
                return '<span style=\'font-size:30px;font-weight: 900; font-family:Roboto;\'>' + this.point.total + '</span>'
                        + '<br /><span style="font-family: Roboto; margin: auto;font-weight: 100;">' + translate.instant('total') + '</span>';
          }
       },
        }
      },
      series: data
    });
  }

}
