import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {FormControl} from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {MapsAPILoader} from '@agm/core';
import {Router} from '@angular/router';
import {MdrService} from '../../providers/mdr.service';
import {MDR} from '../../models/mdr';

@Component({
  selector: 'app-new-mdr-request',
  templateUrl: './new-mdr-request.component.html',
  styleUrls: ['./new-mdr-request.component.scss']
})
export class NewMdrRequestComponent implements OnInit {

  public searchControl: FormControl;
  displayedColumns = ['id', 'status'];
  data: MDR[] = [];
  dataSource = new MatTableDataSource<MDR>(this.data);
  inputOVs: number;
  opened: any;
  //
  // displayedColumns2 = ['id'];
  // dataSourceOV = new MatTableDataSource<OVs>(OVS_DATA);

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader,
              private drService: DrService,
              public mdrService: MdrService, public router: Router) {
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.searchControl = new FormControl();
    this.mdrService.getAllLSP().subscribe( (data) => {
      // console.log(data);
      this.data = data;
      this.dataSource = new MatTableDataSource<MDR>(data);
    });
  }

  goTo(mdr: MDR) {
    this.router.navigate(['logistic-order', mdr.id]);
  }
}

// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'new-dialog-overview-example-dialog.html',
// })
// export class OverviewExampleDialogComponent {

//   constructor(
//     public dialogRef: MatDialogRef<OverviewExampleDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any) { }

//   onYesClick(): void {
//     this.dialogRef.close();
//   }

// }

export interface Element {
  DO: string;
  status: string;
  actions: string;
}

export interface OVs {
  id: number;
}
