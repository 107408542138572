/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./annex.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i3 from "@angular/material/expansion";
import * as i4 from "@angular/cdk/collections";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "./annex.component";
var styles_AnnexComponent = [i0.styles];
var RenderType_AnnexComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnnexComponent, data: {} });
export { RenderType_AnnexComponent as RenderType_AnnexComponent };
export function View_AnnexComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 10, "mat-expansion-panel", [["class", "mat-expansion-panel"]], [[2, "mat-expanded", null], [2, "_mat-animation-noopable", null], [2, "mat-expansion-panel-spacing", null]], null, null, i2.View_MatExpansionPanel_0, i2.RenderType_MatExpansionPanel)), i1.ɵdid(1, 1753088, null, 1, i3.MatExpansionPanel, [[3, i3.MAT_ACCORDION], i1.ChangeDetectorRef, i4.UniqueSelectionDispatcher, i1.ViewContainerRef, i5.DOCUMENT, [2, i6.ANIMATION_MODULE_TYPE], [2, i3.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵqud(335544320, 1, { _lazyContent: 0 }), i1.ɵprd(256, null, i3.MAT_ACCORDION, undefined, []), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "mat-expansion-panel-header", [["class", "mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatExpansionPanelHeader_0, i2.RenderType_MatExpansionPanelHeader)), i1.ɵdid(5, 180224, null, 0, i3.MatExpansionPanelHeader, [i3.MatExpansionPanel, i1.ElementRef, i7.FocusMonitor, i1.ChangeDetectorRef, [2, i3.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵpod(6, { collapsedHeight: 0, expandedHeight: 1 }), i1.ɵpod(7, { value: 0, params: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵted(-1, null, [" Anexos "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).expanded; var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 1)._hasSpacing(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 5).panel._headerId; var currVal_4 = (i1.ɵnov(_v, 5).disabled ? (0 - 1) : 0); var currVal_5 = i1.ɵnov(_v, 5)._getPanelId(); var currVal_6 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_7 = i1.ɵnov(_v, 5).panel.disabled; var currVal_8 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_9 = _ck(_v, 7, 0, i1.ɵnov(_v, 5)._getExpandedState(), _ck(_v, 6, 0, i1.ɵnov(_v, 5).collapsedHeight, i1.ɵnov(_v, 5).expandedHeight)); _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_AnnexComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-annex", [], null, null, null, View_AnnexComponent_0, RenderType_AnnexComponent)), i1.ɵdid(1, 114688, null, 0, i8.AnnexComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnnexComponentNgFactory = i1.ɵccf("app-annex", i8.AnnexComponent, View_AnnexComponent_Host_0, {}, {}, []);
export { AnnexComponentNgFactory as AnnexComponentNgFactory };
