import { EventEmitter } from '@angular/core';
import { UploadFileService } from '../../providers/upload.service';
import { HttpEventType } from '@angular/common/http';
var UploadComponent = /** @class */ (function () {
    function UploadComponent(uploadService) {
        this.uploadService = uploadService;
        this.messageEvent = new EventEmitter();
        this.currentFileUpload = [];
        this.progress = { percentage: 0 };
        this.fileInputValue = '';
        this.sendStatus = false;
    }
    UploadComponent.prototype.selectFile = function (event) {
        this.selectedFiles = event.target.files;
        for (var k = 0; k < this.selectedFiles.length; k++) {
            this.currentFileUpload.push(this.selectedFiles.item(k));
        }
        if (this.currentFileUpload.length > 1) {
            this.uploadMulti();
        }
        else {
            this.upload();
        }
    };
    UploadComponent.prototype.upload = function () {
        var _this = this;
        this.sendStatus = false;
        this.progress.percentage = 0;
        this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                _this.progress.percentage = Math.round(100 * event.loaded / event.total);
            }
            else if (event.type === HttpEventType.Response) {
                _this.currentFileUpload = [];
                _this.fileInputValue = '';
                _this.messageEvent.emit('ok');
                _this.sendStatus = true;
            }
        });
        this.selectedFiles = undefined;
    };
    UploadComponent.prototype.uploadMulti = function () {
        var _this = this;
        this.sendStatus = false;
        this.progress.percentage = 0;
        this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(function (event) {
            if (HttpEventType.UploadProgress === event.type) {
                _this.progress.percentage = Math.round(100 * event.loaded / event.total);
            }
            else if (event.type === HttpEventType.Response) {
                // console.log('File is completely uploaded!');
                _this.currentFileUpload = [];
                _this.fileInputValue = '';
                _this.messageEvent.emit('ok');
                _this.sendStatus = true;
            }
        });
        this.selectedFiles = undefined;
    };
    return UploadComponent;
}());
export { UploadComponent };
