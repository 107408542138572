
<div *ngIf="!this.auth.isAuthenticated()">
  <router-outlet></router-outlet>
</div>
<div *ngIf="this.auth.isAuthenticated()">
  <app-header-component class="mat-elevation-z3"></app-header-component>
  <mat-drawer-container>
    <mat-drawer mode="side" opened class="mat-elevation-z3">
      <app-menu-component></app-menu-component>
    </mat-drawer>
    <mat-drawer-content>
      <ng4-loading-spinner [timeout]="20 * 1000" [threshold]="1000"> </ng4-loading-spinner>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
