import { Routes } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';
import { TowerControlComponent } from './tower-control.component';
var routes = [
    { path: 'control-tower', component: TowerControlComponent, canActivate: [RoleGuardService] }
];
var TowerControlRoutingModule = /** @class */ (function () {
    function TowerControlRoutingModule() {
    }
    return TowerControlRoutingModule;
}());
export { TowerControlRoutingModule };
