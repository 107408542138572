var Zhstatus = /** @class */ (function () {
    function Zhstatus() {
        this.id = null;
        this.shipment = null;
        this.delivery = null;
        this.salesDoc = null;
        this.storBin = null;
        this.sorg = null;
        this.handlingUnit = null;
        this.shipPoint = null;
        this.totalWght = null;
        this.totalVol = null;
        this.packMatls = null;
        this.status = null;
        this.shty = null;
        this.trspPlanPt = null;
        this.servcAgent = null;
        this.spPi = null;
        this.planned = null;
        this.loadStart = null;
        this.loadEnd = null;
        this.completion = null;
        this.shmtStart = null;
        this.shmtEnd = null;
        this.currShipSt = null;
        this.gm = null;
        this.ops = null;
        this.ps = null;
        this.podStatus = null;
        this.poReference = null;
        this.siteId = null;
        this.completionIndicator = null;
        this.integrated = null;
        this.insertedBy = null;
        this.updatedBy = null;
        this.insert = null;
        this.update = null;
        this.active = null;
    }
    return Zhstatus;
}());
export { Zhstatus };
