import { OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
import { take, concatMap } from 'rxjs/operators';
import { from } from 'rxjs';
var FinanceListComponent = /** @class */ (function () {
    function FinanceListComponent(dialog, mdrService, excelService, drService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.excelService = excelService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.paymentList = [];
        this.data = null;
        this.cachedData = null;
        this.listPay = [];
        this.columns = [
            { value: 'status', name: 'status' },
            { value: 'client', name: 'FINANCE_LIST/table/client' },
            { value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp' },
            { value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr' },
            { value: 'prolog', name: 'FINANCE_LIST/table/prolog' },
            { value: 'costProject', name: 'FINANCE_LIST/table/costProject' },
            { value: 'OV', name: 'FINANCE_LIST/table/ov' },
            { value: 'type', name: 'type' },
            { value: 'origem', name: 'FINANCE_LIST/table/origem' },
            { value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL' },
            { value: 'createdAt', name: 'FINANCE_LIST/table/date' }
        ];
        this.filterDates = {
            start: null,
            end: null,
        };
        this.subscriptions = [];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.filterDates.start = new Date();
        this.filterDates.start.setDate(this.filterDates.start.getDate() - 15);
        this.filterDates.end = new Date();
        console.log(this.filterDates);
    }
    FinanceListComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    FinanceListComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    FinanceListComponent.prototype.getData = function (rangeDates) {
        var _this = this;
        if (rangeDates === void 0) { rangeDates = null; }
        var _a;
        var body = rangeDates && rangeDates.length >= 2 ? rangeDates : [];
        this.subscriptions.push((_a = this.mdrService).getAllPaymentList.apply(_a, body).subscribe(function (data) {
            // console.log(data);
            var keys = data.length ? Object.keys(data[0]) : [];
            if (!data) {
                data = [];
            }
            _this.data = data.map(function (k) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    if (k[keys[i]] === null || k[keys[i]] === undefined) {
                        k[keys[i]] = '';
                    }
                }
            });
            data.forEach(function (k) {
                k.currency = k.value / 100;
                k.createdAt = new Date(k.createdAt);
                k.unique = k.origem + '|' + k.origemId + '|' + k.invoice + '|' + k.OV;
            });
            _this.data = data;
            if (rangeDates) {
                _this.snackBar.open('Novos dados carregandos com sucesso', 'Ok', {
                    duration: 4000,
                });
            }
        }));
    };
    FinanceListComponent.prototype.updateFromCachedData = function (data) {
        if (data === void 0) { data = null; }
        this.snackBar.open('Carregando novos dados, por favor aguarde um instante', 'Ok', {
            duration: 4000,
        });
        this.getData(data);
    };
    FinanceListComponent.prototype.insertPaymentFromFile = function (fileupload) {
        var _this = this;
        // console.log(fileupload);
        // console.log(fileupload);
        var input = fileupload.target;
        // console.log(fileupload);
        this.paymentList = [];
        for (var index = 0; index < input.files.length; index++) {
            this.subscriptions.push(this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                data.forEach(function (el, i, arr) {
                    var payment = {};
                    // console.log(el);
                    try {
                        payment[''] = el.pickup_address;
                        // console.log(drAux.status);
                    }
                    catch (ex) {
                        // console.log('erro ao ler arquivo xlsx ',ex);
                        _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                    }
                });
            }));
        }
    };
    FinanceListComponent.prototype.updateFromFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        this.subscriptions.push(this.excelService.importFromExcel(fileupload).subscribe(function (data) {
            // console.log(data);
            var avisos = [];
            var checkRow = function (row) {
                // status é válido
                if ('status' in row
                    && !Object.values(PAYMENT_STATUS).includes(row['status'])) {
                    avisos.push("Linha " + row.__rowNum__ + " cont\u00E9m status inv\u00E1lido");
                    return false;
                }
                // campos necessários estão presentes
                if (!('origemId' in row)
                    || !('invoice' in row)
                    || !('OV' in row)
                    || !('origem' in row)) {
                    avisos.push("Linha " + row.__rowNum__ + " n\u00E3o cont\u00E9m campos obrigat\u00F3rios [origemId,invoice,OV,origem]");
                    return false;
                }
                // linha já existe, portanto pode ser atualizada
                // if ( !this.data.find((el) => {
                //   return (
                //     // tslint:disable-next-line:triple-equals
                //     el['origemId'] == row['origemId']
                //     // tslint:disable-next-line:triple-equals
                //     && el['invoice'] == row['invoice']
                //     // tslint:disable-next-line:triple-equals
                //     && el['OV'] == row['OV']
                //     // tslint:disable-next-line:triple-equals
                //     && el['origem'] == row['origem']
                //   );
                // }) ) {
                //   avisos.push(`Linha ${row.__rowNum__} não existe no sistema`);
                //   console.log(row);
                //   return false;
                // }
                return true;
            };
            // se todas as linhas recebidas são válidas
            if (data.every(checkRow)) {
                // atualiza as linhas
                var total = data.length;
                // console.log(total);
                //
                var groupSize_1 = 1000;
                var aux = Math.ceil(total / groupSize_1);
                var dataDividedByGroup = {};
                for (var i = 0; i < aux; i++) {
                    dataDividedByGroup[String(i * groupSize_1)] = data.slice(i * groupSize_1, ((i + 1) * groupSize_1));
                }
                var groupedDataArray = Object.values(dataDividedByGroup);
                var splittedQuery = from(groupedDataArray).pipe(concatMap(function (group) { return _this.mdrService.updatePayment(group).pipe(take(1)); }), take(groupedDataArray.length));
                var linesCounter_1 = 0;
                _this.subscriptions.push(splittedQuery.subscribe(function (response) {
                    linesCounter_1++;
                    console.log(response);
                }, function (error) {
                    console.log(linesCounter_1);
                    var errorData = error.error;
                    if (errorData && errorData.text) {
                        var count = (errorData.text.split(',').length);
                        var problems = count + " problema" + (count > 1 ? 's' : '');
                        console.log(errorData.text);
                        var errorsSplitted = (errorData.text).split(',').map(function (elem) { return String(Number(elem) + (linesCounter_1 * groupSize_1)); }).join(',');
                        var errorMsg = "Erro ao subir arquivo, " + problems + " nas linhas: " + errorsSplitted;
                        _this.snackBar.open(errorMsg, 'OK', { duration: 120000 });
                        if (errorData.fails && errorData.fails.length > 0) {
                            _this.openErrorsDialog(errorData, (linesCounter_1 * groupSize_1));
                        }
                    }
                    else if ('status' in error) {
                        _this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
                    }
                    else {
                        _this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
                    }
                }, function () {
                    _this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
                }));
            }
            else {
                _this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
                var _loop_1 = function (i, len) {
                    setTimeout(function () {
                        _this.snackBar.open(avisos[i], 'OK', { duration: 10000 });
                    }, 2000 * (i + 1));
                };
                // mostra avisos gerados
                for (var i = 0, len = avisos.length; i < len; i++) {
                    _loop_1(i, len);
                }
            }
        }));
    };
    FinanceListComponent.prototype.goToMDR = function (row, that) {
        if (row.mdrId !== 0) {
            that.router.navigate(['logistic-order/' + row.mdrId]);
        }
        else {
            that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
        }
    };
    FinanceListComponent.prototype.addList = function (listPayments) {
        this.listPay = listPayments;
    };
    FinanceListComponent.prototype.payNF = function () {
        var _this = this;
        this.subscriptions.push(this.mdrService.payNF(this.listPay).subscribe(function (response) {
            _this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
        }));
    };
    FinanceListComponent.prototype.remove = function () {
        var _this = this;
        var removed = 0;
        var total = this.listPay.length;
        this.listPay.forEach(function (payment) {
            _this.subscriptions.push(_this.mdrService.removePayment(payment.id).subscribe(function (response) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
                var paymentI = _this.data.findIndex(function (e) { return e.id === payment.id; });
                if (paymentI >= 0) {
                    _this.data.splice(paymentI, 1);
                }
            }, function (error) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
            }));
        });
    };
    FinanceListComponent.prototype.openErrorsDialog = function (errorData, rowBase) {
        var lineErrors = [];
        errorData.fails.forEach(function (fail) {
            var errorText = "Erro " + fail.errorCode + " na linha " + fail.line + "...";
            if (fail.errorCode === 500) {
                errorText = "Informa\u00E7\u00E3o inv\u00E1lida ou mal formatada.";
            }
            else if (fail.errorCode === 404) {
                errorText = "Pagamento n\u00E3o encontrado para OV, invoice, origem, origemId.";
            }
            else if (fail.errorCode === 403) {
                errorText = "Faltou informar OV, invoice, origem ou origemId corretamente.";
            }
            lineErrors.push({
                'line': fail.line + rowBase,
                'errorCode': fail.errorCode,
                'errorText': errorText
            });
        });
        var count = (errorData.text.split(',').length);
        var problems = count + " problema" + (count > 1 ? 's' : '');
        var summaryText = "Ao todo " + problems + " nas linhas: " + errorData.text + ".";
        var maxLength = 300;
        if (summaryText.length > maxLength) {
            summaryText = summaryText.substring(0, maxLength) + '...';
        }
        var dialogRef = this.dialog.open(FinanceErrorDialogComponent, {
            // width: '80vw',
            maxHeight: '80vh',
            data: {
                'summaryText': summaryText,
                'lineErrors': lineErrors
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(`resp = ${JSON.stringify(response)}`);
        });
    };
    return FinanceListComponent;
}());
export { FinanceListComponent };
var FinanceErrorDialogComponent = /** @class */ (function () {
    function FinanceErrorDialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.title = 'Erros no Arquivo';
        this.summaryText = '';
        this.lineErrors = [];
        this.columns = [
            { value: 'line', name: 'Linha' },
            { value: 'errorCode', name: 'Código do Erro' },
            { value: 'errorText', name: 'Descrição do Erro' },
        ];
        this.summaryText = data.summaryText;
        this.lineErrors = data.lineErrors;
    }
    FinanceErrorDialogComponent.prototype.submit = function () {
        this.dialogRef.close();
    };
    return FinanceErrorDialogComponent;
}());
export { FinanceErrorDialogComponent };
