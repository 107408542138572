<h1 mat-dialog-title>Razão para rejeição</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
      <input matInput placeholder="Valor" name="text2" [(ngModel)]="occ.resolution" required>
    </mat-form-field>
    <mat-form-field class="col-md-12">
      <input matInput placeholder="Description" name="text3" [(ngModel)]="occ.text" required>
    </mat-form-field>
<br>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button (click)="onYesClick()" [disabled]="!form.valid">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
