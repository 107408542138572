<div class="mx-5 my-4">
  <p class="mat-h1">Resultados para {{ search_term }}</p>
</div>
<div class="colored-background table-background">
  <div *ngIf="OVDataSource.data.length" class="mx-5 py-3">
    <p class="title">OVs</p>
    <table mat-table #table [dataSource]="OVDataSource">
      <ng-container matColumnDef="idDRidentifier">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/drs' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
      </ng-container>
      <ng-container matColumnDef="mdrName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/mdrName' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.mdrName}} </td>
      </ng-container>
      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/address' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/site' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.site}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.receiverPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.receiverName}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorName}} </td>
      </ng-container>
      <ng-container matColumnDef="dateScheduling">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/date' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'status' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.status | translate}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef><th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class='button primary-button' (click)="gotoMDR(element)">Ir para MDR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="colored-background table-background">
  <div *ngIf="NFDataSource.data.length" class="mx-5 py-3">
    <p class="title">NFs</p>
    <table mat-table #table [dataSource]="NFDataSource">
      <ng-container matColumnDef="idDRidentifier">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/drs' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
      </ng-container>
      <ng-container matColumnDef="mdrName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/mdrName' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.mdrName}} </td>
      </ng-container>
      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/address' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/site' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.site}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.receiverPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.receiverName}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorName}} </td>
      </ng-container>
      <ng-container matColumnDef="dateScheduling">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/date' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'status' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.status | translate}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef><th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class='button primary-button' (click)="gotoMDR(element)">Ir para MDR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="colored-background table-background">
  <div *ngIf="MDRDataSource.data.length" class="mx-5 py-3">
    <p class="title">MDRs</p>
    <table mat-table #table [dataSource]="MDRDataSource">
      <ng-container matColumnDef="idDRidentifier">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/drs' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
      </ng-container>
      <ng-container matColumnDef="mdrName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/mdrName' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.mdrName}} </td>
      </ng-container>
      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/address' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/site' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.site}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.receiverPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.receiverName}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorName}} </td>
      </ng-container>
      <ng-container matColumnDef="dateScheduling">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/date' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'status' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.status | translate}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef><th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class='button primary-button' (click)="gotoMDR(element)">Ir para MDR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="colored-background table-background">
  <div *ngIf="SiteDataSource.data.length" class="mx-5 py-3">
    <p class="title">Sites</p>
    <table mat-table #table [dataSource]="SiteDataSource">
      <ng-container matColumnDef="idDRidentifier">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/drs' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
      </ng-container>
      <ng-container matColumnDef="mdrName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/mdrName' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.mdrName}} </td>
      </ng-container>
      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/address' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/site' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.site}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.receiverPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.receiverName}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorPhone">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/phone' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorPhone}} </td>
      </ng-container>
      <ng-container matColumnDef="supervisorName">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.supervisorName}} </td>
      </ng-container>
      <ng-container matColumnDef="dateScheduling">
        <th mat-header-cell *matHeaderCellDef> {{ 'DELIVERY_ROUTE/date' | translate }}<th>
        <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'status' | translate }} <th>
        <td mat-cell *matCellDef="let element"> {{element.status | translate}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef><th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button class='button primary-button' (click)="gotoMDR(element)">Ir para MDR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
