import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Occurrence, OCCURRENCE_STATUS} from '../../../models/occurrence';
import {MDR} from '../../../models/mdr';
import {MdrService} from '../../../providers/mdr.service';
import {UOCMDR} from '../../../models/uoc__mdr';
import {UOC} from '../../../models/uoc';
import {FormControl} from '@angular/forms';
import {DR} from '../../../models/dr';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {UOCObservable} from '../../../services/uoc.service';
import {MdrObservable} from '../../../services/mdr.service';
import {Transportadora} from '../transport/transport.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Copq} from '../../../models/copq';
import {COPQMDR} from '../../../models/copq__mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {environment} from '../../../../environments/environment';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';
import { LSPEmailDialogComponent } from '../logistic-order.component';
import { User } from '../../../models/user';
import { element } from '@angular/core/src/render3';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cost-approval',
  templateUrl: './cost-approval.component.html',
  styleUrls: ['./cost-approval.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CostApprovalComponent implements OnInit {
  base_url = environment.apiUrl;
  dataOccurrence = new MatTableDataSource<Occurrence>();
  occurrenceColumns = ['id', 'uoc', 'text', 'status', 'createdAt', 'upload', 'file', 'resolution'];
  expandedElement: Occurrence | null;
  myControl = new FormControl();
  drList: DR[] = [];
  showAllRows = false;
  occurrence: Occurrence = new Occurrence();
  currentUser: any;
  role = 0;
  columnsToDisplay: string[];
  mdr: MDR = new MDR;
  uocColumns = ['uoc', 'value', 'observation'];
  uoc: UOC[] = [];
  valueUOC: number;
  sumTotal = 0;
  data: DR[] = [];
  copq: Copq[] = [];
  dataSource = new MatTableDataSource<DR>(this.data);
  attachments: any;
  constructor(public dialog: MatDialog, public mdrService: MdrService,
              public snackBar: MatSnackBar,
              public aRoute: ActivatedRoute, private drService: DrService,
              public drObservable: DrObservable, public mdrObservable: MdrObservable,
              public uploadService: UploadFileService,
      private sanitizer: DomSanitizer) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.columnsToDisplay = this.role !== 1 ? ['id', 'uoc', 'text', 'resolution', 'status'] : ['id', 'uoc', 'text', 'status'];
    this.occurrenceColumns.push('action');
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.drObservable.currentDR.subscribe(dr => {
        this.drList = dr;
      });
      this.getListOccurrence();
    });
    this.mdrService.getListCoPQ().subscribe( copq => {
      this.copq = copq;
    });
    this.mdrService.getOccurrenceEnum().subscribe((data) => {
      this.uoc = data.map((e) => {
        const u = new UOC();
        u.id = e.id;
        u.name = e.occurrence;
        return u;
      });
    });
  }
  ngOnInit() {
  }
  createOccurrence() {
    this.occurrence.idMDR = this.mdr.id;
    // console.log(this.occurrence);
    this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
    if (['Status', 'Descarte de Embalagem'].includes(this.occurrence.uoc)) { this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED; } // gambiarra, remover, corrigir TODO DEBUG; kkk remover, corrigir kkk
    this.mdrService.createOccurrence(this.occurrence).subscribe((response) => {
      if (response !== undefined) {
        // console.log(this.occurrence);
        if (this.occurrence.uoc === 'COVID-19') {
          // console.log(this.drList);
          this.mdrService.updateCoronaVirus(this.drList).subscribe(el => {
          });
        }
        this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  showAll(elem: any) {
    this.showAllRows = !this.showAllRows;
    if (this.showAllRows) {
      this.uploadService.findByEntity(elem.id, 'occurrence').subscribe( (ans) => {
        elem.file = (ans.length === 0) ? undefined : ans;
      });
    }
  }

  getListOccurrence() {
    this.mdrService.getOccurences(this.mdr).subscribe((data: Occurrence[]) => {
      if (this.role === 1 || this.role === 14 || this.role === 77) {
        data = data.filter(occurrence => occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL ||
          occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT ||
          occurrence.status === OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED);
      }
      this.dataOccurrence = new MatTableDataSource<Occurrence>(data);
    });
  }

  approveOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_ACCEPT;
    // console.log(occurrence);

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      // console.log("Aqui é dentro");
      // console.log(occurrence);
      // console.log(response);
      const uoc = new UOCMDR();
      uoc.idMDR = this.mdr.id;
      uoc.value = parseFloat(occurrence.resolution) * 100;
      uoc.uoc = occurrence.uoc;
      uoc.needsAttachment = true;
      uoc.idOccurrence = occurrence.id;
      // console.log(uoc);

      this.mdrService.createUOC(uoc).subscribe((responseUOC) => {
        const occ = new Occurrence();
        occ.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        occ.idMDR = this.mdr.id;
        occ.uoc = 'Agendamento';
        occ.text = (occurrence.uoc === 'Balsa') ? 'Custo de Balsa aprovado' : 'Custo Extra Aprovado via sistema';
        occ.createdAt = new Date();
        this.mdrService.createOccurrence(occ).subscribe(elem => {
        });
        // atualiza lista de UOCs da MDR
        // this.mdrService.getUOC(this.data.mdr).subscribe((uocmdr) => {
        //   this.uocObservable.changeUOC(uocmdr);
        // });
      });
      if (response !== undefined) {
        this.snackBar.open('Custo Aprovado com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }

      for (let i = 0; i < this.myControl.value.length; i++) {
        for (let j = 0; j < this.drList.length; j++) {
          if (this.drList[j].id === this.myControl.value[i]) {
            uoc.drList.push(this.drList[j]);
          }
        }
      }
      // cria a UOC
    });
  }

  receiveFile($event) {
    this.getListOccurrence();
  }

  resolveOccurrence(occurrence: Occurrence) {
    // occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;

    this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.getListOccurrence();
      }
    });
  }

  removeOccurrence(j: any) {
    this.mdrService.deleteOccurrence(j).subscribe((data) => {
      this.getListOccurrence();
    });
  }

  isArray(val) {return typeof val === 'object'; }
  getSafeURL(aquivai: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + aquivai.fileName);
  }
  getUOCValue() {
    this.mdrService.getUOCValue(this.occurrence, this.mdr).subscribe( (data) => {
      this.occurrence.value = data;
    });
  }

  editOccurrence(occurrence?) {
    const dialogRef = this.dialog.open(EditOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
      if (response !== undefined) {
        occurrence.resolution = response.resolution;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL;
        this.mdrService.sendApproveCostEmail(this.mdr, []).subscribe(k => {
          // console.log(k);
          // console.log('Email enviado de aprovação');
        });
        this.resolveOccurrence(occurrence);
      }
    });
  }

  getHistory(occurrence: Occurrence): void {
    const dialogRef = this.dialog.open(OccurrenceHistoryDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
      if (response !== undefined) {
        // console.log(response);
        // occurrence.resolution = response;
        // this.resolveOccurrence(occurrence);
      }
    });
  }

  openDialog(occurrence: Occurrence): void {
    const dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
      if (response !== undefined) {
        occurrence.resolution = response;
        this.resolveOccurrence(occurrence);
      }
    });
  }

  rejectOccurrence(occurrence: Occurrence) {
    occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_COST_APPROVAL_DENIED;
    const dialogRef = this.dialog.open(EditOccurrenceDialogComponent, {
      width: '600px',
      data: {
        text: '',
        mdr: this.mdr,
        copq: this.copq,
        drList: this.drList,
        occ: occurrence,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe((users) => {
        if (users !== null) {
          if (users.length > 0) {
            const dialogRef2 = this.dialog.open(LSPEmailDialogComponent, {
              data: {
                users: users.filter( (e: { role: number; }) => e.role === 14),
                existeNfVermelha: null,
              }
            });

            dialogRef2.afterClosed().subscribe(result => {
              if (result[0]) {
                const selectedUsers: User[] = result[1].value;
                if (selectedUsers !== null) {
                  this.mdrService.sendReproveEmail(this.mdr, selectedUsers).subscribe();
                }
                // console.log('Envio de email');
              }
            });
          }
        }
      });
      // console.log(response);
      // if (response !== undefined) {
      //   occurrence.resolution = response;
      //   this.resolveOccurrence(occurrence);
      // }
    });


    // this.mdrService.updateOccurrence(occurrence).subscribe((response) => {
    //   if (response !== undefined) {
    //     this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
    //       duration: 4000,
    //     });
    //     this.getListOccurrence();
    //   }
    // });
  }

}

@Component({
  selector: 'app-accept-occurrence-dialog',
  templateUrl: 'accept-occurrence-dialog.html',
  styleUrls: ['./cost-approval.component.scss']
})
export class AcceptOccurrenceDialogComponent {
  isUOC = false;
  isCOPQ = false;
  copqSelected: any;
  uocSelected: any;
  valueUOC: any;
  mdr: any;
  totalValue = 0;
  uoc: UOC[];
  uocMult = 1;
  copqFinalValue = 0;
  copqMult = 1;
  valueCOPQ = 0;
  copq: Copq[];
  drList: DR[];
  myControl = new FormControl();
  occ: any;

  constructor(
    public dialogRef: MatDialogRef <AcceptOccurrenceDialogComponent>,
    public mdrService: MdrService,
    public uocObservable: UOCObservable,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    // pega UOCs possíveis
    this.mdrService.getListUOC().subscribe((response) => {
      this.uoc = response;
    });
    this.data.needsAttachment = true;
    this.copq = data.copq;
    this.drList = data.drList;
    this.occ = data.occ;
    this.uocSelected = this.occ.uoc;
  }

  onYesClick(): void {
    // se é UOC
    if (this.isUOC === true) {
      if (this.myControl.value === null) {
        this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
          duration: 4000,
        });
        return;
      }
      // nao permite criar UOC com value 0
      if ( (this.valueUOC * this.uocMult) === 0 || isNaN(this.valueUOC * this.uocMult) ) {
        this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
          duration: 4000,
        });
        return;
      }
      const uoc = new UOCMDR();
      uoc.idMDR = this.data.mdr.id;
      uoc.value = this.valueUOC * this.uocMult * 100;
      uoc.uoc = this.uocSelected;
      uoc.needsAttachment = this.data.needsAttachment;
      uoc.idOccurrence = this.occ.id;
      for (let i = 0; i < this.myControl.value.length; i++) {
        for (let j = 0; j < this.drList.length; j++) {
          if (this.drList[j].id === this.myControl.value[i]) {
            uoc.drList.push(this.drList[j]);
          }
        }
      }
      // cria a UOC
      this.mdrService.createUOC(uoc).subscribe((responseUOC) => {
        // atualiza lista de UOCs da MDR
        this.mdrService.getUOC(this.data.mdr).subscribe((uocmdr) => {
          this.uocObservable.changeUOC(uocmdr);
        });
        // se tambem é CoPQ
        if (this.isCOPQ === true) {
          const copq = new COPQMDR();
          copq.idMDR = this.data.mdr.id;
          copq.value = this.valueCOPQ * this.copqMult * 100;
          copq.copq = this.copqSelected;
          if (this.copqSelected === 'Falta de Acesso COVID - 19') {
            this.mdrService.updateCoronaVirus(uoc.drList).subscribe(el => {
              // console.log(el);
            });
          }
          this.mdrService.createCoPQ(copq).subscribe((responseCOPQ) => {
            // fecha dialog
            this.dialogRef.close(this.data.text);
          });
        } else { // nao é CoPQ
          // fecha dialog
          this.dialogRef.close(this.data.text);
        }
      });
    } else { // nao é UOC
      // fecha dialog
      this.dialogRef.close(this.data.text);
    }
  }

  getUOCValue(elem) {
    // console.log('teste')
    this.mdrService.getUOCValue(elem, this.data.mdr).subscribe((data) => {
      this.valueUOC = data;
      // console.log(data);
    });
  }

  onNoClick(): void {
    this.data = 0;
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-view-history-component',
  templateUrl: 'history.html',
  styleUrls: ['./cost-approval.component.scss']
})
export class OccurrenceHistoryDialogComponent implements OnInit {
  history = [];
  columns = [
    {value: 'quando', name: 'quando'},
    {value: 'resolution', name: 'Valor'},
    {value: 'text', name: 'Resolução'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public mdrService: MdrService,
  ) {
    this.mdrService.getOccurrenceHistory(this.data.occ.id).subscribe(el => {
      el.forEach(k => {
        k.quando = new Date(k.quando);
      });
      this.history = el;
    });
  }
  ngOnInit() { }

}


@Component({
  selector: 'app-edit-occurrence-dialog',
  templateUrl: 'edit-cost-approval-dialog.html',
  styleUrls: ['./cost-approval.component.scss']
})
export class EditOccurrenceDialogComponent {
  isUOC = false;
  isCOPQ = false;
  copqSelected: any;
  uocSelected: any;
  valueUOC: any;
  mdr: any;
  totalValue = 0;
  uoc: UOC[];
  uocMult = 1;
  copqFinalValue = 0;
  copqMult = 1;
  valueCOPQ = 0;
  copq: Copq[];
  drList: DR[];
  myControl = new FormControl();
  occ: any;

  constructor(
    public dialogRef: MatDialogRef <EditOccurrenceDialogComponent>,
    public mdrService: MdrService,
    public uocObservable: UOCObservable,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    // pega UOCs possíveis
    this.mdrService.getListUOC().subscribe((response) => {
      this.uoc = response;
    });
    this.data.needsAttachment = true;
    this.copq = data.copq;
    this.drList = data.drList;
    this.occ = data.occ;
    this.uocSelected = this.occ.uoc;
  }

  onYesClick(): void {
    this.occ.obs = 'Aqui Mudou';
    this.mdrService.editOccurrence(this.occ).subscribe((data) => {
      // console.log(data);
      this.dialogRef.close(data);
      // this.mdrService.sendApproveCostEmail(this.data.mdr, []).subscribe(k => {
      //   console.log(k);
      //   console.log('Email enviado de aprovação');

      // });
    });
  }

  getUOCValue(elem) {
    // console.log('teste')
    this.mdrService.getUOCValue(elem, this.data.mdr).subscribe((data) => {
      this.valueUOC = data;
      // console.log(data);
    });
  }

  onNoClick(): void {
    this.data = 0;
    this.dialogRef.close();
  }

}
