import { OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ClientUserService } from '../../providers/client-user.service';
import { TranslateService } from '@ngx-translate/core';
import { Occurrence } from '../../models/occurrence';
var TowerControlComponent = /** @class */ (function () {
    function TowerControlComponent(translate, dialog, mapsAPILoader, clientService, drService, router, snackBar, dpipe) {
        this.translate = translate;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.clientService = clientService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.data = null;
        this.cachedData = null;
        this.allowCacheUpdate = false;
        this.icons = {
            'undefined': 'help',
            'red': 'error',
            'yellow': 'warning',
            'green': 'check_circle',
            'covid': 'notes-medical',
        };
        this.columns = [
            { value: 'client', name: 'CONTROL_TOWER/table/client' },
            { value: 'pickupID', name: 'CONTROL_TOWER/table/mdr' },
            { value: 'icon', name: 'Status Card', icon: 'icon', tooltip: 'tooltip', color: this.styleIcon },
            { value: 'mdrShippingCompany', name: 'lsp' },
            { value: 'site', name: 'CONTROL_TOWER/table/site' },
            { value: 'infraType', name: 'CONTROL_TOWER/table/infra' },
            { value: 'ops', name: 'CONTROL_TOWER/table/specialOps' },
            { value: 'salesOrders', name: 'CONTROL_TOWER/table/salesOrders' },
            { value: 'dateScheduling', name: 'CONTROL_TOWER/table/date' },
            { value: 'status', name: 'status' },
            { value: 'occurrence', name: 'CONTROL_TOWER/table/occurrence' },
            { value: 'covid', name: 'covid-19' },
            { value: 'city', name: 'CONTROL_TOWER/table/city' },
            { value: 'uf', name: 'CONTROL_TOWER/table/uf' },
            { value: 'copqSum', name: 'CONTROL_TOWER/table/copq', currency: 'BRL' },
        ];
        this.filterDates = {
            start: null,
            end: null,
        };
        this.subscriptions = [];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.filterDates.start = new Date();
        this.filterDates.start.setDate(this.filterDates.start.getDate() - 5);
        this.filterDates.end = new Date();
    }
    TowerControlComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    TowerControlComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    TowerControlComponent.prototype.getData = function (rangeDates) {
        var _this = this;
        if (rangeDates === void 0) { rangeDates = null; }
        var _a;
        var body = rangeDates && rangeDates.length >= 2 ? rangeDates : [];
        this.subscriptions.push((_a = this.drService).getTower.apply(_a, body).subscribe(function (data) {
            var objs = [];
            if (!data) {
                data = [];
            }
            data.map(function (k) {
                var y = {};
                // gera campos
                if (!(k.arrivalTime) && k.status === 'InTransit') {
                    y['status'] = 'UNLOADING_PROCESS';
                }
                else {
                    y['status'] = k.status;
                }
                y['covid'] = k.unificationId ? 'covid' : '';
                y['salesOrders'] = k.salesOrders ? k.salesOrders.join(' \n') : '';
                y['mdrID'] = (k.mdr && k.mdr.id) ? k.mdr.id : '';
                y['pickupID'] = (k.mdr && k.mdr.pickupID) ? k.mdr.pickupID : '';
                y['romaneio'] = k.pickupID ? k.pickupID : ('EDB-TP' + ('0000000' + k.mdrID).slice(-6));
                y['mdrShippingCompany'] = (k.mdr && k.mdr.shippingCompany) ? k.mdr.shippingCompany : '';
                y['city'] = (k.city) ? k.city : '';
                y['site'] = (k.site) ? k.site : '';
                y['uf'] = (k.uf) ? k.uf : '';
                y['ops'] = (k.thirdPartInvoice) ? 'Operação Especial' : '';
                y['dateScheduling'] = (k.dateScheduling) ? new Date(k.dateScheduling) : '';
                y['infraType'] = (k.infraType) ? k.infraType : '';
                y['client'] = (k.client) ? k.client : '';
                y['occurrence'] = '';
                y['occurrenceAll'] = '';
                if (k.occ !== null && k.occ.length > 0) {
                    var sortedDates = k.occ.sort(function (a, b) { return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(); });
                    // Add to field, most recent on top
                    for (var i = 0; i < k.occ.length; i++) {
                        y['occurrenceAll'] += '[' + _this.dpipe.transform(sortedDates[i].createdAt, 'dd/MM/yyyy hh:mm') + '] ' + sortedDates[i].text + '\n';
                    }
                    // Add most recent to occurrence
                    y['occurrence'] += '[' + _this.dpipe.transform(sortedDates[0].createdAt, 'dd/MM/yyyy hh:mm') + '] ' + sortedDates[0].text + '\n';
                }
                y['sumUOC'] = 0;
                if (k.mdr.uoc.length) {
                    for (var i = 0; i < k.mdr.uoc.length; i++) {
                        y['sumUOC'] += k.mdr.uoc[i].value / 100;
                    }
                }
                if (k.status === null) {
                    y['status'] = 'Sem Status';
                }
                else {
                    y['status'] = _this.translate.instant(y['status']);
                }
                y['copqSum'] = 0;
                if (k['copq'] !== undefined) {
                    for (var i = 0; i < k['copq'].length; i++) {
                        y['copqSum'] += k['copq'][i].value;
                    }
                    y['copqSum'] = y['copqSum'] / 100;
                }
                y['frete'] = k.mdr.frete ? parseFloat(k.mdr.frete.total) : 0;
                if (y['frete'] + y['sumUOC'] > 0) {
                    y['percentual'] = y['copqSum'] / (y['frete'] + y['sumUOC']);
                }
                else {
                    y['percentual'] = 0;
                }
                var info = _this.getInfo(k);
                y['icon'] = _this.icons[info.color];
                y['tooltip'] = info.tooltip.join(', ');
                objs.push(y);
            });
            // setta a table
            _this.data = objs;
            if (rangeDates) {
                _this.snackBar.open('Novos dados carregandos com sucesso', 'Ok', {
                    duration: 4000,
                });
            }
        }));
    };
    TowerControlComponent.prototype.updateFromCachedData = function (data) {
        if (data === void 0) { data = null; }
        this.snackBar.open('Carregando novos dados, por favor aguarde um instante', 'Ok', {
            duration: 4000,
        });
        this.getData(data);
    };
    TowerControlComponent.prototype.getInfo = function (dr) {
        var tooltip = [];
        var color = 'green';
        var hora = 1000 * 60 * 60;
        var now = Date.now();
        // se está aguardando atualizações da LSP
        if (dr.status === 'Waiting LSP') {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 1 * hora) {
                    // se a última atualização ocorreu a menos de 1h
                    color = 'green';
                }
                else if (now - d.getTime() < 2 * hora) {
                    // se a última atualização ocorreu a menos de 2h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 1h');
                }
                else {
                    // se a última atualização ocorreu a mais de 2h
                    color = 'red';
                    tooltip.push('última atualização a mais de 2h');
                }
            }
        }
        // se está pronto para coleta
        if (dr.status === 'READY_TO_PICKUP') {
            if (dr.mdr.pickupDate === null) {
                color = 'undefined';
                tooltip.push('data de coleta ausente');
            }
            else {
                var d = new Date(dr.mdr.pickupDate);
                if (d.getTime() - now > 1 * hora) {
                    // mais de 1h até a hora de coleta
                    color = 'green';
                }
                else if (d.getTime() - now > 0.5 * hora) {
                    // mais de 30min até a hora de coleta
                    color = 'yellow';
                    tooltip.push('menos de 1h até a hora de coleta');
                }
                else {
                    // menos de 30min até a hora de coleta
                    color = 'red';
                    tooltip.push('menos de 30 minutos até a hora de coleta');
                }
            }
        }
        // se já foi entregue
        if (dr.status === 'Delivered') {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 6 * hora) {
                    // se a última atualização ocorreu a menos de 6h
                    color = 'green';
                }
                else if (now - d.getTime() < 12 * hora) {
                    // se a última atualização ocorreu a menos de 12h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 6h');
                }
                else {
                    // se a última atualização ocorreu a mais de 12h
                    color = 'red';
                    tooltip.push('última atualização a mais de 12h');
                }
            }
        }
        if (['HubReturn',
            'Replan - Retido',
            'Replan - Sefaz',
            'Replan - Antecipação',
            'Replan - Postergação',
            'Replan - Pernoite'].indexOf(dr.status) !== -1) {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 5 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 5d
                    color = 'green';
                }
                else if (now - d.getTime() < 10 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 10d
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 5 dias');
                }
                else {
                    // se a última atualização ocorreu a mais de 10d
                    color = 'red';
                    tooltip.push('última atualização a mais de 10 dias');
                }
            }
        }
        // verifica as ocorrencias da MDR
        var occStatus = 'SENT_TO_LSP';
        if (this.role === 1) {
            occStatus = 'OCCURRENCE_TO_RESOLVE';
        }
        if ('occurrences' in dr && dr.occurrences.length > 0) {
            var occurrencesNotSolved = [];
            for (var i = 0; i < dr.occurrences.length; i++) {
                if (dr.occurrences[i].status === occStatus) {
                    occurrencesNotSolved.push(dr.occurrences[i]);
                }
            }
            if (occurrencesNotSolved.length > 0) {
                var s = ((occurrencesNotSolved.length === 1) ? '' : 's');
                if (occurrencesNotSolved.some(function (el) {
                    var d = new Date(el.createdAt);
                    return (now - d.getTime() > 2 * hora);
                })) {
                    // se alguma ocorrencia foi criada mais de 2h atrás
                    color = 'red';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
                else {
                    // se alguma ocorrencia foi criada, e todas menos de 2h atrás
                    color = (color === 'red') ? color : 'yellow';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
            }
        } // fim verificação ocorrencias
        return {
            color: color,
            tooltip: tooltip
        };
    };
    TowerControlComponent.prototype.getTowerControl = function () {
        this.subscriptions.push(this.drService.getTower().subscribe(function (elem) {
            // console.log(elem);
        }));
    };
    TowerControlComponent.prototype.onRowClick = function (row, that) {
        that.openDialog(row);
    };
    TowerControlComponent.prototype.goToMDR = function (k) {
        // TODO adicionar isso no expandable-row
        this.router.navigate(['logistic-order/' + k.mdr.id]);
    };
    TowerControlComponent.prototype.styleIcon = function (row, that) {
        // colore a celula de acordo com o icon
        for (var _i = 0, _a = Object.keys(that.icons); _i < _a.length; _i++) {
            var key = _a[_i];
            if (that.icons[key] === row.icon) {
                return key;
            }
        }
    };
    TowerControlComponent.prototype.openDialog = function (row) {
        var _this = this;
        var dialogRef = this.dialog.open(CreateOccurrenceStatusDialogComponent, {
            data: row
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (result) {
            // console.log(result);
            _this.getData();
        }));
    };
    return TowerControlComponent;
}());
export { TowerControlComponent };
var CreateOccurrenceStatusDialogComponent = /** @class */ (function () {
    function CreateOccurrenceStatusDialogComponent(mdrService, snackBar, dialogRef, data) {
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.dialogRef = dialogRef;
        this.data = data;
        this.occurrence = new Occurrence();
        this.subscriptions = [];
    }
    CreateOccurrenceStatusDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    CreateOccurrenceStatusDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    CreateOccurrenceStatusDialogComponent.prototype.onClickSave = function () {
        var _this = this;
        this.occurrence.idMDR = this.data.mdrID;
        this.occurrence.status = 'OCCURRENCE_RESOLVED';
        this.occurrence.uoc = 'Status';
        this.subscriptions.push(this.mdrService.createOccurrence(this.occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
                    duration: 4000,
                });
            }
        }));
        this.dialogRef.close({ what: 'update', data: this.data });
    };
    return CreateOccurrenceStatusDialogComponent;
}());
export { CreateOccurrenceStatusDialogComponent };
