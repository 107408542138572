export var TYPE_EXPEDITION;
(function (TYPE_EXPEDITION) {
    TYPE_EXPEDITION["PICTURE_1"] = "MDR lateral Esquerda";
    TYPE_EXPEDITION["PICTURE_2"] = "MDR traseira";
    TYPE_EXPEDITION["PICTURE_3"] = "MDR frontal";
    TYPE_EXPEDITION["PICTURE_4"] = "MDR lateral ";
    TYPE_EXPEDITION["PICTURE_5"] = "MDR carregado a\u00E9reo";
    TYPE_EXPEDITION["PICTURE_6"] = "MDR carregado frontal";
    TYPE_EXPEDITION["PICTURE_7"] = "MDR carregado placa";
})(TYPE_EXPEDITION || (TYPE_EXPEDITION = {}));
var Message = /** @class */ (function () {
    function Message(user, content, channel, createdAt, chatType) {
        this.user = user;
        this.content = content;
        this.channel = channel;
        this.createdAt = createdAt;
        this.chatType = chatType;
    }
    Message.prototype.getUser = function () {
        return this.user;
    };
    Message.prototype.getContent = function () {
        return this.content;
    };
    return Message;
}());
export { Message };
