import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { ConfigurationService } from './configuration.service';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ReportService = /** @class */ (function () {
    function ReportService(http, configService) {
        this.http = http;
        this.configService = configService;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ReportService.prototype.getReport = function (start, end, report, variables, client) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if (variables) {
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end + '/' + variables.toString(), {
                headers: header,
            });
        }
        else if (client) {
            var params = new HttpParams().set('client', String(client));
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end, {
                headers: header,
                params: params
            });
        }
        else {
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end, {
                headers: header,
            });
        }
    };
    ReportService.prototype.getStakeholdersByDR = function (drs) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders_dr/[' + drs + ']', {
            headers: header,
        });
    };
    return ReportService;
}());
export { ReportService };
