import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { ConfigurationService } from './configuration.service';
import { of } from 'rxjs';
import { stringify } from '@angular/core/src/util';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ReportService {
    public token: string;

    constructor(private http: HttpClient,
        public configService: ConfigurationService) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    getReport(start: Date, end: Date, report: string, variables?: string[], client?: string): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if (variables) {
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end + '/' + variables.toString(),  {
                headers: header,
            });
        } else if (client) {
            const params = new HttpParams().set('client', String(client));
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end ,  {
                headers: header,
                params: params
            });
        } else  {
            return this.http.get(base_url + 'report/' + report + '/' + start + '/' + end,  {
                headers: header,
            });
        }

    }

    getStakeholdersByDR(drs: any[]): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders_dr/[' + drs + ']', {
            headers: header,
        });
    }

}
